import React, { useState } from 'react'

import { useForm, Controller } from 'react-hook-form'
import FormLabel from '../FormLabel/FormLabel'
import Button, { ButtonSize, ButtonVariant } from '../Button'

import Notice, { NoticeVariant } from '../Notice'
import Input from '../Input/Input'
import { language } from '../../common/i18n'
import { Color } from '../../color.enum'

interface Props {
  token: string
  onSubmitSuccess?: () => void
  onSubmitError?: (error: string) => void
}

type FormInputs = {
  password: string
  confirmPassword: string
}

const passwordFieldOptions = {
  required: 'Password is required',
  minLength: { value: 8, message: 'Password must be at least 8 characters' }
}

export const RegistrationForm: React.FC<Props> = ({ token, onSubmitSuccess, onSubmitError }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<any>({})

  const {
    handleSubmit,
    formState: { errors },
    control
  } = useForm<FormInputs>({ mode: 'onChange', reValidateMode: 'onChange' })

  const onRegister = async (data: FormInputs) => {
    setLoading(true)
    console.log(data)
    if (data.password !== data.confirmPassword) {
      setError({
        password: 'Confirmation password does not match.'
      })
      setLoading(false)
      return
    }

    const payload = {
      password: data.password,
      token: token
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API}/invitation/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      })

      if (!response.ok) {
        const responseData = await response.json()
        throw new Error(responseData.error || 'Failed to register')
      }

      if (onSubmitSuccess) onSubmitSuccess()
    } catch (err: any) {
      setError({ form: err.message })
      if (onSubmitError) onSubmitError(err.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <FormLabel className="mb-2">{language.en.auth.register.headline}</FormLabel>
      <form action="#" autoComplete="no" onSubmit={handleSubmit(onRegister)}>
        <Controller
          rules={{ ...passwordFieldOptions }}
          name="password"
          control={control}
          render={({ field }) => (
            <Input as="input" type="password" width="full" {...field} placeholder="Enter new password" />
          )}
        />
        <Controller
          rules={{ ...passwordFieldOptions }}
          name="confirmPassword"
          control={control}
          render={({ field }) => (
            <Input as="input" type="password" width="full" {...field} placeholder="Confirm password" />
          )}
        />
        {errors.password && <p className="text-sm text-red-500 mt-2">{errors.password.message}</p>}
        {errors.confirmPassword && <p className="text-sm text-red-500 mt-2">{errors.confirmPassword.message}</p>}
        {error.password && <p className="text-sm text-red-500 mt-2">{error.password}</p>}
        <div className="py-4">
          <Button
            className="justify-center"
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.MEDIUM}
            color={Color.BLUE}
            style={{ width: '100%' }}
            type="submit"
            disabled={loading}
          >
            {loading ? 'Registering...' : 'Register FiftyFour Account'}
          </Button>

          {error.form && (
            <Notice
              className="mt-2 mb-1 w-full"
              variant={NoticeVariant.SMALL}
              icon="error"
              color={Color.GRAY}
              children={<div className="text-brand-red">{error.form}</div>}
            />
          )}
        </div>
      </form>
    </div>
  )
}
