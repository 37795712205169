import { RouteComponentProps } from '@reach/router'
import UserList from './UsersTable'
import { useQuery, gql } from '@apollo/client'
import Loader from '../../components/Loader/Loader'
import HeadingPage from '../../components/HeadingPage'
import { Color } from '../../color.enum'
import HeadingSection from '../../components/HeadingSection'
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import { Helmet } from 'react-helmet'
import { language } from '../../common/i18n'

const OrgUsers: React.FC<RouteComponentProps> = () => {
  const { loading, error, data, refetch } = useQuery(GET_ALL_ORG_USERS, { fetchPolicy: 'no-cache' })
  const breadCrumbPath = [{ name: 'Users', path: '#', current: true }]
  return (
    <div className="page-container-lg lg:mb-36">
      <BreadCrumbs pages={breadCrumbPath} className="pl-0 pb-10" />
      <header>
        <Helmet>
          <title>{language.en.admin.user.list.title}</title>
          <meta name="description" content={language.en.admin.user.list.description} />
          <meta name="robots" content="noindex" data-react-helmet="true" />
        </Helmet>
        <HeadingPage
          headline={language.en.admin.dashBoard.users.title}
          color={Color.BLUE}
          description={language.en.admin.user.list.description}
        />
      </header>
      <HeadingSection headline={language.en.admin.dashBoard.users.headline} color={Color.BLUE} />

      {loading && <Loader className="h-20 flex flex-col items-center justify-center" />}
      {!loading && !error && (
        <UserList
          items={data?.findOrgUsers?.docs}
          refetch={refetch}
          loading={loading}
          pagination={{
            currentPage: data.findOrgUsers.page,
            totalPages: data.findOrgUsers.totalPages,
            hasNextPage: data.findOrgUsers.hasNextPage,
            hasPrevPage: data.findOrgUsers.hasPrevPage
          }}
        />
      )}
    </div>
  )
}
export default OrgUsers

const GET_ALL_ORG_USERS = gql`
  query findOrgUsers($sort: SortArgs, $pagination: PaginationArgs, $input: QueryUsersInput) {
    findOrgUsers(sort: $sort, pagination: $pagination, input: $input) {
      docs {
        id
        firstName
        lastName
        email
        role
        isSuperAdmin
        isPeer
        isOrgLeader
        organization {
          name
        }
        organizations {
          name
        }
      }
      limit
      totalPages
      page
      hasPrevPage
      hasNextPage
    }
  }
`
