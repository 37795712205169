import React, { useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import { ChartBarIcon, ChartPieIcon } from '@heroicons/react/solid'
import GraphComponent from './GraphComponent'
import GraphFilters from './GraphFilters'
import DataContext, { DataItem } from './DataContext'
import { Color } from '../../color.enum'
import HeadingSection from '../HeadingSection/HeadingSection'
import { ButtonSize, ButtonVariant } from '../Button'
import Button from '../Button/Button'
import StatsComponent from './StatsComponent'
import { PageWrapper } from '../../modules/PageWrapper'
import { PeerDashboard } from '../../pages/Dashboard/Peer'
import { Table } from '@plusplusminus/plusplusdash'
import { navigate } from '@reach/router'
import Loader from '../Loader/Loader'
import { getBadge } from '../../pages/Dashboard/scans/peerScans/PeerScanListItem'

const GET_GRAPH_DATA = gql`
  query peerStats($peerId: String!) {
    peerStatistics(peer_id: $peerId) {
      graph {
        organisation
        factor
        country
        avg
        year
      }
      progress {
        number_organisations
        number_scans
        completed_scans
        count_in_progress
        count_completed
        count
      }
      assignments {
        id
        name
        dueDate
        status
        organisationName
        count_in_progress
        count_completed
        count
      }
    }
    allFactors(surveyId: "524a9732-1a70-4394-a2af-51afc6199ce6") {
      id
      name
      headline
      coreFactor
      position
      factorType {
        name
        code
        color
        indexOrder
      }
    }
  }
`

type GraphDataItem = {
  data: DataItem[]
  type: string
}

const PeerGraphComponent = ({ peerId }: { peerId: string }) => {
  const { loading, error, data } = useQuery(GET_GRAPH_DATA, { variables: { peerId } })
  const [graphData, setGraphData] = useState<GraphDataItem[]>([{ data: [], type: 'radial' }])
  const [graphType, setGraphType] = useState<'bar' | 'radar'>('bar')

  const addGraph = () => {
    setGraphData([...graphData, { data: [], type: 'radial' }])
  }

  const onRowClick = (item: any) => {
    console.log(item)
    if (item.status === 'completed') {
      navigate(`/dashboard/organisation/${item.id}/result`)
    } else {
      navigate(`/dashboard/organisation/edit-scan/${item.id}`)
    }
  }

  if (loading) return <Loader />
  if (error) return <p>Error :(</p>

  return (
    <PageWrapper headline={'Practitioner Dashboard'} sectionHeading="Scans Overview">
      <DataContext.Provider value={data.peerStatistics.graph}>
        <StatsComponent progress={data.peerStatistics.progress} />
        <div className="mb-12">
          <Table
            shape={[
              { label: 'Name', key: 'firstName', isSortable: false },
              { label: 'Organisation', key: 'organization', isSortable: false },
              { label: 'Due Date', key: 'organization', isSortable: false },
              { label: 'Status', key: 'status', isSortable: false },
              { label: 'Respondents', key: 'count', isSortable: false },
              { label: 'Respondents In Progress', key: 'inprogress', isSortable: false },
              { label: 'Respondents Completed', key: 'count_completed', isSortable: false }
            ]}
          >
            {data.peerStatistics.assignments?.map((item: any) => {
              return (
                <Table.Row key={item.id} className="cursor-pointer" onClick={() => onRowClick(item)}>
                  <Table.Cell className="text-gray-900">{item.name}</Table.Cell>
                  <Table.Cell className="text-gray-900">{item.organisationName}</Table.Cell>
                  <Table.Cell className="text-gray-900">{item.dueDate}</Table.Cell>
                  <Table.Cell className="text-gray-900">{getBadge(item.status.toUpperCase())}</Table.Cell>
                  <Table.Cell className="text-gray-900">{item.count}</Table.Cell>
                  <Table.Cell className="text-gray-900">{item.count_in_progress}</Table.Cell>
                  <Table.Cell className="text-gray-900">{item.count_completed}</Table.Cell>
                </Table.Row>
              )
            })}
          </Table>
        </div>
        <HeadingSection headline="Results Overview" color={Color.BLUE} className="my-4">
          {() => (
            <>
              <button
                className="flex items-center space-x-1 mr-4"
                onClick={() => setGraphType(graphType === 'bar' ? 'radar' : 'bar')}
              >
                {graphType === 'bar' ? (
                  <ChartPieIcon className="h-5 w-5 text-white" />
                ) : (
                  <ChartBarIcon className="h-5 w-5 text-white" />
                )}
                <span>Switch to {graphType === 'bar' ? 'Radar' : 'Bar'} Graph</span>
              </button>
              <Button
                className="justify-self-center self-end"
                variant={ButtonVariant.PRIMARY}
                color={Color.PURPLE}
                size={ButtonSize.SMALL}
                onClick={addGraph}
              >
                Add Graph
              </Button>
            </>
          )}
        </HeadingSection>
        <div className="flex flex-wrap my-8">
          {graphData.map((graph, index) => (
            <div key={index} className="w-full sm:w-1/2 ">
              <GraphFilters
                graphIndex={index + 1}
                setGraphType={(type) => {
                  const newGraphData = [...graphData]
                  newGraphData[index].type = type
                  setGraphData(newGraphData)
                }}
                setData={(data) => {
                  const newGraphData = [...graphData]
                  newGraphData[index].data = data
                  setGraphData(newGraphData)
                }}
              />
              <GraphComponent data={graph.data} type={graphType} factors={data.allFactors} id={`graph-${index}`} />
            </div>
          ))}
        </div>
      </DataContext.Provider>
      <PeerDashboard />
    </PageWrapper>
  )
}

export default PeerGraphComponent
