import { useState, useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { RouteComponentProps, useNavigate } from '@reach/router'
import { useMutation, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import client from '../../contentful'
import { LoginForm } from './ui/LoginForm'
import { OtpForm } from './ui/OtpVerification'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import { Context } from '../../context/context'
import { useFormatApolloError } from '../../hooks/useFormatApolloError'
import { language } from '../../common/i18n'
import { LOGIN_CONTENT_QUERY } from '../../graphql/contentful-queries'
import Loader from '../../components/Loader/Loader'
import { LoginMethodSelector } from './ui/LoginMethodSelector'
import { useUserAuthQuery, useUserQuery } from '../../hooks/useUserQuery'

interface LoginProps extends RouteComponentProps {
  redirect?: string
}

export const Login: React.FC<LoginProps> = () => {
  const [, setValue] = useLocalStorage()
  const { loading: userLoading, data: userData } = useUserAuthQuery()
  const [formatError] = useFormatApolloError()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [delayOtp, setDelayOtp] = useState(false)
  const [error, setError] = useState({ message: '', action: '' })
  const [user, setUser] = useState({
    token: ''
  })
  const [step, setStep] = useState('validateEmail')
  const [resendOtpLoading, setResendOtpLoading] = useState<boolean>(false)
  const [resendOtpSuccess, setSuccess] = useState<boolean>(false)
  const [resendOtpResponse, setResponse] = useState<any>({})

  const { contextValue, setContextValue } = useContext(Context)

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)

  const regToken = urlParams.get('token')
  const afterRegStep = urlParams.get('step')
  const redirectTo = urlParams.get('redirectTo')

  const currentStep = afterRegStep ? afterRegStep : step
  const token = regToken ? regToken : user.token

  const [validateEmail] = useMutation(VALIDATE_EMAIL, { fetchPolicy: 'no-cache' })
  const [login] = useMutation(LOGIN, { fetchPolicy: 'no-cache' })
  const [resendOtp] = useMutation(RESEND_OTP)

  const { data, loading: dataLoading } = useQuery(LOGIN_CONTENT_QUERY, { client })

  const { headline, content, image } = data?.contentCollection?.items?.[0] ?? {}

  const onLogin = (otp: string) => {
    if (regToken) {
      setUser({ token: regToken })
    }

    setSuccess(false)

    setLoading(true)
    setError({
      message: '',
      action: ''
    })
    if (!!otp) {
      otp = otp.trim()
    }

    login({
      variables: {
        input: {
          token: token,
          otp: otp
        }
      }
    })
      .then(({ data }) => {
        setLoading(false)
        if (data?.login?.token) {
          setValue('osp-token', data.login.token)
          setContextValue({ ...contextValue, ...JSON.parse(data.login.data) })
          if (redirectTo) {
            navigate(redirectTo)
          } else {
            navigate(data.login.redirect)
          }
        }
      })
      .catch((err) => {
        setLoading(false)
        setError({ message: formatError(err), action: 'no action' })
      })
  }

  const onValidateEmail = (email: string) => {
    setLoading(true)
    setError({
      message: '',
      action: ''
    })

    if (!!email) {
      email = email.toLowerCase().trim()
    }

    validateEmail({
      variables: {
        email
      }
    })
      .then(({ data }) => {
        setLoading(false)
        if (data?.validateEmail?.token) {
          const { token } = data.validateEmail
          setUser({ token: token })
          setStep('otp')
        }
      })
      .catch((err) => {
        setLoading(false)
        setError({ message: formatError(err), action: 'no action' })
      })
  }

  const onResendOtp = () => {
    setResendOtpLoading(true)

    const fingerprint = token ? token : user?.token
    setError({ message: '', action: '' })
    if (!fingerprint) {
      setError({ message: 'Something went wrong. Please try again later.', action: 'no action' })
      return
    }
    resendOtp({
      variables: {
        fingerPrint: fingerprint
      }
    })
      .then(({ data }) => {
        setResendOtpLoading(false)
        setSuccess(true)
        setResponse(data)
        setDelayOtp(true)
        setTimeout(
          () => {
            setDelayOtp(false)
          },
          process.env.REACT_APP_OTP_DELAY ? parseInt(process.env.REACT_APP_OTP_DELAY) : 20000
        )
      })
      .catch(() => {
        setResendOtpLoading(false)

        setError({ message: 'Something went wrong. Please try again later.', action: 'no action' })
      })
  }

  const getWizardComponent = (step: string) => {
    switch (step) {
      case 'validateEmail':
        return (
          <LoginForm
            onSubmit={onValidateEmail}
            loading={loading}
            headline={headline}
            content={content}
            error={error.message}
          />
        )
      case 'otp':
        return (
          <OtpForm
            onSubmit={onLogin}
            loading={loading}
            setUser={setUser}
            setError={setError}
            onResendOtp={onResendOtp}
            resendOtpLoading={resendOtpLoading}
            resendOtpResponse={resendOtpResponse}
            resendOtpSuccess={resendOtpSuccess}
            delayOtp={delayOtp}
            error={error.message}
            headline={headline}
          />
        )

      default:
        return (
          <LoginForm
            onSubmit={onValidateEmail}
            loading={loading}
            error={error.message}
            headline={headline}
            content={content}
          />
        )
    }
  }

  useEffect(() => {
    if (userData) {
      navigate('/dashboard')
    }
  }, [userData])

  if (loading || dataLoading || userLoading) return <Loader />

  return (
    <main>
      <Helmet>
        <title>{language.en.auth.title.login}</title>
        <meta name="description" content={language.en.auth.description.login} />
        <meta name="robots" content="noindex" data-react-helmet="true" />
      </Helmet>
      <div className="flex items-center flex-col py-16 space-y-10 md:flex-row px-0 justify-center sm:py-20 sm:px-3 lg:px-28">
        <div className="w-full sm:pt-10 max-w-lg sm:w-4/6 justify-center items-center">
          <h3 className="text-brand-blue font-normal leading-none text-4xl md:text-5xl mx-auto">
            Welcome to FiftyFour
          </h3>
          <h3 className="text-brand-blue my-5 font-normal mx-auto">
            The 9D Capacity Index will give your management team a balanced view across 9 important dimensions of
            organisational health and provide insight into your performance.
          </h3>
          <h3 className="text-brand-blue my-5 font-normal mx-auto ">{language.en.auth.title.login}</h3>
          <LoginMethodSelector getWizardComponent={getWizardComponent} currentStep={currentStep} />
        </div>
        <div className="w-full max-w-xl sm:w-4/6 justify-center items-center">
          <img src={image?.url} alt="" className="object-contain h-96 w-full" />
        </div>
      </div>
    </main>
  )
}

const VALIDATE_EMAIL = gql`
  mutation validate($email: String!) {
    validateEmail(email: $email) {
      message
      token
    }
  }
`

const LOGIN = gql`
  mutation login($input: LoginInput!) {
    login(input: $input) {
      token
      redirect
      data
    }
  }
`
const RESEND_OTP = gql`
  mutation resendOtp($fingerPrint: String!) {
    resendOtp(fingerPrint: $fingerPrint) {
      message
      status
    }
  }
`
