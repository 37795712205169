import { useMutation, gql } from '@apollo/client'
import { RouteComponentProps, useNavigate } from '@reach/router'
import { Box } from '@plusplusminus/plusplusdash'
import Input from '../../../components/Input/Input'
import FormLabel from '../../../components/FormLabel/FormLabel'
import { useForm, Controller } from 'react-hook-form'
import Button, { ButtonVariant, ButtonSize } from '../../../components/Button'
import { Color } from '../../../color.enum'
import { COUNTRIES } from '../../../common/countries'
import { Select } from '../../../components/Select/Select'

interface Question {
  id: string
  option: Option[]
  Response: any
  text: string
  code: string
  responseType: string
}

interface Option {
  id: string
  code: string
  index: string
  text: string
}

interface DemographicSurvey extends RouteComponentProps {
  questions: Question[]
  demographicSurveyId: string
  assignmentId: string | undefined
  setStep: (step: string) => void
}

export const DemographicForm: React.FC<DemographicSurvey> = (props) => {
  const { questions, assignmentId } = props
  const [submitDemoSurveyResponse, { loading: submitLoader }] = useMutation(SUBMIT_POSTS_SURVEY_RESPONSE)
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({ mode: 'onSubmit', reValidateMode: 'onChange' })
  const onSubmit = (data: any) => {
    if (data) {
      submitDemoSurveyResponse({
        variables: {
          input: {
            assignmentId,
            responses: data
          }
        }
      }).then(() => {
        navigate('/dashboard/organisation')
      })
    }
  }

  const generateArrayOfYears = () => {
    const max = new Date().getFullYear()
    const min = max - 99
    const years = []

    for (let i = max; i >= min; i--) {
      years.push({ id: i.toString(), name: i.toString() })
    }
    return years
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {questions?.map((question: any, index: number) => {
        if (question.responseType === 'country') {
          return (
            <Box className="col-span-2 sm:col-span-2 mt-8" key={question.id}>
              <FormLabel>{`${index + 1}. ${question.text}`}</FormLabel>
              <select
                className="w-full py-3 px-3 text-base text-brand-blue border border-gray-500 rounded-sm focus:border-opacity-20 placeholder-gray-600 placeholder-opacity-75 transition-all"
                {...register(question.code, { required: 'Response required' })}
              >
                <option value="" disabled selected>
                  Please Select
                </option>
                {COUNTRIES.map((c) => {
                  return (
                    <option value={c} key={c}>
                      {c}
                    </option>
                  )
                })}
              </select>
              {errors[question.code]?.message && (
                <p className="text-sm my-2 text-red-500">{errors[question.code]?.message}</p>
              )}
            </Box>
          )
        }

        if (question.responseType === 'custom') {
          return (
            <div>
              <Box className="col-span-2 sm:col-span-2 mt-8">
                <FormLabel>{`${index + 1}. ${question.text}`}</FormLabel>
                <Controller
                  name={question.code}
                  control={control}
                  render={({ field }) => <Input as="input" width="full" {...field} />}
                  rules={{ required: 'Response required' }}
                />
              </Box>
              {errors[question.code]?.message && (
                <p className="text-sm my-2 text-red-500">{errors[question.code]?.message}</p>
              )}
            </div>
          )
        }

        if (question.responseType === 'year') {
          return (
            <div className="col-span-2 sm:col-span-2 mt-8" key={question.id}>
              <FormLabel>{`${index + 1}. ${question.text}`}</FormLabel>
              <Select
                items={generateArrayOfYears()}
                name={question.code}
                register={register}
                validations={{ required: 'Response Required' }}
              />
              {errors[question.code]?.message && (
                <p className="text-sm my-2 text-red-500">{errors[question.code]?.message}</p>
              )}
            </div>
          )
        }

        return (
          <div className="flex flex-col mt-8" key={question.id}>
            <FormLabel>{`${index + 1}. ${question.text}`}</FormLabel>
            {question.postSurveyOption.map((option: any) => {
              if (option.archived) return null
              return (
                <div className="flex flex-row items-start mb-2" key={option.code}>
                  <input
                    className="mt-1.5 mr-2 text-brand-blue focus:ring-brand-blue"
                    id={option.id}
                    type="radio"
                    value={option.id}
                    {...register(question.code, { required: 'Response required' })}
                  />
                  <label className="leading-tight mt-1 text-brand-blue" htmlFor={option.id}>
                    {option.text}
                  </label>
                </div>
              )
            })}
            {errors[question.code]?.message && (
              <p className="text-sm mb-2 text-red-500">{errors[question.code]?.message}</p>
            )}
          </div>
        )
      })}
      <Button
        className="mt-5"
        variant={ButtonVariant.PRIMARY}
        color={Color.BLUE}
        size={ButtonSize.LARGE}
        iconRight="arrowRight"
        type="submit"
      >
        {submitLoader ? 'Loading' : 'Create assessment'}
      </Button>
    </form>
  )
}

const SUBMIT_POSTS_SURVEY_RESPONSE = gql`
  mutation submitPostSurveyResponses($input: CreateQuestionnaireResponseInput!) {
    submitPostSurveyResponses(input: $input)
  }
`
