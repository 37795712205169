import { RouteComponentProps, useNavigate, useParams } from '@reach/router'
import { useQuery, gql, useMutation } from '@apollo/client'
import Loader from '../../../components/Loader/Loader'
import { Color } from '../../../color.enum'
import { formatDate } from '../../../services/formatDate'
import { language } from '../../../common/i18n'
import Button, { ButtonSize, ButtonVariant } from '../../../components/Button'
import { User } from '../../../common/types'
import { PageWrapper } from '../../../modules/PageWrapper'
import { Table } from '@plusplusminus/plusplusdash'
import { getBadge } from '../scans/peerScans/PeerScanListItem'
import React from 'react'

interface SingleScanProps extends RouteComponentProps {
  user?: User
}

const SingleScanView: React.FC<SingleScanProps> = (props) => {
  const [message, setMessage] = React.useState('')
  const { id } = useParams()
  const { loading, data } = useQuery(GET_A_SCAN, { variables: { id }, fetchPolicy: 'no-cache' })

  const navigate = useNavigate()

  if (loading) return <Loader />
  return (
    <PageWrapper
      metaDesc={language.en.admin.user.singleUserView.title}
      metaTitle={language.en.admin.user.singleUserView.description}
      headline={data?.assignment?.name}
      sectionHeading=""
      description={'See assessment details below'}
      renderCta={() => {
        return (
          <div className="flex items-center">
            <Button
              iconRight="plus"
              className="justify-center"
              variant={ButtonVariant.PRIMARY}
              size={ButtonSize.SMALL}
              color={Color.BLUE}
              style={{ width: '100%' }}
              type="submit"
              onClick={() => {
                navigate(`/dashboard/organisation/edit-scan/${id}`)
              }}
            >
              Edit Assessment
            </Button>
          </div>
        )
      }}
    >
      <div className="mt-5  space-x-4 flex my-4">
        <div>
          <span className="inline text-brand-blue font-medium">Created on:</span>
          <span className="ml-2 text-brand-blue">{formatDate(data.assignment.createdAt)}</span>
        </div>
        <div>
          <p className="inline text-brand-blue font-medium">Due Date:</p>
          <span className="ml-2 text-brand-blue">{formatDate(data.assignment.dueDate)} </span>
        </div>
        <div>
          <p className="inline text-brand-blue font-medium">Status:</p>
          <span className="ml-2 text-brand-blue">{data.assignment.status}</span>
        </div>
        <div>
          <p className="inline text-brand-blue font-medium">Respondents :</p>
          <span className="ml-2 text-brand-blue">{data.assignment.respondentCount}</span>
        </div>
      </div>

      {message && (
        <div
          className="p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400"
          role="alert"
        >
          {message}
        </div>
      )}

      <Table
        shape={[
          { label: 'Name', key: 'firstName', isSortable: false },
          { label: 'Email', key: 'email', isSortable: false },
          { label: 'Status', key: 'status', isSortable: false },
          { label: 'Invitation', key: 'isInvitationSent', isSortable: false }
        ]}
      >
        {data.respondents?.map((item: any) => {
          return (
            <Table.Row key={item.id} className="cursor-pointer">
              <Table.Cell className="text-gray-900">{`${item.user.firstName} ${item.user.lastName}`}</Table.Cell>
              <Table.Cell className="text-gray-900">{item.user.email}</Table.Cell>
              <Table.Cell className="text-gray-900">{getBadge(item.surveyStatus.toUpperCase())}</Table.Cell>
              <Table.Cell className="text-gray-900">
                <ResendRespondentInvitataion
                  respondentId={item.id}
                  assignmentId={id}
                  onComplete={(msg) => setMessage(msg)}
                />
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table>
    </PageWrapper>
  )
}

export default SingleScanView

const ResendRespondentInvitataion: React.FC<{
  respondentId: string
  assignmentId: string
  onComplete?: (message: string) => void
}> = ({ respondentId, assignmentId, onComplete }) => {
  const [resendInvitation, { loading }] = useMutation(RESEND_INVITATION, {
    onCompleted: () => {
      if (onComplete) {
        onComplete('Invitation has been resent')
      }
    },
    onError: (error) => {
      if (onComplete) {
        onComplete('Error sending invitation. Please try again.')
      }
    }
  })

  return (
    <Button
      onClick={() => {
        resendInvitation({ variables: { respondentId, assignmentId } })
      }}
      variant={ButtonVariant.PRIMARY}
      size={ButtonSize.SMALL}
      color={Color.BLUE}
      disabled={loading}
    >
      {!loading ? 'Resend Invitation' : 'Resending Invitation'}
    </Button>
  )
}

const RESEND_INVITATION = gql`
  mutation ResendInvitation($respondentId: String!, $assignmentId: String!) {
    resendInvitation(respondentId: $respondentId, assignmentId: $assignmentId)
  }
`

const GET_A_SCAN = gql`
  query SingleAssignment($id: String!) {
    assignment(id: $id) {
      id
      name
      createdAt
      dueDate
      preSurveyStatus
      respondentCount
      status
    }
    respondents(assignmentId: $id) {
      id
      isInvitationSent
      surveyStatus
      completedDate
      user {
        id
        firstName
        lastName
        email
      }
    }
  }
`
