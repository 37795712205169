import { api } from './BreadCrumbs.api'
import Icon from '../Icon/Icon'
import { Link } from '@reach/router'
import { classNames } from '../../utils/class-names'
import { useLocation } from '@reach/router'

export interface Page {
  name: string
  path: string
  current: boolean
}
interface BreadCrumbsProps {
  pages: Array<Page>
  // Aditional styles for the container
  className?: string
}

const BreadCrumbs: React.FC<BreadCrumbsProps> = ({ pages, className }) => {
  const { pathname } = useLocation()

  const getHomeUrl = () => {
    const urlMap: any = {
      organisation: '/dashboard',
      admin: '/dashboard',
      peer: '/dashboard',
      respondent: '/dashboard'
    }

    const url = urlMap[Object.keys(urlMap).filter((key: any) => pathname.indexOf(key) > 0)[0]] ?? '/'
    return url
  }

  return (
    <nav className={classNames(className, `flex flex-col sm:flex-row`)} aria-label="Breadcrumb">
      <ol role="list" className="flex flex-wrap items-center space-x-4 sm:space-x-0 sm:ml-4">
        <li>
          <div>
            <Link to={getHomeUrl()} className="text-gray-400 hover:opacity-80">
              <Icon
                className="flex-shrink-0 h-5 w-5 font-bold hover:opacity-80 text-brand-blue-dark"
                name="homeLgAlt"
                aria-hidden="true"
              />
              <span className="sr-only font-bold text-brand-blue-dark">Home</span>
            </Link>
          </div>
        </li>
        {pages.map((page) => (
          <li key={page.name}>
            <div className="flex items-center">
              <Icon
                className="flex-shrink-0 h-5 w-5 font-bold text-brand-blue-dark sm:block"
                name="chevronRight"
                aria-hidden="true"
              />
              <Link
                to={page.path}
                className="ml-4 text-sm font-bold text-brand-blue-dark hover:opacity-80"
                aria-current={page.current ? 'page' : undefined}
              >
                {page.name}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  )
}

BreadCrumbs.defaultProps = {
  pages: api,
  className: 'pl-20'
}
export default BreadCrumbs
