import { RouteComponentProps, useNavigate, useParams, useLocation } from '@reach/router'
import { Color } from '../../../../color.enum'
import { gql, useMutation } from '@apollo/client'
import { CreateQuestionOptionForm } from './createPreSurveyQuestionForm'
import HeadingPageCenter from '../../../../components/HeadingPageCenter'
import { Helmet } from 'react-helmet'
import { language } from '../../../../common/i18n'

interface CreatePostSurveyQuestionOptionInterface extends RouteComponentProps<any> {
  location: any
}

const CreatePostSurveyQuestionOption: React.FC<CreatePostSurveyQuestionOptionInterface> = () => {
  const navigate = useNavigate()
  const { postSurveyId, questionId, surveyId, optionId } = useParams()
  const { pathname } = useLocation()

  const [createPostSurveyQuestionOption, { loading: createPostSurveyQuestionLoading }] = useMutation(
    CREATE_POST_SURVEY_QUESTION_OPTION,
    {
      onError: (error) => {
        console.log(error)
      },
      onCompleted: (data) => {
        navigate(
          `/dashboard/admin/survey/${surveyId}/postSurvey/${postSurveyId}/question/${questionId}/option/${data?.createPostSurveyQuestionOption?.id}`
        )
      }
    }
  )

  const [updatePostSurveyQuestionOption, { loading: updateLoading }] = useMutation(UPDATE_POST_SURVEY_QUESTION_OPTION, {
    onError: (error) => {
      console.log(error)
    },
    onCompleted: () => {
      navigate(
        `/dashboard/admin/survey/${surveyId}/postSurvey/${postSurveyId}/question/${questionId}/option/${optionId}`
      )
    }
  })

  const onSubmit = (data: any, type: string) => {
    if (type === 'create') {
      createPostSurveyQuestionOption({
        variables: {
          input: { ...data, postSurveyQuestionId: questionId }
        }
      })
    } else {
      updatePostSurveyQuestionOption({
        variables: {
          id: optionId,
          input: {
            index: data.index,
            text: data.text,
            code: data.code,
            postSurveyQuestionId: questionId
          }
        }
      })
    }
  }

  const type = pathname.endsWith('edit') ? 'edit' : 'create'

  return (
    <div className="page-container-lg">
      <Helmet>
        <title>{language.en.admin.option.title}</title>
        <meta name="description" content={language.en.admin.option.description} />
        <meta name="robots" content="noindex" data-react-helmet="true" />
      </Helmet>
      <main>
        <HeadingPageCenter
          headline={
            pathname.endsWith('edit')
              ? 'Edit Participant Demographic Question Option'
              : 'Create Participant Demographic Question Option'
          }
          color={Color.BLUE}
        />
        <div className="section-container-sm">
          <CreateQuestionOptionForm
            loading={createPostSurveyQuestionLoading || updateLoading}
            optionId={optionId}
            onSubmit={onSubmit}
            formType={type}
          />
        </div>
      </main>
    </div>
  )
}

export default CreatePostSurveyQuestionOption

const CREATE_POST_SURVEY_QUESTION_OPTION = gql`
  mutation _createPreSurveyQuestionOption($input: CreatePostSurveyQuestionOptionInput!) {
    createPostSurveyQuestionOption(input: $input) {
      text
      id
    }
  }
`
const UPDATE_POST_SURVEY_QUESTION_OPTION = gql`
  mutation updatePOSTSurveyQuestionOption($id: String!, $input: UpdatePostSurveyQuestionOptionInput!) {
    updatePostSurveyQuestionOption(input: $input, optionId: $id) {
      text
      id
    }
  }
`
