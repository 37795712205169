import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type Answers = {
  id: Scalars['String'];
  value: Scalars['String'];
};

export type Assignment = {
  __typename: 'Assignment';
  archived: Scalars['Boolean'];
  canShareResults: Scalars['Boolean'];
  canViewResults: Scalars['Boolean'];
  completedDate?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  dueDate: Scalars['DateTime'];
  id: Scalars['String'];
  invitationMessage?: Maybe<Scalars['String']>;
  isOwner?: Maybe<Scalars['Boolean']>;
  maxRespondents: Scalars['Float'];
  minRespondents: Scalars['Float'];
  name: Scalars['String'];
  orgReminder: Scalars['Boolean'];
  organization?: Maybe<Organization>;
  pastDueDate: Scalars['Boolean'];
  peer: Peer;
  peerReminder: Scalars['Boolean'];
  preSurvey: PreSurvey;
  preSurveyStatus: RespondentSurveyStatusEnum;
  questionnaireResponse: Array<QuestionnaireResponse>;
  questionnaireStatus: RespondentSurveyStatusEnum;
  respondent: Array<Respondent>;
  respondentCount: Scalars['Float'];
  respondentProfile?: Maybe<Respondent>;
  status: AssignmentStatusEnum;
  survey: Survey;
  updatedAt: Scalars['DateTime'];
};

export type AssignmentPaginatedResults = {
  __typename: 'AssignmentPaginatedResults';
  docs: Array<Assignment>;
  hasNextPage: Scalars['Boolean'];
  hasPrevPage: Scalars['Boolean'];
  limit: Scalars['Int'];
  nextPage?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  pagingCounter: Scalars['Int'];
  prevPage?: Maybe<Scalars['Int']>;
  totalDocs: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type AssignmentStatus = {
  __typename: 'AssignmentStatus';
  count: Scalars['Float'];
  count_completed: Scalars['Float'];
  count_in_progress: Scalars['Float'];
  dueDate: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  organisationName: Scalars['String'];
  status: Scalars['String'];
};

export enum AssignmentStatusEnum {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Expired = 'EXPIRED',
  Pending = 'PENDING',
  Published = 'PUBLISHED',
  Started = 'STARTED'
}

export type BasicResults = {
  __typename: 'BasicResults';
  areOrgResultsAvailable?: Maybe<Scalars['Boolean']>;
  assignmentName?: Maybe<Scalars['String']>;
  results: Array<Results>;
  statementCount: Scalars['Int'];
};

export type Channel = {
  __typename: 'Channel';
  archived: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  emailTemplate: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
};

export type CreateAdminInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type CreateAssignmentInput = {
  canShareResults?: Maybe<Scalars['Boolean']>;
  canViewResults?: Maybe<Scalars['Boolean']>;
  dueDate: Scalars['DateTime'];
  invitationMessage?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  status?: Maybe<AssignmentStatusEnum>;
  surveyId: Scalars['String'];
};

export type CreateFactorInput = {
  code: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  coreFactor: Scalars['Boolean'];
  description: Scalars['String'];
  discussionQuestions?: Maybe<Scalars['String']>;
  factorTypeId: Scalars['String'];
  headline: Scalars['String'];
  name: Scalars['String'];
  position: Scalars['String'];
  surveyId: Scalars['String'];
  weight?: Maybe<Scalars['String']>;
};

export type CreateFactorTypeInput = {
  code: Scalars['String'];
  color: Scalars['String'];
  description: Scalars['String'];
  indexOrder: Scalars['Float'];
  name: Scalars['String'];
  reportDescription: Scalars['String'];
};

export type CreateLocaleInput = {
  code: Scalars['String'];
  name: Scalars['String'];
};

export type CreateOrgOwnerInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type CreateOrganizationInput = {
  channelId?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  foundedDate: Scalars['DateTime'];
  internalId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  owner: CreateOrgOwnerInput;
  surveyId: Scalars['String'];
};

export type CreateOrganizationOwnerInput = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type CreatePeerUserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  peerId: Scalars['String'];
};

export type CreatePostSurveyInput = {
  channelId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  localeId: Scalars['String'];
  name: Scalars['String'];
  surveyId: Scalars['String'];
};

export type CreatePostSurveyQuestionInput = {
  code: Scalars['String'];
  description: Scalars['String'];
  position: Scalars['Float'];
  postSurveyId: Scalars['String'];
  responseType: ResponseTypeEnum;
  text: Scalars['String'];
};

export type CreatePostSurveyQuestionOptionInput = {
  code: Scalars['String'];
  index: Scalars['String'];
  postSurveyQuestionId: Scalars['String'];
  text: Scalars['String'];
};

export type CreatePostSurveyResponseInput = {
  postSurveyOptionId: Scalars['String'];
  postSurveyQuestionId: Scalars['String'];
  respondentId: Scalars['String'];
};

export type CreatePreSurveyInput = {
  channelId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  localeId: Scalars['String'];
  name: Scalars['String'];
  surveyId: Scalars['String'];
};

export type CreatePreSurveyQuestionInput = {
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  position: Scalars['Float'];
  preSurveyId: Scalars['String'];
  text: Scalars['String'];
};

export type CreatePreSurveyQuestionOptionInput = {
  code: Scalars['String'];
  factorId?: Maybe<Scalars['String']>;
  index: Scalars['String'];
  preSurveyQuestionId: Scalars['String'];
  text: Scalars['String'];
};

export type CreatePreSurveyResponseInput = {
  assignmentId: Scalars['String'];
  preSurveyOptionId: Scalars['String'];
  preSurveyQuestionId: Scalars['String'];
};

export type CreateQuestionnaireInput = {
  channelId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  localeId: Scalars['String'];
  name: Scalars['String'];
};

export type CreateQuestionnaireQuestionInput = {
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  position: Scalars['Float'];
  questionnaireId: Scalars['String'];
  text: Scalars['String'];
};

export type CreateQuestionnaireResponseInput = {
  assignmentId: Scalars['String'];
  responses: Scalars['JSON'];
};

export type CreateRespondentInput = {
  branch?: Maybe<UserLocationEnum>;
  department?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  orgRole?: Maybe<UserOrgRoleEnum>;
  organizationId: Scalars['String'];
};

export type CreateRespondentProfileInput = {
  branch?: Maybe<UserLocationEnum>;
  department?: Maybe<Scalars['String']>;
  orgRole?: Maybe<UserOrgRoleEnum>;
};

export type CreateScanInput = {
  customMessage?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  organizationId: Scalars['String'];
  scanId?: Maybe<Scalars['String']>;
};

export type CreateStatementInput = {
  code: Scalars['String'];
  factorId: Scalars['String'];
  label: Scalars['String'];
  position: Scalars['Float'];
  statement: Scalars['String'];
  surveyId: Scalars['String'];
};

export type CreateStatementOptionInput = {
  code: Scalars['String'];
  name: Scalars['String'];
  title: Scalars['String'];
  value: Scalars['Float'];
};

export type CreateSurveyInput = {
  channelId: Scalars['String'];
  localeId: Scalars['String'];
  name: Scalars['String'];
};

export type CreateSurveyResponseInput = {
  assignmentId: Scalars['String'];
  respondentId: Scalars['String'];
  responseId?: Maybe<Scalars['String']>;
  statementId: Scalars['String'];
  surveyId: Scalars['String'];
};

export type CreateTopicStatementInput = {
  rank: Scalars['Float'];
  statementId: Scalars['String'];
  topicName: Scalars['String'];
  wordpressTopicId?: Maybe<Scalars['Float']>;
};

export type CreateUserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  organizationId: Scalars['String'];
};

export type DetailedFactors = {
  __typename: 'DetailedFactors';
  averages: Array<ParticipantScores>;
  content?: Maybe<Scalars['String']>;
  discussionQuestions?: Maybe<Scalars['String']>;
  factorAverage: Scalars['Float'];
  headline?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  statements: Array<StatementResult>;
};

export type DetailedResults = {
  __typename: 'DetailedResults';
  factorType: Scalars['String'];
  factorTypeColor?: Maybe<Scalars['String']>;
  factorTypeDescription: Scalars['String'];
  factorTypeReportDescription?: Maybe<Scalars['String']>;
  factors: Array<DetailedFactors>;
};

export type Factor = {
  __typename: 'Factor';
  archived: Scalars['Boolean'];
  code: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  coreFactor: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  discussionQuestions?: Maybe<Scalars['String']>;
  factorType?: Maybe<FactorType>;
  headline?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  isFactorCompleted: Scalars['Boolean'];
  name: Scalars['String'];
  position: Scalars['Float'];
  preSurveyOption?: Maybe<Array<PreSurveyOption>>;
  statements: Array<Statement>;
  survey: Survey;
  updatedAt: Scalars['DateTime'];
  weight?: Maybe<Scalars['Float']>;
};

export type FactorType = {
  __typename: 'FactorType';
  archived: Scalars['Boolean'];
  code: Scalars['String'];
  color: Scalars['String'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  indexOrder?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  reportDescription?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type Factors = {
  __typename: 'Factors';
  content?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type Locale = {
  __typename: 'Locale';
  archived: Scalars['Boolean'];
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type LoginInput = {
  otp: Scalars['String'];
  token: Scalars['String'];
};

export type Mutation = {
  __typename: 'Mutation';
  addUserToPeer: User;
  completePostSurvey: Response;
  completePreSurvey: Response;
  completeSurvey: Respondent;
  createAdmin: User;
  /** Create scan. Only peers are allowed */
  createAssignment: Assignment;
  createChannel: Channel;
  createFactor: Factor;
  createFactorType: FactorType;
  createLocale: Locale;
  createOrg: Organization;
  createOrgFromPeer: User;
  createOrgFromPeerOrg: User;
  createOrganization: Organization;
  createPostSurvey: PostSurvey;
  createPostSurveyQuestion: PostSurveyQuestion;
  createPostSurveyQuestionOption: PostSurveyOption;
  createPreSurvey: PreSurvey;
  createPreSurveyQuestion: PreSurveyQuestion;
  createPreSurveyQuestionOption: PreSurveyOption;
  createQuestionnaire: Questionnaire;
  createQuestionnaireQuestion: QuestionnaireQuestion;
  createQuestionnaireResponse: QuestionnaireResponse;
  createQuestionnaireResponses: Response;
  createRespondent: Respondent;
  createRespondentProfile: Respondent;
  /** Create scan. Only peers are allowed */
  createScan: Assignment;
  /** Create scan. Only org leaders allowed */
  createScanOrg: Assignment;
  createStatement: Statement;
  createStatementOption: Statement;
  createStatmentTopic: Statement;
  createSurvey: Survey;
  createSurveyResponse: SurveyResponse;
  createUser: User;
  deleteFactor: Response;
  deleteFactorType: Response;
  deleteLocale: Response;
  deletePostSurvey: Response;
  deletePostSurveyOption: Response;
  deletePostSurveyQuestion: Response;
  deletePreSurvey: Response;
  deletePreSurveyOption: Response;
  deletePreSurveyQuestion: Response;
  deleteRespondent: Response;
  deleteStatement: Response;
  deleteSurvey: Response;
  deleteTopicStatement: Scalars['Boolean'];
  /** Login with the combination of token/fingerPrint and otp */
  login: Response;
  publishAssignment: Scalars['Boolean'];
  /** Register on the system */
  register: Response;
  /** Peer registration on the system */
  registerPeer: User;
  resendInvitation: Scalars['Boolean'];
  /** Use generated token/fingerprint which will be generated after validating email to send otp email  */
  resendOtp: Response;
  setUserAsAdmin: User;
  startPostSurvey: Respondent;
  startSurvey: Respondent;
  /** Main mutation for post-survey response submission. Create or update */
  submitPostSurveyResponse: PostSurveyResponse;
  submitPostSurveyResponses: Scalars['Boolean'];
  /** Main mutation for pre-survey response submission. Create or update */
  submitPreSurveyResponse: PreSurveyResponse;
  submitScan: Scalars['Boolean'];
  surveyOptIn: Response;
  /** Update assignment details. Only org owner allowed */
  updateAssignment: Assignment;
  updateFactor: Factor;
  updateFactorOrder: Scalars['Boolean'];
  updateFactorType: FactorType;
  updateOrganization: Organization;
  updateOrganizationOrg: Organization;
  updatePeer: Peer;
  updatePostSurvey: PostSurvey;
  updatePostSurveyQuestion: PostSurveyQuestion;
  updatePostSurveyQuestionOption: PostSurveyOption;
  updatePostSurveyQuestionOrder: Scalars['Boolean'];
  updatePreSurvey: PreSurvey;
  updatePreSurveyQuestion: PreSurveyQuestion;
  updatePreSurveyQuestionOption: PreSurveyOption;
  updatePreSurveyQuestionOrder: Scalars['Boolean'];
  updateQuestionOptions: Array<PostSurveyOption>;
  updateStatement: Statement;
  updateStatementOrder: Scalars['Boolean'];
  updateStatmentTopic: Statement;
  updateSurvey: Survey;
  updateUser: User;
  /** Check if email exists in database */
  validateEmail: Response;
};


export type MutationAddUserToPeerArgs = {
  input: CreatePeerUserInput;
};


export type MutationCompletePostSurveyArgs = {
  respondentId: Scalars['String'];
};


export type MutationCompletePreSurveyArgs = {
  assignmentId: Scalars['String'];
};


export type MutationCompleteSurveyArgs = {
  respondentId: Scalars['String'];
};


export type MutationCreateAdminArgs = {
  input: CreateAdminInput;
};


export type MutationCreateAssignmentArgs = {
  assignmentId?: Maybe<Scalars['String']>;
  input: CreateAssignmentInput;
  peerId?: Maybe<Scalars['String']>;
};


export type MutationCreateChannelArgs = {
  name: Scalars['String'];
};


export type MutationCreateFactorArgs = {
  input: CreateFactorInput;
};


export type MutationCreateFactorTypeArgs = {
  input: CreateFactorTypeInput;
};


export type MutationCreateLocaleArgs = {
  input: CreateLocaleInput;
};


export type MutationCreateOrgArgs = {
  country: Scalars['String'];
  name: Scalars['String'];
  surveyId?: Maybe<Scalars['String']>;
};


export type MutationCreateOrgFromPeerArgs = {
  input: CreateOrganizationInput;
};


export type MutationCreateOrgFromPeerOrgArgs = {
  input: CreateOrganizationInput;
};


export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};


export type MutationCreatePostSurveyArgs = {
  input: CreatePostSurveyInput;
};


export type MutationCreatePostSurveyQuestionArgs = {
  input: CreatePostSurveyQuestionInput;
};


export type MutationCreatePostSurveyQuestionOptionArgs = {
  input: CreatePostSurveyQuestionOptionInput;
};


export type MutationCreatePreSurveyArgs = {
  input: CreatePreSurveyInput;
};


export type MutationCreatePreSurveyQuestionArgs = {
  input: CreatePreSurveyQuestionInput;
};


export type MutationCreatePreSurveyQuestionOptionArgs = {
  input: CreatePreSurveyQuestionOptionInput;
};


export type MutationCreateQuestionnaireArgs = {
  input: CreateQuestionnaireInput;
};


export type MutationCreateQuestionnaireQuestionArgs = {
  input: CreateQuestionnaireQuestionInput;
};


export type MutationCreateQuestionnaireResponseArgs = {
  assignmentId: Scalars['String'];
  questionId: Scalars['String'];
  value: Scalars['String'];
};


export type MutationCreateQuestionnaireResponsesArgs = {
  answers: Array<Answers>;
  assignmentId: Scalars['String'];
};


export type MutationCreateRespondentArgs = {
  assignmentId: Scalars['String'];
  input: CreateRespondentInput;
};


export type MutationCreateRespondentProfileArgs = {
  assignmentId: Scalars['String'];
  input: CreateRespondentProfileInput;
  userId: Scalars['String'];
};


export type MutationCreateScanArgs = {
  input: CreateScanInput;
  peerId?: Maybe<Scalars['String']>;
};


export type MutationCreateScanOrgArgs = {
  input: CreateScanInput;
  peerId?: Maybe<Scalars['String']>;
};


export type MutationCreateStatementArgs = {
  input: CreateStatementInput;
};


export type MutationCreateStatementOptionArgs = {
  input: CreateStatementOptionInput;
};


export type MutationCreateStatmentTopicArgs = {
  input: CreateTopicStatementInput;
};


export type MutationCreateSurveyArgs = {
  input: CreateSurveyInput;
};


export type MutationCreateSurveyResponseArgs = {
  input: CreateSurveyResponseInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationDeleteFactorArgs = {
  id: Scalars['String'];
};


export type MutationDeleteFactorTypeArgs = {
  id: Scalars['String'];
};


export type MutationDeleteLocaleArgs = {
  id: Scalars['String'];
};


export type MutationDeletePostSurveyArgs = {
  id: Scalars['String'];
};


export type MutationDeletePostSurveyOptionArgs = {
  id: Scalars['String'];
};


export type MutationDeletePostSurveyQuestionArgs = {
  id: Scalars['String'];
};


export type MutationDeletePreSurveyArgs = {
  id: Scalars['String'];
};


export type MutationDeletePreSurveyOptionArgs = {
  id: Scalars['String'];
};


export type MutationDeletePreSurveyQuestionArgs = {
  id: Scalars['String'];
};


export type MutationDeleteRespondentArgs = {
  assignmentId: Scalars['String'];
  respondentId: Scalars['String'];
};


export type MutationDeleteStatementArgs = {
  id: Scalars['String'];
};


export type MutationDeleteSurveyArgs = {
  id: Scalars['String'];
};


export type MutationDeleteTopicStatementArgs = {
  id: Scalars['String'];
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationPublishAssignmentArgs = {
  assignmentId: Scalars['String'];
  invitationMessage?: Maybe<Scalars['String']>;
  orgId: Scalars['String'];
};


export type MutationRegisterArgs = {
  input: RegisterOrgInput;
};


export type MutationRegisterPeerArgs = {
  addAdminAsPeer?: Maybe<Scalars['Boolean']>;
  input: RegisterPeerInput;
};


export type MutationResendInvitationArgs = {
  assignmentId: Scalars['String'];
  respondentId: Scalars['String'];
};


export type MutationResendOtpArgs = {
  fingerPrint: Scalars['String'];
};


export type MutationSetUserAsAdminArgs = {
  isSuperAdmin: Scalars['Boolean'];
  userId: Scalars['String'];
};


export type MutationStartPostSurveyArgs = {
  respondentId: Scalars['String'];
};


export type MutationStartSurveyArgs = {
  respondentId: Scalars['String'];
};


export type MutationSubmitPostSurveyResponseArgs = {
  input: CreatePostSurveyResponseInput;
};


export type MutationSubmitPostSurveyResponsesArgs = {
  input: CreateQuestionnaireResponseInput;
};


export type MutationSubmitPreSurveyResponseArgs = {
  input: CreatePreSurveyResponseInput;
};


export type MutationSubmitScanArgs = {
  assignmentId: Scalars['String'];
  message: Scalars['String'];
};


export type MutationSurveyOptInArgs = {
  assignmentId: Scalars['String'];
  doSurvey: Scalars['Boolean'];
};


export type MutationUpdateAssignmentArgs = {
  assignmentId: Scalars['String'];
  input: UpdateAssignmentInput;
};


export type MutationUpdateFactorArgs = {
  factorId: Scalars['String'];
  input: UpdateFactorInput;
};


export type MutationUpdateFactorOrderArgs = {
  orderInput: Array<OrderInput>;
};


export type MutationUpdateFactorTypeArgs = {
  id: Scalars['String'];
  input: UpdateFactorTypeInput;
};


export type MutationUpdateOrganizationArgs = {
  id: Scalars['String'];
  input: UpdateOrgInput;
};


export type MutationUpdateOrganizationOrgArgs = {
  id: Scalars['String'];
  input: UpdateOrgInput;
};


export type MutationUpdatePeerArgs = {
  id: Scalars['String'];
  input: UpdatePeerInput;
};


export type MutationUpdatePostSurveyArgs = {
  id: Scalars['String'];
  input: UpdatePostSurveyInput;
};


export type MutationUpdatePostSurveyQuestionArgs = {
  id: Scalars['String'];
  input: UpdatePostSurveyQuestionInput;
};


export type MutationUpdatePostSurveyQuestionOptionArgs = {
  input: UpdatePostSurveyQuestionOptionInput;
  optionId: Scalars['String'];
};


export type MutationUpdatePostSurveyQuestionOrderArgs = {
  orderInput: Array<OrderInput>;
};


export type MutationUpdatePreSurveyArgs = {
  input: UpdatePreSurveyInput;
  preSurveyId: Scalars['String'];
};


export type MutationUpdatePreSurveyQuestionArgs = {
  id: Scalars['String'];
  input: UpdatePreSurveyQuestionInput;
};


export type MutationUpdatePreSurveyQuestionOptionArgs = {
  id: Scalars['String'];
  input: UpdatePreSurveyQuestionOptionInput;
};


export type MutationUpdatePreSurveyQuestionOrderArgs = {
  orderInput: Array<OrderInput>;
};


export type MutationUpdateQuestionOptionsArgs = {
  options: Array<OptionInput>;
  questionId: Scalars['String'];
};


export type MutationUpdateStatementArgs = {
  id: Scalars['String'];
  input: UpdateStatementInput;
};


export type MutationUpdateStatementOrderArgs = {
  orderInput: Array<OrderInput>;
};


export type MutationUpdateStatmentTopicArgs = {
  id: Scalars['String'];
  input: UpdateTopicStatementInput;
};


export type MutationUpdateSurveyArgs = {
  input: UpdateSurveyInput;
  surveyId: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
  userId: Scalars['String'];
};


export type MutationValidateEmailArgs = {
  email: Scalars['String'];
};

export type OptionInput = {
  archived?: Maybe<Scalars['Boolean']>;
  code: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  index: Scalars['String'];
  text: Scalars['String'];
};

export type OrderInput = {
  id: Scalars['String'];
  position: Scalars['Float'];
};

export type OrgPaginatedResults = {
  __typename: 'OrgPaginatedResults';
  docs: Array<Organization>;
  hasNextPage: Scalars['Boolean'];
  hasPrevPage: Scalars['Boolean'];
  limit: Scalars['Int'];
  nextPage?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  pagingCounter: Scalars['Int'];
  prevPage?: Maybe<Scalars['Int']>;
  totalDocs: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type Organization = {
  __typename: 'Organization';
  archived: Scalars['Boolean'];
  assignment?: Maybe<Array<Assignment>>;
  channel: Channel;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  foundedDate: Scalars['DateTime'];
  id: Scalars['String'];
  internalId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  owner?: Maybe<User>;
  peer?: Maybe<Peer>;
  survey?: Maybe<Survey>;
  surveyId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user: Array<User>;
};

export type Overview = {
  __typename: 'Overview';
  averages: Array<ParticipantScores>;
  results: Array<ReportResult>;
  totalAverage: Scalars['Float'];
};

export type PaginatedResults = {
  __typename: 'PaginatedResults';
  docs: Array<User>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  hasPrevPage?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  nextPage?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  pagingCounter?: Maybe<Scalars['Int']>;
  prevPage?: Maybe<Scalars['Int']>;
  totalDocs?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
};

export type PaginationArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  pagination?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<Scalars['String']>;
};

export type ParticipantScores = {
  __typename: 'ParticipantScores';
  participantId: Scalars['String'];
  score: Scalars['Float'];
};

export type Peer = {
  __typename: 'Peer';
  archived: Scalars['Boolean'];
  assignment: Array<Assignment>;
  channel: Channel;
  country: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  organizations: Array<Organization>;
  updatedAt: Scalars['DateTime'];
  user: Array<User>;
};

export type PeerGraph = {
  __typename: 'PeerGraph';
  avg: Scalars['Float'];
  country?: Maybe<Scalars['String']>;
  factor: Scalars['String'];
  organisation: Scalars['String'];
  year: Scalars['String'];
};

export type PeerProgress = {
  __typename: 'PeerProgress';
  completed_scans: Scalars['Float'];
  count: Scalars['Float'];
  count_completed: Scalars['Float'];
  count_in_progress: Scalars['Float'];
  number_organisations: Scalars['Float'];
  number_scans: Scalars['Float'];
  started_scans: Scalars['Float'];
};

export type PeerStatistics = {
  __typename: 'PeerStatistics';
  assignments: Array<AssignmentStatus>;
  graph: Array<PeerGraph>;
  progress: PeerProgress;
};

export type PostSurvey = {
  __typename: 'PostSurvey';
  archived: Scalars['Boolean'];
  channel: Channel;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  locale: Locale;
  name: Scalars['String'];
  questions: Array<PostSurveyQuestion>;
  status: RespondentSurveyStatusEnum;
  survey: Survey;
  updatedAt: Scalars['DateTime'];
};


export type PostSurveyStatusArgs = {
  assignmentId: Scalars['String'];
};

export type PostSurveyOption = {
  __typename: 'PostSurveyOption';
  archived: Scalars['Boolean'];
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  index: Scalars['String'];
  postSurveyQuestion: PostSurveyQuestion;
  text: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PostSurveyQuestion = {
  __typename: 'PostSurveyQuestion';
  archived: Scalars['Boolean'];
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  position: Scalars['Float'];
  postSurvey: PostSurvey;
  postSurveyOption: Array<PostSurveyOption>;
  postSurveyResponse?: Maybe<PostSurveyResponse>;
  responseType: ResponseTypeEnum;
  text: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PostSurveyResponse = {
  __typename: 'PostSurveyResponse';
  archived: Scalars['Boolean'];
  assignment: Assignment;
  createdAt: Scalars['DateTime'];
  customResponse?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  postSurveyOption?: Maybe<PostSurveyOption>;
  postSurveyQuestion: PostSurveyQuestion;
  updatedAt: Scalars['DateTime'];
};

export type PreSurvey = {
  __typename: 'PreSurvey';
  archived: Scalars['Boolean'];
  channel: Channel;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  locale: Locale;
  name: Scalars['String'];
  /** Returns all questions with responses with respondent id. */
  questions: Array<PreSurveyQuestion>;
  survey: Survey;
  updatedAt: Scalars['DateTime'];
};


export type PreSurveyQuestionsArgs = {
  assignmentId?: Maybe<Scalars['String']>;
};

export type PreSurveyOption = {
  __typename: 'PreSurveyOption';
  archived: Scalars['Boolean'];
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  factor?: Maybe<Factor>;
  id: Scalars['String'];
  index: Scalars['String'];
  preSurveyQuestion: PreSurveyQuestion;
  preSurveyResponse: PreSurveyResponse;
  text: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PreSurveyQuestion = {
  __typename: 'PreSurveyQuestion';
  archived: Scalars['Boolean'];
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  position: Scalars['Float'];
  preSurvey: PreSurvey;
  preSurveyOption: Array<PreSurveyOption>;
  preSurveyResponse?: Maybe<PreSurveyResponse>;
  text: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PreSurveyResponse = {
  __typename: 'PreSurveyResponse';
  archived: Scalars['Boolean'];
  assignment: Assignment;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  preSurveyOption: PreSurveyOption;
  preSurveyQuestion: PreSurveyQuestion;
  updatedAt: Scalars['DateTime'];
  user: User;
};

export type Query = {
  __typename: 'Query';
  allFactors: Array<Factor>;
  allFactoryTypes: Array<FactorType>;
  allOptionalFactors: Array<Factor>;
  allPeerUsers: Array<User>;
  allPostSurveys: Array<PostSurvey>;
  assignedAssignments: Array<Assignment>;
  /** Get one assignment via id */
  assignment: Assignment;
  /** Results by respondent id */
  assignmentBasicResults: BasicResults;
  /** Get paginated assignments */
  assignments: AssignmentPaginatedResults;
  /** Results by respondent id */
  basicResults: BasicResults;
  channel: Channel;
  factor?: Maybe<Factor>;
  factorType: FactorType;
  findAssignmentsByPeerAndAssignmentId: Assignment;
  findOrgUsers: PaginatedResults;
  findUser?: Maybe<User>;
  getPeer: Peer;
  getPeerOwner: User;
  getQuestionnaire: Questionnaire;
  healthCheck: Scalars['Boolean'];
  locales?: Maybe<Array<Locale>>;
  me: User;
  myAssignedAssignments: Array<Assignment>;
  myAssignments: Array<Assignment>;
  orgUsers: Array<User>;
  organization: Organization;
  /** Get list of assignment that organization created */
  organizationAssignments: Array<Assignment>;
  organizations: OrgPaginatedResults;
  organizationsByName: Array<Organization>;
  organizationsByNameOrg: Array<Organization>;
  peerAssignments: Array<Assignment>;
  peerStatistics: PeerStatistics;
  peerUsers: Array<User>;
  peers: Array<Peer>;
  postSurvey?: Maybe<PostSurvey>;
  /** Main query to get the post survey */
  postSurveyByAssignment: PostSurvey;
  postSurveyQuestion: PostSurveyQuestion;
  postSurveyQuestionOption: PostSurveyOption;
  /** Main query to get pre-survey  */
  preSurveyByAssignmentId: PreSurvey;
  /** Main query to get pre-survey  */
  preSurveyById: PreSurvey;
  preSurveyQuestion: PreSurveyQuestion;
  preSurveyQuestionOption: PreSurveyOption;
  questionnaire: PostSurvey;
  report: Report;
  respondentAssignment: Respondent;
  respondentByUserId: Respondent;
  respondents: Array<Respondent>;
  statement?: Maybe<Statement>;
  statementOptions?: Maybe<Array<StatementAnswerOption>>;
  /** Filtered factors according to pre-survey responses. Main survey query. */
  surveyByAssignmentId: Array<Factor>;
  surveyById?: Maybe<Survey>;
  surveyResponses: Array<SurveyResponse>;
  surveys?: Maybe<Array<Survey>>;
  unassignedOrganizations: Array<Organization>;
  user?: Maybe<User>;
  userAssignments: Array<Respondent>;
  users: PaginatedResults;
};


export type QueryAllFactorsArgs = {
  surveyId: Scalars['String'];
};


export type QueryAllOptionalFactorsArgs = {
  surveyId: Scalars['String'];
};


export type QueryAssignmentArgs = {
  id: Scalars['String'];
};


export type QueryAssignmentBasicResultsArgs = {
  assignmentId: Scalars['String'];
};


export type QueryAssignmentsArgs = {
  input?: Maybe<QueryAssignmentsInput>;
  pagination?: Maybe<PaginationArgs>;
  sort?: Maybe<SortArgs>;
};


export type QueryBasicResultsArgs = {
  assignmentId: Scalars['String'];
};


export type QueryChannelArgs = {
  id: Scalars['String'];
};


export type QueryFactorArgs = {
  id: Scalars['String'];
};


export type QueryFactorTypeArgs = {
  id: Scalars['String'];
};


export type QueryFindAssignmentsByPeerAndAssignmentIdArgs = {
  assignmentId: Scalars['String'];
  peerId: Scalars['String'];
};


export type QueryFindOrgUsersArgs = {
  input?: Maybe<QueryUsersInput>;
  pagination?: Maybe<PaginationArgs>;
  sort?: Maybe<SortArgs>;
};


export type QueryFindUserArgs = {
  email: Scalars['String'];
};


export type QueryGetPeerArgs = {
  id: Scalars['String'];
};


export type QueryGetPeerOwnerArgs = {
  peerId: Scalars['String'];
};


export type QueryGetQuestionnaireArgs = {
  name: Scalars['String'];
};


export type QueryOrgUsersArgs = {
  id: Scalars['String'];
};


export type QueryOrganizationArgs = {
  id: Scalars['String'];
};


export type QueryOrganizationAssignmentsArgs = {
  orgId?: Maybe<Scalars['String']>;
};


export type QueryOrganizationsArgs = {
  input?: Maybe<QueryOrgsInput>;
  pagination?: Maybe<PaginationArgs>;
  sort?: Maybe<SortArgs>;
};


export type QueryOrganizationsByNameArgs = {
  name?: Maybe<Scalars['String']>;
  peerId?: Maybe<Scalars['String']>;
};


export type QueryOrganizationsByNameOrgArgs = {
  name?: Maybe<Scalars['String']>;
  peerId?: Maybe<Scalars['String']>;
};


export type QueryPeerAssignmentsArgs = {
  peerId?: Maybe<Scalars['String']>;
};


export type QueryPeerStatisticsArgs = {
  peer_id: Scalars['String'];
};


export type QueryPeerUsersArgs = {
  peerId: Scalars['String'];
};


export type QueryPostSurveyArgs = {
  id: Scalars['String'];
};


export type QueryPostSurveyByAssignmentArgs = {
  assignmentId: Scalars['String'];
};


export type QueryPostSurveyQuestionArgs = {
  id: Scalars['String'];
};


export type QueryPostSurveyQuestionOptionArgs = {
  id: Scalars['String'];
};


export type QueryPreSurveyByAssignmentIdArgs = {
  assignmentId: Scalars['String'];
};


export type QueryPreSurveyByIdArgs = {
  preSurveyId: Scalars['String'];
};


export type QueryPreSurveyQuestionArgs = {
  id: Scalars['String'];
};


export type QueryPreSurveyQuestionOptionArgs = {
  id: Scalars['String'];
};


export type QueryReportArgs = {
  assignmentId: Scalars['String'];
  isIndividual: Scalars['Boolean'];
};


export type QueryRespondentAssignmentArgs = {
  assignmentId: Scalars['String'];
  respondentId: Scalars['String'];
};


export type QueryRespondentByUserIdArgs = {
  assignmentId: Scalars['String'];
  userId: Scalars['String'];
};


export type QueryRespondentsArgs = {
  assignmentId: Scalars['String'];
};


export type QueryStatementArgs = {
  id: Scalars['String'];
};


export type QuerySurveyByAssignmentIdArgs = {
  assignmentId: Scalars['String'];
  respondentId: Scalars['String'];
};


export type QuerySurveyByIdArgs = {
  id: Scalars['String'];
};


export type QuerySurveyResponsesArgs = {
  assignmentId: Scalars['String'];
  respondentId: Scalars['String'];
};


export type QuerySurveysArgs = {
  status?: Maybe<Scalars['String']>;
};


export type QueryUnassignedOrganizationsArgs = {
  peerId: Scalars['String'];
};


export type QueryUserArgs = {
  id: Scalars['String'];
};


export type QueryUsersArgs = {
  input?: Maybe<QueryUsersInput>;
  pagination?: Maybe<PaginationArgs>;
  sort?: Maybe<SortArgs>;
};

export type QueryAssignmentsInput = {
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  preSurveyStatus?: Maybe<RespondentSurveyStatusEnum>;
  status?: Maybe<AssignmentStatusEnum>;
};

export type QueryOrgsInput = {
  name?: Maybe<Scalars['String']>;
};

export type QueryUsersInput = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  role?: Maybe<UserRoleEnum>;
};

export type Questionnaire = {
  __typename: 'Questionnaire';
  archived: Scalars['Boolean'];
  channel: Channel;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  locale: Locale;
  name: Scalars['String'];
  questionnaireQuestion: Array<QuestionnaireQuestion>;
  updatedAt: Scalars['DateTime'];
};

export type QuestionnaireQuestion = {
  __typename: 'QuestionnaireQuestion';
  archived: Scalars['Boolean'];
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  position: Scalars['Float'];
  questionnaire: Questionnaire;
  questionnaireResponse?: Maybe<QuestionnaireResponse>;
  text: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type QuestionnaireResponse = {
  __typename: 'QuestionnaireResponse';
  archived: Scalars['Boolean'];
  assignment: Assignment;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  questionnaireQuestion: QuestionnaireQuestion;
  updatedAt: Scalars['DateTime'];
  user: User;
  value: Scalars['String'];
};

export type RegisterOrgInput = {
  channelId: Scalars['String'];
  country: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  organizationName: Scalars['String'];
  surveyId: Scalars['String'];
};

export type RegisterPeerInput = {
  channelId?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  customMessage?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  peerName: Scalars['String'];
};

export type Report = {
  __typename: 'Report';
  date?: Maybe<Scalars['DateTime']>;
  detailedResults: Array<DetailedResults>;
  overview: Overview;
  title: Scalars['String'];
};

export type ReportFactor = {
  __typename: 'ReportFactor';
  average: Scalars['Int'];
  averageScore: Scalars['Float'];
  headline?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  scores: Array<ParticipantScores>;
};

export type ReportResult = {
  __typename: 'ReportResult';
  factorType: Scalars['String'];
  factorTypeColor?: Maybe<Scalars['String']>;
  factorTypeDescription: Scalars['String'];
  factorTypeReportDescription?: Maybe<Scalars['String']>;
  factors: Array<ReportFactor>;
};

export type Respondent = {
  __typename: 'Respondent';
  archived: Scalars['Boolean'];
  assignment: Assignment;
  branch?: Maybe<UserLocationEnum>;
  completedDate?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  department?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isApproachingReminderSent: Scalars['Boolean'];
  isDueDateReminderSent: Scalars['Boolean'];
  isInvitationSent: Scalars['Boolean'];
  orgRole?: Maybe<UserOrgRoleEnum>;
  postSurveyStatus: RespondentSurveyStatusEnum;
  surveyStatus: RespondentSurveyStatusEnum;
  updatedAt: Scalars['DateTime'];
  user: User;
};

export enum RespondentSurveyStatusEnum {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Expired = 'EXPIRED',
  Pending = 'PENDING',
  Started = 'STARTED'
}

export type Response = {
  __typename: 'Response';
  action?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  redirect?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  token?: Maybe<Scalars['String']>;
};

export enum ResponseTypeEnum {
  Country = 'country',
  Custom = 'custom',
  Options = 'options',
  Year = 'year'
}

export type Responses = {
  __typename: 'Responses';
  factor: Factors;
  score: Scalars['Int'];
};

export type Results = {
  __typename: 'Results';
  factorType: Scalars['String'];
  factorTypeColor?: Maybe<Scalars['String']>;
  factorTypeDescription: Scalars['String'];
  factorTypeReportDescription?: Maybe<Scalars['String']>;
  responses: Array<Responses>;
};

export type SortArgs = {
  direction: SortEnum;
  sortField: Scalars['String'];
};

export enum SortEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Statement = {
  __typename: 'Statement';
  archived: Scalars['Boolean'];
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  factor: Factor;
  id: Scalars['String'];
  isCompleted: Scalars['Boolean'];
  label?: Maybe<Scalars['String']>;
  position: Scalars['Float'];
  statement: Scalars['String'];
  subDimension?: Maybe<Scalars['String']>;
  survey: Survey;
  updatedAt: Scalars['DateTime'];
};

export type StatementAnswerOption = {
  __typename: 'StatementAnswerOption';
  archived: Scalars['Boolean'];
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['Float'];
};

export type StatementResult = {
  __typename: 'StatementResult';
  average: Scalars['Float'];
  label: Scalars['String'];
  scores: Array<ParticipantScores>;
  statement: Scalars['String'];
  subDimension?: Maybe<Scalars['String']>;
};

export type Survey = {
  __typename: 'Survey';
  archived: Scalars['Boolean'];
  channel: Channel;
  createdAt: Scalars['DateTime'];
  factors: Array<Factor>;
  id: Scalars['String'];
  locale: Locale;
  name: Scalars['String'];
  postSurvey?: Maybe<PostSurvey>;
  preSurvey?: Maybe<PreSurvey>;
  statements: Array<Statement>;
  status: SurveyStatusEnum;
  updatedAt: Scalars['DateTime'];
};

export type SurveyResponse = {
  __typename: 'SurveyResponse';
  archived: Scalars['Boolean'];
  assignment: Assignment;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  respondent: Respondent;
  response?: Maybe<StatementAnswerOption>;
  statement?: Maybe<Statement>;
  survey: Survey;
  updatedAt: Scalars['DateTime'];
};

export enum SurveyStatusEnum {
  Closed = 'CLOSED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
}

export type UpdateAssignmentInput = {
  canViewResults?: Maybe<Scalars['Boolean']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  invitationMessage?: Maybe<Scalars['String']>;
  maxRespondents?: Maybe<Scalars['Float']>;
  minRespondents?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['String']>;
  orgReminder?: Maybe<Scalars['Boolean']>;
  peerReminder?: Maybe<Scalars['Boolean']>;
  postSurveyStatus?: Maybe<RespondentSurveyStatusEnum>;
  questionnaireStatus?: Maybe<RespondentSurveyStatusEnum>;
  status?: Maybe<AssignmentStatusEnum>;
  surveyId?: Maybe<Scalars['String']>;
};

export type UpdateFactorInput = {
  code?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  coreFactor?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  discussionQuestions?: Maybe<Scalars['String']>;
  factorTypeId?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Float']>;
  surveyId?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
};

export type UpdateFactorTypeInput = {
  code?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  indexOrder?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  reportDescription?: Maybe<Scalars['String']>;
};

export type UpdateOrgInput = {
  country?: Maybe<Scalars['String']>;
  foundedDate?: Maybe<Scalars['DateTime']>;
  internalId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  owner?: Maybe<CreateOrganizationOwnerInput>;
  ownerId?: Maybe<Scalars['String']>;
  questionnaireStatus?: Maybe<Scalars['Boolean']>;
  surveyId?: Maybe<Scalars['String']>;
};

export type UpdatePeerInput = {
  country?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type UpdatePostSurveyInput = {
  description?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  localeId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type UpdatePostSurveyQuestionInput = {
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Float']>;
  responseType?: Maybe<ResponseTypeEnum>;
  text?: Maybe<Scalars['String']>;
};

export type UpdatePostSurveyQuestionOptionInput = {
  archived?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['String']>;
  postSurveyQuestionId?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type UpdatePreSurveyInput = {
  description?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  localeId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type UpdatePreSurveyQuestionInput = {
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Float']>;
  preSurveyId?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type UpdatePreSurveyQuestionOptionInput = {
  code?: Maybe<Scalars['String']>;
  factorId?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['String']>;
  preSurveyQuestionId?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type UpdateStatementInput = {
  code?: Maybe<Scalars['String']>;
  factorId?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Float']>;
  statement?: Maybe<Scalars['String']>;
  subDimension?: Maybe<Scalars['String']>;
  surveyId?: Maybe<Scalars['String']>;
};

export type UpdateSurveyInput = {
  channelId?: Maybe<Scalars['String']>;
  localeId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postSurveyId?: Maybe<Scalars['String']>;
  preSurveyId?: Maybe<Scalars['String']>;
  status?: Maybe<SurveyStatusEnum>;
};

export type UpdateTopicStatementInput = {
  rank?: Maybe<Scalars['Int']>;
  statementId?: Maybe<Scalars['String']>;
  topicName?: Maybe<Scalars['String']>;
};

export type UpdateUserInput = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  isOrgLeader?: Maybe<Scalars['Boolean']>;
  isPeer?: Maybe<Scalars['Boolean']>;
  isSuperAdmin?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
};

export type User = {
  __typename: 'User';
  archived: Scalars['Boolean'];
  channel: Channel;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  isOrgLeader: Scalars['Boolean'];
  isPeer: Scalars['Boolean'];
  isRespondent: Scalars['Boolean'];
  isSuperAdmin: Scalars['Boolean'];
  lastName: Scalars['String'];
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['String']>;
  organizations: Array<Organization>;
  peer?: Maybe<Peer>;
  respondent: Array<Respondent>;
  role: UserRoleEnum;
  updatedAt: Scalars['DateTime'];
};

export enum UserLocationEnum {
  CentralOffice = 'centralOffice',
  FieldOffice = 'fieldOffice',
  Other = 'other'
}

export enum UserOrgRoleEnum {
  BoardMember = 'boardMember',
  Leader = 'leader',
  Management = 'management',
  Other = 'other',
  SeniorManagement = 'seniorManagement',
  TeamMember = 'teamMember'
}

export enum UserRoleEnum {
  Admin = 'admin',
  OrganizationOwner = 'organization_owner',
  Peer = 'peer',
  Respondent = 'respondent'
}

export type _OrganizationAssignmentsQueryVariables = Exact<{
  orgId: Scalars['String'];
}>;


export type _OrganizationAssignmentsQuery = { __typename: 'Query', organizationAssignments: Array<{ __typename: 'Assignment', id: string, dueDate: any, status: AssignmentStatusEnum, name: string, respondentCount: number, questionnaireStatus: RespondentSurveyStatusEnum, pastDueDate: boolean, preSurveyStatus: RespondentSurveyStatusEnum, organization?: { __typename: 'Organization', name: string, peer?: { __typename: 'Peer', id: string } | null | undefined } | null | undefined, respondentProfile?: { __typename: 'Respondent', id: string, surveyStatus: RespondentSurveyStatusEnum } | null | undefined }> };

export type PeerAssignmentsQueryVariables = Exact<{
  peerId?: Maybe<Scalars['String']>;
}>;


export type PeerAssignmentsQuery = { __typename: 'Query', peerAssignments: Array<{ __typename: 'Assignment', id: string, dueDate: any, status: AssignmentStatusEnum, name: string, respondentCount: number, preSurveyStatus: RespondentSurveyStatusEnum, organization?: { __typename: 'Organization', name: string } | null | undefined, respondentProfile?: { __typename: 'Respondent', id: string, surveyStatus: RespondentSurveyStatusEnum } | null | undefined }> };

export type AssignmentsQueryVariables = Exact<{
  sort?: Maybe<SortArgs>;
  pagination?: Maybe<PaginationArgs>;
  input?: Maybe<QueryAssignmentsInput>;
}>;


export type AssignmentsQuery = { __typename: 'Query', assignments: { __typename: 'AssignmentPaginatedResults', limit: number, totalPages: number, page: number, hasPrevPage: boolean, hasNextPage: boolean, docs: Array<{ __typename: 'Assignment', id: string, dueDate: any, name: string, status: AssignmentStatusEnum, respondentCount: number, organization?: { __typename: 'Organization', name: string } | null | undefined, peer: { __typename: 'Peer', name: string } }> } };

export type UpdateAssignmentDateMutationVariables = Exact<{
  assignmentId: Scalars['String'];
  input: UpdateAssignmentInput;
}>;


export type UpdateAssignmentDateMutation = { __typename: 'Mutation', updateAssignment: { __typename: 'Assignment', id: string, dueDate: any, minRespondents: number, maxRespondents: number } };

export type MyAssignedAssignmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyAssignedAssignmentsQuery = { __typename: 'Query', myAssignedAssignments: Array<{ __typename: 'Assignment', id: string, dueDate: any, status: AssignmentStatusEnum, name: string, respondentCount: number, preSurveyStatus: RespondentSurveyStatusEnum, isOwner?: boolean | null | undefined, respondentProfile?: { __typename: 'Respondent', id: string, surveyStatus: RespondentSurveyStatusEnum } | null | undefined, organization?: { __typename: 'Organization', id: string } | null | undefined }> };

export type ResendInvitationMutationVariables = Exact<{
  respondentId: Scalars['String'];
  assignmentId: Scalars['String'];
}>;


export type ResendInvitationMutation = { __typename: 'Mutation', resendInvitation: boolean };

export type SingleAssignmentQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type SingleAssignmentQuery = { __typename: 'Query', assignment: { __typename: 'Assignment', id: string, name: string, createdAt: any, dueDate: any, preSurveyStatus: RespondentSurveyStatusEnum, respondentCount: number, status: AssignmentStatusEnum }, respondents: Array<{ __typename: 'Respondent', id: string, isInvitationSent: boolean, surveyStatus: RespondentSurveyStatusEnum, completedDate?: any | null | undefined, user: { __typename: 'User', id: string, firstName: string, lastName: string, email: string } }> };

export type RegisterPeerMutationVariables = Exact<{
  input: RegisterPeerInput;
  addAdminAsPeer?: Maybe<Scalars['Boolean']>;
}>;


export type RegisterPeerMutation = { __typename: 'Mutation', registerPeer: { __typename: 'User', email: string, firstName: string, lastName: string } };

export type UpdatePeerMutationVariables = Exact<{
  id: Scalars['String'];
  input: UpdatePeerInput;
}>;


export type UpdatePeerMutation = { __typename: 'Mutation', updatePeer: { __typename: 'Peer', id: string } };

export type PeersQueryVariables = Exact<{ [key: string]: never; }>;


export type PeersQuery = { __typename: 'Query', allPeerUsers: Array<{ __typename: 'User', firstName: string, lastName: string, role: UserRoleEnum, email: string, organization?: { __typename: 'Organization', name: string } | null | undefined, peer?: { __typename: 'Peer', id: string, name: string, country: string } | null | undefined }> };

export type GetQuestionnaireQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type GetQuestionnaireQuery = { __typename: 'Query', getQuestionnaire: { __typename: 'Questionnaire', id: string, name: string, description?: string | null | undefined, headline?: string | null | undefined, questionnaireQuestion: Array<{ __typename: 'QuestionnaireQuestion', id: string, text: string, position: number, name: string }> } };

export type CreateQuestionnaireResponsesMutationVariables = Exact<{
  answers: Array<Answers> | Answers;
  assignmentId: Scalars['String'];
}>;


export type CreateQuestionnaireResponsesMutation = { __typename: 'Mutation', createQuestionnaireResponses: { __typename: 'Response', message?: string | null | undefined, status: string, redirect?: string | null | undefined } };

export type OrganizationAssignmentsQueryVariables = Exact<{
  orgId: Scalars['String'];
}>;


export type OrganizationAssignmentsQuery = { __typename: 'Query', organizationAssignments: Array<{ __typename: 'Assignment', id: string, dueDate: any, status: AssignmentStatusEnum, name: string, respondentCount: number, questionnaireStatus: RespondentSurveyStatusEnum, preSurveyStatus: RespondentSurveyStatusEnum, organization?: { __typename: 'Organization', name: string } | null | undefined, respondentProfile?: { __typename: 'Respondent', id: string, surveyStatus: RespondentSurveyStatusEnum } | null | undefined }> };

export type CreateOrgMutationVariables = Exact<{
  name: Scalars['String'];
  country: Scalars['String'];
}>;


export type CreateOrgMutation = { __typename: 'Mutation', createOrg: { __typename: 'Organization', id: string } };

export type _GetAllUserAssignmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type _GetAllUserAssignmentsQuery = { __typename: 'Query', myAssignments: Array<{ __typename: 'Assignment', id: string, dueDate: any, status: AssignmentStatusEnum, name: string, respondentCount: number, preSurveyStatus: RespondentSurveyStatusEnum, isOwner?: boolean | null | undefined, respondentProfile?: { __typename: 'Respondent', id: string, surveyStatus: RespondentSurveyStatusEnum } | null | undefined, organization?: { __typename: 'Organization', id: string } | null | undefined }> };

export type CreateSurveyMutationVariables = Exact<{
  input: CreateSurveyInput;
}>;


export type CreateSurveyMutation = { __typename: 'Mutation', createSurvey: { __typename: 'Survey', id: string, name: string } };

export type UpdateSurveyMutationVariables = Exact<{
  input: UpdateSurveyInput;
  surveyId: Scalars['String'];
}>;


export type UpdateSurveyMutation = { __typename: 'Mutation', updateSurvey: { __typename: 'Survey', id: string, name: string } };

export type GetLocalesQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLocalesQueryQuery = { __typename: 'Query', locales?: Array<{ __typename: 'Locale', id: string, name: string, code: string }> | null | undefined };

export type _SurveyQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type _SurveyQuery = { __typename: 'Query', surveyById?: { __typename: 'Survey', id: string, name: string, locale: { __typename: 'Locale', id: string, code: string, name: string } } | null | undefined };

export type _PreSurveyByIdQueryVariables = Exact<{
  preSurveyId: Scalars['String'];
}>;


export type _PreSurveyByIdQuery = { __typename: 'Query', preSurveyById: { __typename: 'PreSurvey', id: string, name: string, headline?: string | null | undefined, createdAt: any, description?: string | null | undefined, questions: Array<{ __typename: 'PreSurveyQuestion', text: string, description?: string | null | undefined, code: string, preSurveyOption: Array<{ __typename: 'PreSurveyOption', id: string, text: string, code: string }> }>, locale: { __typename: 'Locale', id: string, name: string } } };

export type PreSurveyLocalesQueryVariables = Exact<{ [key: string]: never; }>;


export type PreSurveyLocalesQuery = { __typename: 'Query', locales?: Array<{ __typename: 'Locale', id: string, name: string, code: string }> | null | undefined };

export type _PostSurveyByIdQueryVariables = Exact<{
  postSurveyId: Scalars['String'];
}>;


export type _PostSurveyByIdQuery = { __typename: 'Query', postSurvey?: { __typename: 'PostSurvey', id: string, name: string, headline?: string | null | undefined, createdAt: any, description?: string | null | undefined, questions: Array<{ __typename: 'PostSurveyQuestion', text: string, code: string, postSurveyOption: Array<{ __typename: 'PostSurveyOption', id: string, text: string, code: string }> }>, locale: { __typename: 'Locale', id: string, name: string } } | null | undefined };

export type PostSurveyLocalesQueryVariables = Exact<{ [key: string]: never; }>;


export type PostSurveyLocalesQuery = { __typename: 'Query', locales?: Array<{ __typename: 'Locale', id: string, name: string, code: string }> | null | undefined };

export type CreatePreSurveyMutationVariables = Exact<{
  input: CreatePreSurveyInput;
}>;


export type CreatePreSurveyMutation = { __typename: 'Mutation', createPreSurvey: { __typename: 'PreSurvey', name: string, id: string, description?: string | null | undefined, headline?: string | null | undefined } };

export type UpdatePreSurveyMutationVariables = Exact<{
  input: UpdatePreSurveyInput;
  preSurveyId: Scalars['String'];
}>;


export type UpdatePreSurveyMutation = { __typename: 'Mutation', updatePreSurvey: { __typename: 'PreSurvey', id: string, name: string } };

export type CreatePostSurveyMutationVariables = Exact<{
  input: CreatePostSurveyInput;
}>;


export type CreatePostSurveyMutation = { __typename: 'Mutation', createPostSurvey: { __typename: 'PostSurvey', name: string, id: string, description?: string | null | undefined, headline?: string | null | undefined } };

export type UpdatePostSurveyMutationVariables = Exact<{
  input: UpdatePostSurveyInput;
  id: Scalars['String'];
}>;


export type UpdatePostSurveyMutation = { __typename: 'Mutation', updatePostSurvey: { __typename: 'PostSurvey', id: string, name: string } };

export type _DeletePreSurveyMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type _DeletePreSurveyMutation = { __typename: 'Mutation', deletePreSurvey: { __typename: 'Response', status: string, message?: string | null | undefined } };

export type DeletePostMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeletePostMutation = { __typename: 'Mutation', deletePostSurvey: { __typename: 'Response', status: string, message?: string | null | undefined } };

export type DeletePostSurveyQuestionMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeletePostSurveyQuestionMutation = { __typename: 'Mutation', deletePostSurveyQuestion: { __typename: 'Response', status: string, message?: string | null | undefined } };

export type UpdatePreSurveyQuestionOrderMutationVariables = Exact<{
  orderInput: Array<OrderInput> | OrderInput;
}>;


export type UpdatePreSurveyQuestionOrderMutation = { __typename: 'Mutation', updatePreSurveyQuestionOrder: boolean };

export type UpdatePostSurveyQuestionOrderMutationVariables = Exact<{
  orderInput: Array<OrderInput> | OrderInput;
}>;


export type UpdatePostSurveyQuestionOrderMutation = { __typename: 'Mutation', updatePostSurveyQuestionOrder: boolean };

export type PreSurveyByIdQueryVariables = Exact<{
  preSurveyId: Scalars['String'];
}>;


export type PreSurveyByIdQuery = { __typename: 'Query', preSurveyById: { __typename: 'PreSurvey', id: string, name: string, headline?: string | null | undefined, createdAt: any, description?: string | null | undefined, survey: { __typename: 'Survey', status: SurveyStatusEnum }, questions: Array<{ __typename: 'PreSurveyQuestion', id: string, text: string, description?: string | null | undefined, code: string, preSurveyOption: Array<{ __typename: 'PreSurveyOption', id: string, text: string, code: string }> }>, locale: { __typename: 'Locale', id: string, name: string } } };

export type PostSurveyByIdQueryVariables = Exact<{ [key: string]: never; }>;


export type PostSurveyByIdQuery = { __typename: 'Query', questionnaire: { __typename: 'PostSurvey', id: string, name: string, description?: string | null | undefined, headline?: string | null | undefined, questions: Array<{ __typename: 'PostSurveyQuestion', id: string, text: string, code: string, responseType: ResponseTypeEnum, position: number, postSurveyOption: Array<{ __typename: 'PostSurveyOption', id: string, text: string, index: string, code: string }> }> } };

export type CreateFactorTypeMutationVariables = Exact<{
  input: CreateFactorTypeInput;
}>;


export type CreateFactorTypeMutation = { __typename: 'Mutation', createFactorType: { __typename: 'FactorType', name: string, id: string } };

export type UpdateFactorTypeMutationVariables = Exact<{
  id: Scalars['String'];
  input: UpdateFactorTypeInput;
}>;


export type UpdateFactorTypeMutation = { __typename: 'Mutation', updateFactorType: { __typename: 'FactorType', name: string, id: string } };

export type FactorTypeQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type FactorTypeQuery = { __typename: 'Query', factorType: { __typename: 'FactorType', id: string, name: string, code: string, description?: string | null | undefined, color: string, indexOrder?: number | null | undefined, reportDescription?: string | null | undefined } };

export type CreateFactorMutationVariables = Exact<{
  input: CreateFactorInput;
}>;


export type CreateFactorMutation = { __typename: 'Mutation', createFactor: { __typename: 'Factor', name: string, id: string } };

export type UpdateFactorMutationVariables = Exact<{
  factorId: Scalars['String'];
  input: UpdateFactorInput;
}>;


export type UpdateFactorMutation = { __typename: 'Mutation', updateFactor: { __typename: 'Factor', name: string, id: string } };

export type AllFactoryTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllFactoryTypesQuery = { __typename: 'Query', allFactoryTypes: Array<{ __typename: 'FactorType', id: string, createdAt: any, name: string, code: string, description?: string | null | undefined }> };

export type SingleFactorQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type SingleFactorQuery = { __typename: 'Query', factor?: { __typename: 'Factor', id: string, name: string, headline?: string | null | undefined, code: string, description: string, discussionQuestions?: string | null | undefined, content?: string | null | undefined, weight?: number | null | undefined, position: number, image?: string | null | undefined, coreFactor: boolean, factorType?: { __typename: 'FactorType', id: string } | null | undefined, statements: Array<{ __typename: 'Statement', id: string, statement: string }>, preSurveyOption?: Array<{ __typename: 'PreSurveyOption', id: string }> | null | undefined } | null | undefined };

export type UpdateFactorOrderMutationVariables = Exact<{
  orderInput: Array<OrderInput> | OrderInput;
}>;


export type UpdateFactorOrderMutation = { __typename: 'Mutation', updateFactorOrder: boolean };

export type DeleteFactorMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteFactorMutation = { __typename: 'Mutation', deleteFactor: { __typename: 'Response', status: string, message?: string | null | undefined } };

export type DeleteFactorTypeMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteFactorTypeMutation = { __typename: 'Mutation', deleteFactorType: { __typename: 'Response', status: string, message?: string | null | undefined } };

export type FactorQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type FactorQuery = { __typename: 'Query', factor?: { __typename: 'Factor', id: string, name: string, headline?: string | null | undefined, code: string, description: string, discussionQuestions?: string | null | undefined, content?: string | null | undefined, weight?: number | null | undefined, position: number, image?: string | null | undefined, factorType?: { __typename: 'FactorType', name: string, id: string } | null | undefined, statements: Array<{ __typename: 'Statement', id: string, statement: string, code: string }>, preSurveyOption?: Array<{ __typename: 'PreSurveyOption', id: string }> | null | undefined, survey: { __typename: 'Survey', id: string, name: string, status: SurveyStatusEnum } } | null | undefined };

export type SurveysQueryVariables = Exact<{ [key: string]: never; }>;


export type SurveysQuery = { __typename: 'Query', surveys?: Array<{ __typename: 'Survey', id: string, createdAt: any, status: SurveyStatusEnum, name: string, locale: { __typename: 'Locale', name: string, id: string }, factors: Array<{ __typename: 'Factor', id: string, name: string }> }> | null | undefined };

export type _CreatePreSurveyQuestionOptionMutationVariables = Exact<{
  input: CreatePostSurveyQuestionOptionInput;
}>;


export type _CreatePreSurveyQuestionOptionMutation = { __typename: 'Mutation', createPostSurveyQuestionOption: { __typename: 'PostSurveyOption', text: string, id: string } };

export type UpdatePostSurveyQuestionOptionMutationVariables = Exact<{
  id: Scalars['String'];
  input: UpdatePostSurveyQuestionOptionInput;
}>;


export type UpdatePostSurveyQuestionOptionMutation = { __typename: 'Mutation', updatePostSurveyQuestionOption: { __typename: 'PostSurveyOption', text: string, id: string } };

export type _PreSurveyQuestionOptionQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type _PreSurveyQuestionOptionQuery = { __typename: 'Query', preSurveyQuestionOption: { __typename: 'PreSurveyOption', text: string, code: string, index: string, factor?: { __typename: 'Factor', name: string, id: string } | null | undefined } };

export type OptionalFactorsQueryVariables = Exact<{
  surveyId: Scalars['String'];
}>;


export type OptionalFactorsQuery = { __typename: 'Query', allOptionalFactors: Array<{ __typename: 'Factor', id: string, name: string }> };

export type _PostSurveyQuestionOptionQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type _PostSurveyQuestionOptionQuery = { __typename: 'Query', postSurveyQuestionOption: { __typename: 'PostSurveyOption', text: string, code: string, index: string } };

export type CreatePreSurveyQuestionOptionMutationVariables = Exact<{
  input: CreatePreSurveyQuestionOptionInput;
}>;


export type CreatePreSurveyQuestionOptionMutation = { __typename: 'Mutation', createPreSurveyQuestionOption: { __typename: 'PreSurveyOption', text: string, id: string } };

export type UpdatePreSurveyQuestionOptionMutationVariables = Exact<{
  id: Scalars['String'];
  input: UpdatePreSurveyQuestionOptionInput;
}>;


export type UpdatePreSurveyQuestionOptionMutation = { __typename: 'Mutation', updatePreSurveyQuestionOption: { __typename: 'PreSurveyOption', text: string, id: string } };

export type PostSurveyQuestionOptionQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type PostSurveyQuestionOptionQuery = { __typename: 'Query', postSurveyQuestionOption: { __typename: 'PostSurveyOption', text: string, code: string } };

export type PreSurveyQuestionOptionQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type PreSurveyQuestionOptionQuery = { __typename: 'Query', preSurveyQuestionOption: { __typename: 'PreSurveyOption', text: string, code: string, factor?: { __typename: 'Factor', name: string, id: string, factorType?: { __typename: 'FactorType', name: string } | null | undefined, statements: Array<{ __typename: 'Statement', id: string }>, survey: { __typename: 'Survey', status: SurveyStatusEnum } } | null | undefined } };

export type CreatePreSurveyQuestionMutationVariables = Exact<{
  input: CreatePreSurveyQuestionInput;
}>;


export type CreatePreSurveyQuestionMutation = { __typename: 'Mutation', createPreSurveyQuestion: { __typename: 'PreSurveyQuestion', text: string, id: string, description?: string | null | undefined } };

export type UpdatePreSurveyQuestionMutationVariables = Exact<{
  id: Scalars['String'];
  input: UpdatePreSurveyQuestionInput;
}>;


export type UpdatePreSurveyQuestionMutation = { __typename: 'Mutation', updatePreSurveyQuestion: { __typename: 'PreSurveyQuestion', text: string, id: string, description?: string | null | undefined } };

export type PreSurveyQuestionQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type PreSurveyQuestionQuery = { __typename: 'Query', preSurveyQuestion: { __typename: 'PreSurveyQuestion', text: string, code: string, position: number, description?: string | null | undefined, id: string, preSurveyOption: Array<{ __typename: 'PreSurveyOption', id: string, text: string, code: string }> } };

export type DeletePreSurveyOptionMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeletePreSurveyOptionMutation = { __typename: 'Mutation', deletePreSurveyOption: { __typename: 'Response', status: string, message?: string | null | undefined } };

export type _PreSurveyQuestionQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type _PreSurveyQuestionQuery = { __typename: 'Query', preSurveyQuestion: { __typename: 'PreSurveyQuestion', text: string, code: string, position: number, description?: string | null | undefined, id: string, preSurvey: { __typename: 'PreSurvey', survey: { __typename: 'Survey', status: SurveyStatusEnum } }, preSurveyOption: Array<{ __typename: 'PreSurveyOption', id: string, text: string, code: string }> } };

export type CreatePostSurveyQuestionMutationVariables = Exact<{
  input: CreatePostSurveyQuestionInput;
}>;


export type CreatePostSurveyQuestionMutation = { __typename: 'Mutation', createPostSurveyQuestion: { __typename: 'PostSurveyQuestion', text: string, id: string, position: number } };

export type UpdatePostSurveyQuestionMutationVariables = Exact<{
  id: Scalars['String'];
  input: UpdatePostSurveyQuestionInput;
}>;


export type UpdatePostSurveyQuestionMutation = { __typename: 'Mutation', updatePostSurveyQuestion: { __typename: 'PostSurveyQuestion', text: string, id: string, position: number } };

export type _PostSurveyQuestionQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type _PostSurveyQuestionQuery = { __typename: 'Query', postSurveyQuestion: { __typename: 'PostSurveyQuestion', text: string, code: string, position: number, id: string, description?: string | null | undefined, responseType: ResponseTypeEnum, postSurveyOption: Array<{ __typename: 'PostSurveyOption', id: string, text: string, code: string }> } };

export type DeletePostSurveyOptionMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeletePostSurveyOptionMutation = { __typename: 'Mutation', deletePostSurveyOption: { __typename: 'Response', status: string, message?: string | null | undefined } };

export type PostSurveyQuestionQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type PostSurveyQuestionQuery = { __typename: 'Query', postSurveyQuestion: { __typename: 'PostSurveyQuestion', text: string, code: string, position: number, id: string, description?: string | null | undefined, responseType: ResponseTypeEnum, postSurveyOption: Array<{ __typename: 'PostSurveyOption', id: string, text: string, code: string, index: string, archived: boolean }> } };

export type UpdateQuestionOptionsMutationVariables = Exact<{
  options: Array<OptionInput> | OptionInput;
  questionId: Scalars['String'];
}>;


export type UpdateQuestionOptionsMutation = { __typename: 'Mutation', updateQuestionOptions: Array<{ __typename: 'PostSurveyOption', id: string, code: string, text: string, index: string }> };

export type SurveyByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type SurveyByIdQuery = { __typename: 'Query', surveyById?: { __typename: 'Survey', id: string, name: string, createdAt: any, status: SurveyStatusEnum, locale: { __typename: 'Locale', id: string, name: string }, factors: Array<{ __typename: 'Factor', id: string, name: string, headline?: string | null | undefined, factorType?: { __typename: 'FactorType', name: string } | null | undefined, statements: Array<{ __typename: 'Statement', id: string }> }> } | null | undefined, questionnaire: { __typename: 'PostSurvey', id: string, name: string, description?: string | null | undefined, headline?: string | null | undefined, questions: Array<{ __typename: 'PostSurveyQuestion', id: string }> } };

export type DeletePostSurveyMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeletePostSurveyMutation = { __typename: 'Mutation', deletePostSurvey: { __typename: 'Response', status: string } };

export type FactorTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type FactorTypesQuery = { __typename: 'Query', allFactoryTypes: Array<{ __typename: 'FactorType', id: string, name: string, code: string, description?: string | null | undefined, color: string }> };

export type CreateStatementMutationVariables = Exact<{
  input: CreateStatementInput;
}>;


export type CreateStatementMutation = { __typename: 'Mutation', createStatement: { __typename: 'Statement', statement: string, code: string, position: number, label?: string | null | undefined, survey: { __typename: 'Survey', id: string }, factor: { __typename: 'Factor', id: string } } };

export type UpdateStatementMutationVariables = Exact<{
  id: Scalars['String'];
  input: UpdateStatementInput;
}>;


export type UpdateStatementMutation = { __typename: 'Mutation', updateStatement: { __typename: 'Statement', statement: string, code: string, position: number, label?: string | null | undefined, survey: { __typename: 'Survey', id: string }, factor: { __typename: 'Factor', id: string } } };

export type StatementQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type StatementQuery = { __typename: 'Query', statement?: { __typename: 'Statement', statement: string, code: string, position: number, label?: string | null | undefined, survey: { __typename: 'Survey', id: string }, factor: { __typename: 'Factor', id: string } } | null | undefined };

export type _StatementQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type _StatementQuery = { __typename: 'Query', statement?: { __typename: 'Statement', statement: string, code: string, position: number, label?: string | null | undefined, subDimension?: string | null | undefined, factor: { __typename: 'Factor', id: string }, survey: { __typename: 'Survey', id: string } } | null | undefined };

export type UpdateStatementOrderMutationVariables = Exact<{
  orderInput: Array<OrderInput> | OrderInput;
}>;


export type UpdateStatementOrderMutation = { __typename: 'Mutation', updateStatementOrder: boolean };

export type DeleteStatementMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteStatementMutation = { __typename: 'Mutation', deleteStatement: { __typename: 'Response', status: string, message?: string | null | undefined } };

export type DeleteSurveyMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteSurveyMutation = { __typename: 'Mutation', deleteSurvey: { __typename: 'Response', status: string, message?: string | null | undefined } };

export type UnassignedOrganizationsQueryVariables = Exact<{
  peerId: Scalars['String'];
}>;


export type UnassignedOrganizationsQuery = { __typename: 'Query', unassignedOrganizations: Array<{ __typename: 'Organization', id: string, name: string, owner?: { __typename: 'User', firstName: string, lastName: string, email: string } | null | undefined, assignment?: Array<{ __typename: 'Assignment', id: string }> | null | undefined }> };

export type FindAssignmentsByPeerAndAssignmentIdQueryVariables = Exact<{
  peerId: Scalars['String'];
  assignmentId: Scalars['String'];
}>;


export type FindAssignmentsByPeerAndAssignmentIdQuery = { __typename: 'Query', findAssignmentsByPeerAndAssignmentId: { __typename: 'Assignment', id: string, name: string, organization?: { __typename: 'Organization', id: string, name: string, owner?: { __typename: 'User', firstName: string, lastName: string, email: string } | null | undefined } | null | undefined } };

export type CreateRespondentMutationVariables = Exact<{
  input: CreateRespondentInput;
  assignmentId: Scalars['String'];
}>;


export type CreateRespondentMutation = { __typename: 'Mutation', createRespondent: { __typename: 'Respondent', id: string } };

export type CreateRespondentProfileMutationVariables = Exact<{
  userId: Scalars['String'];
  assignmentId: Scalars['String'];
  input: CreateRespondentProfileInput;
}>;


export type CreateRespondentProfileMutation = { __typename: 'Mutation', createRespondentProfile: { __typename: 'Respondent', id: string } };

export type RespondentsQueryVariables = Exact<{
  assignmentId: Scalars['String'];
}>;


export type RespondentsQuery = { __typename: 'Query', respondents: Array<{ __typename: 'Respondent', id: string, user: { __typename: 'User', id: string, firstName: string, lastName: string, email: string } }> };

export type DeleteRespondentMutationVariables = Exact<{
  respondentId: Scalars['String'];
  assignmentId: Scalars['String'];
}>;


export type DeleteRespondentMutation = { __typename: 'Mutation', deleteRespondent: { __typename: 'Response', message?: string | null | undefined, status: string } };

export type UpdateAssignmentRespMutationVariables = Exact<{
  input: UpdateAssignmentInput;
  id: Scalars['String'];
}>;


export type UpdateAssignmentRespMutation = { __typename: 'Mutation', updateAssignment: { __typename: 'Assignment', id: string } };

export type SubmitScanMutationVariables = Exact<{
  message: Scalars['String'];
  assignmentId: Scalars['String'];
}>;


export type SubmitScanMutation = { __typename: 'Mutation', submitScan: boolean };

export type GetAssignmentQueryVariables = Exact<{
  assignmentId: Scalars['String'];
  orgId: Scalars['String'];
}>;


export type GetAssignmentQuery = { __typename: 'Query', assignment: { __typename: 'Assignment', name: string, dueDate: any, canViewResults: boolean, canShareResults: boolean }, organization: { __typename: 'Organization', name: string, owner?: { __typename: 'User', firstName: string, lastName: string, email: string } | null | undefined } };

export type PublishAssignmentMutationVariables = Exact<{
  invitationMessage: Scalars['String'];
  assignmentId: Scalars['String'];
  orgId: Scalars['String'];
}>;


export type PublishAssignmentMutation = { __typename: 'Mutation', publishAssignment: boolean };

export type CreateAssignmentMutationVariables = Exact<{
  input: CreateAssignmentInput;
  id?: Maybe<Scalars['String']>;
  peerId?: Maybe<Scalars['String']>;
}>;


export type CreateAssignmentMutation = { __typename: 'Mutation', createAssignment: { __typename: 'Assignment', id: string, minRespondents: number, maxRespondents: number } };

export type CreateScanMutationVariables = Exact<{
  input: CreateScanInput;
  peerId?: Maybe<Scalars['String']>;
}>;


export type CreateScanMutation = { __typename: 'Mutation', createScan: { __typename: 'Assignment', id: string } };

export type GetAssignmentDetailsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetAssignmentDetailsQuery = { __typename: 'Query', assignment: { __typename: 'Assignment', id: string, name: string, dueDate: any, canViewResults: boolean, canShareResults: boolean, invitationMessage?: string | null | undefined, status: AssignmentStatusEnum, organization?: { __typename: 'Organization', id: string, name: string, country?: string | null | undefined, owner?: { __typename: 'User', id: string, firstName: string, lastName: string, email: string } | null | undefined } | null | undefined } };

export type OrganizationsByNameQueryVariables = Exact<{
  peerId: Scalars['String'];
}>;


export type OrganizationsByNameQuery = { __typename: 'Query', organizationsByName: Array<{ __typename: 'Organization', id: string, name: string, owner?: { __typename: 'User', firstName: string, lastName: string, email: string } | null | undefined, assignment?: Array<{ __typename: 'Assignment', id: string }> | null | undefined }> };

export type PreSurveyQueryVariables = Exact<{
  assignmentId: Scalars['String'];
}>;


export type PreSurveyQuery = { __typename: 'Query', preSurveyByAssignmentId: { __typename: 'PreSurvey', id: string, name: string, description?: string | null | undefined, headline?: string | null | undefined, questions: Array<{ __typename: 'PreSurveyQuestion', code: string, id: string, text: string, preSurveyResponse?: { __typename: 'PreSurveyResponse', id: string, preSurveyOption: { __typename: 'PreSurveyOption', code: string, id: string } } | null | undefined, preSurveyOption: Array<{ __typename: 'PreSurveyOption', text: string, code: string, index: string, id: string }> }> } };

export type SubmitPreSurveyResponseMutationVariables = Exact<{
  input: CreatePreSurveyResponseInput;
}>;


export type SubmitPreSurveyResponseMutation = { __typename: 'Mutation', submitPreSurveyResponse: { __typename: 'PreSurveyResponse', id: string } };

export type CompletePreSurveyMutationVariables = Exact<{
  assignmentId: Scalars['String'];
}>;


export type CompletePreSurveyMutation = { __typename: 'Mutation', completePreSurvey: { __typename: 'Response', message?: string | null | undefined } };

export type AssignmentQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type AssignmentQuery = { __typename: 'Query', assignment: { __typename: 'Assignment', id: string, name: string, createdAt: any, dueDate: any, preSurveyStatus: RespondentSurveyStatusEnum, respondentCount: number, status: AssignmentStatusEnum } };

export type HealthCheckQueryVariables = Exact<{ [key: string]: never; }>;


export type HealthCheckQuery = { __typename: 'Query', healthCheck: boolean };

export type ValidateMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ValidateMutation = { __typename: 'Mutation', validateEmail: { __typename: 'Response', message?: string | null | undefined, token?: string | null | undefined } };

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginMutation = { __typename: 'Mutation', login: { __typename: 'Response', token?: string | null | undefined, redirect?: string | null | undefined, data?: string | null | undefined } };

export type ResendOtpMutationVariables = Exact<{
  fingerPrint: Scalars['String'];
}>;


export type ResendOtpMutation = { __typename: 'Mutation', resendOtp: { __typename: 'Response', message?: string | null | undefined, status: string } };

export type SingleOrganisationQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type SingleOrganisationQuery = { __typename: 'Query', organization: { __typename: 'Organization', name: string, internalId?: string | null | undefined, surveyId?: string | null | undefined, country?: string | null | undefined, foundedDate: any, owner?: { __typename: 'User', firstName: string, lastName: string, email: string } | null | undefined } };

export type CreateOrganizationMutationVariables = Exact<{
  input: CreateOrganizationInput;
}>;


export type CreateOrganizationMutation = { __typename: 'Mutation', createOrganization: { __typename: 'Organization', id: string, name: string, owner?: { __typename: 'User', firstName: string, lastName: string, email: string } | null | undefined } };

export type UpdateUserMutationVariables = Exact<{
  userId: Scalars['String'];
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename: 'Mutation', updateUser: { __typename: 'User', id: string, email: string, firstName: string, lastName: string, role: UserRoleEnum } };

export type CreateOrgFromPeerMutationVariables = Exact<{
  input: CreateOrganizationInput;
}>;


export type CreateOrgFromPeerMutation = { __typename: 'Mutation', createOrgFromPeer: { __typename: 'User', id: string, firstName: string, lastName: string, email: string, organization?: { __typename: 'Organization', id: string, name: string } | null | undefined } };

export type UpdateOrganizationMutationVariables = Exact<{
  orgId: Scalars['String'];
  input: UpdateOrgInput;
}>;


export type UpdateOrganizationMutation = { __typename: 'Mutation', updateOrganization: { __typename: 'Organization', id: string } };

export type OrganisationsQueryVariables = Exact<{
  sort?: Maybe<SortArgs>;
  pagination?: Maybe<PaginationArgs>;
  input?: Maybe<QueryOrgsInput>;
}>;


export type OrganisationsQuery = { __typename: 'Query', organizations: { __typename: 'OrgPaginatedResults', limit: number, totalPages: number, page: number, hasPrevPage: boolean, hasNextPage: boolean, docs: Array<{ __typename: 'Organization', name: string, createdAt: any, assignment?: Array<{ __typename: 'Assignment', id: string }> | null | undefined, owner?: { __typename: 'User', id: string, firstName: string, lastName: string } | null | undefined, user: Array<{ __typename: 'User', id: string }> }> } };

export type RegisterUserMutationVariables = Exact<{
  input: RegisterOrgInput;
}>;


export type RegisterUserMutation = { __typename: 'Mutation', register: { __typename: 'Response', message?: string | null | undefined, token?: string | null | undefined } };

export type PostSurveyQueryQueryVariables = Exact<{
  assignmentId: Scalars['String'];
}>;


export type PostSurveyQueryQuery = { __typename: 'Query', questionnaire: { __typename: 'PostSurvey', id: string, name: string, description?: string | null | undefined, headline?: string | null | undefined, status: RespondentSurveyStatusEnum, questions: Array<{ __typename: 'PostSurveyQuestion', id: string, text: string, code: string, responseType: ResponseTypeEnum, postSurveyOption: Array<{ __typename: 'PostSurveyOption', id: string, archived: boolean, text: string, index: string, code: string }> }> } };

export type SubmitPostSurveyResponsesMutationVariables = Exact<{
  input: CreateQuestionnaireResponseInput;
}>;


export type SubmitPostSurveyResponsesMutation = { __typename: 'Mutation', submitPostSurveyResponses: boolean };

export type SurveyQueryVariables = Exact<{
  respondentId: Scalars['String'];
  assignmentId: Scalars['String'];
}>;


export type SurveyQuery = { __typename: 'Query', surveyByAssignmentId: Array<{ __typename: 'Factor', id: string, name: string, image?: string | null | undefined, description: string, headline?: string | null | undefined, isFactorCompleted: boolean, factorType?: { __typename: 'FactorType', name: string, color: string } | null | undefined, statements: Array<{ __typename: 'Statement', id: string, code: string, statement: string, isCompleted: boolean, position: number }> }> };

export type StatementOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type StatementOptionsQuery = { __typename: 'Query', statementOptions?: Array<{ __typename: 'StatementAnswerOption', id: string, code: string, name: string, title: string, value: number }> | null | undefined };

export type CreateSurveyResponseMutationVariables = Exact<{
  input: CreateSurveyResponseInput;
}>;


export type CreateSurveyResponseMutation = { __typename: 'Mutation', createSurveyResponse: { __typename: 'SurveyResponse', id: string } };

export type CompleteSurveyMutationVariables = Exact<{
  respondentId: Scalars['String'];
}>;


export type CompleteSurveyMutation = { __typename: 'Mutation', completeSurvey: { __typename: 'Respondent', id: string } };

export type GetAllUserAssignmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllUserAssignmentsQuery = { __typename: 'Query', userAssignments: Array<{ __typename: 'Respondent', id: string, postSurveyStatus: RespondentSurveyStatusEnum, surveyStatus: RespondentSurveyStatusEnum, user: { __typename: 'User', email: string, firstName: string }, assignment: { __typename: 'Assignment', id: string, name: string, dueDate: any, status: AssignmentStatusEnum, survey: { __typename: 'Survey', id: string } } }> };

export type AssignmentResultsQueryVariables = Exact<{
  assignmentId: Scalars['String'];
}>;


export type AssignmentResultsQuery = { __typename: 'Query', assignmentBasicResults: { __typename: 'BasicResults', assignmentName?: string | null | undefined, statementCount: number, results: Array<{ __typename: 'Results', factorType: string, factorTypeDescription: string, factorTypeReportDescription?: string | null | undefined, color?: string | null | undefined, responses: Array<{ __typename: 'Responses', score: number, factor: { __typename: 'Factors', name: string, description?: string | null | undefined, headline?: string | null | undefined } }> }> } };

export type ResultsQueryVariables = Exact<{
  assignmentId: Scalars['String'];
}>;


export type ResultsQuery = { __typename: 'Query', basicResults: { __typename: 'BasicResults', statementCount: number, areOrgResultsAvailable?: boolean | null | undefined, results: Array<{ __typename: 'Results', factorType: string, factorTypeDescription: string, factorTypeReportDescription?: string | null | undefined, color?: string | null | undefined, responses: Array<{ __typename: 'Responses', score: number, factor: { __typename: 'Factors', name: string, description?: string | null | undefined, headline?: string | null | undefined, content?: string | null | undefined } }> }> } };

export type AssignmentBasicResultsQueryVariables = Exact<{
  assignmentId: Scalars['String'];
}>;


export type AssignmentBasicResultsQuery = { __typename: 'Query', assignmentBasicResults: { __typename: 'BasicResults', assignmentName?: string | null | undefined, statementCount: number, results: Array<{ __typename: 'Results', factorType: string, factorTypeDescription: string, factorTypeReportDescription?: string | null | undefined, color?: string | null | undefined, responses: Array<{ __typename: 'Responses', score: number, factor: { __typename: 'Factors', name: string, description?: string | null | undefined, headline?: string | null | undefined } }> }> } };

export type ReportQueryVariables = Exact<{
  assignmentId: Scalars['String'];
  isIndividual: Scalars['Boolean'];
}>;


export type ReportQuery = { __typename: 'Query', report: { __typename: 'Report', title: string, date?: any | null | undefined, overview: { __typename: 'Overview', totalAverage: number, results: Array<{ __typename: 'ReportResult', factorType: string, factorTypeDescription: string, factorTypeReportDescription?: string | null | undefined, factorTypeColor?: string | null | undefined, factors: Array<{ __typename: 'ReportFactor', headline?: string | null | undefined, average: number, averageScore: number, scores: Array<{ __typename: 'ParticipantScores', participantId: string, score: number }> }> }>, averages: Array<{ __typename: 'ParticipantScores', participantId: string, score: number }> }, detailedResults: Array<{ __typename: 'DetailedResults', factorType: string, factorTypeDescription: string, factorTypeReportDescription?: string | null | undefined, factorTypeColor?: string | null | undefined, factors: Array<{ __typename: 'DetailedFactors', headline?: string | null | undefined, content?: string | null | undefined, discussionQuestions?: string | null | undefined, factorAverage: number, statements: Array<{ __typename: 'StatementResult', subDimension?: string | null | undefined, label: string, statement: string, average: number, scores: Array<{ __typename: 'ParticipantScores', participantId: string, score: number }> }>, averages: Array<{ __typename: 'ParticipantScores', participantId: string, score: number }> }> }> } };

export type ParticipantScoreFieldsFragment = { __typename: 'ParticipantScores', participantId: string, score: number };

export type RespondentAssignmentQueryVariables = Exact<{
  respondentId: Scalars['String'];
  assignmentId: Scalars['String'];
}>;


export type RespondentAssignmentQuery = { __typename: 'Query', respondentAssignment: { __typename: 'Respondent', id: string, postSurveyStatus: RespondentSurveyStatusEnum, surveyStatus: RespondentSurveyStatusEnum, user: { __typename: 'User', email: string, firstName: string }, assignment: { __typename: 'Assignment', id: string, name: string, dueDate: any, survey: { __typename: 'Survey', id: string }, organization?: { __typename: 'Organization', id: string, name: string } | null | undefined } } };

export type GetAllUserAssignmentsSurveyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllUserAssignmentsSurveyQuery = { __typename: 'Query', userAssignments: Array<{ __typename: 'Respondent', id: string, postSurveyStatus: RespondentSurveyStatusEnum, surveyStatus: RespondentSurveyStatusEnum, user: { __typename: 'User', email: string, firstName: string }, assignment: { __typename: 'Assignment', id: string, name: string, dueDate: any, survey: { __typename: 'Survey', id: string } } }> };

export type UserDetailsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type UserDetailsQuery = { __typename: 'Query', user?: { __typename: 'User', email: string, firstName: string, lastName: string, isSuperAdmin: boolean } | null | undefined };

export type SetUserAsAdminMutationVariables = Exact<{
  userId: Scalars['String'];
  isSuperAdmin: Scalars['Boolean'];
}>;


export type SetUserAsAdminMutation = { __typename: 'Mutation', setUserAsAdmin: { __typename: 'User', id: string } };

export type CreateAdminMutationVariables = Exact<{
  input: CreateAdminInput;
}>;


export type CreateAdminMutation = { __typename: 'Mutation', createAdmin: { __typename: 'User', id: string, email: string, firstName: string, lastName: string, role: UserRoleEnum } };

export type FindOrgUsersQueryVariables = Exact<{
  sort?: Maybe<SortArgs>;
  pagination?: Maybe<PaginationArgs>;
  input?: Maybe<QueryUsersInput>;
}>;


export type FindOrgUsersQuery = { __typename: 'Query', findOrgUsers: { __typename: 'PaginatedResults', limit?: number | null | undefined, totalPages?: number | null | undefined, page?: number | null | undefined, hasPrevPage?: boolean | null | undefined, hasNextPage?: boolean | null | undefined, docs: Array<{ __typename: 'User', id: string, firstName: string, lastName: string, email: string, role: UserRoleEnum, isSuperAdmin: boolean, isPeer: boolean, isOrgLeader: boolean, organization?: { __typename: 'Organization', name: string } | null | undefined, organizations: Array<{ __typename: 'Organization', name: string }> }> } };

export type PeerUsersQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type PeerUsersQuery = { __typename: 'Query', peerUsers: Array<{ __typename: 'User', id: string, firstName: string, lastName: string, email: string, role: UserRoleEnum, organization?: { __typename: 'Organization', name: string } | null | undefined }> };

export type UserQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type UserQuery = { __typename: 'Query', user?: { __typename: 'User', id: string, createdAt: any, updatedAt: any, email: string, firstName: string, lastName: string, role: UserRoleEnum, isSuperAdmin: boolean, organization?: { __typename: 'Organization', name: string, peer?: { __typename: 'Peer', name: string } | null | undefined } | null | undefined, respondent: Array<{ __typename: 'Respondent', id: string, assignment: { __typename: 'Assignment', id: string, name: string } }> } | null | undefined };

export type UsersQueryVariables = Exact<{
  sort?: Maybe<SortArgs>;
  pagination?: Maybe<PaginationArgs>;
  input?: Maybe<QueryUsersInput>;
}>;


export type UsersQuery = { __typename: 'Query', users: { __typename: 'PaginatedResults', limit?: number | null | undefined, totalPages?: number | null | undefined, page?: number | null | undefined, hasPrevPage?: boolean | null | undefined, hasNextPage?: boolean | null | undefined, docs: Array<{ __typename: 'User', id: string, firstName: string, lastName: string, email: string, role: UserRoleEnum, isSuperAdmin: boolean, isPeer: boolean, isOrgLeader: boolean, organization?: { __typename: 'Organization', name: string } | null | undefined, organizations: Array<{ __typename: 'Organization', name: string }> }> } };

export type GetAllUsersAssignmentsWelcomeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllUsersAssignmentsWelcomeQuery = { __typename: 'Query', userAssignments: Array<{ __typename: 'Respondent', id: string, user: { __typename: 'User', email: string }, assignment: { __typename: 'Assignment', id: string, name: string, dueDate: any } }> };

export const ParticipantScoreFieldsFragmentDoc = gql`
    fragment ParticipantScoreFields on ParticipantScores {
  participantId
  score
}
    `;
export const _OrganizationAssignmentsDocument = gql`
    query _organizationAssignments($orgId: String!) {
  organizationAssignments(orgId: $orgId) {
    id
    dueDate
    status
    name
    respondentCount
    questionnaireStatus
    pastDueDate
    organization {
      name
      peer {
        id
      }
    }
    preSurveyStatus
    respondentProfile {
      id
      surveyStatus
    }
  }
}
    `;

/**
 * __use_OrganizationAssignmentsQuery__
 *
 * To run a query within a React component, call `use_OrganizationAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `use_OrganizationAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = use_OrganizationAssignmentsQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function use_OrganizationAssignmentsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<_OrganizationAssignmentsQuery, _OrganizationAssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<_OrganizationAssignmentsQuery, _OrganizationAssignmentsQueryVariables>(_OrganizationAssignmentsDocument, options);
      }
export function use_OrganizationAssignmentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<_OrganizationAssignmentsQuery, _OrganizationAssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<_OrganizationAssignmentsQuery, _OrganizationAssignmentsQueryVariables>(_OrganizationAssignmentsDocument, options);
        }
export type _OrganizationAssignmentsQueryHookResult = ReturnType<typeof use_OrganizationAssignmentsQuery>;
export type _OrganizationAssignmentsLazyQueryHookResult = ReturnType<typeof use_OrganizationAssignmentsLazyQuery>;
export type _OrganizationAssignmentsQueryResult = Apollo.QueryResult<_OrganizationAssignmentsQuery, _OrganizationAssignmentsQueryVariables>;
export const PeerAssignmentsDocument = gql`
    query PeerAssignments($peerId: String) {
  peerAssignments(peerId: $peerId) {
    id
    dueDate
    status
    name
    respondentCount
    preSurveyStatus
    organization {
      name
    }
    respondentProfile {
      id
      surveyStatus
    }
  }
}
    `;

/**
 * __usePeerAssignmentsQuery__
 *
 * To run a query within a React component, call `usePeerAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeerAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeerAssignmentsQuery({
 *   variables: {
 *      peerId: // value for 'peerId'
 *   },
 * });
 */
export function usePeerAssignmentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PeerAssignmentsQuery, PeerAssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<PeerAssignmentsQuery, PeerAssignmentsQueryVariables>(PeerAssignmentsDocument, options);
      }
export function usePeerAssignmentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PeerAssignmentsQuery, PeerAssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<PeerAssignmentsQuery, PeerAssignmentsQueryVariables>(PeerAssignmentsDocument, options);
        }
export type PeerAssignmentsQueryHookResult = ReturnType<typeof usePeerAssignmentsQuery>;
export type PeerAssignmentsLazyQueryHookResult = ReturnType<typeof usePeerAssignmentsLazyQuery>;
export type PeerAssignmentsQueryResult = Apollo.QueryResult<PeerAssignmentsQuery, PeerAssignmentsQueryVariables>;
export const AssignmentsDocument = gql`
    query Assignments($sort: SortArgs, $pagination: PaginationArgs, $input: QueryAssignmentsInput) {
  assignments(sort: $sort, pagination: $pagination, input: $input) {
    docs {
      id
      dueDate
      name
      status
      respondentCount
      organization {
        name
      }
      peer {
        name
      }
    }
    limit
    totalPages
    page
    hasPrevPage
    hasNextPage
  }
}
    `;

/**
 * __useAssignmentsQuery__
 *
 * To run a query within a React component, call `useAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignmentsQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignmentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AssignmentsQuery, AssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<AssignmentsQuery, AssignmentsQueryVariables>(AssignmentsDocument, options);
      }
export function useAssignmentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AssignmentsQuery, AssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<AssignmentsQuery, AssignmentsQueryVariables>(AssignmentsDocument, options);
        }
export type AssignmentsQueryHookResult = ReturnType<typeof useAssignmentsQuery>;
export type AssignmentsLazyQueryHookResult = ReturnType<typeof useAssignmentsLazyQuery>;
export type AssignmentsQueryResult = Apollo.QueryResult<AssignmentsQuery, AssignmentsQueryVariables>;
export const UpdateAssignmentDateDocument = gql`
    mutation updateAssignmentDate($assignmentId: String!, $input: UpdateAssignmentInput!) {
  updateAssignment(input: $input, assignmentId: $assignmentId) {
    id
    dueDate
    minRespondents
    maxRespondents
  }
}
    `;
export type UpdateAssignmentDateMutationFn = Apollo.MutationFunction<UpdateAssignmentDateMutation, UpdateAssignmentDateMutationVariables>;

/**
 * __useUpdateAssignmentDateMutation__
 *
 * To run a mutation, you first call `useUpdateAssignmentDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssignmentDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssignmentDateMutation, { data, loading, error }] = useUpdateAssignmentDateMutation({
 *   variables: {
 *      assignmentId: // value for 'assignmentId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAssignmentDateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAssignmentDateMutation, UpdateAssignmentDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateAssignmentDateMutation, UpdateAssignmentDateMutationVariables>(UpdateAssignmentDateDocument, options);
      }
export type UpdateAssignmentDateMutationHookResult = ReturnType<typeof useUpdateAssignmentDateMutation>;
export type UpdateAssignmentDateMutationResult = Apollo.MutationResult<UpdateAssignmentDateMutation>;
export type UpdateAssignmentDateMutationOptions = Apollo.BaseMutationOptions<UpdateAssignmentDateMutation, UpdateAssignmentDateMutationVariables>;
export const MyAssignedAssignmentsDocument = gql`
    query myAssignedAssignments {
  myAssignedAssignments {
    id
    dueDate
    status
    name
    respondentCount
    preSurveyStatus
    isOwner
    respondentProfile {
      id
      surveyStatus
    }
    organization {
      id
    }
  }
}
    `;

/**
 * __useMyAssignedAssignmentsQuery__
 *
 * To run a query within a React component, call `useMyAssignedAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyAssignedAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyAssignedAssignmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyAssignedAssignmentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyAssignedAssignmentsQuery, MyAssignedAssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<MyAssignedAssignmentsQuery, MyAssignedAssignmentsQueryVariables>(MyAssignedAssignmentsDocument, options);
      }
export function useMyAssignedAssignmentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyAssignedAssignmentsQuery, MyAssignedAssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<MyAssignedAssignmentsQuery, MyAssignedAssignmentsQueryVariables>(MyAssignedAssignmentsDocument, options);
        }
export type MyAssignedAssignmentsQueryHookResult = ReturnType<typeof useMyAssignedAssignmentsQuery>;
export type MyAssignedAssignmentsLazyQueryHookResult = ReturnType<typeof useMyAssignedAssignmentsLazyQuery>;
export type MyAssignedAssignmentsQueryResult = Apollo.QueryResult<MyAssignedAssignmentsQuery, MyAssignedAssignmentsQueryVariables>;
export const ResendInvitationDocument = gql`
    mutation ResendInvitation($respondentId: String!, $assignmentId: String!) {
  resendInvitation(respondentId: $respondentId, assignmentId: $assignmentId)
}
    `;
export type ResendInvitationMutationFn = Apollo.MutationFunction<ResendInvitationMutation, ResendInvitationMutationVariables>;

/**
 * __useResendInvitationMutation__
 *
 * To run a mutation, you first call `useResendInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendInvitationMutation, { data, loading, error }] = useResendInvitationMutation({
 *   variables: {
 *      respondentId: // value for 'respondentId'
 *      assignmentId: // value for 'assignmentId'
 *   },
 * });
 */
export function useResendInvitationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResendInvitationMutation, ResendInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ResendInvitationMutation, ResendInvitationMutationVariables>(ResendInvitationDocument, options);
      }
export type ResendInvitationMutationHookResult = ReturnType<typeof useResendInvitationMutation>;
export type ResendInvitationMutationResult = Apollo.MutationResult<ResendInvitationMutation>;
export type ResendInvitationMutationOptions = Apollo.BaseMutationOptions<ResendInvitationMutation, ResendInvitationMutationVariables>;
export const SingleAssignmentDocument = gql`
    query SingleAssignment($id: String!) {
  assignment(id: $id) {
    id
    name
    createdAt
    dueDate
    preSurveyStatus
    respondentCount
    status
  }
  respondents(assignmentId: $id) {
    id
    isInvitationSent
    surveyStatus
    completedDate
    user {
      id
      firstName
      lastName
      email
    }
  }
}
    `;

/**
 * __useSingleAssignmentQuery__
 *
 * To run a query within a React component, call `useSingleAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useSingleAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSingleAssignmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSingleAssignmentQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SingleAssignmentQuery, SingleAssignmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SingleAssignmentQuery, SingleAssignmentQueryVariables>(SingleAssignmentDocument, options);
      }
export function useSingleAssignmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SingleAssignmentQuery, SingleAssignmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SingleAssignmentQuery, SingleAssignmentQueryVariables>(SingleAssignmentDocument, options);
        }
export type SingleAssignmentQueryHookResult = ReturnType<typeof useSingleAssignmentQuery>;
export type SingleAssignmentLazyQueryHookResult = ReturnType<typeof useSingleAssignmentLazyQuery>;
export type SingleAssignmentQueryResult = Apollo.QueryResult<SingleAssignmentQuery, SingleAssignmentQueryVariables>;
export const RegisterPeerDocument = gql`
    mutation registerPeer($input: RegisterPeerInput!, $addAdminAsPeer: Boolean) {
  registerPeer(input: $input, addAdminAsPeer: $addAdminAsPeer) {
    email
    firstName
    lastName
  }
}
    `;
export type RegisterPeerMutationFn = Apollo.MutationFunction<RegisterPeerMutation, RegisterPeerMutationVariables>;

/**
 * __useRegisterPeerMutation__
 *
 * To run a mutation, you first call `useRegisterPeerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterPeerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerPeerMutation, { data, loading, error }] = useRegisterPeerMutation({
 *   variables: {
 *      input: // value for 'input'
 *      addAdminAsPeer: // value for 'addAdminAsPeer'
 *   },
 * });
 */
export function useRegisterPeerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegisterPeerMutation, RegisterPeerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RegisterPeerMutation, RegisterPeerMutationVariables>(RegisterPeerDocument, options);
      }
export type RegisterPeerMutationHookResult = ReturnType<typeof useRegisterPeerMutation>;
export type RegisterPeerMutationResult = Apollo.MutationResult<RegisterPeerMutation>;
export type RegisterPeerMutationOptions = Apollo.BaseMutationOptions<RegisterPeerMutation, RegisterPeerMutationVariables>;
export const UpdatePeerDocument = gql`
    mutation updatePeer($id: String!, $input: UpdatePeerInput!) {
  updatePeer(id: $id, input: $input) {
    id
  }
}
    `;
export type UpdatePeerMutationFn = Apollo.MutationFunction<UpdatePeerMutation, UpdatePeerMutationVariables>;

/**
 * __useUpdatePeerMutation__
 *
 * To run a mutation, you first call `useUpdatePeerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePeerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePeerMutation, { data, loading, error }] = useUpdatePeerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePeerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePeerMutation, UpdatePeerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdatePeerMutation, UpdatePeerMutationVariables>(UpdatePeerDocument, options);
      }
export type UpdatePeerMutationHookResult = ReturnType<typeof useUpdatePeerMutation>;
export type UpdatePeerMutationResult = Apollo.MutationResult<UpdatePeerMutation>;
export type UpdatePeerMutationOptions = Apollo.BaseMutationOptions<UpdatePeerMutation, UpdatePeerMutationVariables>;
export const PeersDocument = gql`
    query Peers {
  allPeerUsers {
    firstName
    lastName
    role
    email
    organization {
      name
    }
    peer {
      id
      name
      country
    }
  }
}
    `;

/**
 * __usePeersQuery__
 *
 * To run a query within a React component, call `usePeersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeersQuery({
 *   variables: {
 *   },
 * });
 */
export function usePeersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PeersQuery, PeersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<PeersQuery, PeersQueryVariables>(PeersDocument, options);
      }
export function usePeersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PeersQuery, PeersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<PeersQuery, PeersQueryVariables>(PeersDocument, options);
        }
export type PeersQueryHookResult = ReturnType<typeof usePeersQuery>;
export type PeersLazyQueryHookResult = ReturnType<typeof usePeersLazyQuery>;
export type PeersQueryResult = Apollo.QueryResult<PeersQuery, PeersQueryVariables>;
export const GetQuestionnaireDocument = gql`
    query getQuestionnaire($name: String!) {
  getQuestionnaire(name: $name) {
    id
    name
    description
    headline
    questionnaireQuestion {
      id
      text
      position
      name
    }
  }
}
    `;

/**
 * __useGetQuestionnaireQuery__
 *
 * To run a query within a React component, call `useGetQuestionnaireQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionnaireQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionnaireQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetQuestionnaireQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetQuestionnaireQuery, GetQuestionnaireQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetQuestionnaireQuery, GetQuestionnaireQueryVariables>(GetQuestionnaireDocument, options);
      }
export function useGetQuestionnaireLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetQuestionnaireQuery, GetQuestionnaireQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetQuestionnaireQuery, GetQuestionnaireQueryVariables>(GetQuestionnaireDocument, options);
        }
export type GetQuestionnaireQueryHookResult = ReturnType<typeof useGetQuestionnaireQuery>;
export type GetQuestionnaireLazyQueryHookResult = ReturnType<typeof useGetQuestionnaireLazyQuery>;
export type GetQuestionnaireQueryResult = Apollo.QueryResult<GetQuestionnaireQuery, GetQuestionnaireQueryVariables>;
export const CreateQuestionnaireResponsesDocument = gql`
    mutation createQuestionnaireResponses($answers: [Answers!]!, $assignmentId: String!) {
  createQuestionnaireResponses(answers: $answers, assignmentId: $assignmentId) {
    message
    status
    redirect
  }
}
    `;
export type CreateQuestionnaireResponsesMutationFn = Apollo.MutationFunction<CreateQuestionnaireResponsesMutation, CreateQuestionnaireResponsesMutationVariables>;

/**
 * __useCreateQuestionnaireResponsesMutation__
 *
 * To run a mutation, you first call `useCreateQuestionnaireResponsesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuestionnaireResponsesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuestionnaireResponsesMutation, { data, loading, error }] = useCreateQuestionnaireResponsesMutation({
 *   variables: {
 *      answers: // value for 'answers'
 *      assignmentId: // value for 'assignmentId'
 *   },
 * });
 */
export function useCreateQuestionnaireResponsesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateQuestionnaireResponsesMutation, CreateQuestionnaireResponsesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateQuestionnaireResponsesMutation, CreateQuestionnaireResponsesMutationVariables>(CreateQuestionnaireResponsesDocument, options);
      }
export type CreateQuestionnaireResponsesMutationHookResult = ReturnType<typeof useCreateQuestionnaireResponsesMutation>;
export type CreateQuestionnaireResponsesMutationResult = Apollo.MutationResult<CreateQuestionnaireResponsesMutation>;
export type CreateQuestionnaireResponsesMutationOptions = Apollo.BaseMutationOptions<CreateQuestionnaireResponsesMutation, CreateQuestionnaireResponsesMutationVariables>;
export const OrganizationAssignmentsDocument = gql`
    query OrganizationAssignments($orgId: String!) {
  organizationAssignments(orgId: $orgId) {
    id
    dueDate
    status
    name
    respondentCount
    questionnaireStatus
    organization {
      name
    }
    preSurveyStatus
    respondentProfile {
      id
      surveyStatus
    }
  }
}
    `;

/**
 * __useOrganizationAssignmentsQuery__
 *
 * To run a query within a React component, call `useOrganizationAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationAssignmentsQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useOrganizationAssignmentsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<OrganizationAssignmentsQuery, OrganizationAssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<OrganizationAssignmentsQuery, OrganizationAssignmentsQueryVariables>(OrganizationAssignmentsDocument, options);
      }
export function useOrganizationAssignmentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationAssignmentsQuery, OrganizationAssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<OrganizationAssignmentsQuery, OrganizationAssignmentsQueryVariables>(OrganizationAssignmentsDocument, options);
        }
export type OrganizationAssignmentsQueryHookResult = ReturnType<typeof useOrganizationAssignmentsQuery>;
export type OrganizationAssignmentsLazyQueryHookResult = ReturnType<typeof useOrganizationAssignmentsLazyQuery>;
export type OrganizationAssignmentsQueryResult = Apollo.QueryResult<OrganizationAssignmentsQuery, OrganizationAssignmentsQueryVariables>;
export const CreateOrgDocument = gql`
    mutation createOrg($name: String!, $country: String!) {
  createOrg(name: $name, country: $country) {
    id
  }
}
    `;
export type CreateOrgMutationFn = Apollo.MutationFunction<CreateOrgMutation, CreateOrgMutationVariables>;

/**
 * __useCreateOrgMutation__
 *
 * To run a mutation, you first call `useCreateOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrgMutation, { data, loading, error }] = useCreateOrgMutation({
 *   variables: {
 *      name: // value for 'name'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useCreateOrgMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrgMutation, CreateOrgMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateOrgMutation, CreateOrgMutationVariables>(CreateOrgDocument, options);
      }
export type CreateOrgMutationHookResult = ReturnType<typeof useCreateOrgMutation>;
export type CreateOrgMutationResult = Apollo.MutationResult<CreateOrgMutation>;
export type CreateOrgMutationOptions = Apollo.BaseMutationOptions<CreateOrgMutation, CreateOrgMutationVariables>;
export const _GetAllUserAssignmentsDocument = gql`
    query _getAllUserAssignments {
  myAssignments {
    id
    dueDate
    status
    name
    respondentCount
    preSurveyStatus
    isOwner
    respondentProfile {
      id
      surveyStatus
    }
    organization {
      id
    }
  }
}
    `;

/**
 * __use_GetAllUserAssignmentsQuery__
 *
 * To run a query within a React component, call `use_GetAllUserAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `use_GetAllUserAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = use_GetAllUserAssignmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function use_GetAllUserAssignmentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<_GetAllUserAssignmentsQuery, _GetAllUserAssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<_GetAllUserAssignmentsQuery, _GetAllUserAssignmentsQueryVariables>(_GetAllUserAssignmentsDocument, options);
      }
export function use_GetAllUserAssignmentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<_GetAllUserAssignmentsQuery, _GetAllUserAssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<_GetAllUserAssignmentsQuery, _GetAllUserAssignmentsQueryVariables>(_GetAllUserAssignmentsDocument, options);
        }
export type _GetAllUserAssignmentsQueryHookResult = ReturnType<typeof use_GetAllUserAssignmentsQuery>;
export type _GetAllUserAssignmentsLazyQueryHookResult = ReturnType<typeof use_GetAllUserAssignmentsLazyQuery>;
export type _GetAllUserAssignmentsQueryResult = Apollo.QueryResult<_GetAllUserAssignmentsQuery, _GetAllUserAssignmentsQueryVariables>;
export const CreateSurveyDocument = gql`
    mutation createSurvey($input: CreateSurveyInput!) {
  createSurvey(input: $input) {
    id
    name
  }
}
    `;
export type CreateSurveyMutationFn = Apollo.MutationFunction<CreateSurveyMutation, CreateSurveyMutationVariables>;

/**
 * __useCreateSurveyMutation__
 *
 * To run a mutation, you first call `useCreateSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSurveyMutation, { data, loading, error }] = useCreateSurveyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSurveyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSurveyMutation, CreateSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateSurveyMutation, CreateSurveyMutationVariables>(CreateSurveyDocument, options);
      }
export type CreateSurveyMutationHookResult = ReturnType<typeof useCreateSurveyMutation>;
export type CreateSurveyMutationResult = Apollo.MutationResult<CreateSurveyMutation>;
export type CreateSurveyMutationOptions = Apollo.BaseMutationOptions<CreateSurveyMutation, CreateSurveyMutationVariables>;
export const UpdateSurveyDocument = gql`
    mutation updateSurvey($input: UpdateSurveyInput!, $surveyId: String!) {
  updateSurvey(input: $input, surveyId: $surveyId) {
    id
    name
  }
}
    `;
export type UpdateSurveyMutationFn = Apollo.MutationFunction<UpdateSurveyMutation, UpdateSurveyMutationVariables>;

/**
 * __useUpdateSurveyMutation__
 *
 * To run a mutation, you first call `useUpdateSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSurveyMutation, { data, loading, error }] = useUpdateSurveyMutation({
 *   variables: {
 *      input: // value for 'input'
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useUpdateSurveyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSurveyMutation, UpdateSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateSurveyMutation, UpdateSurveyMutationVariables>(UpdateSurveyDocument, options);
      }
export type UpdateSurveyMutationHookResult = ReturnType<typeof useUpdateSurveyMutation>;
export type UpdateSurveyMutationResult = Apollo.MutationResult<UpdateSurveyMutation>;
export type UpdateSurveyMutationOptions = Apollo.BaseMutationOptions<UpdateSurveyMutation, UpdateSurveyMutationVariables>;
export const GetLocalesQueryDocument = gql`
    query GetLocalesQuery {
  locales {
    id
    name
    code
  }
}
    `;

/**
 * __useGetLocalesQueryQuery__
 *
 * To run a query within a React component, call `useGetLocalesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocalesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocalesQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLocalesQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLocalesQueryQuery, GetLocalesQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetLocalesQueryQuery, GetLocalesQueryQueryVariables>(GetLocalesQueryDocument, options);
      }
export function useGetLocalesQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLocalesQueryQuery, GetLocalesQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetLocalesQueryQuery, GetLocalesQueryQueryVariables>(GetLocalesQueryDocument, options);
        }
export type GetLocalesQueryQueryHookResult = ReturnType<typeof useGetLocalesQueryQuery>;
export type GetLocalesQueryLazyQueryHookResult = ReturnType<typeof useGetLocalesQueryLazyQuery>;
export type GetLocalesQueryQueryResult = Apollo.QueryResult<GetLocalesQueryQuery, GetLocalesQueryQueryVariables>;
export const _SurveyDocument = gql`
    query _survey($id: String!) {
  surveyById(id: $id) {
    id
    name
    locale {
      id
      code
      name
    }
  }
}
    `;

/**
 * __use_SurveyQuery__
 *
 * To run a query within a React component, call `use_SurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `use_SurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = use_SurveyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function use_SurveyQuery(baseOptions: ApolloReactHooks.QueryHookOptions<_SurveyQuery, _SurveyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<_SurveyQuery, _SurveyQueryVariables>(_SurveyDocument, options);
      }
export function use_SurveyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<_SurveyQuery, _SurveyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<_SurveyQuery, _SurveyQueryVariables>(_SurveyDocument, options);
        }
export type _SurveyQueryHookResult = ReturnType<typeof use_SurveyQuery>;
export type _SurveyLazyQueryHookResult = ReturnType<typeof use_SurveyLazyQuery>;
export type _SurveyQueryResult = Apollo.QueryResult<_SurveyQuery, _SurveyQueryVariables>;
export const _PreSurveyByIdDocument = gql`
    query _preSurveyById($preSurveyId: String!) {
  preSurveyById(preSurveyId: $preSurveyId) {
    id
    name
    headline
    createdAt
    description
    questions {
      text
      description
      preSurveyOption {
        id
        text
        code
      }
      code
    }
    locale {
      id
      name
    }
  }
}
    `;

/**
 * __use_PreSurveyByIdQuery__
 *
 * To run a query within a React component, call `use_PreSurveyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `use_PreSurveyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = use_PreSurveyByIdQuery({
 *   variables: {
 *      preSurveyId: // value for 'preSurveyId'
 *   },
 * });
 */
export function use_PreSurveyByIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<_PreSurveyByIdQuery, _PreSurveyByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<_PreSurveyByIdQuery, _PreSurveyByIdQueryVariables>(_PreSurveyByIdDocument, options);
      }
export function use_PreSurveyByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<_PreSurveyByIdQuery, _PreSurveyByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<_PreSurveyByIdQuery, _PreSurveyByIdQueryVariables>(_PreSurveyByIdDocument, options);
        }
export type _PreSurveyByIdQueryHookResult = ReturnType<typeof use_PreSurveyByIdQuery>;
export type _PreSurveyByIdLazyQueryHookResult = ReturnType<typeof use_PreSurveyByIdLazyQuery>;
export type _PreSurveyByIdQueryResult = Apollo.QueryResult<_PreSurveyByIdQuery, _PreSurveyByIdQueryVariables>;
export const PreSurveyLocalesDocument = gql`
    query preSurveyLocales {
  locales {
    id
    name
    code
  }
}
    `;

/**
 * __usePreSurveyLocalesQuery__
 *
 * To run a query within a React component, call `usePreSurveyLocalesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreSurveyLocalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreSurveyLocalesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePreSurveyLocalesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PreSurveyLocalesQuery, PreSurveyLocalesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<PreSurveyLocalesQuery, PreSurveyLocalesQueryVariables>(PreSurveyLocalesDocument, options);
      }
export function usePreSurveyLocalesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PreSurveyLocalesQuery, PreSurveyLocalesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<PreSurveyLocalesQuery, PreSurveyLocalesQueryVariables>(PreSurveyLocalesDocument, options);
        }
export type PreSurveyLocalesQueryHookResult = ReturnType<typeof usePreSurveyLocalesQuery>;
export type PreSurveyLocalesLazyQueryHookResult = ReturnType<typeof usePreSurveyLocalesLazyQuery>;
export type PreSurveyLocalesQueryResult = Apollo.QueryResult<PreSurveyLocalesQuery, PreSurveyLocalesQueryVariables>;
export const _PostSurveyByIdDocument = gql`
    query _postSurveyById($postSurveyId: String!) {
  postSurvey(id: $postSurveyId) {
    id
    name
    headline
    createdAt
    description
    questions {
      text
      postSurveyOption {
        id
        text
        code
      }
      code
    }
    locale {
      id
      name
    }
  }
}
    `;

/**
 * __use_PostSurveyByIdQuery__
 *
 * To run a query within a React component, call `use_PostSurveyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `use_PostSurveyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = use_PostSurveyByIdQuery({
 *   variables: {
 *      postSurveyId: // value for 'postSurveyId'
 *   },
 * });
 */
export function use_PostSurveyByIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<_PostSurveyByIdQuery, _PostSurveyByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<_PostSurveyByIdQuery, _PostSurveyByIdQueryVariables>(_PostSurveyByIdDocument, options);
      }
export function use_PostSurveyByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<_PostSurveyByIdQuery, _PostSurveyByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<_PostSurveyByIdQuery, _PostSurveyByIdQueryVariables>(_PostSurveyByIdDocument, options);
        }
export type _PostSurveyByIdQueryHookResult = ReturnType<typeof use_PostSurveyByIdQuery>;
export type _PostSurveyByIdLazyQueryHookResult = ReturnType<typeof use_PostSurveyByIdLazyQuery>;
export type _PostSurveyByIdQueryResult = Apollo.QueryResult<_PostSurveyByIdQuery, _PostSurveyByIdQueryVariables>;
export const PostSurveyLocalesDocument = gql`
    query postSurveyLocales {
  locales {
    id
    name
    code
  }
}
    `;

/**
 * __usePostSurveyLocalesQuery__
 *
 * To run a query within a React component, call `usePostSurveyLocalesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostSurveyLocalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostSurveyLocalesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePostSurveyLocalesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PostSurveyLocalesQuery, PostSurveyLocalesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<PostSurveyLocalesQuery, PostSurveyLocalesQueryVariables>(PostSurveyLocalesDocument, options);
      }
export function usePostSurveyLocalesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PostSurveyLocalesQuery, PostSurveyLocalesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<PostSurveyLocalesQuery, PostSurveyLocalesQueryVariables>(PostSurveyLocalesDocument, options);
        }
export type PostSurveyLocalesQueryHookResult = ReturnType<typeof usePostSurveyLocalesQuery>;
export type PostSurveyLocalesLazyQueryHookResult = ReturnType<typeof usePostSurveyLocalesLazyQuery>;
export type PostSurveyLocalesQueryResult = Apollo.QueryResult<PostSurveyLocalesQuery, PostSurveyLocalesQueryVariables>;
export const CreatePreSurveyDocument = gql`
    mutation createPreSurvey($input: CreatePreSurveyInput!) {
  createPreSurvey(input: $input) {
    name
    id
    description
    headline
  }
}
    `;
export type CreatePreSurveyMutationFn = Apollo.MutationFunction<CreatePreSurveyMutation, CreatePreSurveyMutationVariables>;

/**
 * __useCreatePreSurveyMutation__
 *
 * To run a mutation, you first call `useCreatePreSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePreSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPreSurveyMutation, { data, loading, error }] = useCreatePreSurveyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePreSurveyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePreSurveyMutation, CreatePreSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreatePreSurveyMutation, CreatePreSurveyMutationVariables>(CreatePreSurveyDocument, options);
      }
export type CreatePreSurveyMutationHookResult = ReturnType<typeof useCreatePreSurveyMutation>;
export type CreatePreSurveyMutationResult = Apollo.MutationResult<CreatePreSurveyMutation>;
export type CreatePreSurveyMutationOptions = Apollo.BaseMutationOptions<CreatePreSurveyMutation, CreatePreSurveyMutationVariables>;
export const UpdatePreSurveyDocument = gql`
    mutation updatePreSurvey($input: UpdatePreSurveyInput!, $preSurveyId: String!) {
  updatePreSurvey(input: $input, preSurveyId: $preSurveyId) {
    id
    name
  }
}
    `;
export type UpdatePreSurveyMutationFn = Apollo.MutationFunction<UpdatePreSurveyMutation, UpdatePreSurveyMutationVariables>;

/**
 * __useUpdatePreSurveyMutation__
 *
 * To run a mutation, you first call `useUpdatePreSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePreSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePreSurveyMutation, { data, loading, error }] = useUpdatePreSurveyMutation({
 *   variables: {
 *      input: // value for 'input'
 *      preSurveyId: // value for 'preSurveyId'
 *   },
 * });
 */
export function useUpdatePreSurveyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePreSurveyMutation, UpdatePreSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdatePreSurveyMutation, UpdatePreSurveyMutationVariables>(UpdatePreSurveyDocument, options);
      }
export type UpdatePreSurveyMutationHookResult = ReturnType<typeof useUpdatePreSurveyMutation>;
export type UpdatePreSurveyMutationResult = Apollo.MutationResult<UpdatePreSurveyMutation>;
export type UpdatePreSurveyMutationOptions = Apollo.BaseMutationOptions<UpdatePreSurveyMutation, UpdatePreSurveyMutationVariables>;
export const CreatePostSurveyDocument = gql`
    mutation createPostSurvey($input: CreatePostSurveyInput!) {
  createPostSurvey(input: $input) {
    name
    id
    description
    headline
  }
}
    `;
export type CreatePostSurveyMutationFn = Apollo.MutationFunction<CreatePostSurveyMutation, CreatePostSurveyMutationVariables>;

/**
 * __useCreatePostSurveyMutation__
 *
 * To run a mutation, you first call `useCreatePostSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostSurveyMutation, { data, loading, error }] = useCreatePostSurveyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePostSurveyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePostSurveyMutation, CreatePostSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreatePostSurveyMutation, CreatePostSurveyMutationVariables>(CreatePostSurveyDocument, options);
      }
export type CreatePostSurveyMutationHookResult = ReturnType<typeof useCreatePostSurveyMutation>;
export type CreatePostSurveyMutationResult = Apollo.MutationResult<CreatePostSurveyMutation>;
export type CreatePostSurveyMutationOptions = Apollo.BaseMutationOptions<CreatePostSurveyMutation, CreatePostSurveyMutationVariables>;
export const UpdatePostSurveyDocument = gql`
    mutation updatePostSurvey($input: UpdatePostSurveyInput!, $id: String!) {
  updatePostSurvey(input: $input, id: $id) {
    id
    name
  }
}
    `;
export type UpdatePostSurveyMutationFn = Apollo.MutationFunction<UpdatePostSurveyMutation, UpdatePostSurveyMutationVariables>;

/**
 * __useUpdatePostSurveyMutation__
 *
 * To run a mutation, you first call `useUpdatePostSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostSurveyMutation, { data, loading, error }] = useUpdatePostSurveyMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdatePostSurveyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePostSurveyMutation, UpdatePostSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdatePostSurveyMutation, UpdatePostSurveyMutationVariables>(UpdatePostSurveyDocument, options);
      }
export type UpdatePostSurveyMutationHookResult = ReturnType<typeof useUpdatePostSurveyMutation>;
export type UpdatePostSurveyMutationResult = Apollo.MutationResult<UpdatePostSurveyMutation>;
export type UpdatePostSurveyMutationOptions = Apollo.BaseMutationOptions<UpdatePostSurveyMutation, UpdatePostSurveyMutationVariables>;
export const _DeletePreSurveyDocument = gql`
    mutation _deletePreSurvey($id: String!) {
  deletePreSurvey(id: $id) {
    status
    message
  }
}
    `;
export type _DeletePreSurveyMutationFn = Apollo.MutationFunction<_DeletePreSurveyMutation, _DeletePreSurveyMutationVariables>;

/**
 * __use_DeletePreSurveyMutation__
 *
 * To run a mutation, you first call `use_DeletePreSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `use_DeletePreSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePreSurveyMutation, { data, loading, error }] = use_DeletePreSurveyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function use_DeletePreSurveyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<_DeletePreSurveyMutation, _DeletePreSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<_DeletePreSurveyMutation, _DeletePreSurveyMutationVariables>(_DeletePreSurveyDocument, options);
      }
export type _DeletePreSurveyMutationHookResult = ReturnType<typeof use_DeletePreSurveyMutation>;
export type _DeletePreSurveyMutationResult = Apollo.MutationResult<_DeletePreSurveyMutation>;
export type _DeletePreSurveyMutationOptions = Apollo.BaseMutationOptions<_DeletePreSurveyMutation, _DeletePreSurveyMutationVariables>;
export const DeletePostDocument = gql`
    mutation deletePost($id: String!) {
  deletePostSurvey(id: $id) {
    status
    message
  }
}
    `;
export type DeletePostMutationFn = Apollo.MutationFunction<DeletePostMutation, DeletePostMutationVariables>;

/**
 * __useDeletePostMutation__
 *
 * To run a mutation, you first call `useDeletePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePostMutation, { data, loading, error }] = useDeletePostMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePostMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePostMutation, DeletePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeletePostMutation, DeletePostMutationVariables>(DeletePostDocument, options);
      }
export type DeletePostMutationHookResult = ReturnType<typeof useDeletePostMutation>;
export type DeletePostMutationResult = Apollo.MutationResult<DeletePostMutation>;
export type DeletePostMutationOptions = Apollo.BaseMutationOptions<DeletePostMutation, DeletePostMutationVariables>;
export const DeletePostSurveyQuestionDocument = gql`
    mutation deletePostSurveyQuestion($id: String!) {
  deletePostSurveyQuestion(id: $id) {
    status
    message
  }
}
    `;
export type DeletePostSurveyQuestionMutationFn = Apollo.MutationFunction<DeletePostSurveyQuestionMutation, DeletePostSurveyQuestionMutationVariables>;

/**
 * __useDeletePostSurveyQuestionMutation__
 *
 * To run a mutation, you first call `useDeletePostSurveyQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePostSurveyQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePostSurveyQuestionMutation, { data, loading, error }] = useDeletePostSurveyQuestionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePostSurveyQuestionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePostSurveyQuestionMutation, DeletePostSurveyQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeletePostSurveyQuestionMutation, DeletePostSurveyQuestionMutationVariables>(DeletePostSurveyQuestionDocument, options);
      }
export type DeletePostSurveyQuestionMutationHookResult = ReturnType<typeof useDeletePostSurveyQuestionMutation>;
export type DeletePostSurveyQuestionMutationResult = Apollo.MutationResult<DeletePostSurveyQuestionMutation>;
export type DeletePostSurveyQuestionMutationOptions = Apollo.BaseMutationOptions<DeletePostSurveyQuestionMutation, DeletePostSurveyQuestionMutationVariables>;
export const UpdatePreSurveyQuestionOrderDocument = gql`
    mutation updatePreSurveyQuestionOrder($orderInput: [OrderInput!]!) {
  updatePreSurveyQuestionOrder(orderInput: $orderInput)
}
    `;
export type UpdatePreSurveyQuestionOrderMutationFn = Apollo.MutationFunction<UpdatePreSurveyQuestionOrderMutation, UpdatePreSurveyQuestionOrderMutationVariables>;

/**
 * __useUpdatePreSurveyQuestionOrderMutation__
 *
 * To run a mutation, you first call `useUpdatePreSurveyQuestionOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePreSurveyQuestionOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePreSurveyQuestionOrderMutation, { data, loading, error }] = useUpdatePreSurveyQuestionOrderMutation({
 *   variables: {
 *      orderInput: // value for 'orderInput'
 *   },
 * });
 */
export function useUpdatePreSurveyQuestionOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePreSurveyQuestionOrderMutation, UpdatePreSurveyQuestionOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdatePreSurveyQuestionOrderMutation, UpdatePreSurveyQuestionOrderMutationVariables>(UpdatePreSurveyQuestionOrderDocument, options);
      }
export type UpdatePreSurveyQuestionOrderMutationHookResult = ReturnType<typeof useUpdatePreSurveyQuestionOrderMutation>;
export type UpdatePreSurveyQuestionOrderMutationResult = Apollo.MutationResult<UpdatePreSurveyQuestionOrderMutation>;
export type UpdatePreSurveyQuestionOrderMutationOptions = Apollo.BaseMutationOptions<UpdatePreSurveyQuestionOrderMutation, UpdatePreSurveyQuestionOrderMutationVariables>;
export const UpdatePostSurveyQuestionOrderDocument = gql`
    mutation updatePostSurveyQuestionOrder($orderInput: [OrderInput!]!) {
  updatePostSurveyQuestionOrder(orderInput: $orderInput)
}
    `;
export type UpdatePostSurveyQuestionOrderMutationFn = Apollo.MutationFunction<UpdatePostSurveyQuestionOrderMutation, UpdatePostSurveyQuestionOrderMutationVariables>;

/**
 * __useUpdatePostSurveyQuestionOrderMutation__
 *
 * To run a mutation, you first call `useUpdatePostSurveyQuestionOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostSurveyQuestionOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostSurveyQuestionOrderMutation, { data, loading, error }] = useUpdatePostSurveyQuestionOrderMutation({
 *   variables: {
 *      orderInput: // value for 'orderInput'
 *   },
 * });
 */
export function useUpdatePostSurveyQuestionOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePostSurveyQuestionOrderMutation, UpdatePostSurveyQuestionOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdatePostSurveyQuestionOrderMutation, UpdatePostSurveyQuestionOrderMutationVariables>(UpdatePostSurveyQuestionOrderDocument, options);
      }
export type UpdatePostSurveyQuestionOrderMutationHookResult = ReturnType<typeof useUpdatePostSurveyQuestionOrderMutation>;
export type UpdatePostSurveyQuestionOrderMutationResult = Apollo.MutationResult<UpdatePostSurveyQuestionOrderMutation>;
export type UpdatePostSurveyQuestionOrderMutationOptions = Apollo.BaseMutationOptions<UpdatePostSurveyQuestionOrderMutation, UpdatePostSurveyQuestionOrderMutationVariables>;
export const PreSurveyByIdDocument = gql`
    query preSurveyById($preSurveyId: String!) {
  preSurveyById(preSurveyId: $preSurveyId) {
    id
    name
    headline
    createdAt
    description
    survey {
      status
    }
    questions {
      id
      text
      description
      preSurveyOption {
        id
        text
        code
      }
      code
    }
    locale {
      id
      name
    }
  }
}
    `;

/**
 * __usePreSurveyByIdQuery__
 *
 * To run a query within a React component, call `usePreSurveyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreSurveyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreSurveyByIdQuery({
 *   variables: {
 *      preSurveyId: // value for 'preSurveyId'
 *   },
 * });
 */
export function usePreSurveyByIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<PreSurveyByIdQuery, PreSurveyByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<PreSurveyByIdQuery, PreSurveyByIdQueryVariables>(PreSurveyByIdDocument, options);
      }
export function usePreSurveyByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PreSurveyByIdQuery, PreSurveyByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<PreSurveyByIdQuery, PreSurveyByIdQueryVariables>(PreSurveyByIdDocument, options);
        }
export type PreSurveyByIdQueryHookResult = ReturnType<typeof usePreSurveyByIdQuery>;
export type PreSurveyByIdLazyQueryHookResult = ReturnType<typeof usePreSurveyByIdLazyQuery>;
export type PreSurveyByIdQueryResult = Apollo.QueryResult<PreSurveyByIdQuery, PreSurveyByIdQueryVariables>;
export const PostSurveyByIdDocument = gql`
    query postSurveyById {
  questionnaire {
    id
    name
    description
    headline
    questions {
      id
      text
      code
      responseType
      position
      postSurveyOption {
        id
        text
        index
        code
      }
    }
  }
}
    `;

/**
 * __usePostSurveyByIdQuery__
 *
 * To run a query within a React component, call `usePostSurveyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostSurveyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostSurveyByIdQuery({
 *   variables: {
 *   },
 * });
 */
export function usePostSurveyByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PostSurveyByIdQuery, PostSurveyByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<PostSurveyByIdQuery, PostSurveyByIdQueryVariables>(PostSurveyByIdDocument, options);
      }
export function usePostSurveyByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PostSurveyByIdQuery, PostSurveyByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<PostSurveyByIdQuery, PostSurveyByIdQueryVariables>(PostSurveyByIdDocument, options);
        }
export type PostSurveyByIdQueryHookResult = ReturnType<typeof usePostSurveyByIdQuery>;
export type PostSurveyByIdLazyQueryHookResult = ReturnType<typeof usePostSurveyByIdLazyQuery>;
export type PostSurveyByIdQueryResult = Apollo.QueryResult<PostSurveyByIdQuery, PostSurveyByIdQueryVariables>;
export const CreateFactorTypeDocument = gql`
    mutation createFactorType($input: CreateFactorTypeInput!) {
  createFactorType(input: $input) {
    name
    id
  }
}
    `;
export type CreateFactorTypeMutationFn = Apollo.MutationFunction<CreateFactorTypeMutation, CreateFactorTypeMutationVariables>;

/**
 * __useCreateFactorTypeMutation__
 *
 * To run a mutation, you first call `useCreateFactorTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFactorTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFactorTypeMutation, { data, loading, error }] = useCreateFactorTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFactorTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateFactorTypeMutation, CreateFactorTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateFactorTypeMutation, CreateFactorTypeMutationVariables>(CreateFactorTypeDocument, options);
      }
export type CreateFactorTypeMutationHookResult = ReturnType<typeof useCreateFactorTypeMutation>;
export type CreateFactorTypeMutationResult = Apollo.MutationResult<CreateFactorTypeMutation>;
export type CreateFactorTypeMutationOptions = Apollo.BaseMutationOptions<CreateFactorTypeMutation, CreateFactorTypeMutationVariables>;
export const UpdateFactorTypeDocument = gql`
    mutation updateFactorType($id: String!, $input: UpdateFactorTypeInput!) {
  updateFactorType(input: $input, id: $id) {
    name
    id
  }
}
    `;
export type UpdateFactorTypeMutationFn = Apollo.MutationFunction<UpdateFactorTypeMutation, UpdateFactorTypeMutationVariables>;

/**
 * __useUpdateFactorTypeMutation__
 *
 * To run a mutation, you first call `useUpdateFactorTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFactorTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFactorTypeMutation, { data, loading, error }] = useUpdateFactorTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFactorTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateFactorTypeMutation, UpdateFactorTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateFactorTypeMutation, UpdateFactorTypeMutationVariables>(UpdateFactorTypeDocument, options);
      }
export type UpdateFactorTypeMutationHookResult = ReturnType<typeof useUpdateFactorTypeMutation>;
export type UpdateFactorTypeMutationResult = Apollo.MutationResult<UpdateFactorTypeMutation>;
export type UpdateFactorTypeMutationOptions = Apollo.BaseMutationOptions<UpdateFactorTypeMutation, UpdateFactorTypeMutationVariables>;
export const FactorTypeDocument = gql`
    query FactorType($id: String!) {
  factorType(id: $id) {
    id
    name
    code
    description
    color
    indexOrder
    reportDescription
  }
}
    `;

/**
 * __useFactorTypeQuery__
 *
 * To run a query within a React component, call `useFactorTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFactorTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFactorTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFactorTypeQuery(baseOptions: ApolloReactHooks.QueryHookOptions<FactorTypeQuery, FactorTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<FactorTypeQuery, FactorTypeQueryVariables>(FactorTypeDocument, options);
      }
export function useFactorTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FactorTypeQuery, FactorTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<FactorTypeQuery, FactorTypeQueryVariables>(FactorTypeDocument, options);
        }
export type FactorTypeQueryHookResult = ReturnType<typeof useFactorTypeQuery>;
export type FactorTypeLazyQueryHookResult = ReturnType<typeof useFactorTypeLazyQuery>;
export type FactorTypeQueryResult = Apollo.QueryResult<FactorTypeQuery, FactorTypeQueryVariables>;
export const CreateFactorDocument = gql`
    mutation createFactor($input: CreateFactorInput!) {
  createFactor(input: $input) {
    name
    id
  }
}
    `;
export type CreateFactorMutationFn = Apollo.MutationFunction<CreateFactorMutation, CreateFactorMutationVariables>;

/**
 * __useCreateFactorMutation__
 *
 * To run a mutation, you first call `useCreateFactorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFactorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFactorMutation, { data, loading, error }] = useCreateFactorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFactorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateFactorMutation, CreateFactorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateFactorMutation, CreateFactorMutationVariables>(CreateFactorDocument, options);
      }
export type CreateFactorMutationHookResult = ReturnType<typeof useCreateFactorMutation>;
export type CreateFactorMutationResult = Apollo.MutationResult<CreateFactorMutation>;
export type CreateFactorMutationOptions = Apollo.BaseMutationOptions<CreateFactorMutation, CreateFactorMutationVariables>;
export const UpdateFactorDocument = gql`
    mutation updateFactor($factorId: String!, $input: UpdateFactorInput!) {
  updateFactor(input: $input, factorId: $factorId) {
    name
    id
  }
}
    `;
export type UpdateFactorMutationFn = Apollo.MutationFunction<UpdateFactorMutation, UpdateFactorMutationVariables>;

/**
 * __useUpdateFactorMutation__
 *
 * To run a mutation, you first call `useUpdateFactorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFactorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFactorMutation, { data, loading, error }] = useUpdateFactorMutation({
 *   variables: {
 *      factorId: // value for 'factorId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFactorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateFactorMutation, UpdateFactorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateFactorMutation, UpdateFactorMutationVariables>(UpdateFactorDocument, options);
      }
export type UpdateFactorMutationHookResult = ReturnType<typeof useUpdateFactorMutation>;
export type UpdateFactorMutationResult = Apollo.MutationResult<UpdateFactorMutation>;
export type UpdateFactorMutationOptions = Apollo.BaseMutationOptions<UpdateFactorMutation, UpdateFactorMutationVariables>;
export const AllFactoryTypesDocument = gql`
    query allFactoryTypes {
  allFactoryTypes {
    id
    createdAt
    name
    code
    description
  }
}
    `;

/**
 * __useAllFactoryTypesQuery__
 *
 * To run a query within a React component, call `useAllFactoryTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllFactoryTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllFactoryTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllFactoryTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllFactoryTypesQuery, AllFactoryTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<AllFactoryTypesQuery, AllFactoryTypesQueryVariables>(AllFactoryTypesDocument, options);
      }
export function useAllFactoryTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllFactoryTypesQuery, AllFactoryTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<AllFactoryTypesQuery, AllFactoryTypesQueryVariables>(AllFactoryTypesDocument, options);
        }
export type AllFactoryTypesQueryHookResult = ReturnType<typeof useAllFactoryTypesQuery>;
export type AllFactoryTypesLazyQueryHookResult = ReturnType<typeof useAllFactoryTypesLazyQuery>;
export type AllFactoryTypesQueryResult = Apollo.QueryResult<AllFactoryTypesQuery, AllFactoryTypesQueryVariables>;
export const SingleFactorDocument = gql`
    query SingleFactor($id: String!) {
  factor(id: $id) {
    id
    name
    headline
    code
    description
    discussionQuestions
    content
    weight
    position
    image
    coreFactor
    factorType {
      id
    }
    statements {
      id
      statement
    }
    preSurveyOption {
      id
    }
  }
}
    `;

/**
 * __useSingleFactorQuery__
 *
 * To run a query within a React component, call `useSingleFactorQuery` and pass it any options that fit your needs.
 * When your component renders, `useSingleFactorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSingleFactorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSingleFactorQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SingleFactorQuery, SingleFactorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SingleFactorQuery, SingleFactorQueryVariables>(SingleFactorDocument, options);
      }
export function useSingleFactorLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SingleFactorQuery, SingleFactorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SingleFactorQuery, SingleFactorQueryVariables>(SingleFactorDocument, options);
        }
export type SingleFactorQueryHookResult = ReturnType<typeof useSingleFactorQuery>;
export type SingleFactorLazyQueryHookResult = ReturnType<typeof useSingleFactorLazyQuery>;
export type SingleFactorQueryResult = Apollo.QueryResult<SingleFactorQuery, SingleFactorQueryVariables>;
export const UpdateFactorOrderDocument = gql`
    mutation updateFactorOrder($orderInput: [OrderInput!]!) {
  updateFactorOrder(orderInput: $orderInput)
}
    `;
export type UpdateFactorOrderMutationFn = Apollo.MutationFunction<UpdateFactorOrderMutation, UpdateFactorOrderMutationVariables>;

/**
 * __useUpdateFactorOrderMutation__
 *
 * To run a mutation, you first call `useUpdateFactorOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFactorOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFactorOrderMutation, { data, loading, error }] = useUpdateFactorOrderMutation({
 *   variables: {
 *      orderInput: // value for 'orderInput'
 *   },
 * });
 */
export function useUpdateFactorOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateFactorOrderMutation, UpdateFactorOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateFactorOrderMutation, UpdateFactorOrderMutationVariables>(UpdateFactorOrderDocument, options);
      }
export type UpdateFactorOrderMutationHookResult = ReturnType<typeof useUpdateFactorOrderMutation>;
export type UpdateFactorOrderMutationResult = Apollo.MutationResult<UpdateFactorOrderMutation>;
export type UpdateFactorOrderMutationOptions = Apollo.BaseMutationOptions<UpdateFactorOrderMutation, UpdateFactorOrderMutationVariables>;
export const DeleteFactorDocument = gql`
    mutation deleteFactor($id: String!) {
  deleteFactor(id: $id) {
    status
    message
  }
}
    `;
export type DeleteFactorMutationFn = Apollo.MutationFunction<DeleteFactorMutation, DeleteFactorMutationVariables>;

/**
 * __useDeleteFactorMutation__
 *
 * To run a mutation, you first call `useDeleteFactorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFactorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFactorMutation, { data, loading, error }] = useDeleteFactorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFactorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteFactorMutation, DeleteFactorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteFactorMutation, DeleteFactorMutationVariables>(DeleteFactorDocument, options);
      }
export type DeleteFactorMutationHookResult = ReturnType<typeof useDeleteFactorMutation>;
export type DeleteFactorMutationResult = Apollo.MutationResult<DeleteFactorMutation>;
export type DeleteFactorMutationOptions = Apollo.BaseMutationOptions<DeleteFactorMutation, DeleteFactorMutationVariables>;
export const DeleteFactorTypeDocument = gql`
    mutation deleteFactorType($id: String!) {
  deleteFactorType(id: $id) {
    status
    message
  }
}
    `;
export type DeleteFactorTypeMutationFn = Apollo.MutationFunction<DeleteFactorTypeMutation, DeleteFactorTypeMutationVariables>;

/**
 * __useDeleteFactorTypeMutation__
 *
 * To run a mutation, you first call `useDeleteFactorTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFactorTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFactorTypeMutation, { data, loading, error }] = useDeleteFactorTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFactorTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteFactorTypeMutation, DeleteFactorTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteFactorTypeMutation, DeleteFactorTypeMutationVariables>(DeleteFactorTypeDocument, options);
      }
export type DeleteFactorTypeMutationHookResult = ReturnType<typeof useDeleteFactorTypeMutation>;
export type DeleteFactorTypeMutationResult = Apollo.MutationResult<DeleteFactorTypeMutation>;
export type DeleteFactorTypeMutationOptions = Apollo.BaseMutationOptions<DeleteFactorTypeMutation, DeleteFactorTypeMutationVariables>;
export const FactorDocument = gql`
    query Factor($id: String!) {
  factor(id: $id) {
    id
    name
    headline
    code
    description
    discussionQuestions
    content
    weight
    position
    image
    factorType {
      name
      id
    }
    statements {
      id
      statement
      code
    }
    preSurveyOption {
      id
    }
    survey {
      id
      name
      status
    }
  }
}
    `;

/**
 * __useFactorQuery__
 *
 * To run a query within a React component, call `useFactorQuery` and pass it any options that fit your needs.
 * When your component renders, `useFactorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFactorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFactorQuery(baseOptions: ApolloReactHooks.QueryHookOptions<FactorQuery, FactorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<FactorQuery, FactorQueryVariables>(FactorDocument, options);
      }
export function useFactorLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FactorQuery, FactorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<FactorQuery, FactorQueryVariables>(FactorDocument, options);
        }
export type FactorQueryHookResult = ReturnType<typeof useFactorQuery>;
export type FactorLazyQueryHookResult = ReturnType<typeof useFactorLazyQuery>;
export type FactorQueryResult = Apollo.QueryResult<FactorQuery, FactorQueryVariables>;
export const SurveysDocument = gql`
    query Surveys {
  surveys {
    id
    createdAt
    status
    name
    locale {
      name
      id
    }
    factors {
      id
      name
    }
  }
}
    `;

/**
 * __useSurveysQuery__
 *
 * To run a query within a React component, call `useSurveysQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveysQuery({
 *   variables: {
 *   },
 * });
 */
export function useSurveysQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SurveysQuery, SurveysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SurveysQuery, SurveysQueryVariables>(SurveysDocument, options);
      }
export function useSurveysLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SurveysQuery, SurveysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SurveysQuery, SurveysQueryVariables>(SurveysDocument, options);
        }
export type SurveysQueryHookResult = ReturnType<typeof useSurveysQuery>;
export type SurveysLazyQueryHookResult = ReturnType<typeof useSurveysLazyQuery>;
export type SurveysQueryResult = Apollo.QueryResult<SurveysQuery, SurveysQueryVariables>;
export const _CreatePreSurveyQuestionOptionDocument = gql`
    mutation _createPreSurveyQuestionOption($input: CreatePostSurveyQuestionOptionInput!) {
  createPostSurveyQuestionOption(input: $input) {
    text
    id
  }
}
    `;
export type _CreatePreSurveyQuestionOptionMutationFn = Apollo.MutationFunction<_CreatePreSurveyQuestionOptionMutation, _CreatePreSurveyQuestionOptionMutationVariables>;

/**
 * __use_CreatePreSurveyQuestionOptionMutation__
 *
 * To run a mutation, you first call `use_CreatePreSurveyQuestionOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `use_CreatePreSurveyQuestionOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPreSurveyQuestionOptionMutation, { data, loading, error }] = use_CreatePreSurveyQuestionOptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function use_CreatePreSurveyQuestionOptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<_CreatePreSurveyQuestionOptionMutation, _CreatePreSurveyQuestionOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<_CreatePreSurveyQuestionOptionMutation, _CreatePreSurveyQuestionOptionMutationVariables>(_CreatePreSurveyQuestionOptionDocument, options);
      }
export type _CreatePreSurveyQuestionOptionMutationHookResult = ReturnType<typeof use_CreatePreSurveyQuestionOptionMutation>;
export type _CreatePreSurveyQuestionOptionMutationResult = Apollo.MutationResult<_CreatePreSurveyQuestionOptionMutation>;
export type _CreatePreSurveyQuestionOptionMutationOptions = Apollo.BaseMutationOptions<_CreatePreSurveyQuestionOptionMutation, _CreatePreSurveyQuestionOptionMutationVariables>;
export const UpdatePostSurveyQuestionOptionDocument = gql`
    mutation updatePOSTSurveyQuestionOption($id: String!, $input: UpdatePostSurveyQuestionOptionInput!) {
  updatePostSurveyQuestionOption(input: $input, optionId: $id) {
    text
    id
  }
}
    `;
export type UpdatePostSurveyQuestionOptionMutationFn = Apollo.MutationFunction<UpdatePostSurveyQuestionOptionMutation, UpdatePostSurveyQuestionOptionMutationVariables>;

/**
 * __useUpdatePostSurveyQuestionOptionMutation__
 *
 * To run a mutation, you first call `useUpdatePostSurveyQuestionOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostSurveyQuestionOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostSurveyQuestionOptionMutation, { data, loading, error }] = useUpdatePostSurveyQuestionOptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePostSurveyQuestionOptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePostSurveyQuestionOptionMutation, UpdatePostSurveyQuestionOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdatePostSurveyQuestionOptionMutation, UpdatePostSurveyQuestionOptionMutationVariables>(UpdatePostSurveyQuestionOptionDocument, options);
      }
export type UpdatePostSurveyQuestionOptionMutationHookResult = ReturnType<typeof useUpdatePostSurveyQuestionOptionMutation>;
export type UpdatePostSurveyQuestionOptionMutationResult = Apollo.MutationResult<UpdatePostSurveyQuestionOptionMutation>;
export type UpdatePostSurveyQuestionOptionMutationOptions = Apollo.BaseMutationOptions<UpdatePostSurveyQuestionOptionMutation, UpdatePostSurveyQuestionOptionMutationVariables>;
export const _PreSurveyQuestionOptionDocument = gql`
    query _preSurveyQuestionOption($id: String!) {
  preSurveyQuestionOption(id: $id) {
    text
    code
    index
    factor {
      name
      id
    }
  }
}
    `;

/**
 * __use_PreSurveyQuestionOptionQuery__
 *
 * To run a query within a React component, call `use_PreSurveyQuestionOptionQuery` and pass it any options that fit your needs.
 * When your component renders, `use_PreSurveyQuestionOptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = use_PreSurveyQuestionOptionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function use_PreSurveyQuestionOptionQuery(baseOptions: ApolloReactHooks.QueryHookOptions<_PreSurveyQuestionOptionQuery, _PreSurveyQuestionOptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<_PreSurveyQuestionOptionQuery, _PreSurveyQuestionOptionQueryVariables>(_PreSurveyQuestionOptionDocument, options);
      }
export function use_PreSurveyQuestionOptionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<_PreSurveyQuestionOptionQuery, _PreSurveyQuestionOptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<_PreSurveyQuestionOptionQuery, _PreSurveyQuestionOptionQueryVariables>(_PreSurveyQuestionOptionDocument, options);
        }
export type _PreSurveyQuestionOptionQueryHookResult = ReturnType<typeof use_PreSurveyQuestionOptionQuery>;
export type _PreSurveyQuestionOptionLazyQueryHookResult = ReturnType<typeof use_PreSurveyQuestionOptionLazyQuery>;
export type _PreSurveyQuestionOptionQueryResult = Apollo.QueryResult<_PreSurveyQuestionOptionQuery, _PreSurveyQuestionOptionQueryVariables>;
export const OptionalFactorsDocument = gql`
    query optionalFactors($surveyId: String!) {
  allOptionalFactors(surveyId: $surveyId) {
    id
    name
  }
}
    `;

/**
 * __useOptionalFactorsQuery__
 *
 * To run a query within a React component, call `useOptionalFactorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptionalFactorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptionalFactorsQuery({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useOptionalFactorsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<OptionalFactorsQuery, OptionalFactorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<OptionalFactorsQuery, OptionalFactorsQueryVariables>(OptionalFactorsDocument, options);
      }
export function useOptionalFactorsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OptionalFactorsQuery, OptionalFactorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<OptionalFactorsQuery, OptionalFactorsQueryVariables>(OptionalFactorsDocument, options);
        }
export type OptionalFactorsQueryHookResult = ReturnType<typeof useOptionalFactorsQuery>;
export type OptionalFactorsLazyQueryHookResult = ReturnType<typeof useOptionalFactorsLazyQuery>;
export type OptionalFactorsQueryResult = Apollo.QueryResult<OptionalFactorsQuery, OptionalFactorsQueryVariables>;
export const _PostSurveyQuestionOptionDocument = gql`
    query _postSurveyQuestionOption($id: String!) {
  postSurveyQuestionOption(id: $id) {
    text
    code
    index
  }
}
    `;

/**
 * __use_PostSurveyQuestionOptionQuery__
 *
 * To run a query within a React component, call `use_PostSurveyQuestionOptionQuery` and pass it any options that fit your needs.
 * When your component renders, `use_PostSurveyQuestionOptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = use_PostSurveyQuestionOptionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function use_PostSurveyQuestionOptionQuery(baseOptions: ApolloReactHooks.QueryHookOptions<_PostSurveyQuestionOptionQuery, _PostSurveyQuestionOptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<_PostSurveyQuestionOptionQuery, _PostSurveyQuestionOptionQueryVariables>(_PostSurveyQuestionOptionDocument, options);
      }
export function use_PostSurveyQuestionOptionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<_PostSurveyQuestionOptionQuery, _PostSurveyQuestionOptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<_PostSurveyQuestionOptionQuery, _PostSurveyQuestionOptionQueryVariables>(_PostSurveyQuestionOptionDocument, options);
        }
export type _PostSurveyQuestionOptionQueryHookResult = ReturnType<typeof use_PostSurveyQuestionOptionQuery>;
export type _PostSurveyQuestionOptionLazyQueryHookResult = ReturnType<typeof use_PostSurveyQuestionOptionLazyQuery>;
export type _PostSurveyQuestionOptionQueryResult = Apollo.QueryResult<_PostSurveyQuestionOptionQuery, _PostSurveyQuestionOptionQueryVariables>;
export const CreatePreSurveyQuestionOptionDocument = gql`
    mutation createPreSurveyQuestionOption($input: CreatePreSurveyQuestionOptionInput!) {
  createPreSurveyQuestionOption(input: $input) {
    text
    id
  }
}
    `;
export type CreatePreSurveyQuestionOptionMutationFn = Apollo.MutationFunction<CreatePreSurveyQuestionOptionMutation, CreatePreSurveyQuestionOptionMutationVariables>;

/**
 * __useCreatePreSurveyQuestionOptionMutation__
 *
 * To run a mutation, you first call `useCreatePreSurveyQuestionOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePreSurveyQuestionOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPreSurveyQuestionOptionMutation, { data, loading, error }] = useCreatePreSurveyQuestionOptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePreSurveyQuestionOptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePreSurveyQuestionOptionMutation, CreatePreSurveyQuestionOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreatePreSurveyQuestionOptionMutation, CreatePreSurveyQuestionOptionMutationVariables>(CreatePreSurveyQuestionOptionDocument, options);
      }
export type CreatePreSurveyQuestionOptionMutationHookResult = ReturnType<typeof useCreatePreSurveyQuestionOptionMutation>;
export type CreatePreSurveyQuestionOptionMutationResult = Apollo.MutationResult<CreatePreSurveyQuestionOptionMutation>;
export type CreatePreSurveyQuestionOptionMutationOptions = Apollo.BaseMutationOptions<CreatePreSurveyQuestionOptionMutation, CreatePreSurveyQuestionOptionMutationVariables>;
export const UpdatePreSurveyQuestionOptionDocument = gql`
    mutation updatePreSurveyQuestionOption($id: String!, $input: UpdatePreSurveyQuestionOptionInput!) {
  updatePreSurveyQuestionOption(input: $input, id: $id) {
    text
    id
  }
}
    `;
export type UpdatePreSurveyQuestionOptionMutationFn = Apollo.MutationFunction<UpdatePreSurveyQuestionOptionMutation, UpdatePreSurveyQuestionOptionMutationVariables>;

/**
 * __useUpdatePreSurveyQuestionOptionMutation__
 *
 * To run a mutation, you first call `useUpdatePreSurveyQuestionOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePreSurveyQuestionOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePreSurveyQuestionOptionMutation, { data, loading, error }] = useUpdatePreSurveyQuestionOptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePreSurveyQuestionOptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePreSurveyQuestionOptionMutation, UpdatePreSurveyQuestionOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdatePreSurveyQuestionOptionMutation, UpdatePreSurveyQuestionOptionMutationVariables>(UpdatePreSurveyQuestionOptionDocument, options);
      }
export type UpdatePreSurveyQuestionOptionMutationHookResult = ReturnType<typeof useUpdatePreSurveyQuestionOptionMutation>;
export type UpdatePreSurveyQuestionOptionMutationResult = Apollo.MutationResult<UpdatePreSurveyQuestionOptionMutation>;
export type UpdatePreSurveyQuestionOptionMutationOptions = Apollo.BaseMutationOptions<UpdatePreSurveyQuestionOptionMutation, UpdatePreSurveyQuestionOptionMutationVariables>;
export const PostSurveyQuestionOptionDocument = gql`
    query postSurveyQuestionOption($id: String!) {
  postSurveyQuestionOption(id: $id) {
    text
    code
  }
}
    `;

/**
 * __usePostSurveyQuestionOptionQuery__
 *
 * To run a query within a React component, call `usePostSurveyQuestionOptionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostSurveyQuestionOptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostSurveyQuestionOptionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePostSurveyQuestionOptionQuery(baseOptions: ApolloReactHooks.QueryHookOptions<PostSurveyQuestionOptionQuery, PostSurveyQuestionOptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<PostSurveyQuestionOptionQuery, PostSurveyQuestionOptionQueryVariables>(PostSurveyQuestionOptionDocument, options);
      }
export function usePostSurveyQuestionOptionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PostSurveyQuestionOptionQuery, PostSurveyQuestionOptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<PostSurveyQuestionOptionQuery, PostSurveyQuestionOptionQueryVariables>(PostSurveyQuestionOptionDocument, options);
        }
export type PostSurveyQuestionOptionQueryHookResult = ReturnType<typeof usePostSurveyQuestionOptionQuery>;
export type PostSurveyQuestionOptionLazyQueryHookResult = ReturnType<typeof usePostSurveyQuestionOptionLazyQuery>;
export type PostSurveyQuestionOptionQueryResult = Apollo.QueryResult<PostSurveyQuestionOptionQuery, PostSurveyQuestionOptionQueryVariables>;
export const PreSurveyQuestionOptionDocument = gql`
    query preSurveyQuestionOption($id: String!) {
  preSurveyQuestionOption(id: $id) {
    text
    code
    factor {
      name
      id
      factorType {
        name
      }
      statements {
        id
      }
      survey {
        status
      }
    }
  }
}
    `;

/**
 * __usePreSurveyQuestionOptionQuery__
 *
 * To run a query within a React component, call `usePreSurveyQuestionOptionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreSurveyQuestionOptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreSurveyQuestionOptionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePreSurveyQuestionOptionQuery(baseOptions: ApolloReactHooks.QueryHookOptions<PreSurveyQuestionOptionQuery, PreSurveyQuestionOptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<PreSurveyQuestionOptionQuery, PreSurveyQuestionOptionQueryVariables>(PreSurveyQuestionOptionDocument, options);
      }
export function usePreSurveyQuestionOptionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PreSurveyQuestionOptionQuery, PreSurveyQuestionOptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<PreSurveyQuestionOptionQuery, PreSurveyQuestionOptionQueryVariables>(PreSurveyQuestionOptionDocument, options);
        }
export type PreSurveyQuestionOptionQueryHookResult = ReturnType<typeof usePreSurveyQuestionOptionQuery>;
export type PreSurveyQuestionOptionLazyQueryHookResult = ReturnType<typeof usePreSurveyQuestionOptionLazyQuery>;
export type PreSurveyQuestionOptionQueryResult = Apollo.QueryResult<PreSurveyQuestionOptionQuery, PreSurveyQuestionOptionQueryVariables>;
export const CreatePreSurveyQuestionDocument = gql`
    mutation createPreSurveyQuestion($input: CreatePreSurveyQuestionInput!) {
  createPreSurveyQuestion(input: $input) {
    text
    id
    description
  }
}
    `;
export type CreatePreSurveyQuestionMutationFn = Apollo.MutationFunction<CreatePreSurveyQuestionMutation, CreatePreSurveyQuestionMutationVariables>;

/**
 * __useCreatePreSurveyQuestionMutation__
 *
 * To run a mutation, you first call `useCreatePreSurveyQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePreSurveyQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPreSurveyQuestionMutation, { data, loading, error }] = useCreatePreSurveyQuestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePreSurveyQuestionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePreSurveyQuestionMutation, CreatePreSurveyQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreatePreSurveyQuestionMutation, CreatePreSurveyQuestionMutationVariables>(CreatePreSurveyQuestionDocument, options);
      }
export type CreatePreSurveyQuestionMutationHookResult = ReturnType<typeof useCreatePreSurveyQuestionMutation>;
export type CreatePreSurveyQuestionMutationResult = Apollo.MutationResult<CreatePreSurveyQuestionMutation>;
export type CreatePreSurveyQuestionMutationOptions = Apollo.BaseMutationOptions<CreatePreSurveyQuestionMutation, CreatePreSurveyQuestionMutationVariables>;
export const UpdatePreSurveyQuestionDocument = gql`
    mutation updatePreSurveyQuestion($id: String!, $input: UpdatePreSurveyQuestionInput!) {
  updatePreSurveyQuestion(input: $input, id: $id) {
    text
    id
    description
  }
}
    `;
export type UpdatePreSurveyQuestionMutationFn = Apollo.MutationFunction<UpdatePreSurveyQuestionMutation, UpdatePreSurveyQuestionMutationVariables>;

/**
 * __useUpdatePreSurveyQuestionMutation__
 *
 * To run a mutation, you first call `useUpdatePreSurveyQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePreSurveyQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePreSurveyQuestionMutation, { data, loading, error }] = useUpdatePreSurveyQuestionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePreSurveyQuestionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePreSurveyQuestionMutation, UpdatePreSurveyQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdatePreSurveyQuestionMutation, UpdatePreSurveyQuestionMutationVariables>(UpdatePreSurveyQuestionDocument, options);
      }
export type UpdatePreSurveyQuestionMutationHookResult = ReturnType<typeof useUpdatePreSurveyQuestionMutation>;
export type UpdatePreSurveyQuestionMutationResult = Apollo.MutationResult<UpdatePreSurveyQuestionMutation>;
export type UpdatePreSurveyQuestionMutationOptions = Apollo.BaseMutationOptions<UpdatePreSurveyQuestionMutation, UpdatePreSurveyQuestionMutationVariables>;
export const PreSurveyQuestionDocument = gql`
    query preSurveyQuestion($id: String!) {
  preSurveyQuestion(id: $id) {
    text
    code
    position
    description
    id
    preSurveyOption {
      id
      text
      code
    }
  }
}
    `;

/**
 * __usePreSurveyQuestionQuery__
 *
 * To run a query within a React component, call `usePreSurveyQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreSurveyQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreSurveyQuestionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePreSurveyQuestionQuery(baseOptions: ApolloReactHooks.QueryHookOptions<PreSurveyQuestionQuery, PreSurveyQuestionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<PreSurveyQuestionQuery, PreSurveyQuestionQueryVariables>(PreSurveyQuestionDocument, options);
      }
export function usePreSurveyQuestionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PreSurveyQuestionQuery, PreSurveyQuestionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<PreSurveyQuestionQuery, PreSurveyQuestionQueryVariables>(PreSurveyQuestionDocument, options);
        }
export type PreSurveyQuestionQueryHookResult = ReturnType<typeof usePreSurveyQuestionQuery>;
export type PreSurveyQuestionLazyQueryHookResult = ReturnType<typeof usePreSurveyQuestionLazyQuery>;
export type PreSurveyQuestionQueryResult = Apollo.QueryResult<PreSurveyQuestionQuery, PreSurveyQuestionQueryVariables>;
export const DeletePreSurveyOptionDocument = gql`
    mutation deletePreSurveyOption($id: String!) {
  deletePreSurveyOption(id: $id) {
    status
    message
  }
}
    `;
export type DeletePreSurveyOptionMutationFn = Apollo.MutationFunction<DeletePreSurveyOptionMutation, DeletePreSurveyOptionMutationVariables>;

/**
 * __useDeletePreSurveyOptionMutation__
 *
 * To run a mutation, you first call `useDeletePreSurveyOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePreSurveyOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePreSurveyOptionMutation, { data, loading, error }] = useDeletePreSurveyOptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePreSurveyOptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePreSurveyOptionMutation, DeletePreSurveyOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeletePreSurveyOptionMutation, DeletePreSurveyOptionMutationVariables>(DeletePreSurveyOptionDocument, options);
      }
export type DeletePreSurveyOptionMutationHookResult = ReturnType<typeof useDeletePreSurveyOptionMutation>;
export type DeletePreSurveyOptionMutationResult = Apollo.MutationResult<DeletePreSurveyOptionMutation>;
export type DeletePreSurveyOptionMutationOptions = Apollo.BaseMutationOptions<DeletePreSurveyOptionMutation, DeletePreSurveyOptionMutationVariables>;
export const _PreSurveyQuestionDocument = gql`
    query _preSurveyQuestion($id: String!) {
  preSurveyQuestion(id: $id) {
    text
    code
    position
    description
    id
    preSurvey {
      survey {
        status
      }
    }
    preSurveyOption {
      id
      text
      code
    }
  }
}
    `;

/**
 * __use_PreSurveyQuestionQuery__
 *
 * To run a query within a React component, call `use_PreSurveyQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `use_PreSurveyQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = use_PreSurveyQuestionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function use_PreSurveyQuestionQuery(baseOptions: ApolloReactHooks.QueryHookOptions<_PreSurveyQuestionQuery, _PreSurveyQuestionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<_PreSurveyQuestionQuery, _PreSurveyQuestionQueryVariables>(_PreSurveyQuestionDocument, options);
      }
export function use_PreSurveyQuestionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<_PreSurveyQuestionQuery, _PreSurveyQuestionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<_PreSurveyQuestionQuery, _PreSurveyQuestionQueryVariables>(_PreSurveyQuestionDocument, options);
        }
export type _PreSurveyQuestionQueryHookResult = ReturnType<typeof use_PreSurveyQuestionQuery>;
export type _PreSurveyQuestionLazyQueryHookResult = ReturnType<typeof use_PreSurveyQuestionLazyQuery>;
export type _PreSurveyQuestionQueryResult = Apollo.QueryResult<_PreSurveyQuestionQuery, _PreSurveyQuestionQueryVariables>;
export const CreatePostSurveyQuestionDocument = gql`
    mutation createPostSurveyQuestion($input: CreatePostSurveyQuestionInput!) {
  createPostSurveyQuestion(input: $input) {
    text
    id
    position
  }
}
    `;
export type CreatePostSurveyQuestionMutationFn = Apollo.MutationFunction<CreatePostSurveyQuestionMutation, CreatePostSurveyQuestionMutationVariables>;

/**
 * __useCreatePostSurveyQuestionMutation__
 *
 * To run a mutation, you first call `useCreatePostSurveyQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostSurveyQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostSurveyQuestionMutation, { data, loading, error }] = useCreatePostSurveyQuestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePostSurveyQuestionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePostSurveyQuestionMutation, CreatePostSurveyQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreatePostSurveyQuestionMutation, CreatePostSurveyQuestionMutationVariables>(CreatePostSurveyQuestionDocument, options);
      }
export type CreatePostSurveyQuestionMutationHookResult = ReturnType<typeof useCreatePostSurveyQuestionMutation>;
export type CreatePostSurveyQuestionMutationResult = Apollo.MutationResult<CreatePostSurveyQuestionMutation>;
export type CreatePostSurveyQuestionMutationOptions = Apollo.BaseMutationOptions<CreatePostSurveyQuestionMutation, CreatePostSurveyQuestionMutationVariables>;
export const UpdatePostSurveyQuestionDocument = gql`
    mutation updatePostSurveyQuestion($id: String!, $input: UpdatePostSurveyQuestionInput!) {
  updatePostSurveyQuestion(input: $input, id: $id) {
    text
    id
    position
  }
}
    `;
export type UpdatePostSurveyQuestionMutationFn = Apollo.MutationFunction<UpdatePostSurveyQuestionMutation, UpdatePostSurveyQuestionMutationVariables>;

/**
 * __useUpdatePostSurveyQuestionMutation__
 *
 * To run a mutation, you first call `useUpdatePostSurveyQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostSurveyQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostSurveyQuestionMutation, { data, loading, error }] = useUpdatePostSurveyQuestionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePostSurveyQuestionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePostSurveyQuestionMutation, UpdatePostSurveyQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdatePostSurveyQuestionMutation, UpdatePostSurveyQuestionMutationVariables>(UpdatePostSurveyQuestionDocument, options);
      }
export type UpdatePostSurveyQuestionMutationHookResult = ReturnType<typeof useUpdatePostSurveyQuestionMutation>;
export type UpdatePostSurveyQuestionMutationResult = Apollo.MutationResult<UpdatePostSurveyQuestionMutation>;
export type UpdatePostSurveyQuestionMutationOptions = Apollo.BaseMutationOptions<UpdatePostSurveyQuestionMutation, UpdatePostSurveyQuestionMutationVariables>;
export const _PostSurveyQuestionDocument = gql`
    query _postSurveyQuestion($id: String!) {
  postSurveyQuestion(id: $id) {
    text
    code
    position
    id
    description
    responseType
    postSurveyOption {
      id
      text
      code
    }
  }
}
    `;

/**
 * __use_PostSurveyQuestionQuery__
 *
 * To run a query within a React component, call `use_PostSurveyQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `use_PostSurveyQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = use_PostSurveyQuestionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function use_PostSurveyQuestionQuery(baseOptions: ApolloReactHooks.QueryHookOptions<_PostSurveyQuestionQuery, _PostSurveyQuestionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<_PostSurveyQuestionQuery, _PostSurveyQuestionQueryVariables>(_PostSurveyQuestionDocument, options);
      }
export function use_PostSurveyQuestionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<_PostSurveyQuestionQuery, _PostSurveyQuestionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<_PostSurveyQuestionQuery, _PostSurveyQuestionQueryVariables>(_PostSurveyQuestionDocument, options);
        }
export type _PostSurveyQuestionQueryHookResult = ReturnType<typeof use_PostSurveyQuestionQuery>;
export type _PostSurveyQuestionLazyQueryHookResult = ReturnType<typeof use_PostSurveyQuestionLazyQuery>;
export type _PostSurveyQuestionQueryResult = Apollo.QueryResult<_PostSurveyQuestionQuery, _PostSurveyQuestionQueryVariables>;
export const DeletePostSurveyOptionDocument = gql`
    mutation deletePostSurveyOption($id: String!) {
  deletePostSurveyOption(id: $id) {
    status
    message
  }
}
    `;
export type DeletePostSurveyOptionMutationFn = Apollo.MutationFunction<DeletePostSurveyOptionMutation, DeletePostSurveyOptionMutationVariables>;

/**
 * __useDeletePostSurveyOptionMutation__
 *
 * To run a mutation, you first call `useDeletePostSurveyOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePostSurveyOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePostSurveyOptionMutation, { data, loading, error }] = useDeletePostSurveyOptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePostSurveyOptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePostSurveyOptionMutation, DeletePostSurveyOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeletePostSurveyOptionMutation, DeletePostSurveyOptionMutationVariables>(DeletePostSurveyOptionDocument, options);
      }
export type DeletePostSurveyOptionMutationHookResult = ReturnType<typeof useDeletePostSurveyOptionMutation>;
export type DeletePostSurveyOptionMutationResult = Apollo.MutationResult<DeletePostSurveyOptionMutation>;
export type DeletePostSurveyOptionMutationOptions = Apollo.BaseMutationOptions<DeletePostSurveyOptionMutation, DeletePostSurveyOptionMutationVariables>;
export const PostSurveyQuestionDocument = gql`
    query postSurveyQuestion($id: String!) {
  postSurveyQuestion(id: $id) {
    text
    code
    position
    id
    description
    responseType
    postSurveyOption {
      id
      text
      code
      index
      archived
    }
  }
}
    `;

/**
 * __usePostSurveyQuestionQuery__
 *
 * To run a query within a React component, call `usePostSurveyQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostSurveyQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostSurveyQuestionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePostSurveyQuestionQuery(baseOptions: ApolloReactHooks.QueryHookOptions<PostSurveyQuestionQuery, PostSurveyQuestionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<PostSurveyQuestionQuery, PostSurveyQuestionQueryVariables>(PostSurveyQuestionDocument, options);
      }
export function usePostSurveyQuestionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PostSurveyQuestionQuery, PostSurveyQuestionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<PostSurveyQuestionQuery, PostSurveyQuestionQueryVariables>(PostSurveyQuestionDocument, options);
        }
export type PostSurveyQuestionQueryHookResult = ReturnType<typeof usePostSurveyQuestionQuery>;
export type PostSurveyQuestionLazyQueryHookResult = ReturnType<typeof usePostSurveyQuestionLazyQuery>;
export type PostSurveyQuestionQueryResult = Apollo.QueryResult<PostSurveyQuestionQuery, PostSurveyQuestionQueryVariables>;
export const UpdateQuestionOptionsDocument = gql`
    mutation updateQuestionOptions($options: [OptionInput!]!, $questionId: String!) {
  updateQuestionOptions(options: $options, questionId: $questionId) {
    id
    code
    text
    index
  }
}
    `;
export type UpdateQuestionOptionsMutationFn = Apollo.MutationFunction<UpdateQuestionOptionsMutation, UpdateQuestionOptionsMutationVariables>;

/**
 * __useUpdateQuestionOptionsMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionOptionsMutation, { data, loading, error }] = useUpdateQuestionOptionsMutation({
 *   variables: {
 *      options: // value for 'options'
 *      questionId: // value for 'questionId'
 *   },
 * });
 */
export function useUpdateQuestionOptionsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateQuestionOptionsMutation, UpdateQuestionOptionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateQuestionOptionsMutation, UpdateQuestionOptionsMutationVariables>(UpdateQuestionOptionsDocument, options);
      }
export type UpdateQuestionOptionsMutationHookResult = ReturnType<typeof useUpdateQuestionOptionsMutation>;
export type UpdateQuestionOptionsMutationResult = Apollo.MutationResult<UpdateQuestionOptionsMutation>;
export type UpdateQuestionOptionsMutationOptions = Apollo.BaseMutationOptions<UpdateQuestionOptionsMutation, UpdateQuestionOptionsMutationVariables>;
export const SurveyByIdDocument = gql`
    query SurveyById($id: String!) {
  surveyById(id: $id) {
    id
    name
    locale {
      id
      name
    }
    createdAt
    status
    factors {
      id
      name
      headline
      factorType {
        name
      }
      statements {
        id
      }
    }
  }
  questionnaire {
    id
    name
    description
    headline
    questions {
      id
    }
  }
}
    `;

/**
 * __useSurveyByIdQuery__
 *
 * To run a query within a React component, call `useSurveyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSurveyByIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SurveyByIdQuery, SurveyByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SurveyByIdQuery, SurveyByIdQueryVariables>(SurveyByIdDocument, options);
      }
export function useSurveyByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SurveyByIdQuery, SurveyByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SurveyByIdQuery, SurveyByIdQueryVariables>(SurveyByIdDocument, options);
        }
export type SurveyByIdQueryHookResult = ReturnType<typeof useSurveyByIdQuery>;
export type SurveyByIdLazyQueryHookResult = ReturnType<typeof useSurveyByIdLazyQuery>;
export type SurveyByIdQueryResult = Apollo.QueryResult<SurveyByIdQuery, SurveyByIdQueryVariables>;
export const DeletePostSurveyDocument = gql`
    mutation deletePostSurvey($id: String!) {
  deletePostSurvey(id: $id) {
    status
  }
}
    `;
export type DeletePostSurveyMutationFn = Apollo.MutationFunction<DeletePostSurveyMutation, DeletePostSurveyMutationVariables>;

/**
 * __useDeletePostSurveyMutation__
 *
 * To run a mutation, you first call `useDeletePostSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePostSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePostSurveyMutation, { data, loading, error }] = useDeletePostSurveyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePostSurveyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePostSurveyMutation, DeletePostSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeletePostSurveyMutation, DeletePostSurveyMutationVariables>(DeletePostSurveyDocument, options);
      }
export type DeletePostSurveyMutationHookResult = ReturnType<typeof useDeletePostSurveyMutation>;
export type DeletePostSurveyMutationResult = Apollo.MutationResult<DeletePostSurveyMutation>;
export type DeletePostSurveyMutationOptions = Apollo.BaseMutationOptions<DeletePostSurveyMutation, DeletePostSurveyMutationVariables>;
export const FactorTypesDocument = gql`
    query factorTypes {
  allFactoryTypes {
    id
    name
    code
    description
    color
  }
}
    `;

/**
 * __useFactorTypesQuery__
 *
 * To run a query within a React component, call `useFactorTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFactorTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFactorTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFactorTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FactorTypesQuery, FactorTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<FactorTypesQuery, FactorTypesQueryVariables>(FactorTypesDocument, options);
      }
export function useFactorTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FactorTypesQuery, FactorTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<FactorTypesQuery, FactorTypesQueryVariables>(FactorTypesDocument, options);
        }
export type FactorTypesQueryHookResult = ReturnType<typeof useFactorTypesQuery>;
export type FactorTypesLazyQueryHookResult = ReturnType<typeof useFactorTypesLazyQuery>;
export type FactorTypesQueryResult = Apollo.QueryResult<FactorTypesQuery, FactorTypesQueryVariables>;
export const CreateStatementDocument = gql`
    mutation createStatement($input: CreateStatementInput!) {
  createStatement(input: $input) {
    statement
    code
    position
    label
    survey {
      id
    }
    factor {
      id
    }
  }
}
    `;
export type CreateStatementMutationFn = Apollo.MutationFunction<CreateStatementMutation, CreateStatementMutationVariables>;

/**
 * __useCreateStatementMutation__
 *
 * To run a mutation, you first call `useCreateStatementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStatementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStatementMutation, { data, loading, error }] = useCreateStatementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStatementMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStatementMutation, CreateStatementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateStatementMutation, CreateStatementMutationVariables>(CreateStatementDocument, options);
      }
export type CreateStatementMutationHookResult = ReturnType<typeof useCreateStatementMutation>;
export type CreateStatementMutationResult = Apollo.MutationResult<CreateStatementMutation>;
export type CreateStatementMutationOptions = Apollo.BaseMutationOptions<CreateStatementMutation, CreateStatementMutationVariables>;
export const UpdateStatementDocument = gql`
    mutation updateStatement($id: String!, $input: UpdateStatementInput!) {
  updateStatement(input: $input, id: $id) {
    statement
    code
    position
    label
    survey {
      id
    }
    factor {
      id
    }
  }
}
    `;
export type UpdateStatementMutationFn = Apollo.MutationFunction<UpdateStatementMutation, UpdateStatementMutationVariables>;

/**
 * __useUpdateStatementMutation__
 *
 * To run a mutation, you first call `useUpdateStatementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStatementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStatementMutation, { data, loading, error }] = useUpdateStatementMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStatementMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStatementMutation, UpdateStatementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateStatementMutation, UpdateStatementMutationVariables>(UpdateStatementDocument, options);
      }
export type UpdateStatementMutationHookResult = ReturnType<typeof useUpdateStatementMutation>;
export type UpdateStatementMutationResult = Apollo.MutationResult<UpdateStatementMutation>;
export type UpdateStatementMutationOptions = Apollo.BaseMutationOptions<UpdateStatementMutation, UpdateStatementMutationVariables>;
export const StatementDocument = gql`
    query statement($id: String!) {
  statement(id: $id) {
    statement
    code
    position
    label
    survey {
      id
    }
    factor {
      id
    }
  }
}
    `;

/**
 * __useStatementQuery__
 *
 * To run a query within a React component, call `useStatementQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatementQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStatementQuery(baseOptions: ApolloReactHooks.QueryHookOptions<StatementQuery, StatementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<StatementQuery, StatementQueryVariables>(StatementDocument, options);
      }
export function useStatementLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StatementQuery, StatementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<StatementQuery, StatementQueryVariables>(StatementDocument, options);
        }
export type StatementQueryHookResult = ReturnType<typeof useStatementQuery>;
export type StatementLazyQueryHookResult = ReturnType<typeof useStatementLazyQuery>;
export type StatementQueryResult = Apollo.QueryResult<StatementQuery, StatementQueryVariables>;
export const _StatementDocument = gql`
    query _statement($id: String!) {
  statement(id: $id) {
    statement
    code
    position
    label
    subDimension
    factor {
      id
    }
    survey {
      id
    }
  }
}
    `;

/**
 * __use_StatementQuery__
 *
 * To run a query within a React component, call `use_StatementQuery` and pass it any options that fit your needs.
 * When your component renders, `use_StatementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = use_StatementQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function use_StatementQuery(baseOptions: ApolloReactHooks.QueryHookOptions<_StatementQuery, _StatementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<_StatementQuery, _StatementQueryVariables>(_StatementDocument, options);
      }
export function use_StatementLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<_StatementQuery, _StatementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<_StatementQuery, _StatementQueryVariables>(_StatementDocument, options);
        }
export type _StatementQueryHookResult = ReturnType<typeof use_StatementQuery>;
export type _StatementLazyQueryHookResult = ReturnType<typeof use_StatementLazyQuery>;
export type _StatementQueryResult = Apollo.QueryResult<_StatementQuery, _StatementQueryVariables>;
export const UpdateStatementOrderDocument = gql`
    mutation updateStatementOrder($orderInput: [OrderInput!]!) {
  updateStatementOrder(orderInput: $orderInput)
}
    `;
export type UpdateStatementOrderMutationFn = Apollo.MutationFunction<UpdateStatementOrderMutation, UpdateStatementOrderMutationVariables>;

/**
 * __useUpdateStatementOrderMutation__
 *
 * To run a mutation, you first call `useUpdateStatementOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStatementOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStatementOrderMutation, { data, loading, error }] = useUpdateStatementOrderMutation({
 *   variables: {
 *      orderInput: // value for 'orderInput'
 *   },
 * });
 */
export function useUpdateStatementOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStatementOrderMutation, UpdateStatementOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateStatementOrderMutation, UpdateStatementOrderMutationVariables>(UpdateStatementOrderDocument, options);
      }
export type UpdateStatementOrderMutationHookResult = ReturnType<typeof useUpdateStatementOrderMutation>;
export type UpdateStatementOrderMutationResult = Apollo.MutationResult<UpdateStatementOrderMutation>;
export type UpdateStatementOrderMutationOptions = Apollo.BaseMutationOptions<UpdateStatementOrderMutation, UpdateStatementOrderMutationVariables>;
export const DeleteStatementDocument = gql`
    mutation deleteStatement($id: String!) {
  deleteStatement(id: $id) {
    status
    message
  }
}
    `;
export type DeleteStatementMutationFn = Apollo.MutationFunction<DeleteStatementMutation, DeleteStatementMutationVariables>;

/**
 * __useDeleteStatementMutation__
 *
 * To run a mutation, you first call `useDeleteStatementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStatementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStatementMutation, { data, loading, error }] = useDeleteStatementMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStatementMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteStatementMutation, DeleteStatementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteStatementMutation, DeleteStatementMutationVariables>(DeleteStatementDocument, options);
      }
export type DeleteStatementMutationHookResult = ReturnType<typeof useDeleteStatementMutation>;
export type DeleteStatementMutationResult = Apollo.MutationResult<DeleteStatementMutation>;
export type DeleteStatementMutationOptions = Apollo.BaseMutationOptions<DeleteStatementMutation, DeleteStatementMutationVariables>;
export const DeleteSurveyDocument = gql`
    mutation deleteSurvey($id: String!) {
  deleteSurvey(id: $id) {
    status
    message
  }
}
    `;
export type DeleteSurveyMutationFn = Apollo.MutationFunction<DeleteSurveyMutation, DeleteSurveyMutationVariables>;

/**
 * __useDeleteSurveyMutation__
 *
 * To run a mutation, you first call `useDeleteSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSurveyMutation, { data, loading, error }] = useDeleteSurveyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSurveyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSurveyMutation, DeleteSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteSurveyMutation, DeleteSurveyMutationVariables>(DeleteSurveyDocument, options);
      }
export type DeleteSurveyMutationHookResult = ReturnType<typeof useDeleteSurveyMutation>;
export type DeleteSurveyMutationResult = Apollo.MutationResult<DeleteSurveyMutation>;
export type DeleteSurveyMutationOptions = Apollo.BaseMutationOptions<DeleteSurveyMutation, DeleteSurveyMutationVariables>;
export const UnassignedOrganizationsDocument = gql`
    query unassignedOrganizations($peerId: String!) {
  unassignedOrganizations(peerId: $peerId) {
    id
    name
    owner {
      firstName
      lastName
      email
    }
    assignment {
      id
    }
  }
}
    `;

/**
 * __useUnassignedOrganizationsQuery__
 *
 * To run a query within a React component, call `useUnassignedOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnassignedOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnassignedOrganizationsQuery({
 *   variables: {
 *      peerId: // value for 'peerId'
 *   },
 * });
 */
export function useUnassignedOrganizationsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<UnassignedOrganizationsQuery, UnassignedOrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UnassignedOrganizationsQuery, UnassignedOrganizationsQueryVariables>(UnassignedOrganizationsDocument, options);
      }
export function useUnassignedOrganizationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UnassignedOrganizationsQuery, UnassignedOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UnassignedOrganizationsQuery, UnassignedOrganizationsQueryVariables>(UnassignedOrganizationsDocument, options);
        }
export type UnassignedOrganizationsQueryHookResult = ReturnType<typeof useUnassignedOrganizationsQuery>;
export type UnassignedOrganizationsLazyQueryHookResult = ReturnType<typeof useUnassignedOrganizationsLazyQuery>;
export type UnassignedOrganizationsQueryResult = Apollo.QueryResult<UnassignedOrganizationsQuery, UnassignedOrganizationsQueryVariables>;
export const FindAssignmentsByPeerAndAssignmentIdDocument = gql`
    query findAssignmentsByPeerAndAssignmentId($peerId: String!, $assignmentId: String!) {
  findAssignmentsByPeerAndAssignmentId(
    peerId: $peerId
    assignmentId: $assignmentId
  ) {
    id
    name
    organization {
      id
      name
      owner {
        firstName
        lastName
        email
      }
    }
  }
}
    `;

/**
 * __useFindAssignmentsByPeerAndAssignmentIdQuery__
 *
 * To run a query within a React component, call `useFindAssignmentsByPeerAndAssignmentIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAssignmentsByPeerAndAssignmentIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAssignmentsByPeerAndAssignmentIdQuery({
 *   variables: {
 *      peerId: // value for 'peerId'
 *      assignmentId: // value for 'assignmentId'
 *   },
 * });
 */
export function useFindAssignmentsByPeerAndAssignmentIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<FindAssignmentsByPeerAndAssignmentIdQuery, FindAssignmentsByPeerAndAssignmentIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<FindAssignmentsByPeerAndAssignmentIdQuery, FindAssignmentsByPeerAndAssignmentIdQueryVariables>(FindAssignmentsByPeerAndAssignmentIdDocument, options);
      }
export function useFindAssignmentsByPeerAndAssignmentIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FindAssignmentsByPeerAndAssignmentIdQuery, FindAssignmentsByPeerAndAssignmentIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<FindAssignmentsByPeerAndAssignmentIdQuery, FindAssignmentsByPeerAndAssignmentIdQueryVariables>(FindAssignmentsByPeerAndAssignmentIdDocument, options);
        }
export type FindAssignmentsByPeerAndAssignmentIdQueryHookResult = ReturnType<typeof useFindAssignmentsByPeerAndAssignmentIdQuery>;
export type FindAssignmentsByPeerAndAssignmentIdLazyQueryHookResult = ReturnType<typeof useFindAssignmentsByPeerAndAssignmentIdLazyQuery>;
export type FindAssignmentsByPeerAndAssignmentIdQueryResult = Apollo.QueryResult<FindAssignmentsByPeerAndAssignmentIdQuery, FindAssignmentsByPeerAndAssignmentIdQueryVariables>;
export const CreateRespondentDocument = gql`
    mutation createRespondent($input: CreateRespondentInput!, $assignmentId: String!) {
  createRespondent(input: $input, assignmentId: $assignmentId) {
    id
  }
}
    `;
export type CreateRespondentMutationFn = Apollo.MutationFunction<CreateRespondentMutation, CreateRespondentMutationVariables>;

/**
 * __useCreateRespondentMutation__
 *
 * To run a mutation, you first call `useCreateRespondentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRespondentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRespondentMutation, { data, loading, error }] = useCreateRespondentMutation({
 *   variables: {
 *      input: // value for 'input'
 *      assignmentId: // value for 'assignmentId'
 *   },
 * });
 */
export function useCreateRespondentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRespondentMutation, CreateRespondentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateRespondentMutation, CreateRespondentMutationVariables>(CreateRespondentDocument, options);
      }
export type CreateRespondentMutationHookResult = ReturnType<typeof useCreateRespondentMutation>;
export type CreateRespondentMutationResult = Apollo.MutationResult<CreateRespondentMutation>;
export type CreateRespondentMutationOptions = Apollo.BaseMutationOptions<CreateRespondentMutation, CreateRespondentMutationVariables>;
export const CreateRespondentProfileDocument = gql`
    mutation createRespondentProfile($userId: String!, $assignmentId: String!, $input: CreateRespondentProfileInput!) {
  createRespondentProfile(
    userId: $userId
    assignmentId: $assignmentId
    input: $input
  ) {
    id
  }
}
    `;
export type CreateRespondentProfileMutationFn = Apollo.MutationFunction<CreateRespondentProfileMutation, CreateRespondentProfileMutationVariables>;

/**
 * __useCreateRespondentProfileMutation__
 *
 * To run a mutation, you first call `useCreateRespondentProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRespondentProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRespondentProfileMutation, { data, loading, error }] = useCreateRespondentProfileMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      assignmentId: // value for 'assignmentId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRespondentProfileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRespondentProfileMutation, CreateRespondentProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateRespondentProfileMutation, CreateRespondentProfileMutationVariables>(CreateRespondentProfileDocument, options);
      }
export type CreateRespondentProfileMutationHookResult = ReturnType<typeof useCreateRespondentProfileMutation>;
export type CreateRespondentProfileMutationResult = Apollo.MutationResult<CreateRespondentProfileMutation>;
export type CreateRespondentProfileMutationOptions = Apollo.BaseMutationOptions<CreateRespondentProfileMutation, CreateRespondentProfileMutationVariables>;
export const RespondentsDocument = gql`
    query respondents($assignmentId: String!) {
  respondents(assignmentId: $assignmentId) {
    id
    user {
      id
      firstName
      lastName
      email
    }
  }
}
    `;

/**
 * __useRespondentsQuery__
 *
 * To run a query within a React component, call `useRespondentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRespondentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRespondentsQuery({
 *   variables: {
 *      assignmentId: // value for 'assignmentId'
 *   },
 * });
 */
export function useRespondentsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<RespondentsQuery, RespondentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<RespondentsQuery, RespondentsQueryVariables>(RespondentsDocument, options);
      }
export function useRespondentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RespondentsQuery, RespondentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<RespondentsQuery, RespondentsQueryVariables>(RespondentsDocument, options);
        }
export type RespondentsQueryHookResult = ReturnType<typeof useRespondentsQuery>;
export type RespondentsLazyQueryHookResult = ReturnType<typeof useRespondentsLazyQuery>;
export type RespondentsQueryResult = Apollo.QueryResult<RespondentsQuery, RespondentsQueryVariables>;
export const DeleteRespondentDocument = gql`
    mutation deleteRespondent($respondentId: String!, $assignmentId: String!) {
  deleteRespondent(respondentId: $respondentId, assignmentId: $assignmentId) {
    message
    status
  }
}
    `;
export type DeleteRespondentMutationFn = Apollo.MutationFunction<DeleteRespondentMutation, DeleteRespondentMutationVariables>;

/**
 * __useDeleteRespondentMutation__
 *
 * To run a mutation, you first call `useDeleteRespondentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRespondentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRespondentMutation, { data, loading, error }] = useDeleteRespondentMutation({
 *   variables: {
 *      respondentId: // value for 'respondentId'
 *      assignmentId: // value for 'assignmentId'
 *   },
 * });
 */
export function useDeleteRespondentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteRespondentMutation, DeleteRespondentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteRespondentMutation, DeleteRespondentMutationVariables>(DeleteRespondentDocument, options);
      }
export type DeleteRespondentMutationHookResult = ReturnType<typeof useDeleteRespondentMutation>;
export type DeleteRespondentMutationResult = Apollo.MutationResult<DeleteRespondentMutation>;
export type DeleteRespondentMutationOptions = Apollo.BaseMutationOptions<DeleteRespondentMutation, DeleteRespondentMutationVariables>;
export const UpdateAssignmentRespDocument = gql`
    mutation updateAssignmentResp($input: UpdateAssignmentInput!, $id: String!) {
  updateAssignment(input: $input, assignmentId: $id) {
    id
  }
}
    `;
export type UpdateAssignmentRespMutationFn = Apollo.MutationFunction<UpdateAssignmentRespMutation, UpdateAssignmentRespMutationVariables>;

/**
 * __useUpdateAssignmentRespMutation__
 *
 * To run a mutation, you first call `useUpdateAssignmentRespMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssignmentRespMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssignmentRespMutation, { data, loading, error }] = useUpdateAssignmentRespMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateAssignmentRespMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAssignmentRespMutation, UpdateAssignmentRespMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateAssignmentRespMutation, UpdateAssignmentRespMutationVariables>(UpdateAssignmentRespDocument, options);
      }
export type UpdateAssignmentRespMutationHookResult = ReturnType<typeof useUpdateAssignmentRespMutation>;
export type UpdateAssignmentRespMutationResult = Apollo.MutationResult<UpdateAssignmentRespMutation>;
export type UpdateAssignmentRespMutationOptions = Apollo.BaseMutationOptions<UpdateAssignmentRespMutation, UpdateAssignmentRespMutationVariables>;
export const SubmitScanDocument = gql`
    mutation submitScan($message: String!, $assignmentId: String!) {
  submitScan(message: $message, assignmentId: $assignmentId)
}
    `;
export type SubmitScanMutationFn = Apollo.MutationFunction<SubmitScanMutation, SubmitScanMutationVariables>;

/**
 * __useSubmitScanMutation__
 *
 * To run a mutation, you first call `useSubmitScanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitScanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitScanMutation, { data, loading, error }] = useSubmitScanMutation({
 *   variables: {
 *      message: // value for 'message'
 *      assignmentId: // value for 'assignmentId'
 *   },
 * });
 */
export function useSubmitScanMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitScanMutation, SubmitScanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SubmitScanMutation, SubmitScanMutationVariables>(SubmitScanDocument, options);
      }
export type SubmitScanMutationHookResult = ReturnType<typeof useSubmitScanMutation>;
export type SubmitScanMutationResult = Apollo.MutationResult<SubmitScanMutation>;
export type SubmitScanMutationOptions = Apollo.BaseMutationOptions<SubmitScanMutation, SubmitScanMutationVariables>;
export const GetAssignmentDocument = gql`
    query GetAssignment($assignmentId: String!, $orgId: String!) {
  assignment(id: $assignmentId) {
    name
    dueDate
    canViewResults
    canShareResults
  }
  organization(id: $orgId) {
    name
    owner {
      firstName
      lastName
      email
    }
  }
}
    `;

/**
 * __useGetAssignmentQuery__
 *
 * To run a query within a React component, call `useGetAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentQuery({
 *   variables: {
 *      assignmentId: // value for 'assignmentId'
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useGetAssignmentQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetAssignmentQuery, GetAssignmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAssignmentQuery, GetAssignmentQueryVariables>(GetAssignmentDocument, options);
      }
export function useGetAssignmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAssignmentQuery, GetAssignmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAssignmentQuery, GetAssignmentQueryVariables>(GetAssignmentDocument, options);
        }
export type GetAssignmentQueryHookResult = ReturnType<typeof useGetAssignmentQuery>;
export type GetAssignmentLazyQueryHookResult = ReturnType<typeof useGetAssignmentLazyQuery>;
export type GetAssignmentQueryResult = Apollo.QueryResult<GetAssignmentQuery, GetAssignmentQueryVariables>;
export const PublishAssignmentDocument = gql`
    mutation publishAssignment($invitationMessage: String!, $assignmentId: String!, $orgId: String!) {
  publishAssignment(
    invitationMessage: $invitationMessage
    assignmentId: $assignmentId
    orgId: $orgId
  )
}
    `;
export type PublishAssignmentMutationFn = Apollo.MutationFunction<PublishAssignmentMutation, PublishAssignmentMutationVariables>;

/**
 * __usePublishAssignmentMutation__
 *
 * To run a mutation, you first call `usePublishAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishAssignmentMutation, { data, loading, error }] = usePublishAssignmentMutation({
 *   variables: {
 *      invitationMessage: // value for 'invitationMessage'
 *      assignmentId: // value for 'assignmentId'
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function usePublishAssignmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PublishAssignmentMutation, PublishAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<PublishAssignmentMutation, PublishAssignmentMutationVariables>(PublishAssignmentDocument, options);
      }
export type PublishAssignmentMutationHookResult = ReturnType<typeof usePublishAssignmentMutation>;
export type PublishAssignmentMutationResult = Apollo.MutationResult<PublishAssignmentMutation>;
export type PublishAssignmentMutationOptions = Apollo.BaseMutationOptions<PublishAssignmentMutation, PublishAssignmentMutationVariables>;
export const CreateAssignmentDocument = gql`
    mutation createAssignment($input: CreateAssignmentInput!, $id: String, $peerId: String) {
  createAssignment(input: $input, assignmentId: $id, peerId: $peerId) {
    id
    minRespondents
    maxRespondents
  }
}
    `;
export type CreateAssignmentMutationFn = Apollo.MutationFunction<CreateAssignmentMutation, CreateAssignmentMutationVariables>;

/**
 * __useCreateAssignmentMutation__
 *
 * To run a mutation, you first call `useCreateAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssignmentMutation, { data, loading, error }] = useCreateAssignmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *      peerId: // value for 'peerId'
 *   },
 * });
 */
export function useCreateAssignmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAssignmentMutation, CreateAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateAssignmentMutation, CreateAssignmentMutationVariables>(CreateAssignmentDocument, options);
      }
export type CreateAssignmentMutationHookResult = ReturnType<typeof useCreateAssignmentMutation>;
export type CreateAssignmentMutationResult = Apollo.MutationResult<CreateAssignmentMutation>;
export type CreateAssignmentMutationOptions = Apollo.BaseMutationOptions<CreateAssignmentMutation, CreateAssignmentMutationVariables>;
export const CreateScanDocument = gql`
    mutation createScan($input: CreateScanInput!, $peerId: String) {
  createScan(input: $input, peerId: $peerId) {
    id
  }
}
    `;
export type CreateScanMutationFn = Apollo.MutationFunction<CreateScanMutation, CreateScanMutationVariables>;

/**
 * __useCreateScanMutation__
 *
 * To run a mutation, you first call `useCreateScanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScanMutation, { data, loading, error }] = useCreateScanMutation({
 *   variables: {
 *      input: // value for 'input'
 *      peerId: // value for 'peerId'
 *   },
 * });
 */
export function useCreateScanMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateScanMutation, CreateScanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateScanMutation, CreateScanMutationVariables>(CreateScanDocument, options);
      }
export type CreateScanMutationHookResult = ReturnType<typeof useCreateScanMutation>;
export type CreateScanMutationResult = Apollo.MutationResult<CreateScanMutation>;
export type CreateScanMutationOptions = Apollo.BaseMutationOptions<CreateScanMutation, CreateScanMutationVariables>;
export const GetAssignmentDetailsDocument = gql`
    query GetAssignmentDetails($id: String!) {
  assignment(id: $id) {
    id
    name
    dueDate
    canViewResults
    canShareResults
    invitationMessage
    status
    organization {
      id
      name
      country
      owner {
        id
        firstName
        lastName
        email
      }
    }
  }
}
    `;

/**
 * __useGetAssignmentDetailsQuery__
 *
 * To run a query within a React component, call `useGetAssignmentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAssignmentDetailsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetAssignmentDetailsQuery, GetAssignmentDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAssignmentDetailsQuery, GetAssignmentDetailsQueryVariables>(GetAssignmentDetailsDocument, options);
      }
export function useGetAssignmentDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAssignmentDetailsQuery, GetAssignmentDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAssignmentDetailsQuery, GetAssignmentDetailsQueryVariables>(GetAssignmentDetailsDocument, options);
        }
export type GetAssignmentDetailsQueryHookResult = ReturnType<typeof useGetAssignmentDetailsQuery>;
export type GetAssignmentDetailsLazyQueryHookResult = ReturnType<typeof useGetAssignmentDetailsLazyQuery>;
export type GetAssignmentDetailsQueryResult = Apollo.QueryResult<GetAssignmentDetailsQuery, GetAssignmentDetailsQueryVariables>;
export const OrganizationsByNameDocument = gql`
    query organizationsByName($peerId: String!) {
  organizationsByName(peerId: $peerId) {
    id
    name
    owner {
      firstName
      lastName
      email
    }
    assignment {
      id
    }
  }
}
    `;

/**
 * __useOrganizationsByNameQuery__
 *
 * To run a query within a React component, call `useOrganizationsByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationsByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationsByNameQuery({
 *   variables: {
 *      peerId: // value for 'peerId'
 *   },
 * });
 */
export function useOrganizationsByNameQuery(baseOptions: ApolloReactHooks.QueryHookOptions<OrganizationsByNameQuery, OrganizationsByNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<OrganizationsByNameQuery, OrganizationsByNameQueryVariables>(OrganizationsByNameDocument, options);
      }
export function useOrganizationsByNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationsByNameQuery, OrganizationsByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<OrganizationsByNameQuery, OrganizationsByNameQueryVariables>(OrganizationsByNameDocument, options);
        }
export type OrganizationsByNameQueryHookResult = ReturnType<typeof useOrganizationsByNameQuery>;
export type OrganizationsByNameLazyQueryHookResult = ReturnType<typeof useOrganizationsByNameLazyQuery>;
export type OrganizationsByNameQueryResult = Apollo.QueryResult<OrganizationsByNameQuery, OrganizationsByNameQueryVariables>;
export const PreSurveyDocument = gql`
    query PreSurvey($assignmentId: String!) {
  preSurveyByAssignmentId(assignmentId: $assignmentId) {
    id
    name
    description
    headline
    questions(assignmentId: $assignmentId) {
      code
      id
      text
      preSurveyResponse {
        id
        preSurveyOption {
          code
          id
        }
      }
      preSurveyOption {
        text
        code
        index
        id
      }
    }
  }
}
    `;

/**
 * __usePreSurveyQuery__
 *
 * To run a query within a React component, call `usePreSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreSurveyQuery({
 *   variables: {
 *      assignmentId: // value for 'assignmentId'
 *   },
 * });
 */
export function usePreSurveyQuery(baseOptions: ApolloReactHooks.QueryHookOptions<PreSurveyQuery, PreSurveyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<PreSurveyQuery, PreSurveyQueryVariables>(PreSurveyDocument, options);
      }
export function usePreSurveyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PreSurveyQuery, PreSurveyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<PreSurveyQuery, PreSurveyQueryVariables>(PreSurveyDocument, options);
        }
export type PreSurveyQueryHookResult = ReturnType<typeof usePreSurveyQuery>;
export type PreSurveyLazyQueryHookResult = ReturnType<typeof usePreSurveyLazyQuery>;
export type PreSurveyQueryResult = Apollo.QueryResult<PreSurveyQuery, PreSurveyQueryVariables>;
export const SubmitPreSurveyResponseDocument = gql`
    mutation submitPreSurveyResponse($input: CreatePreSurveyResponseInput!) {
  submitPreSurveyResponse(input: $input) {
    id
  }
}
    `;
export type SubmitPreSurveyResponseMutationFn = Apollo.MutationFunction<SubmitPreSurveyResponseMutation, SubmitPreSurveyResponseMutationVariables>;

/**
 * __useSubmitPreSurveyResponseMutation__
 *
 * To run a mutation, you first call `useSubmitPreSurveyResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitPreSurveyResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitPreSurveyResponseMutation, { data, loading, error }] = useSubmitPreSurveyResponseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitPreSurveyResponseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitPreSurveyResponseMutation, SubmitPreSurveyResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SubmitPreSurveyResponseMutation, SubmitPreSurveyResponseMutationVariables>(SubmitPreSurveyResponseDocument, options);
      }
export type SubmitPreSurveyResponseMutationHookResult = ReturnType<typeof useSubmitPreSurveyResponseMutation>;
export type SubmitPreSurveyResponseMutationResult = Apollo.MutationResult<SubmitPreSurveyResponseMutation>;
export type SubmitPreSurveyResponseMutationOptions = Apollo.BaseMutationOptions<SubmitPreSurveyResponseMutation, SubmitPreSurveyResponseMutationVariables>;
export const CompletePreSurveyDocument = gql`
    mutation completePreSurvey($assignmentId: String!) {
  completePreSurvey(assignmentId: $assignmentId) {
    message
  }
}
    `;
export type CompletePreSurveyMutationFn = Apollo.MutationFunction<CompletePreSurveyMutation, CompletePreSurveyMutationVariables>;

/**
 * __useCompletePreSurveyMutation__
 *
 * To run a mutation, you first call `useCompletePreSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompletePreSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completePreSurveyMutation, { data, loading, error }] = useCompletePreSurveyMutation({
 *   variables: {
 *      assignmentId: // value for 'assignmentId'
 *   },
 * });
 */
export function useCompletePreSurveyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompletePreSurveyMutation, CompletePreSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CompletePreSurveyMutation, CompletePreSurveyMutationVariables>(CompletePreSurveyDocument, options);
      }
export type CompletePreSurveyMutationHookResult = ReturnType<typeof useCompletePreSurveyMutation>;
export type CompletePreSurveyMutationResult = Apollo.MutationResult<CompletePreSurveyMutation>;
export type CompletePreSurveyMutationOptions = Apollo.BaseMutationOptions<CompletePreSurveyMutation, CompletePreSurveyMutationVariables>;
export const AssignmentDocument = gql`
    query Assignment($id: String!) {
  assignment(id: $id) {
    id
    name
    createdAt
    dueDate
    preSurveyStatus
    respondentCount
    status
  }
}
    `;

/**
 * __useAssignmentQuery__
 *
 * To run a query within a React component, call `useAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssignmentQuery(baseOptions: ApolloReactHooks.QueryHookOptions<AssignmentQuery, AssignmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<AssignmentQuery, AssignmentQueryVariables>(AssignmentDocument, options);
      }
export function useAssignmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AssignmentQuery, AssignmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<AssignmentQuery, AssignmentQueryVariables>(AssignmentDocument, options);
        }
export type AssignmentQueryHookResult = ReturnType<typeof useAssignmentQuery>;
export type AssignmentLazyQueryHookResult = ReturnType<typeof useAssignmentLazyQuery>;
export type AssignmentQueryResult = Apollo.QueryResult<AssignmentQuery, AssignmentQueryVariables>;
export const HealthCheckDocument = gql`
    query HealthCheck {
  healthCheck
}
    `;

/**
 * __useHealthCheckQuery__
 *
 * To run a query within a React component, call `useHealthCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthCheckQuery({
 *   variables: {
 *   },
 * });
 */
export function useHealthCheckQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HealthCheckQuery, HealthCheckQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<HealthCheckQuery, HealthCheckQueryVariables>(HealthCheckDocument, options);
      }
export function useHealthCheckLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HealthCheckQuery, HealthCheckQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<HealthCheckQuery, HealthCheckQueryVariables>(HealthCheckDocument, options);
        }
export type HealthCheckQueryHookResult = ReturnType<typeof useHealthCheckQuery>;
export type HealthCheckLazyQueryHookResult = ReturnType<typeof useHealthCheckLazyQuery>;
export type HealthCheckQueryResult = Apollo.QueryResult<HealthCheckQuery, HealthCheckQueryVariables>;
export const ValidateDocument = gql`
    mutation validate($email: String!) {
  validateEmail(email: $email) {
    message
    token
  }
}
    `;
export type ValidateMutationFn = Apollo.MutationFunction<ValidateMutation, ValidateMutationVariables>;

/**
 * __useValidateMutation__
 *
 * To run a mutation, you first call `useValidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateMutation, { data, loading, error }] = useValidateMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useValidateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ValidateMutation, ValidateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ValidateMutation, ValidateMutationVariables>(ValidateDocument, options);
      }
export type ValidateMutationHookResult = ReturnType<typeof useValidateMutation>;
export type ValidateMutationResult = Apollo.MutationResult<ValidateMutation>;
export type ValidateMutationOptions = Apollo.BaseMutationOptions<ValidateMutation, ValidateMutationVariables>;
export const LoginDocument = gql`
    mutation login($input: LoginInput!) {
  login(input: $input) {
    token
    redirect
    data
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const ResendOtpDocument = gql`
    mutation resendOtp($fingerPrint: String!) {
  resendOtp(fingerPrint: $fingerPrint) {
    message
    status
  }
}
    `;
export type ResendOtpMutationFn = Apollo.MutationFunction<ResendOtpMutation, ResendOtpMutationVariables>;

/**
 * __useResendOtpMutation__
 *
 * To run a mutation, you first call `useResendOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendOtpMutation, { data, loading, error }] = useResendOtpMutation({
 *   variables: {
 *      fingerPrint: // value for 'fingerPrint'
 *   },
 * });
 */
export function useResendOtpMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResendOtpMutation, ResendOtpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ResendOtpMutation, ResendOtpMutationVariables>(ResendOtpDocument, options);
      }
export type ResendOtpMutationHookResult = ReturnType<typeof useResendOtpMutation>;
export type ResendOtpMutationResult = Apollo.MutationResult<ResendOtpMutation>;
export type ResendOtpMutationOptions = Apollo.BaseMutationOptions<ResendOtpMutation, ResendOtpMutationVariables>;
export const SingleOrganisationDocument = gql`
    query singleOrganisation($id: String!) {
  organization(id: $id) {
    name
    internalId
    surveyId
    country
    foundedDate
    owner {
      firstName
      lastName
      email
    }
  }
}
    `;

/**
 * __useSingleOrganisationQuery__
 *
 * To run a query within a React component, call `useSingleOrganisationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSingleOrganisationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSingleOrganisationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSingleOrganisationQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SingleOrganisationQuery, SingleOrganisationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SingleOrganisationQuery, SingleOrganisationQueryVariables>(SingleOrganisationDocument, options);
      }
export function useSingleOrganisationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SingleOrganisationQuery, SingleOrganisationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SingleOrganisationQuery, SingleOrganisationQueryVariables>(SingleOrganisationDocument, options);
        }
export type SingleOrganisationQueryHookResult = ReturnType<typeof useSingleOrganisationQuery>;
export type SingleOrganisationLazyQueryHookResult = ReturnType<typeof useSingleOrganisationLazyQuery>;
export type SingleOrganisationQueryResult = Apollo.QueryResult<SingleOrganisationQuery, SingleOrganisationQueryVariables>;
export const CreateOrganizationDocument = gql`
    mutation createOrganization($input: CreateOrganizationInput!) {
  createOrganization(input: $input) {
    id
    name
    owner {
      firstName
      lastName
      email
    }
  }
}
    `;
export type CreateOrganizationMutationFn = Apollo.MutationFunction<CreateOrganizationMutation, CreateOrganizationMutationVariables>;

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationMutation, { data, loading, error }] = useCreateOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrganizationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateOrganizationMutation, CreateOrganizationMutationVariables>(CreateOrganizationDocument, options);
      }
export type CreateOrganizationMutationHookResult = ReturnType<typeof useCreateOrganizationMutation>;
export type CreateOrganizationMutationResult = Apollo.MutationResult<CreateOrganizationMutation>;
export type CreateOrganizationMutationOptions = Apollo.BaseMutationOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($userId: String!, $input: UpdateUserInput!) {
  updateUser(userId: $userId, input: $input) {
    id
    email
    firstName
    lastName
    role
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const CreateOrgFromPeerDocument = gql`
    mutation createOrgFromPeer($input: CreateOrganizationInput!) {
  createOrgFromPeer(input: $input) {
    id
    firstName
    lastName
    email
    organization {
      id
      name
    }
  }
}
    `;
export type CreateOrgFromPeerMutationFn = Apollo.MutationFunction<CreateOrgFromPeerMutation, CreateOrgFromPeerMutationVariables>;

/**
 * __useCreateOrgFromPeerMutation__
 *
 * To run a mutation, you first call `useCreateOrgFromPeerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrgFromPeerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrgFromPeerMutation, { data, loading, error }] = useCreateOrgFromPeerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrgFromPeerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrgFromPeerMutation, CreateOrgFromPeerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateOrgFromPeerMutation, CreateOrgFromPeerMutationVariables>(CreateOrgFromPeerDocument, options);
      }
export type CreateOrgFromPeerMutationHookResult = ReturnType<typeof useCreateOrgFromPeerMutation>;
export type CreateOrgFromPeerMutationResult = Apollo.MutationResult<CreateOrgFromPeerMutation>;
export type CreateOrgFromPeerMutationOptions = Apollo.BaseMutationOptions<CreateOrgFromPeerMutation, CreateOrgFromPeerMutationVariables>;
export const UpdateOrganizationDocument = gql`
    mutation updateOrganization($orgId: String!, $input: UpdateOrgInput!) {
  updateOrganization(id: $orgId, input: $input) {
    id
  }
}
    `;
export type UpdateOrganizationMutationFn = Apollo.MutationFunction<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMutation, { data, loading, error }] = useUpdateOrganizationMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>(UpdateOrganizationDocument, options);
      }
export type UpdateOrganizationMutationHookResult = ReturnType<typeof useUpdateOrganizationMutation>;
export type UpdateOrganizationMutationResult = Apollo.MutationResult<UpdateOrganizationMutation>;
export type UpdateOrganizationMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;
export const OrganisationsDocument = gql`
    query Organisations($sort: SortArgs, $pagination: PaginationArgs, $input: QueryOrgsInput) {
  organizations(sort: $sort, pagination: $pagination, input: $input) {
    docs {
      name
      createdAt
      assignment {
        id
      }
      owner {
        id
        firstName
        lastName
      }
      user {
        id
      }
    }
    limit
    totalPages
    page
    hasPrevPage
    hasNextPage
  }
}
    `;

/**
 * __useOrganisationsQuery__
 *
 * To run a query within a React component, call `useOrganisationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationsQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganisationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganisationsQuery, OrganisationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<OrganisationsQuery, OrganisationsQueryVariables>(OrganisationsDocument, options);
      }
export function useOrganisationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganisationsQuery, OrganisationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<OrganisationsQuery, OrganisationsQueryVariables>(OrganisationsDocument, options);
        }
export type OrganisationsQueryHookResult = ReturnType<typeof useOrganisationsQuery>;
export type OrganisationsLazyQueryHookResult = ReturnType<typeof useOrganisationsLazyQuery>;
export type OrganisationsQueryResult = Apollo.QueryResult<OrganisationsQuery, OrganisationsQueryVariables>;
export const RegisterUserDocument = gql`
    mutation registerUser($input: RegisterOrgInput!) {
  register(input: $input) {
    message
    token
  }
}
    `;
export type RegisterUserMutationFn = Apollo.MutationFunction<RegisterUserMutation, RegisterUserMutationVariables>;

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegisterUserMutation, RegisterUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RegisterUserMutation, RegisterUserMutationVariables>(RegisterUserDocument, options);
      }
export type RegisterUserMutationHookResult = ReturnType<typeof useRegisterUserMutation>;
export type RegisterUserMutationResult = Apollo.MutationResult<RegisterUserMutation>;
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<RegisterUserMutation, RegisterUserMutationVariables>;
export const PostSurveyQueryDocument = gql`
    query postSurveyQuery($assignmentId: String!) {
  questionnaire {
    id
    name
    description
    headline
    status(assignmentId: $assignmentId)
    questions {
      id
      text
      code
      responseType
      postSurveyOption {
        id
        archived
        text
        index
        code
      }
    }
  }
}
    `;

/**
 * __usePostSurveyQueryQuery__
 *
 * To run a query within a React component, call `usePostSurveyQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostSurveyQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostSurveyQueryQuery({
 *   variables: {
 *      assignmentId: // value for 'assignmentId'
 *   },
 * });
 */
export function usePostSurveyQueryQuery(baseOptions: ApolloReactHooks.QueryHookOptions<PostSurveyQueryQuery, PostSurveyQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<PostSurveyQueryQuery, PostSurveyQueryQueryVariables>(PostSurveyQueryDocument, options);
      }
export function usePostSurveyQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PostSurveyQueryQuery, PostSurveyQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<PostSurveyQueryQuery, PostSurveyQueryQueryVariables>(PostSurveyQueryDocument, options);
        }
export type PostSurveyQueryQueryHookResult = ReturnType<typeof usePostSurveyQueryQuery>;
export type PostSurveyQueryLazyQueryHookResult = ReturnType<typeof usePostSurveyQueryLazyQuery>;
export type PostSurveyQueryQueryResult = Apollo.QueryResult<PostSurveyQueryQuery, PostSurveyQueryQueryVariables>;
export const SubmitPostSurveyResponsesDocument = gql`
    mutation submitPostSurveyResponses($input: CreateQuestionnaireResponseInput!) {
  submitPostSurveyResponses(input: $input)
}
    `;
export type SubmitPostSurveyResponsesMutationFn = Apollo.MutationFunction<SubmitPostSurveyResponsesMutation, SubmitPostSurveyResponsesMutationVariables>;

/**
 * __useSubmitPostSurveyResponsesMutation__
 *
 * To run a mutation, you first call `useSubmitPostSurveyResponsesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitPostSurveyResponsesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitPostSurveyResponsesMutation, { data, loading, error }] = useSubmitPostSurveyResponsesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitPostSurveyResponsesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitPostSurveyResponsesMutation, SubmitPostSurveyResponsesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SubmitPostSurveyResponsesMutation, SubmitPostSurveyResponsesMutationVariables>(SubmitPostSurveyResponsesDocument, options);
      }
export type SubmitPostSurveyResponsesMutationHookResult = ReturnType<typeof useSubmitPostSurveyResponsesMutation>;
export type SubmitPostSurveyResponsesMutationResult = Apollo.MutationResult<SubmitPostSurveyResponsesMutation>;
export type SubmitPostSurveyResponsesMutationOptions = Apollo.BaseMutationOptions<SubmitPostSurveyResponsesMutation, SubmitPostSurveyResponsesMutationVariables>;
export const SurveyDocument = gql`
    query survey($respondentId: String!, $assignmentId: String!) {
  surveyByAssignmentId(assignmentId: $assignmentId, respondentId: $respondentId) {
    id
    name
    image
    description
    headline
    isFactorCompleted
    factorType {
      name
      color
    }
    statements {
      id
      code
      statement
      isCompleted
      position
    }
  }
}
    `;

/**
 * __useSurveyQuery__
 *
 * To run a query within a React component, call `useSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyQuery({
 *   variables: {
 *      respondentId: // value for 'respondentId'
 *      assignmentId: // value for 'assignmentId'
 *   },
 * });
 */
export function useSurveyQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SurveyQuery, SurveyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SurveyQuery, SurveyQueryVariables>(SurveyDocument, options);
      }
export function useSurveyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SurveyQuery, SurveyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SurveyQuery, SurveyQueryVariables>(SurveyDocument, options);
        }
export type SurveyQueryHookResult = ReturnType<typeof useSurveyQuery>;
export type SurveyLazyQueryHookResult = ReturnType<typeof useSurveyLazyQuery>;
export type SurveyQueryResult = Apollo.QueryResult<SurveyQuery, SurveyQueryVariables>;
export const StatementOptionsDocument = gql`
    query statementOptions {
  statementOptions {
    id
    code
    name
    title
    value
  }
}
    `;

/**
 * __useStatementOptionsQuery__
 *
 * To run a query within a React component, call `useStatementOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatementOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatementOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStatementOptionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StatementOptionsQuery, StatementOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<StatementOptionsQuery, StatementOptionsQueryVariables>(StatementOptionsDocument, options);
      }
export function useStatementOptionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StatementOptionsQuery, StatementOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<StatementOptionsQuery, StatementOptionsQueryVariables>(StatementOptionsDocument, options);
        }
export type StatementOptionsQueryHookResult = ReturnType<typeof useStatementOptionsQuery>;
export type StatementOptionsLazyQueryHookResult = ReturnType<typeof useStatementOptionsLazyQuery>;
export type StatementOptionsQueryResult = Apollo.QueryResult<StatementOptionsQuery, StatementOptionsQueryVariables>;
export const CreateSurveyResponseDocument = gql`
    mutation createSurveyResponse($input: CreateSurveyResponseInput!) {
  createSurveyResponse(input: $input) {
    id
  }
}
    `;
export type CreateSurveyResponseMutationFn = Apollo.MutationFunction<CreateSurveyResponseMutation, CreateSurveyResponseMutationVariables>;

/**
 * __useCreateSurveyResponseMutation__
 *
 * To run a mutation, you first call `useCreateSurveyResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSurveyResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSurveyResponseMutation, { data, loading, error }] = useCreateSurveyResponseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSurveyResponseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSurveyResponseMutation, CreateSurveyResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateSurveyResponseMutation, CreateSurveyResponseMutationVariables>(CreateSurveyResponseDocument, options);
      }
export type CreateSurveyResponseMutationHookResult = ReturnType<typeof useCreateSurveyResponseMutation>;
export type CreateSurveyResponseMutationResult = Apollo.MutationResult<CreateSurveyResponseMutation>;
export type CreateSurveyResponseMutationOptions = Apollo.BaseMutationOptions<CreateSurveyResponseMutation, CreateSurveyResponseMutationVariables>;
export const CompleteSurveyDocument = gql`
    mutation completeSurvey($respondentId: String!) {
  completeSurvey(respondentId: $respondentId) {
    id
  }
}
    `;
export type CompleteSurveyMutationFn = Apollo.MutationFunction<CompleteSurveyMutation, CompleteSurveyMutationVariables>;

/**
 * __useCompleteSurveyMutation__
 *
 * To run a mutation, you first call `useCompleteSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeSurveyMutation, { data, loading, error }] = useCompleteSurveyMutation({
 *   variables: {
 *      respondentId: // value for 'respondentId'
 *   },
 * });
 */
export function useCompleteSurveyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompleteSurveyMutation, CompleteSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CompleteSurveyMutation, CompleteSurveyMutationVariables>(CompleteSurveyDocument, options);
      }
export type CompleteSurveyMutationHookResult = ReturnType<typeof useCompleteSurveyMutation>;
export type CompleteSurveyMutationResult = Apollo.MutationResult<CompleteSurveyMutation>;
export type CompleteSurveyMutationOptions = Apollo.BaseMutationOptions<CompleteSurveyMutation, CompleteSurveyMutationVariables>;
export const GetAllUserAssignmentsDocument = gql`
    query GetAllUserAssignments {
  userAssignments {
    id
    postSurveyStatus
    surveyStatus
    user {
      email
      firstName
    }
    assignment {
      id
      name
      dueDate
      status
      survey {
        id
      }
    }
  }
}
    `;

/**
 * __useGetAllUserAssignmentsQuery__
 *
 * To run a query within a React component, call `useGetAllUserAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUserAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUserAssignmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllUserAssignmentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllUserAssignmentsQuery, GetAllUserAssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAllUserAssignmentsQuery, GetAllUserAssignmentsQueryVariables>(GetAllUserAssignmentsDocument, options);
      }
export function useGetAllUserAssignmentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllUserAssignmentsQuery, GetAllUserAssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAllUserAssignmentsQuery, GetAllUserAssignmentsQueryVariables>(GetAllUserAssignmentsDocument, options);
        }
export type GetAllUserAssignmentsQueryHookResult = ReturnType<typeof useGetAllUserAssignmentsQuery>;
export type GetAllUserAssignmentsLazyQueryHookResult = ReturnType<typeof useGetAllUserAssignmentsLazyQuery>;
export type GetAllUserAssignmentsQueryResult = Apollo.QueryResult<GetAllUserAssignmentsQuery, GetAllUserAssignmentsQueryVariables>;
export const AssignmentResultsDocument = gql`
    query AssignmentResults($assignmentId: String!) {
  assignmentBasicResults(assignmentId: $assignmentId) {
    assignmentName
    results {
      factorType
      factorTypeDescription
      factorTypeReportDescription
      color: factorTypeColor
      responses {
        factor {
          name
          description
          headline
        }
        score
      }
    }
    statementCount
  }
}
    `;

/**
 * __useAssignmentResultsQuery__
 *
 * To run a query within a React component, call `useAssignmentResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignmentResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignmentResultsQuery({
 *   variables: {
 *      assignmentId: // value for 'assignmentId'
 *   },
 * });
 */
export function useAssignmentResultsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<AssignmentResultsQuery, AssignmentResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<AssignmentResultsQuery, AssignmentResultsQueryVariables>(AssignmentResultsDocument, options);
      }
export function useAssignmentResultsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AssignmentResultsQuery, AssignmentResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<AssignmentResultsQuery, AssignmentResultsQueryVariables>(AssignmentResultsDocument, options);
        }
export type AssignmentResultsQueryHookResult = ReturnType<typeof useAssignmentResultsQuery>;
export type AssignmentResultsLazyQueryHookResult = ReturnType<typeof useAssignmentResultsLazyQuery>;
export type AssignmentResultsQueryResult = Apollo.QueryResult<AssignmentResultsQuery, AssignmentResultsQueryVariables>;
export const ResultsDocument = gql`
    query Results($assignmentId: String!) {
  basicResults(assignmentId: $assignmentId) {
    results {
      factorType
      factorTypeDescription
      factorTypeReportDescription
      color: factorTypeColor
      responses {
        factor {
          name
          description
          headline
          content
        }
        score
      }
    }
    statementCount
    areOrgResultsAvailable
  }
}
    `;

/**
 * __useResultsQuery__
 *
 * To run a query within a React component, call `useResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResultsQuery({
 *   variables: {
 *      assignmentId: // value for 'assignmentId'
 *   },
 * });
 */
export function useResultsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ResultsQuery, ResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ResultsQuery, ResultsQueryVariables>(ResultsDocument, options);
      }
export function useResultsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ResultsQuery, ResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ResultsQuery, ResultsQueryVariables>(ResultsDocument, options);
        }
export type ResultsQueryHookResult = ReturnType<typeof useResultsQuery>;
export type ResultsLazyQueryHookResult = ReturnType<typeof useResultsLazyQuery>;
export type ResultsQueryResult = Apollo.QueryResult<ResultsQuery, ResultsQueryVariables>;
export const AssignmentBasicResultsDocument = gql`
    query assignmentBasicResults($assignmentId: String!) {
  assignmentBasicResults(assignmentId: $assignmentId) {
    assignmentName
    results {
      factorType
      factorTypeDescription
      factorTypeReportDescription
      color: factorTypeColor
      responses {
        factor {
          name
          description
          headline
        }
        score
      }
    }
    statementCount
  }
}
    `;

/**
 * __useAssignmentBasicResultsQuery__
 *
 * To run a query within a React component, call `useAssignmentBasicResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignmentBasicResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignmentBasicResultsQuery({
 *   variables: {
 *      assignmentId: // value for 'assignmentId'
 *   },
 * });
 */
export function useAssignmentBasicResultsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<AssignmentBasicResultsQuery, AssignmentBasicResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<AssignmentBasicResultsQuery, AssignmentBasicResultsQueryVariables>(AssignmentBasicResultsDocument, options);
      }
export function useAssignmentBasicResultsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AssignmentBasicResultsQuery, AssignmentBasicResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<AssignmentBasicResultsQuery, AssignmentBasicResultsQueryVariables>(AssignmentBasicResultsDocument, options);
        }
export type AssignmentBasicResultsQueryHookResult = ReturnType<typeof useAssignmentBasicResultsQuery>;
export type AssignmentBasicResultsLazyQueryHookResult = ReturnType<typeof useAssignmentBasicResultsLazyQuery>;
export type AssignmentBasicResultsQueryResult = Apollo.QueryResult<AssignmentBasicResultsQuery, AssignmentBasicResultsQueryVariables>;
export const ReportDocument = gql`
    query Report($assignmentId: String!, $isIndividual: Boolean!) {
  report(assignmentId: $assignmentId, isIndividual: $isIndividual) {
    title
    date
    overview {
      results {
        factorType
        factorTypeDescription
        factorTypeReportDescription
        factorTypeColor
        factors {
          headline
          scores {
            ...ParticipantScoreFields
          }
          average
          averageScore
        }
      }
      averages {
        participantId
        score
      }
      totalAverage
    }
    detailedResults {
      factorType
      factorTypeDescription
      factorTypeReportDescription
      factorTypeColor
      factors {
        headline
        content
        discussionQuestions
        statements {
          subDimension
          label
          statement
          scores {
            ...ParticipantScoreFields
          }
          average
        }
        averages {
          ...ParticipantScoreFields
        }
        factorAverage
      }
    }
  }
}
    ${ParticipantScoreFieldsFragmentDoc}`;

/**
 * __useReportQuery__
 *
 * To run a query within a React component, call `useReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportQuery({
 *   variables: {
 *      assignmentId: // value for 'assignmentId'
 *      isIndividual: // value for 'isIndividual'
 *   },
 * });
 */
export function useReportQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ReportQuery, ReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ReportQuery, ReportQueryVariables>(ReportDocument, options);
      }
export function useReportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReportQuery, ReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ReportQuery, ReportQueryVariables>(ReportDocument, options);
        }
export type ReportQueryHookResult = ReturnType<typeof useReportQuery>;
export type ReportLazyQueryHookResult = ReturnType<typeof useReportLazyQuery>;
export type ReportQueryResult = Apollo.QueryResult<ReportQuery, ReportQueryVariables>;
export const RespondentAssignmentDocument = gql`
    query RespondentAssignment($respondentId: String!, $assignmentId: String!) {
  respondentAssignment(respondentId: $respondentId, assignmentId: $assignmentId) {
    id
    postSurveyStatus
    surveyStatus
    user {
      email
      firstName
    }
    assignment {
      id
      name
      dueDate
      survey {
        id
      }
      organization {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useRespondentAssignmentQuery__
 *
 * To run a query within a React component, call `useRespondentAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useRespondentAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRespondentAssignmentQuery({
 *   variables: {
 *      respondentId: // value for 'respondentId'
 *      assignmentId: // value for 'assignmentId'
 *   },
 * });
 */
export function useRespondentAssignmentQuery(baseOptions: ApolloReactHooks.QueryHookOptions<RespondentAssignmentQuery, RespondentAssignmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<RespondentAssignmentQuery, RespondentAssignmentQueryVariables>(RespondentAssignmentDocument, options);
      }
export function useRespondentAssignmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RespondentAssignmentQuery, RespondentAssignmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<RespondentAssignmentQuery, RespondentAssignmentQueryVariables>(RespondentAssignmentDocument, options);
        }
export type RespondentAssignmentQueryHookResult = ReturnType<typeof useRespondentAssignmentQuery>;
export type RespondentAssignmentLazyQueryHookResult = ReturnType<typeof useRespondentAssignmentLazyQuery>;
export type RespondentAssignmentQueryResult = Apollo.QueryResult<RespondentAssignmentQuery, RespondentAssignmentQueryVariables>;
export const GetAllUserAssignmentsSurveyDocument = gql`
    query GetAllUserAssignmentsSurvey {
  userAssignments {
    id
    postSurveyStatus
    surveyStatus
    user {
      email
      firstName
    }
    assignment {
      id
      name
      dueDate
      survey {
        id
      }
    }
  }
}
    `;

/**
 * __useGetAllUserAssignmentsSurveyQuery__
 *
 * To run a query within a React component, call `useGetAllUserAssignmentsSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUserAssignmentsSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUserAssignmentsSurveyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllUserAssignmentsSurveyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllUserAssignmentsSurveyQuery, GetAllUserAssignmentsSurveyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAllUserAssignmentsSurveyQuery, GetAllUserAssignmentsSurveyQueryVariables>(GetAllUserAssignmentsSurveyDocument, options);
      }
export function useGetAllUserAssignmentsSurveyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllUserAssignmentsSurveyQuery, GetAllUserAssignmentsSurveyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAllUserAssignmentsSurveyQuery, GetAllUserAssignmentsSurveyQueryVariables>(GetAllUserAssignmentsSurveyDocument, options);
        }
export type GetAllUserAssignmentsSurveyQueryHookResult = ReturnType<typeof useGetAllUserAssignmentsSurveyQuery>;
export type GetAllUserAssignmentsSurveyLazyQueryHookResult = ReturnType<typeof useGetAllUserAssignmentsSurveyLazyQuery>;
export type GetAllUserAssignmentsSurveyQueryResult = Apollo.QueryResult<GetAllUserAssignmentsSurveyQuery, GetAllUserAssignmentsSurveyQueryVariables>;
export const UserDetailsDocument = gql`
    query UserDetails($id: String!) {
  user(id: $id) {
    email
    firstName
    lastName
    isSuperAdmin
  }
}
    `;

/**
 * __useUserDetailsQuery__
 *
 * To run a query within a React component, call `useUserDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserDetailsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<UserDetailsQuery, UserDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UserDetailsQuery, UserDetailsQueryVariables>(UserDetailsDocument, options);
      }
export function useUserDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserDetailsQuery, UserDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UserDetailsQuery, UserDetailsQueryVariables>(UserDetailsDocument, options);
        }
export type UserDetailsQueryHookResult = ReturnType<typeof useUserDetailsQuery>;
export type UserDetailsLazyQueryHookResult = ReturnType<typeof useUserDetailsLazyQuery>;
export type UserDetailsQueryResult = Apollo.QueryResult<UserDetailsQuery, UserDetailsQueryVariables>;
export const SetUserAsAdminDocument = gql`
    mutation setUserAsAdmin($userId: String!, $isSuperAdmin: Boolean!) {
  setUserAsAdmin(userId: $userId, isSuperAdmin: $isSuperAdmin) {
    id
  }
}
    `;
export type SetUserAsAdminMutationFn = Apollo.MutationFunction<SetUserAsAdminMutation, SetUserAsAdminMutationVariables>;

/**
 * __useSetUserAsAdminMutation__
 *
 * To run a mutation, you first call `useSetUserAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserAsAdminMutation, { data, loading, error }] = useSetUserAsAdminMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      isSuperAdmin: // value for 'isSuperAdmin'
 *   },
 * });
 */
export function useSetUserAsAdminMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetUserAsAdminMutation, SetUserAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SetUserAsAdminMutation, SetUserAsAdminMutationVariables>(SetUserAsAdminDocument, options);
      }
export type SetUserAsAdminMutationHookResult = ReturnType<typeof useSetUserAsAdminMutation>;
export type SetUserAsAdminMutationResult = Apollo.MutationResult<SetUserAsAdminMutation>;
export type SetUserAsAdminMutationOptions = Apollo.BaseMutationOptions<SetUserAsAdminMutation, SetUserAsAdminMutationVariables>;
export const CreateAdminDocument = gql`
    mutation createAdmin($input: CreateAdminInput!) {
  createAdmin(input: $input) {
    id
    email
    firstName
    lastName
    role
  }
}
    `;
export type CreateAdminMutationFn = Apollo.MutationFunction<CreateAdminMutation, CreateAdminMutationVariables>;

/**
 * __useCreateAdminMutation__
 *
 * To run a mutation, you first call `useCreateAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdminMutation, { data, loading, error }] = useCreateAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAdminMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAdminMutation, CreateAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateAdminMutation, CreateAdminMutationVariables>(CreateAdminDocument, options);
      }
export type CreateAdminMutationHookResult = ReturnType<typeof useCreateAdminMutation>;
export type CreateAdminMutationResult = Apollo.MutationResult<CreateAdminMutation>;
export type CreateAdminMutationOptions = Apollo.BaseMutationOptions<CreateAdminMutation, CreateAdminMutationVariables>;
export const FindOrgUsersDocument = gql`
    query findOrgUsers($sort: SortArgs, $pagination: PaginationArgs, $input: QueryUsersInput) {
  findOrgUsers(sort: $sort, pagination: $pagination, input: $input) {
    docs {
      id
      firstName
      lastName
      email
      role
      isSuperAdmin
      isPeer
      isOrgLeader
      organization {
        name
      }
      organizations {
        name
      }
    }
    limit
    totalPages
    page
    hasPrevPage
    hasNextPage
  }
}
    `;

/**
 * __useFindOrgUsersQuery__
 *
 * To run a query within a React component, call `useFindOrgUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOrgUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOrgUsersQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindOrgUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FindOrgUsersQuery, FindOrgUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<FindOrgUsersQuery, FindOrgUsersQueryVariables>(FindOrgUsersDocument, options);
      }
export function useFindOrgUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FindOrgUsersQuery, FindOrgUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<FindOrgUsersQuery, FindOrgUsersQueryVariables>(FindOrgUsersDocument, options);
        }
export type FindOrgUsersQueryHookResult = ReturnType<typeof useFindOrgUsersQuery>;
export type FindOrgUsersLazyQueryHookResult = ReturnType<typeof useFindOrgUsersLazyQuery>;
export type FindOrgUsersQueryResult = Apollo.QueryResult<FindOrgUsersQuery, FindOrgUsersQueryVariables>;
export const PeerUsersDocument = gql`
    query PeerUsers($id: String!) {
  peerUsers(peerId: $id) {
    id
    firstName
    lastName
    email
    role
    organization {
      name
    }
  }
}
    `;

/**
 * __usePeerUsersQuery__
 *
 * To run a query within a React component, call `usePeerUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeerUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeerUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePeerUsersQuery(baseOptions: ApolloReactHooks.QueryHookOptions<PeerUsersQuery, PeerUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<PeerUsersQuery, PeerUsersQueryVariables>(PeerUsersDocument, options);
      }
export function usePeerUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PeerUsersQuery, PeerUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<PeerUsersQuery, PeerUsersQueryVariables>(PeerUsersDocument, options);
        }
export type PeerUsersQueryHookResult = ReturnType<typeof usePeerUsersQuery>;
export type PeerUsersLazyQueryHookResult = ReturnType<typeof usePeerUsersLazyQuery>;
export type PeerUsersQueryResult = Apollo.QueryResult<PeerUsersQuery, PeerUsersQueryVariables>;
export const UserDocument = gql`
    query User($id: String!) {
  user(id: $id) {
    id
    createdAt
    updatedAt
    email
    firstName
    lastName
    role
    organization {
      name
      peer {
        name
      }
    }
    isSuperAdmin
    respondent {
      id
      assignment {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions: ApolloReactHooks.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UsersDocument = gql`
    query Users($sort: SortArgs, $pagination: PaginationArgs, $input: QueryUsersInput) {
  users(sort: $sort, pagination: $pagination, input: $input) {
    docs {
      id
      firstName
      lastName
      email
      role
      isSuperAdmin
      isPeer
      isOrgLeader
      organization {
        name
      }
      organizations {
        name
      }
    }
    limit
    totalPages
    page
    hasPrevPage
    hasNextPage
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const GetAllUsersAssignmentsWelcomeDocument = gql`
    query GetAllUsersAssignmentsWelcome {
  userAssignments {
    id
    user {
      email
    }
    assignment {
      id
      name
      dueDate
    }
  }
}
    `;

/**
 * __useGetAllUsersAssignmentsWelcomeQuery__
 *
 * To run a query within a React component, call `useGetAllUsersAssignmentsWelcomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUsersAssignmentsWelcomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUsersAssignmentsWelcomeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllUsersAssignmentsWelcomeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllUsersAssignmentsWelcomeQuery, GetAllUsersAssignmentsWelcomeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAllUsersAssignmentsWelcomeQuery, GetAllUsersAssignmentsWelcomeQueryVariables>(GetAllUsersAssignmentsWelcomeDocument, options);
      }
export function useGetAllUsersAssignmentsWelcomeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllUsersAssignmentsWelcomeQuery, GetAllUsersAssignmentsWelcomeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAllUsersAssignmentsWelcomeQuery, GetAllUsersAssignmentsWelcomeQueryVariables>(GetAllUsersAssignmentsWelcomeDocument, options);
        }
export type GetAllUsersAssignmentsWelcomeQueryHookResult = ReturnType<typeof useGetAllUsersAssignmentsWelcomeQuery>;
export type GetAllUsersAssignmentsWelcomeLazyQueryHookResult = ReturnType<typeof useGetAllUsersAssignmentsWelcomeLazyQuery>;
export type GetAllUsersAssignmentsWelcomeQueryResult = Apollo.QueryResult<GetAllUsersAssignmentsWelcomeQuery, GetAllUsersAssignmentsWelcomeQueryVariables>;