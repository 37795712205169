import { RouteComponentProps, useNavigate } from '@reach/router'
import { useQuery, gql } from '@apollo/client'
import { Color } from '../../../color.enum'
import { User } from '../../../common/types'
import Button, { ButtonVariant, ButtonSize } from '../../../components/Button'
import AssignmentList from './PeerAssignmentsList'
import Loader from '../../../components/Loader/Loader'
import Alert, { AlertVariant, AlertSize } from '../../../components/Alert'
import { language } from '../../../common/i18n'
import { PageWrapper } from '../../../modules/PageWrapper'

interface OwnerProps extends RouteComponentProps {
  user?: User
  test?: string
}

export const PeerAssignments: React.FC<OwnerProps> = (props) => {
  return <Assignments {...props} />
}

export const Assignments: React.FC<OwnerProps> = (props) => {
  const navigate = useNavigate()

  const { loading, data: peerData } = useQuery(GET_PEER_SCANS, {
    variables: { peerId: props.user?.peer?.id },
    fetchPolicy: 'no-cache'
  })

  if (loading) return <Loader color={Color.PURPLE} />

  return (
    <PageWrapper
      metaDesc={language.en.orgOwner.dashboard.title}
      metaTitle={language.en.orgOwner.dashboard.description}
      headline="Scans"
      sectionHeading="Current Scans"
      renderCta={() => {
        return (
          <div className="flex items-center">
            <Button
              variant={ButtonVariant.PRIMARY}
              color={Color.PURPLE}
              size={ButtonSize.SMALL}
              onClick={() => navigate('/dashboard/peer/create-scan')}
              iconRight="plus"
            >
              Create Scan
            </Button>
          </div>
        )
      }}
    >
      {peerData?.peerAssignments.length ? (
        <AssignmentList
          assignments={peerData?.peerAssignments}
          loading={loading}
          organizationId={props?.user?.peer?.id}
        />
      ) : (
        <div className="w-full">
          <Alert size={AlertSize.LARGE} variant={AlertVariant.WARNING} className="w-full">
            Please create your first scan. To set up a new scan and assign organisation for your Scan, click on "Create
            Scan."
          </Alert>
        </div>
      )}
    </PageWrapper>
  )
}

const GET_PEER_SCANS = gql`
  query PeerAssignments($peerId: String) {
    peerAssignments(peerId: $peerId) {
      id
      dueDate
      status
      name
      respondentCount
      preSurveyStatus
      organization {
        name
      }
      respondentProfile {
        id
        surveyStatus
      }
    }
  }
`
