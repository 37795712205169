import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { useUserQuery } from '../../hooks/useUserQuery'
import Loader from '../Loader/Loader'

interface IUser {
  isSuperAdmin: boolean
  isPeer: boolean
  isOrgLeader: boolean
  isRespondent: boolean
}

interface IUserData {
  me: IUser
}

interface IProtectedRouteProps extends RouteComponentProps {
  component: React.ElementType
  roles: (keyof IUser)[]
}

const ProtectedRoute: React.FC<IProtectedRouteProps> = ({ component: Component, roles, ...props }) => {
  const { loading, data, error } = useUserQuery(true, props.location?.pathname ?? '')

  if (loading) return <Loader />

  if (error || !data?.me) {
    const wpOAuthUrl = `${process.env.REACT_APP_WP_HOST}/oauth/authorize?client_id=${process.env.REACT_APP_WP_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_WP_REDIRECT_URI}`
    localStorage.setItem('preAuthUrl', props.location?.pathname ?? '/')
    window.location.href = wpOAuthUrl
    return <Loader />
  }

  const isUserAuthorized = [...roles, 'isSuperAdmin'].some((role) => data.me[role])

  if (!isUserAuthorized) return <p>You are not authorized to view this route.</p>

  return <Component {...props} user={data.me} />
}

export { ProtectedRoute }
