import { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import Button, { ButtonSize, ButtonVariant } from '../../../components/Button'
import { Color } from '../../../color.enum'
import FormLabel from '../../../components/FormLabel/FormLabel'
import DatePicker from '../../../components/DatePicker/DatePicker'
import { useForm } from 'react-hook-form'
import dayjs from 'dayjs'
import HeadingPageCenter from '../../../components/HeadingPageCenter/HeadingPageCenter'
import { navigate, RouteComponentProps, useParams } from '@reach/router'
import { formatDate } from '../../../services/formatDate'
import moment from 'moment'
import Icon from '../../../components/Icon/Icon'

interface EditDueDateProps extends RouteComponentProps {
  location?: any
}

const EditDueDate: React.FC<EditDueDateProps> = (props) => {
  const [date, setDate] = useState<any>(moment(props?.location.state?.currentDate))
  const [dateError, setDateError] = useState<string | null>(null)
  const [updateAssignment, { loading }] = useMutation(UPDATE_ASSIGNMENT_DATE)
  const { id } = useParams()
  const currentDate = props?.location.state?.currentDate

  const { handleSubmit } = useForm({ mode: 'onSubmit', reValidateMode: 'onChange' })

  const form = {
    label: 'Assessment Close Date',
    name: 'dueDate',
    placeholder: 'Assessment Close Date',
    type: 'datePicker',
    description: `This assessment will close at midnight UTC on the  ${formatDate(
      currentDate
    )}. Enter a new date below to extend the due date .`,
    options: {
      required: 'Assessment Close Date is required'
    }
  }

  const onRegister = async () => {
    if (!date) {
      setDateError('Assessment Close Date is required')
      return
    }

    const dueDate = dayjs(date).format('YYYY-MM-DD')
    const vars: any = { input: { dueDate }, assignmentId: id }

    updateAssignment({
      variables: { ...vars }
    })
      .then((response) => {
        if (response?.data?.updateAssignment?.id) {
          navigate(`/dashboard/peer/scans/${id}`)
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  return (
    <main>
      <div className="page-container-lg">
        <HeadingPageCenter headline="Edit due date" color={Color.GRAY} />
        <div className="section-container-sm">
          <form
            action="#"
            autoComplete="no"
            onSubmit={handleSubmit(onRegister)}
            className="grid grid-cols-2 gap-y-4 sm:grid-cols-2 sm:gap-x-8"
          >
            <div className="col-span-2 sm:col-span-2">
              <FormLabel className="text-base font-medium text-brand-purple my-1 inline-block">{form.label}</FormLabel>
              {form.description && <div className="text-xs py-1 text-white">{form.description}</div>}
              <DatePicker selectedDate={date} selectDate={setDate} setDateError={setDateError} />

              {dateError && <p className="text-sm text-red-500">{dateError}</p>}
            </div>
            <div className="flex justify-between">
              <Button
                className="justify-center mb-2"
                variant={ButtonVariant.PLAIN}
                size={ButtonSize.LARGE}
                color={Color.BLUE}
                style={{ width: '100%' }}
                onClick={() => navigate(`/dashboard/peer/scans/${id}`)}
              >
                <Icon className="pr-1" name="arrowLeft" />
                Back
              </Button>
              <Button
                iconRight="arrowRight"
                className="justify-center mb-2"
                variant={ButtonVariant.PRIMARY}
                size={ButtonSize.LARGE}
                color={Color.BLUE}
                style={{ width: '100%' }}
                type="submit"
                onClick={handleSubmit(onRegister)}
                disabled={loading}
              >
                {loading ? 'Loading...' : 'Submit'}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </main>
  )
}

export default EditDueDate

export const UPDATE_ASSIGNMENT_DATE = gql`
  mutation updateAssignmentDate($assignmentId: String!, $input: UpdateAssignmentInput!) {
    updateAssignment(input: $input, assignmentId: $assignmentId) {
      id
      dueDate
      minRespondents
      maxRespondents
    }
  }
`
