import { useEffect } from 'react'
import { RouteComponentProps, useNavigate, useParams, useLocation } from '@reach/router'
import { Color } from '../../../../color.enum'
import { gql, useMutation, useLazyQuery } from '@apollo/client'
import HeadingPageCenter from '../../../../components/HeadingPageCenter'
import { Helmet } from 'react-helmet'
import { language } from '../../../../common/i18n'
import { FormWrapper } from '../../../../modules/FormWrapper'
import { useFormObj } from '../../../../hooks/useForm'
import { FormTypes } from '../../../../common/constants'
import Loader from '../../../../components/Loader/Loader'

interface CreateFactorTypeInterface extends RouteComponentProps<any> {
  location: any
}

const CreateFactorType: React.FC<CreateFactorTypeInterface> = () => {
  const navigate = useNavigate()
  const { factorTypeId, surveyId } = useParams()
  const { pathname } = useLocation()
  const form = useFormObj('factorType')
  const type = pathname.endsWith('edit-factor-type') ? FormTypes.edit : FormTypes.create

  const [createFactorType, { loading: createFactorLoading }] = useMutation(CREATE_FACTOR_TYPE, {
    onError: (error) => {
      console.log(error)
    },
    onCompleted: (data) => {
      navigate(`/dashboard/admin/survey/${surveyId}/factorType/${data?.createFactorType?.id}`)
    }
  })

  const [updateFactorType, { loading: updateLoading }] = useMutation(UPDATE_FACTOR_TYPE, {
    onError: (error) => {
      console.log(error)
    },
    onCompleted: () => {
      navigate(`/dashboard/admin/survey/${surveyId}/factorType/${factorTypeId}`)
    }
  })

  const [loadFactorTypeData, { loading, data }] = useLazyQuery(GET_SINGLE_FACTOR_TYPE, {
    variables: { id: factorTypeId },
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    if (type === FormTypes.edit) {
      loadFactorTypeData()
    }
  }, [])

  const onSubmit = (data: any, type: FormTypes) => {
    if (type === FormTypes.create) {
      const { indexOrder } = data
      createFactorType({ variables: { input: { ...data, indexOrder: Number(indexOrder) } } })
    } else {
      const { indexOrder, ...rest } = data
      updateFactorType({
        variables: {
          id: factorTypeId,
          input: {
            name: rest.name,
            code: rest.code,
            description: rest.description,
            reportDescription: rest.reportDescription,
            color: rest.color,
            indexOrder: Number(indexOrder)
          }
        }
      })
    }
  }

  if (loading) return <Loader />

  return (
    <div className="page-container-lg">
      <Helmet>
        <title>{language.en.admin.factorType.create.title}</title>
        <meta name="description" content={language.en.admin.factorType.create.description} />
        <meta name="robots" content="noindex" data-react-helmet="true" />
      </Helmet>
      <main>
        <HeadingPageCenter
          className="text-center"
          headline={pathname.endsWith('edit-factor-type') ? 'Edit Archetype' : 'Create new Archetype'}
          color={Color.BLUE}
        />
        <div className="section-container-sm">
          <FormWrapper
            buttonLabel="Submit"
            loading={createFactorLoading || updateLoading}
            form={form}
            onSubmit={onSubmit}
            formType={type}
            data={data?.factorType}
          />
        </div>
      </main>
    </div>
  )
}

export default CreateFactorType

const CREATE_FACTOR_TYPE = gql`
  mutation createFactorType($input: CreateFactorTypeInput!) {
    createFactorType(input: $input) {
      name
      id
    }
  }
`
const UPDATE_FACTOR_TYPE = gql`
  mutation updateFactorType($id: String!, $input: UpdateFactorTypeInput!) {
    updateFactorType(input: $input, id: $id) {
      name
      id
    }
  }
`
export const GET_SINGLE_FACTOR_TYPE = gql`
  query FactorType($id: String!) {
    factorType(id: $id) {
      id
      name
      code
      description
      color
      indexOrder
      reportDescription
    }
  }
`
