import React, { useEffect, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import List from '../../../../components/List'
import { ListItemCta, ListItemMeta } from './factorListItems'
import ListItem from '../../../../components/ListItem'
import { Color } from '../../../../color.enum'
import { DndContext, closestCenter } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { SortableItem } from './SortableItem'
import { useHandleDrag } from '../../../../hooks/useHandleDrag'

interface Dimension {
  id: string
  name: string
  headline: string
  statements: any
  factorType: any
}

interface FactorItemProps {
  factors: Dimension[]
  surveyId: string
  surveyStatus?: string
  refetch?: any
}

const FactorList: React.FC<FactorItemProps> = ({ factors, surveyId, surveyStatus, refetch }) => {
  const [indices, setIndices] = useState(factors.map((f: any, index: any) => String(index + 1)))
  const [orderHasChanged, sensors, handleDragEnd, items] = useHandleDrag(indices)

  const [updateFactorOrder] = useMutation(UPDATE_FACTOR_ORDER)

  useEffect(() => {
    setIndices(factors.map((f: any, index: any) => String(index + 1)))
  }, [factors])

  useEffect(() => {
    if (orderHasChanged) {
      const newOrder = calculateFactorOrder()

      updateFactorOrder({
        variables: {
          orderInput: newOrder
        }
      })
    }
  }, [items])

  const getFactor = (index: any) => {
    const factor = factors[index - 1]
    if (factor) {
      return (
        <>
          {factor && (
            <ListItem
              type="statement"
              color={Color.BLUE}
              headline={factor.headline}
              renderMeta={
                <ListItemMeta statementCount={`${factor.statements ? factor.statements?.length : 0} Statements`} />
              }
              description={factor?.factorType?.name || ''}
              renderCta={() => (
                <ListItemCta
                  color={Color.BLUE}
                  id={factor.id}
                  surveyId={surveyId}
                  surveyStatus={surveyStatus}
                  refetch={refetch}
                />
              )}
            />
          )}
        </>
      )
    } else {
      return null
    }
  }

  const calculateFactorOrder = () => {
    return items
      .map((item: any, index: number) => {
        const factor = factors[item - 1]
        if (factor) {
          return { id: factor.id, position: index + 1 }
        }
      })
      .filter((a: any) => a)
  }

  return (
    <>
      <DndContext
        modifiers={[restrictToVerticalAxis]}
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          <List color={Color.BLUE} className="mb-10">
            {(): JSX.Element => {
              return (
                <>
                  {items.map((id: any) => (
                    <SortableItem key={id} id={id}>
                      <div>{getFactor(id)}</div>
                    </SortableItem>
                  ))}
                </>
              )
            }}
          </List>
        </SortableContext>
      </DndContext>
    </>
  )
}

export default FactorList

const UPDATE_FACTOR_ORDER = gql`
  mutation updateFactorOrder($orderInput: [OrderInput!]!) {
    updateFactorOrder(orderInput: $orderInput)
  }
`
