import { RouteComponentProps, useNavigate, useLocation, useParams } from '@reach/router'
import { Color } from '../../../color.enum'
import { gql, useMutation, useQuery } from '@apollo/client'
import { User } from '../../../common/types'
import HeadingPageCenter from '../../../components/HeadingPageCenter'
import { Helmet } from 'react-helmet'
import { language } from '../../../common/i18n'
import Loader from '../../../components/Loader/Loader'
import { useFormObj } from '../../../hooks/useForm'
import { FormTypes } from '../../../common/constants'
import { FormWrapper } from '../../../modules/FormWrapper'

interface CreateEditSurveyProps extends RouteComponentProps {
  user?: User
}

const CreateEditSurvey: React.FC<CreateEditSurveyProps> = ({ user }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { surveyId } = useParams()
  const form = useFormObj('survey')
  const type = pathname.endsWith('edit-survey') ? FormTypes.edit : FormTypes.create

  const { data: surveyData, loading: surveyLoading } = useQuery(GET_SURVEY, { fetchPolicy: 'no-cache' })
  const { data: localesData, loading: localeLoading } = useQuery(GET_LOCALES, { fetchPolicy: 'no-cache' })

  const [updateSurvey, { loading: updateLoading }] = useMutation(EDIT_SURVEY, {
    onError: (error) => {
      console.log(error)
    },
    onCompleted: (data) => {
      navigate(`/dashboard/admin/survey/${data.updateSurvey?.id}`)
    }
  })

  const [createSurvey, { loading: createLoading }] = useMutation(CREATE_SURVEY, {
    onError: (error) => {
      console.log(error)
    },
    onCompleted: (data) => {
      navigate(`/dashboard/admin/survey/${data.createSurvey?.id}`)
    }
  })

  const onSubmit = async (data: any) => {
    if (!surveyId) {
      await createSurvey({
        variables: {
          input: {
            name: data.name,
            localeId: data.locale,
            channelId: user?.channel?.id
          }
        }
      })
    } else {
      await updateSurvey({
        variables: {
          surveyId,
          input: {
            name: data.name,
            localeId: data.locale
          }
        }
      })
    }
  }

  if (surveyLoading || localeLoading || createLoading || updateLoading) return <Loader color={Color.BLUE} />

  return (
    <div className="page-container-lg">
      <Helmet>
        <title>{language.en.admin.survey.create.title}</title>
        <meta name="description" content={language.en.admin.survey.create.description} />
        <meta name="robots" content="noindex" data-react-helmet="true" />
      </Helmet>
      <header>
        <HeadingPageCenter
          headline={
            pathname === `/dashboard/admin/survey/${surveyId}/edit-survey` ? 'Edit Assesment' : 'Create Assesment'
          }
          color={Color.GRAY}
          description={
            pathname === `/dashboard/admin/survey/${surveyId}/edit-survey`
              ? 'Please edit the relevant details below'
              : 'Please enter details below to create new Assessment'
          }
        />
      </header>
      <main>
        <div className="section-container-sm">
          <FormWrapper
            buttonLabel="Submit"
            loading={updateLoading}
            form={form}
            onSubmit={onSubmit}
            formType={type}
            data={surveyData?.survey}
            customDorpDownValues={[
              { parent: 'locale', values: localesData.locales, defaultValue: surveyData.survey.locale.id }
            ]}
          />
        </div>
      </main>
    </div>
  )
}

export default CreateEditSurvey

export const CREATE_SURVEY = gql`
  mutation createSurvey($input: CreateSurveyInput!) {
    createSurvey(input: $input) {
      id
      name
    }
  }
`

export const EDIT_SURVEY = gql`
  mutation updateSurvey($input: UpdateSurveyInput!, $surveyId: String!) {
    updateSurvey(input: $input, surveyId: $surveyId) {
      id
      name
    }
  }
`

export const GET_LOCALES = gql`
  query GetLocalesQuery {
    locales {
      id
      name
      code
    }
  }
`

export const GET_SURVEY = gql`
  query _survey($id: String!) {
    surveyById(id: $id) {
      id
      name
      locale {
        id
        code
        name
      }
    }
  }
`
