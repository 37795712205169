import React from 'react'
import Button, { ButtonSize, ButtonVariant } from '../../../components/Button'
import { Color } from '../../../color.enum'

interface LoginMethodSelectorProps {
  getWizardComponent: (method: string) => any
  currentStep: string
}

const loginMethods = [
  {
    name: 'Email',
    type: 'email',
    enable: false
  },
  {
    name: 'Wordpress',
    type: 'wordpress',
    enable: true
  }
]

export const LoginMethodSelector: React.FC<LoginMethodSelectorProps> = (props) => {
  return (
    <>
      {loginMethods.map((method) => {
        if (method.enable) {
          switch (method.type) {
            case 'email':
              return <EmailLogin {...props} />
            case 'wordpress':
              return <WordpressLogin />
          }
        }
      })}
    </>
  )
}

const EmailLogin: React.FC<LoginMethodSelectorProps> = ({ getWizardComponent, currentStep }) => {
  return <div className="mx-auto px-4">{getWizardComponent(currentStep)}</div>
}

const WordpressLogin: React.FC = () => {
  return (
    <div className="mx-auto">
      <a
        href={`${process.env.REACT_APP_WP_HOST}/oauth/authorize?client_id=${process.env.REACT_APP_WP_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_WP_REDIRECT_URI}`}
      >
        <Button
          className="justify-center"
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.MEDIUM}
          color={Color.BLUE}
          style={{ width: '100%' }}
        >
          Login with FiftyFour Account
        </Button>
      </a>
    </div>
  )
}
