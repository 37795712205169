import React, { useEffect, useMemo } from 'react'
import HeroWrapper from './HeroWrapper'
import SectionCenterWrapper from './SectionCenterWrapper'
import SectionLeftWrapper from './SectionLeftWrapper'
import TestimonialWrapper from './TestimonialWrapper'
import CtaWrapper from './CtaWrapper'
import LayoutContentWrapper from './LayoutContentWrapper'
import Loader from '../components/Loader/Loader'
import { CONTENT_TYPES } from '../common/constants'
import { useUserQuery } from '../hooks/useUserQuery'
import { navigate } from '@reach/router'


export const LAYOUT_MAPPING = {
  [CONTENT_TYPES.HERO]: HeroWrapper,
  [CONTENT_TYPES.SECTION_CENTER]: SectionCenterWrapper,
  [CONTENT_TYPES.SECTION_LEFT]: SectionLeftWrapper,
  [CONTENT_TYPES.TESTIMONIAL]: TestimonialWrapper,
  [CONTENT_TYPES.CTA]: CtaWrapper,
  [CONTENT_TYPES.LAYOUT_CONTENT]: LayoutContentWrapper
}

interface LayoutProps {
  layout: any
  indexInArray?: string
  id?: string
}

const Layout: React.FC<LayoutProps> = ({ layout }) => {
  const { loading, data, error } = useUserQuery()


  useEffect(() => {
    if ((!data || error) && !loading) {
      navigate('https://fiftyfourcollective.com')
    }
  }, [data, error])
  
  const modules = useMemo(() => {
    if (!layout) {
      return <h1>No layout</h1>
    }
    return layout.map((l: any) => {
      if (!l) return null
      const { __typename } = l
      const Component = LAYOUT_MAPPING[__typename]
      if (!Component) return null

      return <Component {...l} key={l.id} id={l.sys.id}  />
    })
  }, [layout])

  return modules
}

export default Layout
