import React from 'react'
import { Color } from '../../color.enum'
import { classNames } from '../../utils/class-names'
import Icon from '../Icon/Icon'

export interface LoaderProps {
  /** Aditional Styles for the container */
  className?: string
  /** What color is the componet? */
  color?: Color
  /** Optional Label Text for  */
  label?: string
}

export const COLOR_MAPS: Record<Color, string> = {
  [Color.GRAY]: 'text-gray-500',
  [Color.BLUE]: 'text-brand-blue',
  [Color.PURPLE]: 'text-brand-purple',
  [Color.RED]: 'text-brand-red',
  [Color.ORANGE]: 'text-brand-orange'
}

/**
 *  Component to show an animated Loader
 */

const Loader: React.FC<LoaderProps> = ({ className, color, label }: LoaderProps) => {
  return (
    <div className={classNames(className, 'flex flex-col items-center justify-center')}>
      <div
        className={classNames(
          'Loader rounded-md animate-spinPulse',
          color ? COLOR_MAPS[color] : COLOR_MAPS[Color.BLUE]
        )}
      >
        <Icon name="loader" />
      </div>
      {label && (
        <div className="pt-2 tracking-wide text-center">
          <p className={classNames(color ? COLOR_MAPS[color] : COLOR_MAPS[Color.BLUE], 'text-base')}>{label}</p>
        </div>
      )}
    </div>
  )
}

Loader.defaultProps = {
  className: 'w-full h-screen',
  label: 'Loading'
}

export default Loader
