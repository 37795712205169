import React, { useContext, useState, useEffect } from 'react'
import { ChevronDownIcon, CheckIcon } from '@heroicons/react/solid'
import DataContext, { DataItem } from './DataContext'
import { Combobox } from '@headlessui/react'

type GraphFiltersProps = {
  graphIndex: number
  setGraphType: (type: string) => void
  setData: (data: DataItem[]) => void
}

const GraphFilters: React.FC<GraphFiltersProps> = ({ setData }) => {
  const data = useContext(DataContext)
  const [filters, setFilters] = useState<Record<string, string[]>>({})

  const handleFilterChange = (name: string, values: string[]) => {
    setFilters({ ...filters, [name]: values || [] })
  }

  const allYears = Array.from(new Set(data.map((item) => item.year.toString())))
  const allCountries = Array.from(new Set(data.map((item) => item.country)))
  const allOrganisations = Array.from(new Set(data.map((item) => item.organisation)))

  const applyFilters = () => {
    if (
      Object.keys(filters).every((filter) => {
        return !filters[filter].length
      })
    ) {
      setData(data)
    } else {
      const filteredData = data.filter(
        (item) =>
          (!(filters.year && filters.year.length) || filters.year.includes(item.year.toString())) &&
          (!(filters.country && filters.country.length) || filters.country.includes(item.country)) &&
          (!(filters.organisation && filters.organisation.length) || filters.organisation.includes(item.organisation))
      )
      setData(filteredData)
    }
  }

  useEffect(() => {
    applyFilters()
  }, [filters, data])

  // Filter options based on selected values
  const years = allYears
  const countries =
    filters.year && filters.year.length
      ? Array.from(
          new Set(data.filter((item) => filters.year.includes(item.year.toString())).map((item) => item.country))
        )
      : allCountries
  const organisations =
    filters.country && filters.country.length
      ? Array.from(
          new Set(data.filter((item) => filters.country.includes(item.country)).map((item) => item.organisation))
        )
      : allOrganisations

  const filtersList = [
    { name: 'year', options: years },
    { name: 'country', options: countries },
    { name: 'organisation', options: organisations }
  ]

  return (
    <div className="flex flex-wrap space-x-2">
      {filtersList.map((filter) => (
        <Combobox
          key={filter.name}
          value={filters[filter.name]}
          onChange={(values) => handleFilterChange(filter.name, values)}
          multiple
        >
          <div className="relative mt-1">
            <Combobox.Button className="relative w-full py-1 pl-2 pr-8 text-base  text-white bg-brand-blue-light border-0 border-gray-200 rounded-sm focus:border-opacity-20 placeholder-gray-600 placeholder-opacity-75 transition-all">
              {filters[filter.name] && filters[filter.name].length > 0
                ? `${filter.name.toUpperCase()} (${filters[filter.name].length})`
                : `Select ${filter.name}`}
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronDownIcon className="w-5 h-5 text-gray-400" />
              </span>
            </Combobox.Button>
            <Combobox.Options className="absolute  py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filter.options.map((option, i) => (
                <Combobox.Option
                  key={i}
                  value={option}
                  className={({ active }) =>
                    `${
                      active ? 'text-amber-900 bg-amber-100' : 'text-gray-900'
                    } cursor-default select-none relative py-2 pl-10 pr-4`
                  }
                >
                  {({ selected }) => (
                    <>
                      <span className={`${selected ? 'font-medium' : 'font-normal'} block truncate`}>{option}</span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                          <CheckIcon className="w-5 h-5" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          </div>
        </Combobox>
      ))}
    </div>
  )
}

export default GraphFilters
