import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Link, navigate } from '@reach/router'

type Option = {
  to: string
  label: string
  external?: boolean
  isSignOut?: boolean
}

interface DropDownProps {
  label?: string
  options?: Option[]
  className?: string
  onSignOut?: () => void
}

const DropDown: React.FC<DropDownProps> = ({ label, options, className, onSignOut }): JSX.Element => {
  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <Menu as="div" className={`relative inline-block text-left text-brand-blue ${className}`}>
      <div>
        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 font-openSans font-normal">
          <span className="HeaderNavItem inline-block transition py-2.5 border-b-3 border-transparent font-openSans font-bold">
            {label}
          </span>
          <span className="py-3.5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-3 w-3"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
            </svg>
          </span>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {options?.map((option, index) => (
              <Menu.Item key={index}>
                {({ active }) =>
                  option.isSignOut ? (
                    <button
                      onClick={() => {
                        if (onSignOut) {
                          onSignOut()
                        } else {
                          localStorage.removeItem('osp-token')
                          navigate('https://fiftyfourcollective.com/login/')
                        }
                      }}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block w-full text-left px-4 py-2 text-sm'
                      )}
                    >
                      {option.label}
                    </button>
                  ) : option.external ? (
                    <a
                      href={option.to as string}
                      rel="noopener noreferrer"
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm'
                      )}
                    >
                      {option.label}
                    </a>
                  ) : (
                    <Link
                      to={option.to}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm'
                      )}
                    >
                      {option.label}
                    </Link>
                  )
                }
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default DropDown
