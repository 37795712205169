import { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { classNames } from '../../utils/class-names'
import { renderLines, renderSlices, renderArcs, ArchetypeProps } from './Radar.helpers'
import theme from '../../theme'
import { Color } from '../../color.enum'
import { svgToBuffer } from '../../utils/blobs'

export interface RadarProps {
  /** The Archetypes data */
  archetypes: Array<ArchetypeProps>
  /** Background Color of Radar */
  bg?: string
  /** Color of the lines */
  linesColor?: string
  /** Aditional Styles for the container */
  className?: string
  setImageBuffer?: (buffer: any) => void
  id?: string
}

interface ColorVariables {
  legend: string
}

const startX = 75,
  startY = 75
const radius = 50

/**
 *  Component to show a Radar graph that visually presents the results of a survey
 */

const COLOR_MAP: Record<Color, ColorVariables> = {
  [Color.BLUE]: {
    legend: 'bg-brand-blue'
  },
  [Color.GRAY]: {
    legend: 'bg-gray-500'
  },
  [Color.PURPLE]: {
    legend: 'bg-brand-purple'
  },
  [Color.RED]: {
    legend: 'bg-brand-red'
  },
  [Color.ORANGE]: {
    legend: 'bg-brand-orange'
  }
}

const Radar = ({ className, archetypes, bg, linesColor, setImageBuffer, id = 'graph' }: RadarProps) => {
  const [{ x, y }, setCoords] = useState<{ x?: number; y?: number }>({})
  const [activeValues, setActiveValues] = useState<{ label: string; percentage: number }>({
    label: '',
    percentage: 0
  })
  const [isHovering, setIsHovering] = useState(false)
  const svgRef = useRef<SVGSVGElement>(null)
  useEffect(() => {
    const setCoordinates = ({ clientX: x, clientY: y }: MouseEvent) => setCoords({ x, y })
    window.addEventListener('mousemove', setCoordinates)
    return () => {
      window.removeEventListener('mousemove', setCoordinates)
    }
  }, [])

  const dimensionCount = archetypes.reduce((acc, { dimensions }) => {
    acc += dimensions.length
    return acc
  }, 0)

  useLayoutEffect(() => {
    const getBlob = async () => {
      if (svgRef.current && setImageBuffer) {
        svgToBuffer(svgRef.current, setImageBuffer)
      }
    }
    getBlob()
  }, [svgRef.current])

  const sliceIndex = 0

  const onMouseEnter = () => {
    setIsHovering(true)
  }

  const onMouseLeave = () => {
    setIsHovering(false)
  }

  const lines = renderLines({ dimensionCount, startX, startY, radius, color: linesColor })

  const slices = renderSlices({
    archetypes: [...archetypes].reverse(),
    idx: sliceIndex,
    dimensionCount,
    startX,
    startY,
    radius,
    setActiveValues: (label: string, percentage: number) => setActiveValues({ label, percentage }),
    onMouseEnter,
    onMouseLeave
  })

  const arcs = renderArcs({ archetypes: [...archetypes].reverse(), dimensionCount, startX, startY, radius })

  return (
    <div className={classNames(className, `Radar w-full bg-${bg}`)}>
      <div>
        {isHovering && (
          <>
            <div
              className={classNames('Triangle fixed z-[1]')}
              style={{
                top: `calc(${y}px + 10px)`,
                left: `calc(${x}px - 7px)`,
                border: '7px solid transparent',
                borderBottom: `7px solid white`
              }}
            />
            <div
              className={classNames(
                'Tooltip fixed px-4 py-2 bg-white text-sm rounded flex justify-center items-center shadow-sm'
              )}
              style={{
                width: '150px',
                left: `calc(${x}px - 75px)`,
                top: `calc(${y}px + 24px)`
              }}
            >
              <p className="text-xs text-black text-center">
                <div className="font-medium">{activeValues.percentage} %</div>
                <div className="text-gray-600">{activeValues.label}</div>
              </p>
            </div>
          </>
        )}
        <svg ref={svgRef} viewBox={`0 0 ${startX * 2} ${startY * 2}`} xmlnsXlink="http://www.w3.org/1999/xlink">
          <circle
            cx={startX}
            cy={startY}
            r={50}
            fill={theme.colors?.gray[100]}
            stroke={linesColor}
            strokeWidth={0.5}
            opacity={0.3}
          />
          {slices}
          {lines}
          {[10, 20, 30, 40].map((radius, index) => (
            <circle
              key={index}
              cx={startX}
              cy={startY}
              r={radius}
              fill="transparent"
              stroke={linesColor}
              strokeLinecap="round"
              strokeDasharray="0 2"
              strokeWidth={0.3}
              opacity={1}
              pointerEvents="none"
            />
          ))}
          {arcs}
        </svg>
      </div>
      <div className="flex flex-col items-center">
        <div>
          {[...archetypes].map(({ color, name }) => (
            <div className="flex items-center justify-start">
              <div className={classNames('w-2 h-2 rounded-full mr-1', COLOR_MAP[color].legend)} />
              <div className="text-sm text-brand-blue">{name}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

Radar.defaultProps = {
  archetypes: [],
  bg: '#ffffff',
  linesColor: theme.colors?.gray[300]
}

export default Radar
