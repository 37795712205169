import React from 'react'
import { classNames } from '../../utils/class-names'
import Logo from '../../../public/collectiveLogo.png'
import { Link } from '@reach/router'

export interface FooterProps {
  /** Legal text to be added to the footer */
  legalText?: string
  /** Navigation to be added to the footer */
  renderNavigation?: React.ReactElement
  /** Aditional Styles for the container */
  className?: string
}

/**
 *  Component to show the Footer of the App
 */
const Footer: React.FC<FooterProps> = ({ className, renderNavigation }: FooterProps) => {
  return (
    <footer className={classNames(className, 'footer-wrapper w-full items-center justify-center bg-brand-blue')}>
      <div className="w-full flex flex-col lg:flex-row items-center justify-between max-w-7xl mx-auto px-2 sm:px-4 lg:px-8 py-10">
        <a href="https://fiftyfourcollective.com/my-learning/" className="flex w-32">
          <img className="block" src={Logo} alt="FiftyFour Collective Logo" />
        </a>
        <div>
          <div className="mb-4">{renderNavigation}</div>
          <div className="mb-2 text-right">
            <p className="text-white mb-2">
              Contact:{' '}
              <a
                href="mailto:info@fiftyfourcollective.com"
                target="_blank"
                rel="noopener"
                className="hover:text-yellow text-white"
              >
                info@fiftyfourcollective.com
              </a>
            </p>
            <p className="text-white">
              <a
                href="https://fiftyfourcollective.com/terms-of-use/"
                title="Terms of Use"
                className="hover:text-yellow text-white text-sm"
              >
                Terms of Use
              </a>{' '}
              |{' '}
              <a
                href="https://fiftyfourcollective.com/frequently-asked-questions/"
                className="hover:text-yellow text-white text-sm"
              >
                Frequently Asked Questions
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
