import React from 'react'
import { Link, RouteComponentProps, Match } from '@reach/router'
import HeaderNavItem from './HeaderNavItem'

export interface HeaderNavItemWrapperProps extends RouteComponentProps {
  className?: string
  to: string
  label: string
  getProps?: any
  isExternal?: boolean
}

const HeaderNavItemWrapper: React.FC<HeaderNavItemWrapperProps> = ({
  label,
  to,
  className,
  isExternal = false,
  ...rest
}) => {
  if (isExternal) {
    return (
      <a href={to} className={className} rel="noopener noreferrer">
        <HeaderNavItem label={label} />
      </a>
    )
  }

  return (
    <Match path={to}>
      {({ match }) => (
        <Link to={to} {...rest}>
          <HeaderNavItem className={className} isActive={!!match} label={label} />
        </Link>
      )}
    </Match>
  )
}

export default HeaderNavItemWrapper
