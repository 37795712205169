import React from 'react'
import { classNames } from '../../utils/class-names'

export interface HeroProps {
  /** Headline of the Hero */
  headline1?: string
  /** The second headline for the Hero */
  headline2?: string
  /** Descriptive text to support the headlines */
  description?: string | React.ReactNode
  /** Add a call to action to the Hero */
  children?: React.ReactElement | React.ReactNode
  /** Aditional Styles for the container */
  className?: string
  /** Id to identify specific component as provided by the CMS */
  id?: string
}

/**
 *  Component to show a Hero - typically at the top of the page
 */

const Hero: React.FC<HeroProps> = ({ className, headline1, headline2, description, children, ...rest }: HeroProps) => {
  return (
    <div className={classNames(className, 'Hero bg-white mt-48')} {...rest}>
      <div className="relative overflow-hidden">
        <div className="relative sm:pt-6 pb-16 sm:pb-24">
          <div className=" sm:mt-8 mx-auto px-4 sm:px-6 max-w-5xl">
            <div className="text-center">
              <h1
                className={classNames(
                  `text-4xl tracking-tight font-medium text-brand-blue sm:text-5xl md:text-6xl ${description && 'mb-10'}`
                )}
              >
                {headline1 && <span className="block">{headline1}</span>}
                {headline2 && <span className="block text-brand-blue">{headline2}</span>}
              </h1>
              {description && (
                <div className="prose mx-auto text-base sm:text-lg md:text-xl max-w-none md:max-w-4xl">
                  <span className="lead text-brand-blue">{description}</span>
                </div>
              )}
              {children && (
                <div className="py-8 prose mx-auto text-base sm:text-lg md:text-xl max-w-none md:max-w-4xl text-brand-blue">
                  {children}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Hero.defaultProps = {}

export default Hero
