import { Width } from './types'

export const styles = {
  standard:
    'block text-[#333] border-[#BBBBBB] ring-0 border outline-none focus:outline-none focus:border-black py-2.5 px-[5px] text-base rounded-none placeholder-gray-600 placeholder-opacity-75 transition-all my-2.5',
  widths: {
    sm: 'w-72',
    md: 'w-80',
    lg: 'w-96',
    full: 'w-full'
  } as Width
}
