import React from 'react'
import { RouteComponentProps, useNavigate } from '@reach/router'
import { useQuery, gql } from '@apollo/client'
import Loader from '../../../components/Loader/Loader'
import { Color } from '../../../color.enum'
import { DemographicForm } from './DemographicSurveyForm'
import { User } from '../../../common/types'
import { PageWrapper } from '../../../modules/PageWrapper'
import Steps from '../../../components/Steps'

interface DemographicSurveyProps extends RouteComponentProps {
  assignmentId: string
  user?: User
  setStep: (step: string) => void
  scanId?: string
}

const DemographicSurvey: React.FC<DemographicSurveyProps> = ({ setStep, scanId }) => {
  const navigate = useNavigate()
  const { loading, data } = useQuery(GET_POST_SURVEYS, {
    variables: { assignmentId: scanId },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const { questionnaire } = data
      if (questionnaire.status === 'COMPLETED') {
        navigate(`/dashboard/organisation/scans/${scanId}`)
      }
    }
  })

  if (loading && !data) return <Loader />

  const { questionnaire } = data
  const steps = [
    { title: 'Assessment Details', isComplete: false, color: Color.BLUE },
    { title: 'Assessment Participants', isComplete: false, color: Color.BLUE },
    { title: 'Organisation Information', isComplete: false, color: Color.BLUE }
  ]

  return (
    <PageWrapper
      headline="Tell us more about your organisation"
      description="Shape the context of your assessment by providing comprehensive information about your organisation. This data will serve as the backbone for your 9D Capacity Index, enabling a tailored analysis across the vital dimensions of organisational health."
      sectionHeading=""
      Steps={<Steps steps={steps} activeDimension={2} />}
    >
      <div className="max-w-lg">
        <DemographicForm
          questions={questionnaire.questions}
          demographicSurveyId={questionnaire.id}
          assignmentId={scanId}
          setStep={setStep}
        />
      </div>
    </PageWrapper>
  )
}

export { DemographicSurvey }

const GET_POST_SURVEYS = gql`
  query postSurveyQuery($assignmentId: String!) {
    questionnaire {
      id
      name
      description
      headline
      status(assignmentId: $assignmentId)
      questions {
        id
        text
        code
        responseType
        postSurveyOption {
          id
          archived
          text
          index
          code
        }
      }
    }
  }
`
