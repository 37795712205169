import { Redirect, RouteComponentProps, useNavigate } from '@reach/router'
import { Color } from '../../color.enum'
import HeadingSection from '../../components/HeadingSection'
import { User } from '../../common/types'
import Button, { ButtonVariant, ButtonSize } from '../../components/Button'
import { language } from '../../common/i18n'
import { roleMenuItems } from '../../components/Nav/MainNav'
import { PageWrapper } from '../../modules/PageWrapper'
import { RespondentDashboard } from '../Survey/RespondentDashboard'
import { OwnerDashboard } from './Owner'
import { PeerDashboard } from './Peer'

interface AdminProps extends RouteComponentProps {
  user?: User
}

const AdminDashboard = ({ user }: { user: User }) => {
  const navigate = useNavigate()
  const { items, title } = roleMenuItems(user)

  return (
    <PageWrapper headline={title} sectionHeading="Links">
      {items.map((sec: any, i: any) => {
        if (sec.hidden) return null
        return (
          <HeadingSection
            headline={sec.description}
            color={Color.BLUE}
            children={() => {
              return (
                <div className="flex items-center" key={i}>
                  <Button
                    variant={ButtonVariant.PRIMARY}
                    color={Color.BLUE}
                    size={ButtonSize.SMALL}
                    onClick={() => navigate(sec.to)}
                    iconRight="arrowRight"
                    className="rounded-[15px]"
                  >
                    {language.en.admin.dashBoard.buttonText.view}
                  </Button>
                </div>
              )
            }}
          />
        )
      })}
    </PageWrapper>
  )
}

export const Dashboard: React.FC<AdminProps> = ({ user }) => {
  if (!user) return <Redirect to="/login" noThrow />

  let Component
  if (user.isSuperAdmin) {
    Component = AdminDashboard
  } else if (user.isPeer) {
    Component = PeerDashboard
  } else if (user.isOrgLeader) {
    Component = OwnerDashboard
  } else {
    Component = RespondentDashboard
  }

  return <Component user={user} />
}
