import React from 'react'
import { FormLabel as BaseFormLabel } from '@plusplusminus/plusplusdash'
import { classNames } from '../../utils/class-names'

interface FormLabelProps {
  /** Aditional Styles for the container */
  className?: string
  /** Children are displayed as the text of the label */
  children: React.ReactElement | string
}

/**
 * Form Labels
 */

export const FormLabel: React.FC<FormLabelProps> = ({ className, children, ...props }: FormLabelProps) => {
  return (
    <BaseFormLabel
      className={classNames(className, 'font-medium text-brand-blue my-1 inline-block form-label')}
      {...props}
    >
      {children}
    </BaseFormLabel>
  )
}

export default FormLabel
