import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import { Redirect, RouteComponentProps } from '@reach/router'
import { Color } from '../../color.enum'
import Loader from '../../components/Loader/Loader'
import Alert, { AlertVariant, AlertSize } from '../../components/Alert'

interface OAuthCallbackProps extends RouteComponentProps {
  redirect?: string
}

export const OAuthCallback: React.FC<OAuthCallbackProps> = () => {
  const [, setValue] = useLocalStorage()
  const [error, setError] = useState('')
  const [redirect, setRedirect] = useState('')

  useEffect(() => {
    async function exchangeCode() {
      const urlParams = new URLSearchParams(window.location.search)
      const code = urlParams.get('code')

      if (!code) {
        setError('No authorization code found')
        return
      }

      try {
        const response = await axios.post(`${process.env.REACT_APP_API}/auth/wordpress/callback`, { code })

        setValue('osp-token', response.data.token)

        const postAuthUrl = localStorage.getItem('preAuthUrl')

        if (postAuthUrl) {
          localStorage.removeItem('preAuthUrl')
          setRedirect(postAuthUrl)
        } else {
          setRedirect(response.data.redirectTo || '/')
        }
      } catch (err) {
        console.error('Error exchanging authorization code:', err)
        setError('Error exchanging authorization code')
      }
    }

    exchangeCode()
  }, [])

  if (error) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div>
          <Alert size={AlertSize.LARGE} variant={AlertVariant.WARNING} className="w-full">
            {error}
          </Alert>
        </div>
      </div>
    )
  }

  if (!redirect) {
    return <Loader color={Color.BLUE} />
  }

  if (redirect) {
    return <Redirect to={redirect} replace={true} noThrow />
  }

  return null
}

export default OAuthCallback
