import { useEffect } from 'react'
import { RouteComponentProps, useNavigate, useParams, useLocation } from '@reach/router'
import { Color } from '../../../../../color.enum'
import { gql, useMutation, useLazyQuery } from '@apollo/client'
import HeadingPageCenter from '../../../../../components/HeadingPageCenter'
import { Helmet } from 'react-helmet'
import { language } from '../../../../../common/i18n'
import { FormWrapper } from '../../../../../modules/FormWrapper'
import { useFormObj } from '../../../../../hooks/useForm'
import { FormTypes } from '../../../../../common/constants'
import Loader from '../../../../../components/Loader/Loader'

interface CreateStatementInterface extends RouteComponentProps<any> {
  location: any
}

const CreatePostSurveyQuestion: React.FC<CreateStatementInterface> = () => {
  const navigate = useNavigate()
  const { postSurveyId, questionId, surveyId } = useParams()
  const { pathname } = useLocation()
  const type = pathname.endsWith('edit') ? FormTypes.edit : FormTypes.create
  const form = useFormObj('question')

  const [createPostSurveyQuestion, { loading: createPostSurveyQuestionLoading }] = useMutation(
    CREATE_POST_SURVEY_QUESTION,
    {
      onError: (error) => {
        console.log(error)
      },
      onCompleted: (data) => {
        navigate(
          `/dashboard/admin/survey/${surveyId}/postSurvey/${postSurveyId}/question/${data?.createPostSurveyQuestion?.id}`
        )
      }
    }
  )

  const [updatePostSurveyQuestion, { loading: updateLoading }] = useMutation(UPDATE_POST_SURVEY_QUESTION, {
    onError: (error) => {
      console.log(error)
    },
    onCompleted: () => {
      navigate(`/dashboard/admin/survey/${surveyId}/postSurvey/${postSurveyId}/question/${questionId}`)
    }
  })

  const [loadPostSurveyQuestionData, { loading, data }] = useLazyQuery(GET_SINGLE_POST_SURVEY_QUESTION, {
    variables: { id: questionId },
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    if (type === FormTypes.edit) {
      loadPostSurveyQuestionData()
    }
  }, [])

  const onSubmit = (data: any, type: FormTypes) => {
    if (type === FormTypes.create) {
      createPostSurveyQuestion({
        variables: {
          input: { ...data, postSurveyId, position: Number(data.position) }
        }
      })
    } else {
      const { position, ...rest } = data

      updatePostSurveyQuestion({
        variables: {
          id: questionId,
          input: {
            position: Number(position),
            code: rest.code,
            text: rest.text,
            responseType: rest.responseType,
            description: rest.description
          }
        }
      })
    }
  }

  if (loading) return <Loader />

  return (
    <div className="py-10">
      <Helmet>
        <title>{language.en.admin.question.create.title}</title>
        <meta name="description" content={language.en.admin.question.create.description} />
        <meta name="robots" content="noindex" data-react-helmet="true" />
      </Helmet>
      <main>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <HeadingPageCenter
            className="text-center  mt-12"
            headline={
              type === FormTypes.edit
                ? 'Edit Participant Demographic Questionnaire Question'
                : 'Create new Questionnaire Question'
            }
            color={Color.GRAY}
          />
          <div className="section-container-sm">
            <FormWrapper
              buttonLabel="Submit"
              loading={createPostSurveyQuestionLoading || updateLoading}
              form={form}
              onSubmit={onSubmit}
              formType={type}
              data={data?.postSurveyQuestion}
            />
          </div>
        </div>
      </main>
    </div>
  )
}

export default CreatePostSurveyQuestion

const CREATE_POST_SURVEY_QUESTION = gql`
  mutation createPostSurveyQuestion($input: CreatePostSurveyQuestionInput!) {
    createPostSurveyQuestion(input: $input) {
      text
      id
      position
    }
  }
`
const UPDATE_POST_SURVEY_QUESTION = gql`
  mutation updatePostSurveyQuestion($id: String!, $input: UpdatePostSurveyQuestionInput!) {
    updatePostSurveyQuestion(input: $input, id: $id) {
      text
      id
      position
    }
  }
`
const GET_SINGLE_POST_SURVEY_QUESTION = gql`
  query _postSurveyQuestion($id: String!) {
    postSurveyQuestion(id: $id) {
      text
      code
      position
      id
      description
      responseType
      postSurveyOption {
        id
        text
        code
      }
    }
  }
`
