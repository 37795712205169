import React from 'react'
import { classNames } from '../../utils/class-names'

export interface HeaderNavItemProps {
  /** Label for the nav item */
  label?: string
  /** Is this the current active item? */
  isActive?: boolean
  /** Aditional Styles for the container */
  className?: string
}

/**
 *  Component to show the Header Navigation Items
 */
const HeaderNavItem: React.FC<HeaderNavItemProps> = ({ label, isActive, className, ...rest }) => {
  return (
    <span
      className={classNames(
        className,
        'HeaderNavItem mt-2.5 inline-block transition py-2.5 font-openSans text-brand-blue',
        isActive
          ? 'border-b-3 border-brand-blue font-openSans font-bold'
          : 'border-b-3 border-transparent font-openSans font-bold hover:border-brand-blue-hover'
      )}
      {...rest}
    >
      {label && label}
    </span>
  )
}

export default HeaderNavItem
