import React, { useEffect, useState } from 'react'
import { RouteComponentProps, useLocation, useNavigate, useParams } from '@reach/router'
import { AddOrganizationForm } from './AddOrganizationForm'
import { useMutation } from '@apollo/client'
import { Alert } from '@plusplusminus/plusplusdash'
import { User } from '../../common/types'
import gql from 'graphql-tag'
import { useFormatApolloError } from '../../hooks/useFormatApolloError'
import HeadingPageCenter from '../../components/HeadingPageCenter'
import { Color } from '../../color.enum'
import Button from '../../components/Button'
import { ButtonVariant, ButtonSize } from '../../components/Button'
import { useUserQuery } from '../../hooks/useUserQuery'
import Loader from '../../components/Loader/Loader'

interface CreateAssignmentProps extends RouteComponentProps {
  inModal?: boolean
  user?: User
  assignmentId?: string
  setShowCreate?: (showCreate: boolean) => void
  setOrg: (org: {
    name: string
    id: string
    owner: {
      firstName: string
      lastName: string
      email: string
    }
  }) => void
  className?: string
  orgId?: string
}

const AddOrgSelector: React.FC<CreateAssignmentProps> = (props) => {
  const { loading, data, refetch } = useUserQuery()
  const navigate = useNavigate()

  useEffect(() => {
    refetch()
  }, [])

  if (loading) return <Loader color={Color.BLUE} />

  if (!data?.me?.peer?.isPeer || !data?.me?.peer?.isSuperAdmin) {
    return <AddOrganizations {...props} user={data.me} />
  } else {
    return (
      <div className="page-container-lg">
        <HeadingPageCenter
          headline="Unfortunately you cannot create an organisation unless you are a peer"
          color={Color.GRAY}
          description="Please click the button below to create a peer"
        />
        <div className="flex justify-center">
          <Button
            variant={ButtonVariant.PRIMARY}
            color={Color.BLUE}
            size={ButtonSize.LARGE}
            onClick={() => navigate('/dashboard/admin/add-admin-as-peer')}
            iconRight="plus"
          >
            Create Peer
          </Button>
        </div>
      </div>
    )
  }
}

const AddOrganizations: React.FC<CreateAssignmentProps> = ({ setShowCreate, setOrg, inModal, user, orgId }) => {
  const { pathname } = useLocation()
  const { userId } = useParams()
  const navigate = useNavigate()

  const [error, setError] = useState({ message: '', action: '' })
  const [success, setSuccess] = useState({ message: '', action: '' })
  const [register, { loading }] = useMutation(CREATE_ORG)
  const [createOrgPeer, { loading: createOrgPeerLoading }] = useMutation(CREATE_ORG_FROM_PEER)
  const [formatError] = useFormatApolloError()

  const [updateOrg, { loading: updateOrgLoading }] = useMutation(UPDATE_ORG, {
    onError: (error) => {
      console.log(error)
    },
    onCompleted: () => {
      navigate(`/dashboard/peer/organisations/${orgId}`)
    }
  })
  const type = pathname.endsWith('edit') ? 'edit' : 'create'

  const onCreateOrg = (submission: any) => {
    setError({
      message: '',
      action: ''
    })
    setSuccess({
      message: '',
      action: ''
    })

    const input: any = {
      name: submission.name.trim(),
      country: submission.country.trim(),
      foundedDate: new Date(submission.foundedDate),
      internalId: submission?.internalId,
      surveyId: submission?.surveyId,
      owner: {
        firstName: submission.firstName.trim(),
        lastName: submission.lastName.trim(),
        email: submission.email.toLowerCase().trim()
      }
    }

    if (type === 'create') {
      register({
        variables: {
          input
        }
      })
        .then(({ data }) => {
          if (setShowCreate) {
            const {
              name,
              id,
              owner: { firstName, lastName, email }
            } = data.createOrganization
            setOrg({
              name,
              id,
              owner: {
                firstName,
                lastName,
                email
              }
            })
            setShowCreate(false)
          } else {
            navigate(`/dashboard/peer/organisations`)
          }
        })
        .catch((err) => {
          console.log(err)
          setError({
            message: formatError(err),
            action: 'no action'
          })
        })
    } else {
      updateOrg({
        variables: {
          orgId,
          input
        }
      })
    }
  }

  const createOrgFromPeer = (submission: any) => {
    const input: any = {
      name: submission.name.trim(),
      country: submission.country.trim(),
      foundedDate: new Date(submission.foundedDate),
      internalId: submission?.internalId,
      owner: {
        firstName: submission.firstName.trim(),
        lastName: submission.lastName.trim(),
        email: submission.email.toLowerCase().trim()
      }
    }

    createOrgPeer({
      variables: {
        input
      }
    })
      .then(({ data }) => {
        if (setShowCreate) {
          const {
            firstName,
            lastName,
            email,
            organization: { name, id }
          } = data.createOrgFromPeer
          setOrg({
            name,
            id,
            owner: {
              firstName,
              lastName,
              email
            }
          })
          setShowCreate(false)
        } else {
          navigate(`/dashboard/peer/organisations`)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  if (createOrgPeerLoading || loading || updateOrgLoading) return <Loader color={Color.BLUE} />

  return (
    <div className={inModal ? '' : 'page-container-lg'}>
      <AddOrganizationForm
        onSubmit={onCreateOrg}
        loading={loading || updateOrgLoading}
        success={success}
        userId={userId}
        formType={type}
        user={user}
        createOrgFromPeer={createOrgFromPeer}
        orgId={orgId}
      />
      {error.message ? <Alert type="error" children={<div>{error.message}</div>} /> : null}
      {success.message ? <Alert type="success" children={<div>{success.message}</div>} /> : null}
    </div>
  )
}

export default AddOrgSelector

export const CREATE_ORG = gql`
  mutation createOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      id
      name
      owner {
        firstName
        lastName
        email
      }
    }
  }
`

export const UPDATE_USER = gql`
  mutation updateUser($userId: String!, $input: UpdateUserInput!) {
    updateUser(userId: $userId, input: $input) {
      id
      email
      firstName
      lastName
      role
    }
  }
`

export const CREATE_ORG_FROM_PEER = gql`
  mutation createOrgFromPeer($input: CreateOrganizationInput!) {
    createOrgFromPeer(input: $input) {
      id
      firstName
      lastName
      email
      organization {
        id
        name
      }
    }
  }
`

export const UPDATE_ORG = gql`
  mutation updateOrganization($orgId: String!, $input: UpdateOrgInput!) {
    updateOrganization(id: $orgId, input: $input) {
      id
    }
  }
`
