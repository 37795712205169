import { Link, RouteComponentProps, useNavigate } from '@reach/router'
import { Color } from '../../color.enum'
import HeadingPage from '../../components/HeadingPage'
import HeadingSection from '../../components/HeadingSection'
import { User } from '../../common/types'
import Button, { ButtonVariant, ButtonSize } from '../../components/Button'
import { Helmet } from 'react-helmet'
import { language } from '../../common/i18n'
import PeerGraphComponent from '../../components/PeerGraph'

interface AdminProps extends RouteComponentProps {
  user?: User
}

type section = {
  headline: string
  color: Color
  path: string
}

export const PeerDashboard: React.FC<AdminProps> = (props) => {
  const { user } = props
  const navigate = useNavigate()
  if (!user) return null

  const headingSections: section[] = [
    {
      headline: language.en.peers.dashboard.navItems.heading.users,
      color: Color.BLUE,
      path: `/dashboard/peer/${props?.user?.peer.id}/users`
    },
    {
      headline: language.en.peers.dashboard.navItems.heading.scans,
      color: Color.BLUE,
      path: '/dashboard/peer/scans'
    },
    {
      headline: language.en.peers.dashboard.navItems.heading.organisations,
      color: Color.BLUE,
      path: '/dashboard/peer/organisations'
    }
  ]

  return (
    <>
      <Helmet>
        <title> {language.en.admin.peer.dashBoard.title} </title>
        <meta name="description" content={language.en.admin.peer.dashBoard.description} />
        <meta name="robots" content="noindex" data-react-helmet="true" />
      </Helmet>

      <main>
        <PeerGraphComponent peerId={props.user?.peer.id} />
        <div className="page-container-lg lg:mb-32">
          <HeadingPage headline={`Links`} color={Color.BLUE} />
          {headingSections.map((sec, i) => (
            <Link to={sec.path}>
              <HeadingSection
                headline={sec.headline}
                color={sec.color}
                children={() => {
                  return (
                    <div className="flex items-center" key={i}>
                      <Button
                        variant={ButtonVariant.PRIMARY}
                        color={Color.BLUE}
                        size={ButtonSize.SMALL}
                        onClick={() => navigate(sec.path)}
                        iconRight="arrowRight"
                      >
                        {language.en.admin.dashBoard.buttonText.view}
                      </Button>
                    </div>
                  )
                }}
              />
            </Link>
          ))}
        </div>
      </main>
    </>
  )
}
