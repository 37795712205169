import { RouteComponentProps } from '@reach/router'
import logo from '../../public/mci-logo-center.png'
import Radar, { api as radarApi } from '../components/Radar'
import Bar, { BarSize } from '../components/Bar'
import { Color } from '../color.enum'

const ReportPreview: React.FC<RouteComponentProps> = () => (
  <div className="mx-auto px-4 max-w-5xl py-10 prose divide-y ">
    <div className="min-h-screen">
      <img src={logo} className="mx-auto w-3/12 pb-20" />
      <div className="mx-auto text-center mb-40">
        <h1 className="text-brand-blue text-4xl">Proverbs 31 Ministries</h1>
        <p className="font-medium  text-lg text-brand-purple">22 August 2021</p>
      </div>
    </div>

    <div className="py-20">
      <h2 className="text-3xl text-brand-blue">Welcome to the 9D Capacity Index</h2>
      <p>
        Please note the title of this diagnostic – all three words were carefully chosen. First, there is the Mission.1
        An organisation’s mission answers the question: “Why are we, as an organisation, in existence?” Consider your
        ministry’s mission for a moment. It is most likely is communicating in some way, “We are in existence
        to…serve/provide/share/plant/equip/build/etc.” What are you serving, providing, sharing, etc.? For/to whom? To
        what end?
      </p>
      <p>
        Second, consider what hinders your organisation in the pursuit of those mission aspirations. What are the
        barriers, headwinds, or deficiencies that slow you down, deflect, or even stop mission progress? Such obstacles
        could be any number of things: weak leadership bench strength, the lack of a creative and coherent strategy,
        inadequate resources, and more. It is this question that moves us on to the second word in our title: Capacity.
        Organisational capacity describes the skills and resources a ministry needs to successfully pursue its mission
        over time.
      </p>
      <blockquote>
        <p>
          Because ends exist only in the imagination, they can be infinite… Means, though, are stubbornly finite;
          they’re the boots on the ground, the ships in the sea, and the bodies required to fill them. Ends and means
          have to connect if anything is to happen. They are never, however, interchangeable.”
        </p>
        <small>John Lewis Gaddis, On Grand Strategy</small>
      </blockquote>
      <p>
        Finally, Assesment. The Mission Capacity Assessment helps you quickly assess whether your ministry has the
        capacity to advance its mission. Such an assessment is a biblical idea. In Luke 14:28-32, we find Jesus
        explaining to the crowd the importance of counting the cost of discipleship – of following Him. We can take this
        same principle and apply it to the idea of measuring the cost (needed capacity) of pursuing a given ministry
        strategy or goal.
      </p>
      <blockquote>
        <p>
          <strong>Count the Cost</strong>
          <br />
          28 For which of you, desiring to build a tower, does not first sit down and count the cost, whether he has
          enough to complete it?
          <br />
          29 Otherwise, when he has laid a foundation and is not able to finish, all who see it begin to mock him,
          <br />
          30 saying, ‘This man began to build and was not able to finish.’
          <br />
          31 Or what king, going out to encounter another king in war, will not sit down first and deliberate whether he
          is able with ten thousand to meet him who comes against him with twenty thousand?
          <br />
          32 And if not, while the other is yet a great way off, he sends a delegation and asks for terms of peace.
        </p>
        <small>Luke 14:28-32 ESV</small>
      </blockquote>
    </div>
    <div className="py-20">
      <h2 className="text-3xl text-brand-blue">Organisation Results</h2>
      <p>An Overview...</p>
      <div className="max-w-3xl mx-auto">
        <Radar archetypes={radarApi} />
      </div>
      <div className="flex justify-between">
        <div className="mt-10 w-5/12">
          <p className="text-brand-blue">
            <strong>Sustaining Factors</strong>
          </p>

          {radarApi[1].dimensions.map((i) => {
            return (
              <Bar
                hasAnimation={false}
                hoverText={null}
                key={i.name}
                headline={i.name}
                scorePercentage={i.score}
                color={radarApi[1].color}
                className="mb-6"
              />
            )
          })}
        </div>

        <div className="mt-10 w-5/12">
          <p>
            <strong>Supporting Factors</strong>
          </p>

          {radarApi[0].dimensions.map((i) => {
            return (
              <Bar
                hasAnimation={false}
                hoverText={null}
                key={i.name}
                headline={i.name}
                scorePercentage={i.score}
                color={radarApi[0].color}
                className="mb-6"
              />
            )
          })}
        </div>
      </div>

      <p className="font-medium">For 50 Questions</p>
      <p className="">
        Highest Score: 250
        <br />
        Lowest Score: 50
      </p>
      <table>
        <thead>
          <tr>
            <th>Description</th>
            <th>Percentage</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Excellent</td>
            <td>90-100%</td>
          </tr>
          <tr>
            <td>Good</td>
            <td>75-89%</td>
          </tr>
          <tr>
            <td>Satisfactory</td>
            <td>60-74%</td>
          </tr>
          <tr>
            <td>Marginal</td>
            <td>51-59%</td>
          </tr>
          <tr>
            <td>Not Sufficient</td>
            <td>35-50%</td>
          </tr>
        </tbody>
      </table>

      <div className="">
        <table>
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th colSpan={5}>
                <p className="text-lg text-center">Participants</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td>
                <p className="font-medium">Factors</p>
              </td>
              <td>
                <p className="font-medium">A</p>
              </td>
              <td>
                <p className="font-medium">B</p>
              </td>
              <td>
                <p className="font-medium">C</p>
              </td>
              <td>
                <p className="font-medium">D</p>
              </td>
              <td>
                <p className="font-medium">E</p>
              </td>
            </tr>
            <tr>
              <td rowSpan={8} className="text-vertical-td text-center">
                <p className="text-vertical-table text-brand-blue">Sustaining Factors</p>
              </td>
              <td>Competent Leadership</td>
              <td>2</td>
              <td>3</td>
              <td>1</td>
              <td>4</td>
              <td>5</td>
            </tr>
            <tr>
              <td></td>
              <td colSpan={5}>
                <Bar
                  hoverText={null}
                  size={BarSize.SMALL}
                  color={Color.BLUE}
                  scorePercentage={60}
                  headline="Average Score"
                />
              </td>
            </tr>
            <tr>
              <td>Effective Governance</td>
              <td>3</td>
              <td>3</td>
              <td>2</td>
              <td>4</td>
              <td>3</td>
            </tr>
            <tr>
              <td></td>
              <td colSpan={5}>
                <Bar
                  hoverText={null}
                  size={BarSize.SMALL}
                  color={Color.BLUE}
                  scorePercentage={60}
                  headline="Average Score"
                />
              </td>
            </tr>
            <tr>
              <td>Clear Mission</td>
              <td>2</td>
              <td>4</td>
              <td>2</td>
              <td>2</td>
              <td>2</td>
            </tr>
            <tr>
              <td></td>
              <td colSpan={5}>
                <Bar
                  hoverText={null}
                  size={BarSize.SMALL}
                  color={Color.BLUE}
                  scorePercentage={48}
                  headline="Average Score"
                />
              </td>
            </tr>
            <tr>
              <td>Healthy Culture</td>
              <td>2</td>
              <td>2</td>
              <td>3</td>
              <td>2</td>
              <td>3</td>
            </tr>
            <tr>
              <td></td>
              <td colSpan={5}>
                <Bar
                  hoverText={null}
                  size={BarSize.SMALL}
                  color={Color.BLUE}
                  scorePercentage={48}
                  headline="Average Score"
                />
              </td>
            </tr>

            <tr>
              <td rowSpan={8} className="text-vertical-td text-center">
                <p className="text-vertical-table text-brand-purple">Supporting Factors</p>
              </td>
              <td>Impactful Strategy</td>
              <td>3</td>
              <td>2</td>
              <td>2</td>
              <td>1</td>
              <td>3</td>
            </tr>
            <tr>
              <td></td>
              <td colSpan={5}>
                <Bar
                  hoverText={null}
                  size={BarSize.SMALL}
                  color={Color.PURPLE}
                  scorePercentage={44}
                  headline="Average Score"
                />
              </td>
            </tr>
            <tr>
              <td>Sound Resourcing Plan</td>
              <td>3</td>
              <td>1</td>
              <td>2</td>
              <td>5</td>
              <td>2</td>
            </tr>
            <tr>
              <td></td>
              <td colSpan={5}>
                <Bar
                  hoverText={null}
                  size={BarSize.SMALL}
                  color={Color.PURPLE}
                  scorePercentage={52}
                  headline="Average Score"
                />
              </td>
            </tr>
            <tr>
              <td>Relevant Measures</td>
              <td>5</td>
              <td>4</td>
              <td>2</td>
              <td>3</td>
              <td>2</td>
            </tr>
            <tr>
              <td></td>
              <td colSpan={5}>
                <Bar
                  hoverText={null}
                  size={BarSize.SMALL}
                  color={Color.PURPLE}
                  scorePercentage={64}
                  headline="Average Score"
                />
              </td>
            </tr>
            <tr>
              <td>Reliable Operations</td>
              <td>1</td>
              <td>2</td>
              <td>3</td>
              <td>1</td>
              <td>2</td>
            </tr>
            <tr>
              <td></td>
              <td colSpan={5}>
                <Bar
                  hoverText={null}
                  size={BarSize.SMALL}
                  color={Color.PURPLE}
                  scorePercentage={36}
                  headline="Average Score"
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <p className="font-medium">Participant Average Score</p>
              </td>
              <td>
                <p className="font-medium">3.4</p>
              </td>
              <td>
                <p className="font-medium">3.8</p>
              </td>
              <td>
                <p className="font-medium">2.4</p>
              </td>
              <td>
                <p className="font-medium">2.1</p>
              </td>
              <td>
                <p className="font-medium">2.8</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div className="py-20">
      <h2 className="text-3xl text-brand-blue">Sustaining Factors Results</h2>
      <p>
        More extensive descriptions for each factor can be found at{' '}
        <a href="www.missioncapacityinventory.com">www.missioncapacityinventory.com</a>
      </p>
      <h3 className="text-2xl text-brand-blue">1. Competent Leadership</h3>
      <table>
        <thead>
          <tr>
            <th>Descriptor Statements</th>
            <th colSpan={5} className="text-center">
              Participant Number
            </th>
            <th className="text-center max-col-width">Organisational Average</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td className="font-medium max-col-width">A</td>
            <td className="font-medium max-col-width">B</td>
            <td className="font-medium max-col-width">C</td>
            <td className="font-medium max-col-width">D</td>
            <td className="font-medium max-col-width">E</td>
            <td></td>
          </tr>
          <tr>
            <td>Our ministry leader has a spiritual and emotional maturity that inspires the confidence of others</td>
            <td className="text-center">1</td>
            <td className="text-center">3</td>
            <td className="text-center">4</td>
            <td className="text-center">2</td>
            <td className="text-center">4</td>
            <td className="font-medium text-center">2.8</td>
          </tr>
          <tr>
            <td>Our ministry leader has the leadership skills that give confidence to followers</td>
            <td className="text-center">5</td>
            <td className="text-center">3</td>
            <td className="text-center">2</td>
            <td className="text-center">5</td>
            <td className="text-center">3</td>
            <td className="font-medium text-center">3.6</td>
          </tr>
          <tr>
            <td>
              Our Chief Executive has built a talented and committed leadership team that, with God’s grace, has the
              ability to lead the implementation of our ministry strategy
            </td>
            <td className="text-center">1</td>
            <td className="text-center">3</td>
            <td className="text-center">2</td>
            <td className="text-center">4</td>
            <td className="text-center">4</td>
            <td className="font-medium text-center">2.8</td>
          </tr>
          <tr>
            <td>
              Our leaders diligently build the ministry capabilities we need to achieve our goals (For example,
              leadership development, strengthening systems and processes, etc.)
            </td>
            <td className="text-center">4</td>
            <td className="text-center">3</td>
            <td className="text-center">2</td>
            <td className="text-center">5</td>
            <td className="text-center">1</td>
            <td className="font-medium text-center">3.0</td>
          </tr>
          <tr>
            <td>Our ministry leader is a servant leader</td>
            <td className="text-center">4</td>
            <td className="text-center">2</td>
            <td className="text-center">2</td>
            <td className="text-center">3</td>
            <td className="text-center">5</td>
            <td className="font-medium text-center">3.2</td>
          </tr>
          <tr>
            <td>Leaders within our ministry are held responsible to lead as servant leaders</td>
            <td className="text-center">5</td>
            <td className="text-center">3</td>
            <td className="text-center">4</td>
            <td className="text-center">4</td>
            <td className="text-center">1</td>
            <td className="font-medium text-center">3.4</td>
          </tr>
          <tr>
            <td>Our ministry has enough skilled leaders to achieve our long-term goals</td>
            <td className="text-center">4</td>
            <td className="text-center">5</td>
            <td className="text-center">3</td>
            <td className="text-center">3</td>
            <td className="text-center">1</td>
            <td className="font-medium text-center">3.2</td>
          </tr>
          <tr>
            <td className="font-medium">Individual Average</td>
            <td className="font-medium text-center">3.4</td>
            <td className="font-medium text-center">3.1</td>
            <td className="font-medium text-center">2.7</td>
            <td className="font-medium text-center">3.7</td>
            <td className="font-medium text-center">2.7</td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <h4 className="text-xl">Factor Overview</h4>
      <p>
        <strong>What it is</strong>
        <br />
        In the MCI, “Competent Leadership” focuses on the attitudes, competencies, and spiritual character of the chief
        executive, but also addresses the broader area of ministry leadership (i.e., presence of a leadership team,
        leaders at every level accountable to lead as servant leaders, etc.).
      </p>
      <p>
        <strong>Why it matters</strong>
        <br />
        Most organisational effectiveness experts agree that the single most important variable for organisational
        impact and success is competent leadership.
      </p>
      <p>
        <strong>Where it is in Scripture</strong>
        <br />
        Jesus speaks directly to this topic only a few times, but those instances contain His view of the
        non-negotiables of leadership, and they set the tone for the rest of the Bible’s emphasis on the subject. One of
        the most striking examples was during the last supper (Luke 22:24-30) at which He declares: “…the greatest among
        you become as the youngest, and the leader as one who serves.”
      </p>
      <h4 className="text-xl">Discussion Questions</h4>
      <ol>
        <li>
          Were you pleasantly or painfully surprised at the overall scores of each of these descriptors? Specifically,
          which ones?
        </li>
        <li>
          Is there a consensus view regarding how each descriptor should be/was assessed? Do the scores tend to be close
          or is there a wide difference of opinion for some of these statements (i.e., one person gives a statement a
          two; another participant gives it a four or five)?
        </li>
        <li>
          Which single descriptor causes the group the most concern? Why? What are the implications/ consequences of
          this descriptor being lower than you would hope?
        </li>
        <li>What are some ideas and specific steps that could be taken to strengthen this Capacity Factor?</li>
      </ol>
    </div>
    <div className="py-10">
      <h3 className="text-2xl text-brand-blue">2. Effective Governance</h3>
      <table>
        <thead>
          <tr>
            <th>Descriptor Statements</th>
            <th colSpan={5} className="text-center">
              Participant Number
            </th>
            <th className="max-col-width">Organisational Average</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td className="font-medium max-col-width">A</td>
            <td className="font-medium max-col-width">B</td>
            <td className="font-medium max-col-width">C</td>
            <td className="font-medium max-col-width">D</td>
            <td className="font-medium max-col-width">E</td>
            <td></td>
          </tr>
          <tr>
            <td>Our board approaches governance as an act of stewardship, working heartily as unto the Lord</td>
            <td className="text-center">1</td>
            <td className="text-center">3</td>
            <td className="text-center">4</td>
            <td className="text-center">2</td>
            <td className="text-center">4</td>
            <td className="font-medium text-center">2.8</td>
          </tr>
          <tr>
            <td>
              Board members demonstrate significant commitment to and involvement in the ministry - attending ministry
              gatherings, visiting program sites, and staying informed on issues important to our work
            </td>
            <td className="text-center">5</td>
            <td className="text-center">3</td>
            <td className="text-center">2</td>
            <td className="text-center">5</td>
            <td className="text-center">3</td>
            <td className="font-medium text-center">3.6</td>
          </tr>
          <tr>
            <td>
              Our board understands its responsibilities as a board of directors (for example, ensuring clear mission
              and strategy, selecting and overseeing the Chief Executive, monitoring strategy and organisational
              performance, etc.).
            </td>
            <td className="text-center">1</td>
            <td className="text-center">3</td>
            <td className="text-center">2</td>
            <td className="text-center">4</td>
            <td className="text-center">4</td>
            <td className="font-medium text-center">2.8</td>
          </tr>
          <tr>
            <td>A succession strategy is in place for the Chief Executive</td>
            <td className="text-center">4</td>
            <td className="text-center">3</td>
            <td className="text-center">2</td>
            <td className="text-center">5</td>
            <td className="text-center">1</td>
            <td className="font-medium text-center">3.0</td>
          </tr>
          <tr>
            <td>The overall governance effectiveness of our board of directors appears to be strong</td>
            <td className="text-center">4</td>
            <td className="text-center">2</td>
            <td className="text-center">2</td>
            <td className="text-center">3</td>
            <td className="text-center">5</td>
            <td className="font-medium text-center">3.2</td>
          </tr>
          <tr>
            <td>
              The board effectively holds the Chief Executive accountable for organisational performance in achieving
              goals and progressing toward our mission
            </td>
            <td className="text-center">5</td>
            <td className="text-center">3</td>
            <td className="text-center">4</td>
            <td className="text-center">4</td>
            <td className="text-center">1</td>
            <td className="font-medium text-center">3.4</td>
          </tr>
          <tr>
            <td>
              The board holds the Chief Executive accountable for his/her performance, personal character, and spiritual
              leadership
            </td>
            <td className="text-center">4</td>
            <td className="text-center">5</td>
            <td className="text-center">3</td>
            <td className="text-center">3</td>
            <td className="text-center">1</td>
            <td className="font-medium text-center">3.2</td>
          </tr>
          <tr>
            <td className="font-medium">Individual Average</td>
            <td className="font-medium text-center">3.4</td>
            <td className="font-medium text-center">3.1</td>
            <td className="font-medium text-center">2.7</td>
            <td className="font-medium text-center">3.7</td>
            <td className="font-medium text-center">2.7</td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <h4 className="text-xl">Factor Overview</h4>
      <p>
        <strong>What it is</strong>
        <br />
        Effective ministry governance starts with the fact that there is an established governance board in place, and
        that it is staffed with capable and competent members who are totally aligned with organisation’s intended
        mission. The board understands its role and demonstrates a mindset of stewardship and engagement. It
        demonstrates the willingness and ability to hold the Chief Executive accountable for organisational performance,
        personal character, and spiritual leadership, and has established a thoughtful and comprehensive succession
        strategy for both planned and unplanned leadership transitions.
      </p>
      <p>
        <strong>Why it matters</strong>
        <br />
        It is important that ministries see governance as an act of biblical stewardship rather than merely a legal
        obligation. Stewardship requires accountability. Key stakeholders cannot—and don’t want to—monitor every major
        decision a ministry makes. Thus, they rely on a group of committed directors who can do so with discernment and
        wisdom.
      </p>
      <p>
        <strong>Where it is in Scripture</strong>
        <br />
        Jerusalem, 48 AD. In Acts 15, we find Paul and Barnabas in Antioch, having just completed their first missionary
        journey. While there, some men arrived from Judea and “began teaching the young converts that unless they were
        circumcised, according to the custom of Moses, [they] could not be saved.” Paul and Barnabas did not stand idly,
        by ignoring the challenge. The text explains that there was “great dissention and debate” with these
        interlopers. Paul and Barnabas were sent by the Antioch church to Jerusalem to see the “apostles and elders”
        about this issue. It is clear that, although not called a board, these men to whom they were sent were
        considered the ultimate authority in this young organisation called the Church. In Galatians 2, Paul described
        them as “men of reputation” and “pillars.” This sounds very much like a board to us.
      </p>
      <h4 className="text-xl">Discussion Questions</h4>
      <ol>
        <li>
          Were you pleasantly or painfully surprised at the overall scores of each of these descriptors? Specifically,
          which ones?
        </li>
        <li>
          Is there a consensus view regarding how each descriptor should be/was assessed? Do the scores tend to be close
          or is there a wide difference of opinion for some of these statements (i.e., one person gives a statement a
          two; another participant gives it a four or five)?
        </li>
        <li>
          Which single descriptor causes the group the most concern? Why? What are the implications/ consequences of
          this descriptor being lower than you would hope?
        </li>
        <li>What are some ideas and specific steps that could be taken to strengthen this Capacity Factor?</li>
      </ol>
    </div>
    <div className="py-20">
      <h2 className="text-3xl text-brand-blue">In Summary: a final note…</h2>
      <p>
        First, the selection of these specific factors is based on our organisation’s experience and observations in
        ministries around the world over the course of several decades. They are the result of a lot of conversations
        regarding what we have observed, both good and bad, and their outcomes. That said, another group, with their own
        experiential lens, could easily come up with a someone different, but mostly similar, list. We are not claiming
        these are the “correct” eight dimensions to evaluate; we are simply stating that these are the ones that had the
        most impact from our perspective.
      </p>
      <p>
        Second, the importance of one factor versus another will certainly be affected by the specific circumstances of
        each ministry. Size, age, societal culture, mission, strategy, and the specific challenges confronting the
        entity are but a few of the variables that will prompt a conversation as to what Capacity Factors should be
        prioritized.
      </p>
      <p>
        Finally, and most important, as valuable as these factors are, they are not the cornerstone of ministry success.
        That Cornerstone is another Rock entirely.
      </p>
    </div>
    <div className="py-20 min-h-screen">
      <h2 className="text-3xl text-brand-blue">Discussion Questions</h2>
    </div>
    <div className="py-20 min-h-screen">
      <h2 className="text-3xl text-brand-blue">Action Plan</h2>
    </div>
    <div className="py-20 min-h-screen">
      <h2 className="text-3xl text-brand-blue">Monitor &amp; Measure</h2>
    </div>
  </div>
)

export default ReportPreview
