import { useNavigate } from '@reach/router'
import HeadingSection from '../../components/HeadingSection'
import Alert, { AlertVariant, AlertSize } from '../../components/Alert'
import OrganisationTable from './OrganisationTable'
import { Helmet } from 'react-helmet'
import { language } from '../../common/i18n'
import { Color } from '../../color.enum'
import Button from '../../components/Button'
import { ButtonVariant, ButtonSize } from '../../components/Button'
import HeadingPage from '../../components/HeadingPage'

interface OrganisationProps {
  loading: boolean
  data: any
  refetch?: any
}

const Organisations: React.FC<OrganisationProps> = ({ loading, data, refetch }) => {
  const navigate = useNavigate()

  return (
    <div className="page-container-lg">
      <header>
        <Helmet>
          <title>{language.en.admin.peer.organisations.title}</title>
          <meta name="description" content={language.en.admin.peer.organisations.description} />
          <meta name="robots" content="noindex" data-react-helmet="true" />
        </Helmet>
        <HeadingPage
          headline={language.en.admin.peer.organisations.title}
          className=""
          color={Color.BLUE}
          description={language.en.admin.peer.organisations.description}
        />
        <HeadingSection
          headline={language.en.admin.peer.organisations.headline}
          color={Color.BLUE}
          children={() => {
            return (
              <div className="flex">
                <Button
                  variant={ButtonVariant.PRIMARY}
                  color={Color.BLUE}
                  size={ButtonSize.SMALL}
                  onClick={() => navigate('/dashboard/peer/add-organisation')}
                  iconRight="plus"
                >
                  Add Organisation
                </Button>
              </div>
            )
          }}
        />
      </header>
      {data.organizations.docs.length > 0 ? (
        <OrganisationTable
          items={data?.organizations?.docs}
          loading={loading}
          refetch={refetch}
          pagination={{
            currentPage: data?.organizations.page,
            totalPages: data?.organizations.totalPages,
            hasNextPage: data?.organizations.hasNextPage,
            hasPrevPage: data?.organizations.hasPrevPage
          }}
        />
      ) : (
        <div className="w-full">
          <Alert size={AlertSize.LARGE} variant={AlertVariant.WARNING}>
            No organisations exists. Click Add Organisation to create your first organisation.
          </Alert>
        </div>
      )}
    </div>
  )
}

export default Organisations
