import { useQuery, gql } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import Loader from '../components/Loader/Loader'

const HEALTH = gql`
  query HealthCheck {
    healthCheck
  }
`

const HealthCheck: React.FC<RouteComponentProps> = () => {
  const { loading, error, data } = useQuery(HEALTH)

  if (loading) return <Loader />
  if (error) return <div>Error! {error.message}</div>
  return <div className="app-container">{JSON.stringify(data)}</div>
}

export default HealthCheck
