import React from 'react'
import { classNames } from '../../utils/class-names'

export interface FooterNavItemProps {
  /** Label of the menu item */
  name: string
  /** External link destination */
  slug: string
  /** Is this the current active item? */
  isCurrent?: boolean
  /** Aditional Styles for the container */
  className?: string
  /** Set an index to style the first child */
  index?: number
}

/**
 *  Component to show the Footer Navigation Items
 */
const FooterNavItem: React.FC<FooterNavItemProps> = ({
  className,
  name,
  isCurrent,
  slug,
  index
}: FooterNavItemProps) => {
  return (
    <div className={classNames(className, `FooterNavItem nav-item-${index}`)}>
      <a target="_blank" rel="noopener noreferrer" href={slug} title={name}>
        <div
          key={name}
          className={classNames(
            'text-brand-orange py-1 xl:py-2 px-2 text-xs md:text-base font-normal uppercase text-left'
          )}
        >
          {name}
        </div>
      </a>
    </div>
  )
}

export default FooterNavItem
