import { Link, RouteComponentProps, useNavigate } from '@reach/router'
import { useQuery, gql } from '@apollo/client'
import { Color } from '../../color.enum'
import { User } from '../../common/types'
import Button, { ButtonVariant, ButtonSize } from '../../components/Button'
import AssignmentList from './assignments/OrgAssignments'
import Loader from '../../components/Loader/Loader'
import Alert, { AlertVariant, AlertSize } from '../../components/Alert'
import { language } from '../../common/i18n'
import { PageWrapper } from '../../modules/PageWrapper'
import Card, { CardVariant } from '../../components/Card'

interface OwnerProps extends RouteComponentProps {
  user?: User
  test?: string
}

export const OwnerDashboard: React.FC<OwnerProps> = (props) => {
  return <Assignments {...props} />
}

export const Assignments: React.FC<OwnerProps> = (props) => {
  const navigate = useNavigate()

  const { loading, data: orgAssignments } = useQuery(GET_ORG_ASSIGNMENTS, {
    fetchPolicy: 'no-cache',
    variables: { orgId: props?.user?.organization?.id }
  })

  if (loading) return <Loader />

  if (!orgAssignments?.organizationAssignments.length) {
    return (
      <div className="page-container-lg">
        <Card
          headline="Healthy leaders leading healthy organisations will have the greatest, sustainable impact."
          subtitle={props.user?.organization.name}
          variant={CardVariant.PRIMARY}
          media={<img src="https://c.stocksy.com/a/mVe200/z9/632386.jpg" alt="" />}
        >
          <div className="text-brand-blue">
            <p className="pb-2">
              A tool designed to assess and enhance the health of your non-profit organisation. The 9D Capacity Index
              will give your management team a balanced view across 9 important dimensions of organisational health and
              provide insight into your performance in 3 foundational organisational practices.
            </p>
            <p className="pb-2 mb-4">
              It clearly reveals strengths and challenges, and simultaneously equips you with knowledge to lead your
              organisation towards its dynamic future.
            </p>
          </div>

          <Link to="/dashboard/organisation/create-scan">
            <Button
              size={ButtonSize.LARGE}
              variant={ButtonVariant.PRIMARY}
              color={Color.BLUE}
              iconRight="arrowRight"
              className="text-left"
            >
              Create First Assessment
            </Button>
          </Link>
        </Card>
      </div>
    )
  }

  return (
    <PageWrapper
      metaDesc={language.en.orgOwner.dashboard.title}
      metaTitle={language.en.orgOwner.dashboard.description}
      headline={`${props.user?.organization.name} Organisational Assessments`}
      description={
        'Your dashboard presents a comprehensive list of your 9D Capacity Index assessments. Access detailed results, monitor ongoing assessments, and complete pending tasks to maintain a clear picture of your organisation.'
      }
      sectionHeading={''}
      renderCta={() => {
        return (
          <div className="flex items-center">
            <Button
              variant={ButtonVariant.PRIMARY}
              color={Color.BLUE}
              size={ButtonSize.SMALL}
              onClick={() => navigate('/dashboard/organisation/create-scan')}
              iconRight="plus"
            >
              Create New Assessment
            </Button>
          </div>
        )
      }}
    >
      <div className="mt-8 mb-12">
        {orgAssignments?.organizationAssignments.length > 0 ? (
          <AssignmentList
            assignments={orgAssignments?.organizationAssignments}
            loading={loading}
            organizationId={props?.user?.organization?.id}
          />
        ) : (
          <div className="w-full">
            <Alert size={AlertSize.LARGE} variant={AlertVariant.WARNING} className="w-full">
              You do not have any assessments assigned to you.
            </Alert>
          </div>
        )}
      </div>
    </PageWrapper>
  )
}

const GET_ORG_ASSIGNMENTS = gql`
  query _organizationAssignments($orgId: String!) {
    organizationAssignments(orgId: $orgId) {
      id
      dueDate
      status
      name
      respondentCount
      questionnaireStatus
      pastDueDate
      organization {
        name
        peer {
          id
        }
      }
      preSurveyStatus
      respondentProfile {
        id
        surveyStatus
      }
    }
  }
`
