import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

interface InputProps {
  setValue: (name: string, data: any) => void
  placeholder?: any
  name: string
  defaultValue?: string
}

const toolBar = {
  options: ['inline', 'list', 'link'],
  inline: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['bold', 'italic']
  },
  list: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['unordered', 'ordered']
  }
}

const RichTextInput: React.FC<InputProps> = ({ placeholder, name, setValue, defaultValue }) => {
  return (
    <Editor
      toolbarClassName="toolbarClassName border-b-[#BBBBBB] rounded-none"
      wrapperClassName="wrapperClassName border border-[#BBBBBB]"
      editorClassName="editorClassName text-[#333] px-3"
      toolbar={toolBar}
      placeholder={placeholder}
      onContentStateChange={(e) => setValue(name, JSON.stringify(e))}
      defaultContentState={defaultValue ? JSON.parse(defaultValue) : undefined}
    />
  )
}

export default RichTextInput
