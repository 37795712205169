import { RouteComponentProps, useNavigate, useParams } from '@reach/router'
import { Color } from '../../../../../color.enum'
import Button from '../../../../../components/Button'
import { ButtonVariant, ButtonSize } from '../../../../../components/Button'
import { gql, useQuery } from '@apollo/client'
import Loader from '../../../../../components/Loader/Loader'
import queryString from 'query-string'
import DemographicQuestionOptionsList from './demographicQuestionsOptionsList'
import { HeadingPage } from '../../../../../components'
import { useBreadcrumbs } from '../../../../../hooks/useBreadcrumbs'
import BreadCrumbs from '../../../../../components/BreadCrumbs/BreadCrumbs'
import { Helmet } from 'react-helmet'
interface SinglePreSurveyQuestionInterface extends RouteComponentProps<any> {
  history: any
}

interface DetailsInterface {
  code: string
}

const Details: React.FC<DetailsInterface> = ({ code }) => {
  return (
    <div className="mt-5 space-y-1">
      {code && (
        <div>
          <span className="inline text-brand-blue font-medium">Code:</span>
          <span className="ml-1">{code}</span>
        </div>
      )}
    </div>
  )
}

const SinglePreSurveyQuestion: React.FC<SinglePreSurveyQuestionInterface> = () => {
  const navigate = useNavigate()
  const { questionId, surveyId, preSurveyId } = useParams()
  const [getBreadCrumbs] = useBreadcrumbs()

  const { loading, data, refetch } = useQuery(GET_SINGLE_PRE_SURVEY_QUESTION, {
    fetchPolicy: 'no-cache',
    variables: { id: questionId }
  })
  const editPresurveyQuestionUrl = queryString.stringifyUrl({
    url: `/dashboard/admin/survey/${surveyId}/preSurvey/${preSurveyId}/question/${questionId}/edit`
  })

  if (loading) return <Loader />
  const { preSurveyQuestion } = data

  const bc = getBreadCrumbs('demographicQuestion', surveyId, '', 'Organisation Questionaires', preSurveyId)
  return (
    <div className="page-container-lg">
      <Helmet>
        <title>{preSurveyQuestion?.text}</title>
        <meta name="description" content={preSurveyQuestion?.text} />
        <meta name="robots" content="noindex" data-react-helmet="true" />
      </Helmet>
      <main>
        <BreadCrumbs pages={bc} className="py-0 pb-10" />
        <HeadingPage
          headline={preSurveyQuestion?.text}
          color={Color.BLUE}
          description={<Details code={preSurveyQuestion?.code} />}
          children={() => {
            return (
              <div className="flex">
                <Button
                  variant={ButtonVariant.PRIMARY}
                  color={Color.BLUE}
                  size={ButtonSize.SMALL}
                  onClick={() => {
                    navigate(editPresurveyQuestionUrl)
                  }}
                >
                  Edit Question
                </Button>
              </div>
            )
          }}
        />

        <DemographicQuestionOptionsList
          options={preSurveyQuestion?.preSurveyOption}
          surveyId={surveyId}
          type="preSurvey"
          questionId={questionId}
          demoId={preSurveyId}
          surveyStatus={preSurveyQuestion?.preSurvey?.survey?.status}
          refetch={refetch}
        />
        <Button
          variant={ButtonVariant.PRIMARY}
          color={Color.BLUE}
          size={ButtonSize.MEDIUM}
          iconRight="plus"
          onClick={() => {
            navigate(
              `/dashboard/admin/survey/${surveyId}/preSurvey/${preSurveyId}/question/${questionId}/option/create`
            )
          }}
        >
          Add option
        </Button>
      </main>
    </div>
  )
}

export default SinglePreSurveyQuestion

const GET_SINGLE_PRE_SURVEY_QUESTION = gql`
  query _preSurveyQuestion($id: String!) {
    preSurveyQuestion(id: $id) {
      text
      code
      position
      description
      id
      preSurvey {
        survey {
          status
        }
      }
      preSurveyOption {
        id
        text
        code
      }
    }
  }
`
