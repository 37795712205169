import { InputValidator } from '../services/validate'
import { FieldTypes } from '../common/types'

export const useFormObj: any = (form: any) => {
  const formMap: any = {
    factorType,
    factor,
    statement,
    question,
    survey,
    createScan
  }
  return formMap[form]
}

const factorType: FieldTypes[] = [
  {
    label: 'Archetype Name',
    name: 'name',
    type: 'text',
    placeholder: 'Archetype Name',
    options: {
      required: 'Archetype Name is required'
    }
  },
  {
    label: 'Archetype Code',
    name: 'code',
    placeholder: 'Archetype Code',
    options: {
      required: 'Archetype Code is required'
    },
    type: 'text'
  },
  {
    label: 'Index Order',
    name: 'indexOrder',
    placeholder: 'Index Order',
    options: {
      required: 'Index order is required'
    },
    type: 'number'
  },
  {
    label: 'Archetype Color',
    name: 'color',
    placeholder: 'Color',
    options: {
      required: 'Color is required'
    },
    items: [
      { id: 'red', name: 'Red' },
      { id: 'orange', name: 'Orange' },
      { id: 'purple', name: 'Purple' }
    ],
    type: 'select'
  },
  {
    label: 'Short Description',
    name: 'description',
    placeholder: 'Enter factor description here....',
    type: 'textarea',
    options: {
      required: 'Description is required'
    }
  },
  {
    label: 'Report Description',
    name: 'reportDescription',
    placeholder: 'Description that will be displayed in the report here....',
    type: 'richText',
    options: {
      required: 'Report description is required'
    }
  }
]

const factor: FieldTypes[] = [
  {
    label: 'Dimension Name',
    name: 'name',
    type: 'text',
    placeholder: 'Dimension Name',
    options: {
      required: 'Dimension Name is required'
    }
  },
  {
    label: 'Dimension Code',
    name: 'code',
    placeholder: 'Dimension Code',
    type: 'text',
    options: {
      required: 'Dimension Code required'
    }
  },
  {
    label: 'Headline',
    name: 'headline',
    placeholder: 'Headline',
    options: {
      required: 'Headline is required'
    },
    type: 'text'
  },
  {
    label: 'Position',
    name: 'position',
    placeholder: 'Position',
    options: {
      required: 'Position is required'
    },
    type: 'number',
    show: 'create'
  },
  {
    label: 'Description',
    name: 'description',
    placeholder: 'Description',
    type: 'textarea',
    options: {
      required: 'Description is required'
    }
  },
  {
    label: 'Archetype',
    name: 'factorType',
    placeholder: 'Archetype',
    type: 'select',
    options: {
      required: 'Archetype is required'
    }
  },
  {
    label: 'Core factor (If checked the factor will always be in survey)',
    name: 'coreFactor',
    placeholder: 'Add discussion questions here....',
    type: 'checkBox',
    checked: true,
    hidden: true
  }
]

const statement: FieldTypes[] = [
  {
    label: 'Statement Code',
    name: 'code',
    placeholder: 'Statement Code',
    options: {
      required: 'Statement Code is required'
    },

    type: 'text'
  },
  {
    label: 'Statement Label',
    name: 'label',
    placeholder: 'Label',
    options: {
      required: 'Headline is required'
    },
    type: 'text'
  },

  {
    label: 'Position',
    name: 'position',
    placeholder: 'Label',
    options: {
      required: 'Headline is required'
    },
    type: 'number'
  },
  {
    label: 'Statement Text',
    name: 'statement',
    placeholder: 'Statement',
    options: {
      required: 'Statement is required'
    },
    type: 'textarea'
  }
]

const question: FieldTypes[] = [
  {
    label: 'Question Code',
    name: 'code',
    placeholder: 'Question Code',
    options: {
      required: 'Question code is required'
    },
    type: 'text'
  },

  {
    label: 'Position',
    name: 'position',
    placeholder: 'Label',
    options: {
      required: 'Position is required'
    },
    type: 'number'
  },
  {
    label: 'Question',
    name: 'text',
    placeholder: 'Question',
    options: {
      required: 'Question is required'
    },

    type: 'textarea'
  },
  {
    label: 'Description',
    name: 'description',
    placeholder: 'Enter factor description here....',
    type: 'textarea',
    options: {
      required: 'Description is required'
    }
  },
  {
    label: 'Response type',
    name: 'responseType',
    placeholder: 'Response type',
    type: 'select',
    items: [
      { id: 'options', name: 'Selection of options' },
      { id: 'custom', name: 'Custom response' },
      { id: 'country', name: 'Country selection' },
      { id: 'year', name: 'Year selection' }
    ],
    defaultValue: 'options',
    options: {
      required: 'Response type is required'
    }
  }
]

const survey: FieldTypes[] = [
  {
    label: 'Assesment Name',
    name: 'name',
    type: 'text',
    placeholder: 'Assesment Type Name',
    options: {
      required: 'Assesment Name is required'
    }
  },
  {
    label: 'Locale',
    name: 'locale',
    placeholder: 'Locale',
    options: {
      required: 'Locale is required'
    },
    type: 'select'
  }
]

const createScan: FieldTypes[] = [
  {
    label: 'Assessment Name',
    name: 'name',
    type: 'text',
    placeholder: 'Assessment Name',
    description:
      'The name will be used to identify the report. We advise naming the assessment after the organisation or group who will be taking this assessment.',
    options: {
      required: 'Assessment name is required'
    },
    className: 'col-span-1 sm:col-span-1'
  },
  {
    label: 'Assessment Close Date',
    name: 'dueDate',
    placeholder: 'Assessment Close Date',
    type: 'datePicker',
    description:
      'This assessment will close at midnight UTC on the  Assessment Close Date. We recommend giving the participants a minimum of 14 days to complete the assessment.',
    options: {
      required: 'Assessment Close Date is required'
    },
    className: 'col-span-1 sm:col-span-1'
  }
]
