import { useState, useEffect, useRef } from 'react'
import { Router, useLocation } from '@reach/router'
import { Helmet } from 'react-helmet'
import { gql, useQuery } from '@apollo/client'
import { MainNav } from './components/Nav/MainNav'
import './App.css'
import { Login } from './pages/Login'
import AddUsers from './pages/Users/AddUsers'
import AllOrganisations from './pages/Organisations/AllOrganisations'
import PeerOrganisations from './pages/Organisations/PeerOrganisations'
import { ResultsPreview, ReportPreview, Page } from './pages'
import PreviewBasicResults from './pages/Survey/Results/ScanBasicResults'
import { Context } from './context/context'
import Loader from './components/Loader/Loader'
import { OwnerDashboard } from './pages/Dashboard/Owner'
import Users from './pages/Users'
import Surveys from './pages/Dashboard/survey'
import SingleSurvey from './pages/Dashboard/survey/singleSurvey'
import CreateEditSurvey from './pages/Dashboard/survey/createEditSurvey'
import SingleFactor from './pages/Dashboard/survey/factors/singleFactor'
import CreateFactor from './pages/Dashboard/survey/factors/createFactor'
import SingleUserView from './pages/Users/SingleUserView'
import client from './contentful'
import { RespondentDashboard } from './pages/Survey/RespondentDashboard'
import { SurveyContainer } from './pages/Survey/SurveyContainer'
import SingleDemographicSurveyOrg from './pages/Dashboard/survey/demographicSurveys/singleDemographicOrg'
import SingleDemographicSurveyParticipant from './pages/Dashboard/survey/demographicSurveys/singleDemographicParticipant'
import CreateDemographicSurveyOrg from './pages/Dashboard/survey/demographicSurveys/createDemographicSurveyOrg'
import SingleFactorType from './pages/Dashboard/survey/factorType/singleFactorType'
import CreateFactorType from './pages/Dashboard/survey/factorType/createFactorType'
import CreateStatement from './pages/Dashboard/survey/statements/createStatements'
import SingleSatement from './pages/Dashboard/survey/statements/singleStatement'
import CreatePreSurveyQuestion from './pages/Dashboard/survey/questions/DemographicQuestionOrg/createQuestion'
import SinglePreSurveyQuestion from './pages/Dashboard/survey/questions/DemographicQuestionOrg/singlePreSurveyQuestion'
import SinglePostSurveyQuestion from './pages/Dashboard/survey/questions/DemographicQuestionParticipant/singlePostSurveyQuestion'
import { AllAssignments } from './pages/Dashboard/assignments/Allassignments'
import SinglePreSurveyQuestionOption from './pages/Dashboard/survey/options/singlePreSurveyQuestionOption'
import CreatePreSurveyQuestionOption from './pages/Dashboard/survey/options/createPreSurveyQuestionOptions'
import CreatePostSurveyQuestionOption from './pages/Dashboard/survey/options/createPostSurveyQuestionOptions'
import SinglePostSurveyQuestionOption from './pages/Dashboard/survey/options/singlePostSurveyQuestionOption'
import CreatePostSurveyQuestion from './pages/Dashboard/survey/questions/DemographicQuestionParticipant/createQuestion'
import Footer from './components/Footer/Footer'
import AssignmentResults from './pages/Survey/Results/AssignmentResults'
import FooterNavItem from './components/FooterNavItem/FooterNavItem'
import { language } from './common/i18n'
import { Reports } from './pages/Dashboard/reports/Reports'
import Peers from './pages/Dashboard/peers'
import AddPeers from './pages/Dashboard/peers/AddPeers'
import { PeerAssignments } from './pages/Dashboard/assignments/AllPeersAssignment'
import AddOrganizations from './pages/Organisations/AddOrganizations'
import SinglePeerView from './pages/Dashboard/peers/SinglePeerView'
import { AppParticipant } from './pages/Dashboard/wizard/AddParticipant'
import SingleOrganisationView from './pages/Organisations/SingleOrganisation'
import SingleScanView from './pages/Dashboard/assignments/SingleAssignment'
import EditDueDate from './pages/Dashboard/assignments/EditScanDueDate'
import OrgUsers from './pages/Users/OrgUsers'
import CreateDemographicSurveyParticipant from './pages/Dashboard/survey/demographicSurveys/createDemographicSurveyParticipant'
import { DemographicSurvey } from './pages/Survey/DemographicSurvey/DemographicSurvey'
import { MyAssignments } from './pages/Dashboard/assignments/MyAssignments'
import { ScanConfirmation } from './pages/Dashboard/wizard/ScanConfirmation'
import PeerUsers from './pages/Users/PeerUsers'
import { Dashboard } from './pages/Dashboard/Dashboard'
import { CreateScan } from './pages/Dashboard/wizard/CreateScan'
import { PeerScanContainer } from './pages/Dashboard/scans/peerScans/PeerScans'
import { OrgScansContainer } from './pages/Dashboard/scans/orgScans/OrgScans'
import { MyScansContainer } from './pages/Dashboard/scans/myScans/MyScans'
import { OAuthCallback } from './pages/Login/OAuthCallback'
import { ProtectedRoute } from './components/Auth/ProtectRoute'
import RegisterPage from './pages/Register'

const HARDCODED_FOOTER_ITEMS = {
  menu: {
    name: 'Footer Menu',
    menuItemsCollection: {
      items: [
        { slug: 'https://fiftyfourcollective.com/', name: 'Home' },
        { slug: 'https://fiftyfourcollective.com/about-us/', name: 'About Us' },
        { slug: 'https://fiftyfourcollective.com/resources/', name: 'Resources' },
        { slug: 'https://fiftyfourcollective.com/live-learning/', name: 'Live Learning' },
        { slug: 'https://fiftyfourcollective.com/assessments/', name: 'Assessments' }
      ]
    }
  }
}

const footerItems = HARDCODED_FOOTER_ITEMS.menu.menuItemsCollection.items

interface FooterNavigationProps {
  footerItems: any[]
}
const FooterNavigation: React.FC<FooterNavigationProps> = ({ footerItems }) => (
  <div className="flex flex-wrap pt-4 lg:pt-0 items-center justify-center">
    {footerItems?.map((item, index) => (
      <FooterNavItem index={index} {...item} />
    ))}
  </div>
)

const Layout: any = (props: any) => (
  <div className="flex flex-col min-h-screen">
    <Helmet>
      <title>{language.en.site.title}</title>
      <meta name="description" content={language.en.site.description} />
      <meta name="robots" content="noindex" data-react-helmet="true" />
      <link rel="icon" type="image/png" href="../public/OSP.png" />
    </Helmet>
    <MainNav {...props} />
    <div className="grow">{props.children}</div>

    <Footer renderNavigation={<FooterNavigation footerItems={footerItems} />} />
  </div>
)

function useScrollToTop() {
  const location = useLocation()
  const prevPath = useRef(location.pathname)

  useEffect(() => {
    // different base path
    if (prevPath.current !== location.pathname) {
      window.scrollTo({ top: 0 })
      prevPath.current = location.pathname
    }
  }, [location])
}

const App: React.FC = () => {
  const [contextValue, setContextValue] = useState<any>({})
  const { loading, data } = useQuery(FOOTER_QUERY, {
    variables: { id: '5CaVu8MdF5nqGMSh74VL0M' },
    client: client
  })
  useScrollToTop()

  if (loading) return <Loader />

  return (
    <div className="app-container bg-white">
      <Context.Provider value={{ contextValue, setContextValue }}>
        <Router>
          <Layout path="/" footerItems={data?.menu?.menuItemsCollection?.items}>
            <OAuthCallback path="/auth/wordpress/callback" />
            <ProtectedRoute
              roles={['isSuperAdmin', 'isOrgLeader', 'isPeer', 'isRespondent']}
              component={Dashboard}
              path="/"
            />
            <ProtectedRoute
              roles={['isSuperAdmin', 'isOrgLeader', 'isPeer', 'isRespondent']}
              component={Dashboard}
              path="/dashboard"
            />
            <Login path="/login" />
            <RegisterPage path="/invite" />
            <ResultsPreview path="/results" />
            <ReportPreview path="/report" />
            <ProtectedRoute roles={['isSuperAdmin']} component={Dashboard} path="/dashboard/admin" />
            <ProtectedRoute
              roles={['isSuperAdmin']}
              component={CreateEditSurvey}
              path="/dashboard/admin/create-survey"
            />
            <ProtectedRoute roles={['isSuperAdmin']} component={Surveys} path="/dashboard/admin/surveys" />
            <ProtectedRoute
              roles={['isSuperAdmin']}
              component={SingleSurvey}
              path="/dashboard/admin/survey/:surveyId"
            />
            <ProtectedRoute
              roles={['isSuperAdmin']}
              component={CreateEditSurvey}
              path="/dashboard/admin/survey/:surveyId/edit-survey"
            />
            <ProtectedRoute roles={['isSuperAdmin']} component={AddUsers} path="/dashboard/admin/add-user" />
            <ProtectedRoute roles={['isSuperAdmin']} component={AddUsers} path="/dashboard/admin/:userId/edit-user" />
            <ProtectedRoute roles={['isSuperAdmin']} component={Users} path="/dashboard/admin/users" />
            <ProtectedRoute roles={['isSuperAdmin']} component={Reports} path="/dashboard/admin/reports" />
            <ProtectedRoute
              roles={['isSuperAdmin']}
              component={SingleFactor}
              path="/dashboard/admin/survey/:surveyId/dimension/:factorId"
            />
            <ProtectedRoute
              roles={['isSuperAdmin']}
              component={CreateFactor}
              path="/dashboard/admin/survey/:surveyId/create-dimension"
            />
            <ProtectedRoute
              roles={['isSuperAdmin']}
              component={CreateFactor}
              path="/dashboard/admin/survey/:surveyId/:factorId/edit-factor"
            />
            <ProtectedRoute
              roles={['isSuperAdmin']}
              component={SingleDemographicSurveyOrg}
              path="/dashboard/admin/survey/:surveyId/demographic-survey/:preSurveyId/org"
            />
            <ProtectedRoute
              roles={['isSuperAdmin']}
              component={SingleDemographicSurveyParticipant}
              path="/dashboard/admin/survey/:surveyId/demographic-survey/:postSurveyId/participant"
            />
            <ProtectedRoute
              roles={['isSuperAdmin']}
              component={CreateDemographicSurveyParticipant}
              path="/dashboard/admin/:surveyId/create-postsurvey"
            />
            <ProtectedRoute
              roles={['isSuperAdmin']}
              component={CreateDemographicSurveyParticipant}
              path="/dashboard/admin/survey/:surveyId/demographic-survey/:postSurveyId/participant/edit"
            />
            <ProtectedRoute
              roles={['isSuperAdmin']}
              component={CreateDemographicSurveyOrg}
              path="/dashboard/admin/:surveyId/create-presurvey"
            />
            <ProtectedRoute
              roles={['isSuperAdmin']}
              component={SingleDemographicSurveyOrg}
              path="/dashboard/admin/survey/:surveyId/demographic-survey/:preSurveyId/org"
            />
            <ProtectedRoute
              roles={['isSuperAdmin']}
              component={CreateDemographicSurveyOrg}
              path="/dashboard/admin/survey/:surveyId/demographic-survey/:preSurveyId/org/edit"
            />

            <ProtectedRoute
              roles={['isSuperAdmin']}
              component={SinglePostSurveyQuestion}
              path="/dashboard/admin/survey/:surveyId/postSurvey/:postSurveyId/question/:questionId/"
            />
            <ProtectedRoute
              roles={['isSuperAdmin']}
              component={SinglePreSurveyQuestion}
              path="/dashboard/admin/survey/:surveyId/preSurvey/:preSurveyId/question/:questionId/"
            />

            <ProtectedRoute
              roles={['isSuperAdmin']}
              component={SinglePreSurveyQuestionOption}
              path="/dashboard/admin/survey/:surveyId/preSurvey/:preSurveyId/question/:questionId/option/:optionId"
            />

            <ProtectedRoute
              roles={['isSuperAdmin']}
              component={CreatePreSurveyQuestionOption}
              path="/dashboard/admin/survey/:surveyId/preSurvey/:preSurveyId/question/:questionId/option/:optionId/edit"
            />
            <ProtectedRoute
              roles={['isSuperAdmin']}
              component={SinglePostSurveyQuestionOption}
              path="/dashboard/admin/survey/:surveyId/postSurvey/:postSurveyId/question/:questionId/option/:optionId"
            />

            <ProtectedRoute
              roles={['isSuperAdmin']}
              component={CreatePreSurveyQuestionOption}
              path="/dashboard/admin/survey/:surveyId/preSurvey/:preSurveyId/question/:questionId/option/create"
            />
            <ProtectedRoute
              roles={['isSuperAdmin']}
              component={CreatePostSurveyQuestionOption}
              path="/dashboard/admin/survey/:surveyId/postSurvey/:postSurveyId/question/:questionId/create"
            />
            <ProtectedRoute
              roles={['isSuperAdmin']}
              component={CreatePostSurveyQuestionOption}
              path="/dashboard/admin/survey/:surveyId/postSurvey/:postSurveyId/question/:questionId/option/:optionId/edit"
            />

            <ProtectedRoute
              roles={['isSuperAdmin']}
              component={CreatePostSurveyQuestion}
              path="/dashboard/admin/survey/:surveyId/postSurvey/:postSurveyId/question/create"
            />

            <ProtectedRoute
              roles={['isSuperAdmin']}
              component={CreatePostSurveyQuestion}
              path="/dashboard/admin/survey/:surveyId/postSurvey/:postSurveyId/question/:questionId/edit"
            />

            <ProtectedRoute
              roles={['isSuperAdmin']}
              component={CreatePreSurveyQuestion}
              path="/dashboard/admin/survey/:surveyId/preSurvey/:preSurveyId/question/create"
            />
            <ProtectedRoute
              roles={['isSuperAdmin']}
              component={CreatePreSurveyQuestion}
              path="/dashboard/admin/survey/:surveyId/preSurvey/:preSurveyId/question/:questionId/edit"
            />

            <ProtectedRoute
              roles={['isSuperAdmin']}
              component={CreateStatement}
              path="/dashboard/admin/survey/:surveyId/:factorId/create-statement"
            />
            <ProtectedRoute
              roles={['isSuperAdmin']}
              component={CreateStatement}
              path="/dashboard/admin/statement/:statementId/edit-statement"
            />
            <ProtectedRoute
              roles={['isSuperAdmin']}
              component={SingleSatement}
              path="/dashboard/admin/statement/:statementId"
            />
            <ProtectedRoute
              roles={['isSuperAdmin']}
              component={SingleFactorType}
              path="/dashboard/admin/survey/:surveyId/factorType/:factorTypeId"
            />
            <ProtectedRoute
              roles={['isSuperAdmin']}
              component={CreateFactorType}
              path="/dashboard/admin/survey/:surveyId/archetype/create-archetype"
            />
            <ProtectedRoute
              roles={['isSuperAdmin']}
              component={CreateFactorType}
              path="/dashboard/admin/survey/:surveyId/factor-type/:factorTypeId/edit-factor-type"
            />
            <ProtectedRoute roles={['isOrgLeader']} component={PeerScanContainer} path="/dashboard/scans/peer" />
            <ProtectedRoute
              roles={['isOrgLeader']}
              component={OrgScansContainer}
              path="/dashboard/scans/organisation"
            />
            <ProtectedRoute roles={['isOrgLeader']} component={MyScansContainer} path="/dashboard/scans/my-scans" />
            <ProtectedRoute roles={['isSuperAdmin']} component={SingleUserView} path="/dashboard/admin/users/:id" />
            <ProtectedRoute roles={['isSuperAdmin']} component={SinglePeerView} path="/dashboard/admin/peers/:id" />
            <ProtectedRoute roles={['isSuperAdmin']} component={AllAssignments} path="/dashboard/admin/assessments" />
            <ProtectedRoute roles={['isOrgLeader']} component={SingleScanView} path="/dashboard/admin/scans/:id" />
            <ProtectedRoute
              roles={['isSuperAdmin']}
              component={AllOrganisations}
              path="/dashboard/admin/organisations"
            />
            <ProtectedRoute roles={['isSuperAdmin']} component={Peers} path="/dashboard/admin/peers" />
            <ProtectedRoute roles={['isSuperAdmin']} component={AddPeers} path="/dashboard/admin/add-peer" />
            <ProtectedRoute roles={['isSuperAdmin']} component={AddPeers} path="/dashboard/admin/add-admin-as-peer" />
            <ProtectedRoute roles={['isSuperAdmin']} component={AddPeers} path="/dashboard/admin/:id/edit-peer" />
            <ProtectedRoute roles={['isOrgLeader']} component={OwnerDashboard} path="/dashboard/organisation" />
            <ProtectedRoute
              roles={['isOrgLeader']}
              component={AddUsers}
              path="/dashboard/organisation/:userId/edit-user"
            />
            <ProtectedRoute
              roles={['isOrgLeader']}
              component={SingleUserView}
              path="/dashboard/organisation/users/:id"
            />
            <ProtectedRoute
              roles={['isOrgLeader']}
              component={AppParticipant}
              path="/dashboard/organisation/scan/:scanId/add-participants"
            />
            <ProtectedRoute roles={['isOrgLeader']} component={OrgUsers} path="/dashboard/organisation/users" />
            <ProtectedRoute
              roles={['isOrgLeader']}
              component={DemographicSurvey}
              path="/dashboard/organisation/scan/:scanId/questionnaire"
            />
            <ProtectedRoute
              roles={['isOrgLeader']}
              component={ScanConfirmation}
              path="/dashboard/organisation/scan/:scanId/confirmation"
            />
            <ProtectedRoute
              roles={['isOrgLeader', 'isPeer']}
              component={AssignmentResults}
              path="/dashboard/organisation/:assignmentId/result"
            />
            <ProtectedRoute roles={['isOrgLeader']} component={PreviewBasicResults} path="/test-results" />
            <ProtectedRoute roles={['isRespondent']} component={RespondentDashboard} path="/dashboard/respondent" />
            <ProtectedRoute
              roles={['isRespondent']}
              component={SurveyContainer}
              path="/survey/:respondentId/:assignmentId"
            />
            <ProtectedRoute
              roles={['isRespondent']}
              component={PreviewBasicResults}
              path="/survey/:assignmentId/result"
            />
            <ProtectedRoute roles={['isRespondent']} component={MyAssignments} path="/dashboard/my-scans" />
            <ProtectedRoute roles={['isPeer']} component={Dashboard} path="/dashboard/peer" />
            <ProtectedRoute roles={['isPeer']} component={PeerOrganisations} path="/dashboard/peer/organisations" />
            <ProtectedRoute roles={['isPeer']} component={PeerAssignments} path="/dashboard/peer/scans" />
            <ProtectedRoute roles={['isOrgLeader']} component={SingleScanView} path="/dashboard/peer/scans/:id" />
            <ProtectedRoute roles={['isPeer']} component={PeerUsers} path="/dashboard/peer/:id/users" />
            <ProtectedRoute roles={['isPeer']} component={EditDueDate} path="/dashboard/peer/scans/:id/edit" />
            <ProtectedRoute roles={['isPeer']} component={AddOrganizations} path="/dashboard/peer/add-organisation" />
            <ProtectedRoute
              roles={['isPeer']}
              component={AddOrganizations}
              path="/dashboard/peer/organisation/:orgId/edit"
            />
            <ProtectedRoute roles={['isPeer']} component={CreateScan} path="/dashboard/peer/create-scan" />
            <ProtectedRoute roles={['isPeer']} component={CreateScan} path="/dashboard/peer/edit-scan/:scanId" />
            <ProtectedRoute roles={['isOrgLeader']} component={CreateScan} path="/dashboard/organisation/create-scan" />
            <ProtectedRoute
              roles={['isOrgLeader']}
              component={SingleScanView}
              path="/dashboard/organisation/scans/:id"
            />
            <ProtectedRoute
              roles={['isOrgLeader']}
              component={CreateScan}
              path="/dashboard/organisation/edit-scan/:scanId"
            />
            <ProtectedRoute
              roles={['isPeer']}
              component={SingleOrganisationView}
              path="/dashboard/peer/organisations/:id"
            />

            <Page path=":slug" slug=":slug" />
            <Page default slug="not-found" />
          </Layout>
        </Router>
      </Context.Provider>
    </div>
  )
}

export default App

const FOOTER_QUERY = gql`
  query menu($id: String!) {
    menu(id: $id) {
      name
      menuItemsCollection(limit: 10) {
        items {
          slug
          name
        }
      }
    }
  }
`
