import React, { useEffect } from 'react'
import { useNavigate } from '@reach/router'
import { Table } from '@plusplusminus/plusplusdash'
import { useSort } from '../../hooks/useSort'
import { formatDate } from '../../services/formatDate'
import { Color } from '../../color.enum'
import Loader from '../../components/Loader/Loader'
import { usePagination } from '../../hooks/usePagaination'
import { Search } from '../../components/Search/Search'
import Button from '../../components/Button/Button'
import { ButtonSize, ButtonVariant } from '../../components/Button/types'
import Alert, { AlertSize, AlertVariant } from '../../components/Alert'

const searchParams = [
  {
    name: 'name',
    label: 'Name',
    type: 'text',
    options: []
  }
]
interface Items {
  id: string
  name: string
  createdAt: Date
  owner: any
  assignment: []
}

interface ListItems {
  items: Array<Items>
  loading: boolean
  refetch: (value: any) => void
  pagination?: PaginationArgs
  peerId?: string
}

interface PaginationArgs {
  currentPage: number
  totalPages: number
  hasNextPage: boolean
  hasPrevPage: boolean
}

const TABLE_SHAPE = [
  { label: 'Organisation Name', key: 'organisationName', isSortable: true },
  { label: 'Date Created', key: 'dateCreated', isSortable: true },
  { label: 'Organisation Leader Name', key: 'OrganisationLeaderName', isSortable: true },
  { label: 'Scans', key: 'numberOfAsignments', isSortable: true },
  { label: '', key: 'organisationName', isSortable: true }
]

const OrganisationList: React.FC<ListItems> = ({ items, loading, refetch, peerId }) => {
  const [sortField, direction, sortCallback] = useSort()
  const [page, offset, limit] = usePagination(50)

  useEffect(() => {
    refetch({ pagination: { limit, sort: 'ACS', page } })
  }, [page])

  useEffect(() => {
    if (sortField && direction) {
      refetch({ sort: { direction: direction.toUpperCase(), sortField: sortField.replace('-', '') } })
    }
  }, [sortField, direction])

  const navigate = useNavigate()

  const onSearch = (data: any) => {
    Object.keys(data).forEach((key) => {
      if (data[key] === '') {
        delete data[key]
      }
    })

    if (sortField && direction) {
      refetch({
        pagination: { limit, sort: 'ACS', page: 1 },
        sort: { direction: direction.toUpperCase(), sortField: sortField.replace('-', '') },
        peerId,
        name: data?.name
      })
    } else {
      refetch({
        pagination: { limit, sort: 'ACS', page: 1 },
        peerId,
        name: data?.name
      })
    }
  }

  const onClear = () => {
    refetch({ pagination: { limit, sort: 'ACS', page: 1 }, peerId, name: null })
  }

  if (loading) return <Loader />
  return (
    <>
      {items.length < 1 ? (
        <Alert variant={AlertVariant.WARNING} size={AlertSize.LARGE} className="w-full">
          Currently you do not have any organisations. Please click "add organisation" to create an organisation
        </Alert>
      ) : (
        <div>
          <Search onSearch={onSearch} searchParams={searchParams} onClear={onClear} />
          <Table
            shape={TABLE_SHAPE}
            activeSort={direction === 'desc' ? sortField?.slice(1) : sortField}
            sortDirection={direction}
            sortCallback={sortCallback}
          >
            {items?.map((item) => {
              return (
                <Table.Row key={item.id}>
                  <Table.Cell className="font-medium text-gray-900">{item?.name} </Table.Cell>
                  <Table.Cell className="text-gray-900">{formatDate(item?.createdAt)}</Table.Cell>
                  <Table.Cell className="text-gray-900">
                    {item?.owner.firstName} {item?.owner.lastName}
                  </Table.Cell>
                  <Table.Cell className="text-gray-900">{item?.assignment?.length}</Table.Cell>
                  <Table.Cell className="text-gray-900">
                    <div className="flex space-x-3">
                      {item?.id && (
                        <Button
                          variant={ButtonVariant.PLAIN}
                          color={Color.BLUE}
                          size={ButtonSize.SMALL}
                          onClick={() => navigate(`/dashboard/peer/organisations/${item.id}`)}
                          iconRight="arrowRight"
                        >
                          View
                        </Button>
                      )}
                    </div>
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table>
        </div>
      )}
    </>
  )
}

export default OrganisationList
