import { Box } from '@plusplusminus/plusplusdash'
import Input from '../../../../components/Input/Input'
import { InputValidator } from '../../../../services/validate'
import Button, { ButtonSize, ButtonVariant } from '../../../../components/Button'
import { Color } from '../../../../color.enum'
import RichTextInput from '../../../../components/RichTextInput'
import { useForm } from 'react-hook-form'
import { gql, useQuery, useLazyQuery } from '@apollo/client'
import Loader from '../../../../components/Loader/Loader'
import { Select } from './components/Select'
import { useEffect } from 'react'
import FormLabel from '../../../../components/FormLabel/FormLabel'

const form = [
  {
    label: 'Demographic Questionaire Name',
    name: 'name',
    type: 'text',
    placeholder: '',
    options: {
      required: 'Name is required',
      validate: (input: string) =>
        new InputValidator(input).validateAlphaNumericWithSpace() || 'Enter a valid factor name'
    }
  },
  {
    label: 'Demographic Questionaire Headline',
    name: 'headline',
    placeholder: '',
    options: {
      required: 'Headline is required'
    },

    type: 'text'
  },
  {
    label: 'Locale',
    name: 'localeId',
    placeholder: 'Locale',
    options: {
      required: 'Locale is required'
    },
    type: 'locale'
  },
  {
    label: 'Description',
    name: 'description',
    placeholder: 'Description',
    type: 'textarea'
  }
]
interface FormProps {
  onSubmit?: (data: any, type: string) => void
  postSurveyId?: string
  formType: string
  loading: boolean
}

export const CreateDemographicSurveyForm: React.FC<FormProps> = (props) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm()
  const { data: locales, loading: lcLoading } = useQuery(GET_LOCALES, { fetchPolicy: 'no-cache' })

  const [loadPostSurveyData, { loading, data }] = useLazyQuery(GET_POST_SURVEY, {
    variables: { postSurveyId: props.postSurveyId },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const { postSurvey } = data
      Object.keys(postSurvey).forEach((p) => {
        setValue(p, postSurvey[p])
      })
    }
  })

  useEffect(() => {
    if (props?.postSurveyId) {
      loadPostSurveyData()
    }
  }, [])

  const onRegister = async (data: any) => {
    if (props.onSubmit) {
      await props.onSubmit(data, props.formType)
    }
  }

  if (loading || lcLoading) return <Loader />

  return (
    <form
      action="#"
      autoComplete="no"
      onSubmit={handleSubmit(onRegister)}
      className="mt-6 grid grid-cols-2 gap-y-4 sm:grid-cols-2 sm:gap-x-8"
    >
      {form.map((field) => {
        if (field.type === 'text') {
          return (
            <Box className="col-span-2 sm:col-span-2" key={field.name}>
              <FormLabel className="text-base font-medium text-brand-purple my-1 inline-block">{field.label}</FormLabel>
              <Input
                as="input"
                min={1}
                width="full"
                type={field.type}
                {...register(field.name, { ...field.options })}
              />
              {errors[field.name]?.message && <p className="text-sm text-red-500">{errors[field.name].message}</p>}
            </Box>
          )
        }
        if (field.type === 'locale') {
          return (
            <Box className="col-span-2 sm:col-span-2" key={field.name}>
              <FormLabel className="text-base font-medium text-brand-purple my-1 inline-block">{field.label}</FormLabel>
              <Select
                options={locales?.locales}
                name={field.name}
                register={register}
                value={data?.postSurvey.locale?.id}
                validations={field.options}
              />
              {errors[field.name]?.message && <p className="text-sm text-red-500">{errors[field.name].message}</p>}
            </Box>
          )
        }
        if (field.type === 'textarea') {
          return (
            <Box className="col-span-2 sm:col-span-2 mt-4">
              <FormLabel className="text-base font-medium text-brand-purple my-1 inline-block">{field.label}</FormLabel>
              <Input as="textarea" width="full" {...register(field.name, { ...field.options })} />
              {errors[field.name]?.message && <p className="text-sm text-red-500">{errors[field.name].message}</p>}
            </Box>
          )
        }
        if (field.type === 'richText') {
          return (
            <Box className="col-span-2 sm:col-span-2" key={field.name}>
              <FormLabel className="text-base font-medium text-brand-purple my-1 inline-block">{field.label}</FormLabel>
              <RichTextInput
                placeholder={field.placeholder}
                name={field.name}
                setValue={setValue}
                defaultValue={data?.postSurvey && data?.postSurvey[field.name]}
              />
            </Box>
          )
        }
      })}
      <div className="col-span-2">
        <Button
          className="justify-center mb-2"
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.LARGE}
          color={Color.BLUE}
          style={{ width: '100%' }}
          type="submit"
          disabled={props.loading}
        >
          {props.loading ? 'Loading...' : 'Submit'}
        </Button>
      </div>
    </form>
  )
}

const GET_POST_SURVEY = gql`
  query _postSurveyById($postSurveyId: String!) {
    postSurvey(id: $postSurveyId) {
      id
      name
      headline
      createdAt
      description
      questions {
        text
        postSurveyOption {
          id
          text
          code
        }
        code
      }
      locale {
        id
        name
      }
    }
  }
`

export const GET_LOCALES = gql`
  query postSurveyLocales {
    locales {
      id
      name
      code
    }
  }
`
