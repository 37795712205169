import { gql, useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import cn from 'classnames'
import React, { useMemo, useState } from 'react'
import { Color } from '../../../color.enum'
import { User } from '../../../common/types'
import Bar, { BarSize } from '../../../components/Bar'
import Card, { CardVariant } from '../../../components/Card'
import Loader from '../../../components/Loader/Loader'
import Modal from '../../../components/Modal/Modal'
import Radar, { DimensionProps } from '../../../components/Radar'
import { ArchetypeProps } from '../../../components/Radar/Radar.helpers'
import { RESULTS_CONTENT_QUERY } from './contentful-queries'
import PDFDownload from './PDFReport'
import client from '../../../contentful'
import RichTextRenderer from '../../../modules/RichTextRenderer'
import { testResults } from './results.api'
import { BarChart } from '../../../components/BarChart/BarChat'
import Button, { ButtonSize, ButtonVariant } from '../../../components/Button'

interface ResultsContainerProps extends RouteComponentProps {
  user?: User
  assignmentId: string
}

interface Results {
  results: Archetype[]
}

type Archetype = {
  color: string
  factorType: string
  factorTypeDescription?: string
  score?: number
  responses: Responses[]
  content?: string
}

type Responses = {
  name: string
  description?: string
  headline?: string
  content?: string
  score: number
}

const Results: React.FC<ResultsContainerProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [activeItem, setActiveItem] = useState(testResults.results[0])
  const [radarImageBuffer, setRadarImageBuffer] = useState<ArrayBuffer>()
  const [barchartImageBuffer, setBarChatImageBuffer] = useState(null)
  const [orgRadarImageBuffer, setOrgRadarImageBuffer] = useState<ArrayBuffer>()
  const archetypeScore = (dimension: any) => {
    let score = 0
    dimension.map((f: any) => (score += f.score))
    return Math.round(score / dimension.length)
  }

  const { loading: resultsLoading, data: assignmentResults } = useQuery(GET_ASSIGNMENT_RESULTS, {
    fetchPolicy: 'no-cache',
    variables: {
      assignmentId: props.assignmentId
    }
  })

  const { data: contentfulData } = useQuery(RESULTS_CONTENT_QUERY, {
    client,
    variables: { contentIdentifier: 'lms-app-results-message-individual' }
  })

  const { loading, data } = useQuery(GET_RESULTS, {
    fetchPolicy: 'no-cache',
    variables: {
      assignmentId: props.assignmentId
    }
  })

  const testArchetypes = useMemo<Array<ArchetypeProps>>(() => {
    const a = []
    if (data) {
      for (const res of data.basicResults.results) {
        const types = []
        types.push({
          name: res.factorType,
          description: res.factorTypeDescription ?? '',
          color: res.color as Color,
          dimensions: res.responses,
          score: archetypeScore(res.responses),
          factorTypeReportDescription: res.factorTypeReportDescription
        })
        const dimensions = []
        for (const r of res.responses) {
          dimensions.push({
            score: r.score,
            name: r.factor.headline ?? '',
            description: r.factor.description ?? '',
            content: r.factor.content ?? ''
          })
        }
        a.push({
          types,
          dimensions,
          color: res.color as Color,
          name: res.factorType,
          description: res.factorTypeDescription,
          factorTypeReportDescription: res.factorTypeReportDescription
        })
      }
    }
    return a
  }, [data])

  const orgArchetypes = useMemo<Array<ArchetypeProps>>(() => {
    const a = []
    if (assignmentResults) {
      for (const res of assignmentResults.assignmentBasicResults.results) {
        const types = []
        types.push({
          name: res.factorType,
          description: res.factorTypeDescription ?? '',
          color: res.color as Color,
          dimensions: res.responses,
          factorTypeReportDescription: res.factorTypeReportDescription,
          score: archetypeScore(res.responses)
        })

        const dimensions = []
        for (const r of res.responses) {
          dimensions.push({
            score: r.score,
            name: r.factor.headline || r.factor.name,
            description: r.factor.description ?? ''
          })
        }
        a.push({
          types,
          dimensions,
          color: res.color as Color,
          name: res.factorType,
          description: res.factorTypeDescription,
          factorTypeReportDescription: res.factorTypeReportDescription
        })
      }
    }
    return a
  }, [assignmentResults])

  const pdfs = useMemo(() => {
    if (data && barchartImageBuffer) {
      return (
        <>
          <PDFDownload
            key="individual-report"
            assignmentId={props.assignmentId}
            radarImage={radarImageBuffer}
            archetypes={testArchetypes}
            statementCount={data.basicResults.statementCount}
            isIndividual={true}
            reportName={props.user?.organization?.name || ''}
            userRole={props?.user?.role}
            user={props.user}
          />
          {assignmentResults && !data.basicResults.areOrgResultsAvailable ? (
            <Button
              size={ButtonSize.MEDIUM}
              variant={ButtonVariant.PLAIN}
              color={Color.BLUE}
              iconLeft="download"
              isDisabled={true}
            >
              Download Organisation Report
            </Button>
          ) : null}
          {assignmentResults && data.basicResults.areOrgResultsAvailable && (
            <PDFDownload
              key="org-report"
              assignmentId={props.assignmentId}
              radarImage={orgRadarImageBuffer}
              barChartImage={barchartImageBuffer}
              archetypes={orgArchetypes}
              statementCount={assignmentResults.assignmentBasicResults.statementCount}
              isIndividual={false}
              reportName={props.user?.organization?.name || ''}
              userRole={props?.user?.role}
              user={props.user}
            />
          )}
        </>
      )
    }
  }, [radarImageBuffer, barchartImageBuffer, data])

  if (loading || resultsLoading) return <Loader />
  if (!data) return null

  const onClick = (activeItem: any) => {
    setIsOpen(true)
    setActiveItem(activeItem)
  }

  const { headline, content } = contentfulData?.contentCollection?.items?.[0] ?? {}

  return (
    <>
      <main>
        <div className="page-container-lg">
          <Card
            variant={CardVariant.STANDARD}
            subtitle={props.user?.organization?.name || ''}
            headline={`${headline}, ${props.user?.firstName}`}
            media={<Radar setImageBuffer={setRadarImageBuffer} archetypes={testArchetypes} />}
          >
            {content ? (
              <div className="mt-10 text-brand-blue">
                <RichTextRenderer content={content} />
              </div>
            ) : null}
            <div className="my-10 flex space-x-2">
              <Button color={Color.BLUE} iconRight="arrowRight" size={ButtonSize.LARGE} variant={ButtonVariant.PRIMARY}>
                <a href="https://fiftyfourcollective.com/my-learning/">Start Learning</a>
              </Button>
            </div>

            {testArchetypes.map((a, index) => (
              <FactorResult
                className={cn('mt-10', { 'mb-6': index === data.basicResults.results.length - 1 })}
                type={a.name}
                description={a.description}
                dimensions={a.types}
                color={a.color}
                onClick={onClick}
              />
            ))}
            {pdfs}
          </Card>
          <Card
            className="hide-element"
            media={
              <div className="bg-brand-blue-light hide-element">
                <BarChart setImageBuffer={setBarChatImageBuffer} archetypes={orgArchetypes} />
              </div>
            }
          ></Card>
          <Card
            className="hide-element"
            media={
              <div className="bg-brand-blue-light hide-element">
                <Radar setImageBuffer={setOrgRadarImageBuffer} archetypes={orgArchetypes} />
              </div>
            }
          ></Card>
        </div>
      </main>

      <Modal isModalOpen={isOpen} title={activeItem?.name ?? ''} onClose={() => setIsOpen(false)}>
        {activeItem?.description ? <div className="prose text-brand-blue">{activeItem.description}</div> : null}
        <div className="my-4">
          {activeItem.dimensions?.map((dim) => {
            return (
              <>
                <Bar
                  key={dim.name}
                  headline={dim?.factor?.headline ?? ''}
                  scorePercentage={dim.score}
                  color={activeItem.color as Color}
                  className="mb-6"
                  hoverText={null}
                  size={BarSize.SMALL}
                />
              </>
            )
          })}
        </div>
      </Modal>
    </>
  )
}

interface FactorResultProps {
  type: string
  description?: string
  color: Color
  dimensions?: DimensionProps[]
  className?: string
  onClick: (dim: DimensionProps) => void
}

const FactorResult = (props: FactorResultProps) => {
  return (
    <div className={props.className}>
      <div className="my-4">
        {props?.dimensions?.map((dim) => {
          return (
            <>
              <Bar
                key={dim.name}
                headline={dim.name}
                scorePercentage={dim.score}
                color={props.color}
                className="mb-6"
                onClick={() => props.onClick(dim)}
              />
            </>
          )
        })}
      </div>
    </div>
  )
}

export default Results

const GET_RESULTS = gql`
  query Results($assignmentId: String!) {
    basicResults(assignmentId: $assignmentId) {
      results {
        factorType
        factorTypeDescription
        factorTypeReportDescription
        color: factorTypeColor
        responses {
          factor {
            name
            description
            headline
            content
          }
          score
        }
      }
      statementCount
      areOrgResultsAvailable
    }
  }
`
const GET_ASSIGNMENT_RESULTS = gql`
  query assignmentBasicResults($assignmentId: String!) {
    assignmentBasicResults(assignmentId: $assignmentId) {
      assignmentName
      results {
        factorType
        factorTypeDescription
        factorTypeReportDescription
        color: factorTypeColor
        responses {
          factor {
            name
            description
            headline
          }
          score
        }
      }
      statementCount
    }
  }
`
