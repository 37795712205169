import { useEffect, useState } from 'react'
import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { Box } from '@plusplusminus/plusplusdash'
import Input from '../../components/Input/Input'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate } from '@reach/router'
import { InputValidator } from '../../services/validate'
import { Color } from '../../color.enum'
import { ButtonVariant, ButtonSize } from '../../components/Button'
import Button from '../../components/Button'
import Icon from '../../components/Icon/Icon'
import parse from 'html-react-parser'
import HeadingPageCenter from '../../components/HeadingPageCenter'
import Loader from '../../components/Loader/Loader'
import { COUNTRIES } from '../../common/countries'
import FormLabel from '../../components/FormLabel/FormLabel'
import { Select } from '../../components/Select/Select'
import { User } from '../../common/types'
import { GET_ALL_SURVEYS } from '../Dashboard/survey'

const form = [
  {
    label: 'Representative First Name',
    name: 'firstName',
    type: 'text',
    placeholder: 'First Name',
    options: {
      required: 'Last name is required',
      validate: (input: string) =>
        new InputValidator(input).validateAlphaNumericWithSpace() || 'Enter a valid first name'
    }
  },
  {
    label: 'Representative Last Name',
    name: 'lastName',
    placeholder: 'Last Name',
    options: {
      required: 'Last name is required',
      validate: (input: string) =>
        new InputValidator(input).validateAlphaNumericWithSpace() || 'Enter a valid last name'
    },

    type: 'text'
  },
  {
    label: 'Representative Email',
    name: 'email',
    placeholder: 'Email',
    options: {
      required: 'Email is required',
      validate: (input: string) => new InputValidator(input).validateEmail() || 'Enter a valid email'
    },
    type: 'email'
  },
  {
    label: 'Foundation/Organisation name',
    name: 'name',
    placeholder: 'Foundation/Organisation name',
    options: {
      required: 'Foundation/Organisation name is required',
      validate: (input: string) =>
        new InputValidator(input).validateAlphaNumericWithSpace() || 'Enter a valid peer name'
    },
    type: 'text'
  },
  {
    label: 'Internal Organisation ID',
    name: 'internalId',
    placeholder: 'Internal Org ID',
    type: 'text'
  },
  {
    label: 'Current country of head office',
    name: 'country',
    placeholder: 'Country',
    options: {
      required: 'Country is required'
    },
    type: 'select',
    values: COUNTRIES.map((c) => ({ id: c, name: c }))
  },
  {
    label: 'Year Foundation/Organisation was founded',
    name: 'foundedDate',
    placeholder: 'Founded Date',
    options: {
      required: 'Date is required'
    },
    type: 'datePicker'
  }
]

interface Props {
  onSubmit?: (data: any, reset: any, formType: string) => void
  loading: boolean
  success: any
  userId: string
  formType: string
  user?: User
  createOrgFromPeer?: (data: any) => void
  orgId?: string
}
const generateArrayOfYears = () => {
  const max = new Date().getFullYear()
  const min = max - 99
  const years = []

  for (let i = max; i >= min; i--) {
    years.push({ id: i.toString(), name: i.toString() })
  }
  return years
}

export const AddOrganizationForm: React.FC<Props> = (props) => {
  const navigate = useNavigate()
  const [orgFromPeer, setOrgFromPeer] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control
  } = useForm({ mode: 'onChange', reValidateMode: 'onChange' })

  const onRegister = async (data: any) => {
    if (props.onSubmit && !orgFromPeer) {
      await props.onSubmit(data, reset, props.formType)
    }

    if (orgFromPeer && props.createOrgFromPeer) {
      await props.createOrgFromPeer(data)
    }
  }

  const { data } = useQuery(GET_ALL_SURVEYS, {
    fetchPolicy: 'no-cache'
  })

  const [fetchOrg, { loading: loading }] = useLazyQuery(GET_ORG, {
    variables: { id: props.orgId },
    fetchPolicy: 'no-cache',
    onCompleted: (orgData) => {
      const { organization } = orgData
      Object.keys(organization).forEach((o) => {
        if (o === 'foundedDate') {
          const date = new Date(organization[o])
          setValue(o, date.getFullYear())
        } else if (o === 'owner') {
          const user = organization[o]
          Object.keys(user).forEach((u) => {
            setValue(u, user[u])
          })
        } else {
          setValue(o, organization[o])
        }
      })
    }
  })

  useEffect(() => {
    if (props.formType === 'edit') {
      fetchOrg()
    }
  }, [])

  if (loading) return <Loader color={Color.BLUE} />

  return (
    <div>
      <HeadingPageCenter
        headline={props?.formType === 'edit' ? 'Edit Organisation' : 'Add New Organisation'}
        color={Color.BLUE}
        description={
          props?.formType === 'create' &&
          parse('Please fill in the organisation details below to add a new organisation. ')
        }
        children={() => {
          const { user } = props
          if (user && !user.organization && props?.formType === 'create') {
            return (
              <Button
                variant={ButtonVariant.PLAIN}
                size={ButtonSize.MEDIUM}
                color={Color.BLUE}
                iconRight="plus"
                onClick={(e) => {
                  e.preventDefault()
                  setOrgFromPeer(true)
                  setValue('firstName', user.firstName)
                  setValue('lastName', user.lastName)
                  setValue('email', user.email)
                  setValue('name', user.peer.name)
                }}
              >
                Add current peer as organisation
              </Button>
            )
          } else {
            return <></>
          }
        }}
      />
      <div className="section-container-sm">
        <form
          action="#"
          autoComplete="no"
          onSubmit={handleSubmit(onRegister)}
          className="grid grid-cols-2 gap-y-4 sm:grid-cols-2 sm:gap-x-8"
        >
          {form.map((field) => {
            if (field.type === 'select') {
              return (
                <Box className="col-span-2 sm:col-span-2" key={field.name}>
                  <FormLabel>{field.label}</FormLabel>
                  <Select items={field.values} name={field.name} register={register} />
                </Box>
              )
            }

            if (field.type === 'datePicker') {
              return (
                <div className="col-span-2 sm:col-span-2" key={field.name}>
                  <FormLabel>{field.label}</FormLabel>
                  <Select
                    items={generateArrayOfYears()}
                    name={field.name}
                    register={register}
                    validations={field.options}
                  />
                  {errors[field.name]?.message && <p className="text-sm text-red-500">{errors[field.name].message}</p>}
                </div>
              )
            }
            return (
              <Box className="col-span-2 sm:col-span-2" key={field.name}>
                <FormLabel>{field.label}</FormLabel>
                <Controller
                  rules={{ ...field.options }}
                  {...register(field.name, { ...field.options })}
                  control={control}
                  render={({ field }) => <Input as="input" width="full" {...field} />}
                />
                {errors[field.name]?.message && <p className="text-sm text-red-500">{errors[field.name].message}</p>}
              </Box>
            )
          })}

          <div className="col-span-2 sm:col-span-2">
            <FormLabel>Assesment</FormLabel>
            <Select
              items={data?.surveys?.map((survey: any) => ({ id: survey.id, name: survey.name })) ?? []}
              name={'surveyId'}
              register={register}
            />
          </div>

          <div className="flex space-x-2 col-span-2">
            <Button
              className="flex-1 justify-center align-middle mb-2"
              variant={ButtonVariant.PLAIN}
              color={Color.BLUE}
              size={ButtonSize.LARGE}
              style={{ width: '100%' }}
              onClick={() => {
                navigate('/dashboard/peer/organisations')
              }}
            >
              <Icon className="pr-1" name="arrowLeft" />
              Back
            </Button>
            <Button
              className="flex-1 justify-center align-middle mb-2"
              color={Color.BLUE}
              size={ButtonSize.LARGE}
              type="submit"
              variant={ButtonVariant.PRIMARY}
              disabled={props.loading || loading}
            >
              {props.loading || loading ? 'Loading...' : 'Submit'}
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

const GET_ORG = gql`
  query singleOrganisation($id: String!) {
    organization(id: $id) {
      name
      internalId
      surveyId
      country
      foundedDate
      owner {
        firstName
        lastName
        email
      }
    }
  }
`
