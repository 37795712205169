import Button, { ButtonSize, ButtonVariant } from '../Button'
import parse from 'html-react-parser'
import { Color } from '../../color.enum'
// import bg from '../../../public/mci-bg.png'

export const api = {
  subtitle: 'Luke 14:25–33',
  headline: parse('Count Your Bricks.<br/>Count Your Soldiers.<br/>Calculate the Cost.<br/>Advance Your Mission.'),
  description:
    'How does your ministry measure up to these eight biblically sound organisational principles? The 9D Capacity Index will help you find out so you can grow with confidence.',
  color: Color.ORANGE,
  // image: <img src={bg} alt="" className="h-full object-cover" />,
  children: (color: Color): JSX.Element => {
    return (
      <Button variant={ButtonVariant.SECONDARY} size={ButtonSize.LARGE} color={color}>
        Start your MCI
      </Button>
    )
  }
}
