import { RouteComponentProps, useParams, useNavigate } from '@reach/router'
import { Helmet } from 'react-helmet'
import { useQuery } from '@apollo/client'
import Loader from '../../components/Loader/Loader'
import HeadingPage from '../../components/HeadingPage'
import HeadingSection from '../../components/HeadingSection'
import { Color } from '../../color.enum'
import { formatDate } from '../../services/formatDate'
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import { language } from '../../common/i18n'
import { User } from '../../common/types'
import { GET_ORG_BY_ID } from '../../graphql/queries'
import Button from '../../components/Button'
import { ButtonVariant, ButtonSize } from '../../components/Button'

interface SingleOrganisationViewProps extends RouteComponentProps {
  user?: User
}

const SingleOrganisationView: React.FC<SingleOrganisationViewProps> = () => {
  const { id } = useParams()
  const { loading, data } = useQuery(GET_ORG_BY_ID, { variables: { id }, fetchPolicy: 'no-cache' })

  const breadCrumbPath = [
    { name: 'Dashboard', path: '/dashboard/peer/', current: false },
    { name: 'Organisations', path: `/dashboard/peer/organisations`, current: true }
  ]
  const navigate = useNavigate()

  if (loading) return <Loader />

  return (
    <div className="page-container-lg mb-40">
      <Helmet>
        <title>{language.en.admin.user.singleUserView.title}</title>
        <meta name="description" content={language.en.admin.user.singleUserView.description} />
        <meta name="robots" content="noindex" data-react-helmet="true" />
      </Helmet>
      <header>
        <BreadCrumbs className=" pb-10" pages={breadCrumbPath} />
        <HeadingPage
          headline={`${data?.organization?.name}`}
          color={Color.BLUE}
          description="You can use this view to view the current organisation details."
        />
      </header>

      <HeadingSection
        headline="Organisation Profile"
        color={Color.BLUE}
        children={() => {
          return (
            <div className="flex items-center">
              <Button
                className="justify-self-center self-end"
                variant={ButtonVariant.PRIMARY}
                color={Color.BLUE}
                size={ButtonSize.SMALL}
                onClick={() => {
                  navigate(`/dashboard/peer/organisation/${data?.organization?.id}/edit`)
                }}
              >
                Edit Organisation
              </Button>
            </div>
          )
        }}
      />
      <div className="mt-5 space-y-1">
        <div>
          <span className="inline text-brand-blue font-bold">Foundation/Organisation Name:</span>
          <span className="ml-1 text-brand-blue">{data?.organization?.name}</span>
        </div>
        <div>
          <span className="inline text-brand-blue font-bold">International Organisation ID:</span>
          <span className="ml-1 text-brand-blue">{data?.organization?.internalId}</span>
        </div>
        <div>
          <span className="inline text-brand-blue font-bold">Current country of head office:</span>
          <span className="ml-1 text-brand-blue">{data?.organization?.country}</span>
        </div>
        {data?.organization?.foundedDate && (
          <div>
            <span className="inline text-brand-blue font-bold">Year Foundation/Organisation was founded:</span>
            <span className="ml-1 text-brand-blue">{new Date(data?.organization.foundedDate).getFullYear()}</span>
          </div>
        )}

        <div>
          <span className="inline text-brand-blue font-bold">Created on:</span>
          <span className="ml-1 text-brand-blue">
            {data?.organization?.createdAt && formatDate(data?.organization?.createdAt)}
          </span>
        </div>
        <div className="mb-3">
          <p className="inline text-brand-blue font-bold">Organisation Owner:</p>
          <span className="ml-1 text-brand-blue">
            {data?.organization?.owner?.firstName} {data?.organization?.owner?.lastName}
          </span>
        </div>
        <div className="mb-3">
          <p className="inline text-brand-blue font-bold">Organisation Owner Email:</p>
          <span className="ml-1 text-brand-blue">{data?.organization?.owner?.email}</span>
        </div>
        {data?.organization?.peer && (
          <div className="mb-3">
            <p className="inline text-brand-blue font-bold">Peer:</p>
            <span className="ml-1 text-brand-blue">{data?.organization?.peer?.name}</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default SingleOrganisationView
