import { useState } from 'react'
import { RouteComponentProps, useNavigate } from '@reach/router'
import { useMutation, gql, useQuery } from '@apollo/client'
import { User } from '../../../common/types'
import { Color } from '../../../color.enum'
import HeadingPageCenter from '../../../components/HeadingPageCenter'
import FormLabel from '../../../components/FormLabel/FormLabel'
import Button, { ButtonVariant, ButtonSize } from '../../../components/Button'
import Input from '../../../components/Input/Input'
import { PageWrapper } from '../../../modules/PageWrapper'
import { formatDate } from '../../../services/formatDate'

interface ScanConfirmProps extends RouteComponentProps {
  user?: User
  scanId: string
}

export const ScanConfirmation: React.FC<ScanConfirmProps & any> = ({ scanId }) => {
  const navigate = useNavigate()
  const [message, setMessage] = useState('')
  const { loading, data } = useQuery(GET_A_SCAN, {
    variables: { id: '71d10016-ca18-4eff-bfe0-8c34c960e1d9' },
    fetchPolicy: 'no-cache'
  })

  if (loading) {
    return <div>Loading....</div>
  }

  return (
    <PageWrapper
      headline={'Assessment Confirmation'}
      sectionHeading="Invitation Message"
      description='Please ensure that all assessment details are correct before you submit. You will not be able to make any changes to the assessment details after clicking "Submit", you will however, be able to edit participants. Once you click "Submit" the assessment will be live and an invitation email will be sent to the Organisation’s Representative.'
      renderCta={() => (
        <div>
          <div className="mb-3">
            <span className="inline text-brand-blue font-medium">Assessment name:</span>
            <span className="ml-4 text-brand-blue">{data.assignment.name}</span>
          </div>
          <div className="mb-3">
            <span className="inline text-brand-blue font-medium">Created on:</span>
            <span className="ml-4 text-brand-blue">{formatDate(data.assignment.createdAt)}</span>
          </div>
          <div className="mb-3">
            <p className="inline text-brand-blue font-medium">Due Date:</p>
            <span className="ml-4 text-brand-blue">{formatDate(data.assignment.dueDate)} </span>
          </div>
          <div className="mb-3">
            <p className="inline text-brand-blue font-medium">Status :</p>
            <span className="ml-4 text-brand-blue">{data.assignment.status}</span>
          </div>
          <div className="mb-3">
            <p className="inline text-brand-blue font-medium">Respondent Count :</p>
            <span className="ml-4 text-brand-blue">{data.assignment.respondentCount}</span>
          </div>
        </div>
      )}
    >
      <div className="py-8 max-w-lg">
        <div className="flex space-x-2 mt-8">
          <Button
            variant={ButtonVariant.PRIMARY}
            color={Color.BLUE}
            size={ButtonSize.LARGE}
            onClick={() => navigate('/dashboard/organisation')}
            disabled={loading}
          >
            {'Confirm'}
          </Button>
        </div>
      </div>
    </PageWrapper>
  )
}

const GET_A_SCAN = gql`
  query Assignment($id: String!) {
    assignment(id: $id) {
      id
      name
      createdAt
      dueDate
      preSurveyStatus
      respondentCount
      status
    }
  }
`
