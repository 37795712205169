import Input from '../../../components/Input/Input'
import { useForm, Controller } from 'react-hook-form'
import FormLabel from '../../../components/FormLabel/FormLabel'
import Button, { ButtonSize, ButtonVariant } from '../../../components/Button'
import { Color } from '../../../color.enum'
import { InputValidator } from '../../../services/validate'
import { language } from '../../../common/i18n'
import Notice, { NoticeVariant } from '../../../components/Notice'

interface Props {
  onSubmit?: (data: any) => void
  loading?: boolean
  headline?: string
  content?: string
  error?: string
}

type FormInputs = {
  email: string
}

const emailFieldOptions = {
  required: 'Email is required',
  validate: {
    checkEmail: (input: string) => new InputValidator(input).validateEmail() || 'Enter a valid  email'
  }
}

export const LoginForm: React.FC<Props> = (props) => {
  const {
    handleSubmit,
    formState: { errors },
    control
  } = useForm<FormInputs>({ mode: 'onChange', reValidateMode: 'onChange' })

  const onRegister = async (data: any) => {
    if (props.onSubmit) {
      await props.onSubmit(data.email)
    }
  }

  return (
    <div>
      <FormLabel className="mb-2">{language.en.auth.description.login}</FormLabel>
      <form action="#" autoComplete="no" onSubmit={handleSubmit(onRegister)}>
        <Controller
          rules={{ ...emailFieldOptions }}
          name="email"
          control={control}
          render={({ field }) => <Input as="input" width="full" {...field} placeholder="Email address" />}
        />
        {errors.email && <p className="text-sm text-red-500 mt-2">{errors.email.message}</p>}
        <div className="py-4">
          <Button
            className="justify-center"
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.MEDIUM}
            color={Color.BLUE}
            style={{ width: '100%' }}
            type="submit"
            disabled={props.loading}
          >
            {props.loading ? 'Loading...' : 'Log in with email'}
          </Button>

          {props?.error ? (
            <Notice
              className="mt-2 mb-1 w-full"
              variant={NoticeVariant.SMALL}
              icon="error"
              color={Color.GRAY}
              children={<div className="text-brand-red">{props.error}</div>}
            />
          ) : null}
        </div>
      </form>
    </div>
  )
}
