import React, { useMemo } from 'react'
import { Bar } from 'react-chartjs-2'
import { DataItem } from './DataContext'

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title } from 'chart.js'
import Radar from '../Radar/Radar'
import { Color } from '../../color.enum'
import { DimensionProps } from '../Radar'
ChartJS.register(CategoryScale, LinearScale, BarElement, Title)
ChartJS.defaults.color = 'gray'

interface FactorType {
  name: string
  code: string
  color: Color
  headline: string
  indexOrder: number
}

interface GroupedData {
  factorName: string
  position: number
  average: [number]
  total?: number
  factorType: FactorType
}

interface GraphComponentProps {
  id: string
  data: DataItem[]
  type: string
  factors: [
    {
      id: string
      name: string
      headline: string
      coreFactor: boolean
      position: number
      factorType: {
        name: string
        code: string
        color: Color
        indexOrder: number
      }
    }
  ]
}

const GraphComponent: React.FC<GraphComponentProps> = ({ data, type, factors, id }) => {
  const groupedData: { [factorName: string]: GroupedData } = {}

  const factorLookup = factors.reduce((acc, curr) => {
    acc[curr.name] = { ...curr.factorType, headline: curr.headline, position: curr.position }
    return acc
  }, {} as Record<string, { headline: string; name: string; code: string; color: Color; indexOrder: number; position: number }>)

  useMemo(() => {
    data
      .map((item) => ({
        ...item,
        factorType: factorLookup[item.factor]
      }))
      .forEach((item) => {
        const factorName = item.factor
        const avgValue = item.avg

        if (factorName in groupedData) {
          groupedData[factorName].average.push(avgValue)
        } else {
          groupedData[factorName] = {
            factorName,
            position: item.factorType.position,
            average: [avgValue],
            factorType: item.factorType,
            total: 0
          }
        }
      })
  }, [data, factorLookup])

  const result: GroupedData[] = Object.values(groupedData)
    .sort((a, b) => a.position - b.position)
    .sort((a, b) => a.factorType.indexOrder - b.factorType.indexOrder)
    .map((group) => {
      const total = group.average.reduce((sum, value) => sum + value, 0) / group.average.length
      return { ...group, total }
    })

  return (
    <div className="my-6 mx-2">
      {type === 'radar' ? <RadarChart result={result} id={id} /> : <BarChart result={result} key={id} />}
    </div>
  )
}

interface Dimension {
  score: number
  name: string
  description: string
}

interface ResultDataItem {
  dimensions: Dimension[]
  color: Color
  name: string
  types: DimensionProps[]
}

const RadarChart = ({ result, id }: { result: GroupedData[]; id: string }) => {
  const transformDataForRadarPlot = (data: GroupedData[]): ResultDataItem[] => {
    const groups: { [factorType: string]: ResultDataItem } = {}

    data.forEach((factor) => {
      const factorType = factor.factorType.name

      if (!groups[factorType]) {
        groups[factorType] = {
          color: factor.factorType.color,
          name: factorType,
          dimensions: [
            {
              score: Math.round(factor.total || 0),
              name: factor.factorType.headline,
              description: ''
            }
          ],
          types: [
            {
              name: factor.factorType.name,
              color: factor.factorType.color,
              score: 0,
              description: ''
            }
          ]
        }
      } else {
        groups[factorType].dimensions.push({
          score: Math.round(factor.total || 0),
          name: factor.factorType.headline,
          description: ''
        })
      }
    })

    return Object.values(groups)
  }

  const radarData = transformDataForRadarPlot(result)

  return <Radar archetypes={radarData} id={id} />
}

const BarChart = ({ result }: { result: GroupedData[] }) => {
  const factorNames = result.map((item) => item.factorType.headline)
  const averageValues = result.map((item) => item.total)

  const chartData = {
    labels: factorNames,
    datasets: [
      {
        label: 'Average',
        data: averageValues,
        backgroundColor: result.map((item) => item.factorType.color)
      }
    ]
  }

  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
        grid: {
          color: 'gray'
        }
      },
      x: {
        grid: {
          display: false
        }
      }
    }
  }

  return <Bar data={chartData} options={chartOptions} />
}

export default GraphComponent
