import { useState } from 'react'
import { RouteComponentProps, useNavigate, useParams } from '@reach/router'
import { Helmet } from 'react-helmet'
import { Color } from '../../../color.enum'
import Button, { ButtonVariant, ButtonSize } from '../../../components/Button'
import Alert, { AlertSize, AlertVariant } from '../../../components/Alert'
import { gql, useQuery, useMutation } from '@apollo/client'
import Tabs from '../../../components/Tabs'
import { formatDate } from '../../../services/formatDate'
import FactorList from './factors/factorList'
import DemoGraphicList from './demographicSurveys/demographicList'
import Loader from '../../../components/Loader/Loader'
import FactorTypeList from './factors/factorType'
import HeadingSection from '../../../components/HeadingSection'
import HeadingPage from '../../../components/HeadingPage'
import { useBreadcrumbs } from '../../../hooks/useBreadcrumbs'
import BreadCrumbs from '../../../components/BreadCrumbs/BreadCrumbs'
import { language } from '../../../common/i18n'
import { EDIT_SURVEY } from './createEditSurvey'
import Modal from '../../../components/Modal/Modal'

const SingleSurvey: React.FC<RouteComponentProps> = () => {
  const navigate = useNavigate()
  const { surveyId } = useParams()

  const [getBreadCrumbs] = useBreadcrumbs()
  const { loading, data, refetch } = useQuery(GET_A_SINGLE_SURVEY, {
    variables: { id: surveyId },
    fetchPolicy: 'no-cache'
  })

  const { data: factorTypeData, refetch: refetchFactorTypes } = useQuery(GET_ALL_FACTOR_TYPES, {
    fetchPolicy: 'no-cache'
  })

  const [deletePostSurvey] = useMutation(DELETE_POST_SURVEY, {
    onError: (error) => {
      console.log(error)
    },
    onCompleted: () => {
      refetch({ id: surveyId })
    }
  })

  const tabsData = [
    {
      name: 'Dimensions',
      content: (
        <div>
          <HeadingSection
            headline="Dimensions"
            color={Color.BLUE}
            children={() => (
              <Button
                variant={ButtonVariant.PRIMARY}
                color={Color.BLUE}
                size={ButtonSize.MEDIUM}
                onClick={() => navigate(`/dashboard/admin/survey/${surveyId}/create-dimension`)}
                iconRight="plus"
              >
                Add Dimension
              </Button>
            )}
          />
          {data?.surveyById?.factors <= 0 ? (
            <Alert size={AlertSize.LARGE} variant={AlertVariant.INFO} className="mb-4">
              No Factors found for this Assessment. Please add new Dimension.
            </Alert>
          ) : (
            <FactorList
              factors={data?.surveyById?.factors}
              surveyId={surveyId}
              surveyStatus={data?.surveyById?.status}
              refetch={refetch}
            />
          )}
        </div>
      )
    },
    {
      name: 'Archetypes',
      content: (
        <div>
          <HeadingSection
            headline="Archetypes"
            color={Color.BLUE}
            children={() => (
              <Button
                variant={ButtonVariant.PRIMARY}
                color={Color.BLUE}
                size={ButtonSize.MEDIUM}
                onClick={() => navigate(`/dashboard/admin/survey/${surveyId}/archetype/create-archetype`)}
                iconRight="plus"
              >
                Add Archetype
              </Button>
            )}
          />
          {!factorTypeData?.allFactoryTypes.length ? (
            <Alert size={AlertSize.LARGE} variant={AlertVariant.INFO} className="mb-4">
              No Archetypes found for this Assesment. Please add new Archetype.
            </Alert>
          ) : (
            <FactorTypeList
              factorTypes={factorTypeData.allFactoryTypes}
              surveyId={surveyId}
              surveyStatus={data?.surveyById?.status}
              refetch={refetchFactorTypes}
            />
          )}
        </div>
      )
    },
    {
      name: 'Demographic Questionnaire',
      content: (
        <div id="respondent_survey">
          <HeadingSection color={Color.BLUE} headline="Demographic Questionnaire" className="mb-4" />
          {data?.questionnaire ? (
            <DemoGraphicList
              survey={data?.questionnaire}
              type="participant"
              deleteSurvey={deletePostSurvey}
              surveyId={surveyId}
              surveyStatus={data?.surveyById?.status}
              refetch={refetch}
            />
          ) : (
            <div className="my-5">
              <Alert size={AlertSize.LARGE} variant={AlertVariant.WARNING} className="mb-4">
                No Questionnaire found for this Assesment. Please add new Questionnaire.
              </Alert>
              <Button
                variant={ButtonVariant.PRIMARY}
                color={Color.BLUE}
                size={ButtonSize.MEDIUM}
                onClick={() => navigate(`/dashboard/admin/${surveyId}/create-postsurvey`)}
                iconRight="plus"
              >
                Add Questionnaire
              </Button>
            </div>
          )}
        </div>
      )
    }
  ]

  const bc = getBreadCrumbs('survey', data?.surveyById?.id)
  interface DetailsInterface {
    locale?: React.ReactNode
    date?: React.ReactNode
    status: string
  }

  const Details: React.FC<DetailsInterface> = ({ locale, date, status }) => {
    const [showNotification, setShowNotification] = useState(false)
    const [surveyStatus, setSurveyStatus] = useState<string>(status)
    const [updateSurvey, { loading }] = useMutation(EDIT_SURVEY)
    return (
      <div className="mt-5 space-y-1">
        <Modal
          isModalOpen={showNotification}
          title="Change assessment status warning"
          onClose={() => setShowNotification(false)}
        >
          <div className="flex-1  flex flex-col">
            <div>
              <p className="text-md text-brand-blue">
                {['DRAFT', 'CLOSED'].includes(surveyStatus)
                  ? 'This will make the assessment unavailable for participants, are you want sure?'
                  : 'Wont be able to remove any entities when published. Are you sure you want to publish this assessment?'}
              </p>
              <div className="flex space-x-1  mt-3">
                <Button
                  className="flex-1 text-center justify-center outline-none"
                  variant={ButtonVariant.PRIMARY}
                  color={Color.BLUE}
                  size={ButtonSize.LARGE}
                  onClick={() => {
                    setSurveyStatus(status)
                    setShowNotification(false)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="flex-1 text-center justify-center"
                  variant={ButtonVariant.PRIMARY}
                  color={Color.BLUE}
                  size={ButtonSize.LARGE}
                  onClick={() =>
                    updateSurvey({
                      variables: {
                        surveyId,
                        input: { status: surveyStatus }
                      }
                    }).then(() => {
                      refetch()
                      setShowNotification(false)
                    })
                  }
                >
                  {loading ? 'Updating..' : 'Update'}
                </Button>
              </div>
            </div>
          </div>
        </Modal>
        <div>
          <span className="inline brand-blue font-semibold mr-3">Assessment Status:</span>
          <span className="ml-1">
            <select
              className="rounded bg-white border border-brand-blue border-opacity-50 text-brand-blue  pl-5 pr-10 hover:border-gray-400 focus:outline-none font-sm"
              value={surveyStatus || ''}
              onChange={(e) => {
                e.preventDefault()
                setSurveyStatus(e.target.value)
                setShowNotification(true)
              }}
            >
              {[
                { name: 'Publish', value: 'PUBLISHED' },
                { name: 'Close', value: 'CLOSED' },
                { name: 'Draft', value: 'DRAFT' }
              ].map((option: { name: string; value: string }) => (
                <option value={option.value} key={option.value}>
                  {option.name}
                </option>
              ))}
            </select>
          </span>
        </div>
        {date && (
          <div>
            <span className="inline text-brand-blue font-medium mr-3">Date Created:</span>
            <span className="ml-1">{date}</span>
          </div>
        )}
        {locale && (
          <div>
            <span className="inline text-brand-blue font-medium mr-3">Locale:</span>
            <span className="ml-1">{locale}</span>
          </div>
        )}
      </div>
    )
  }

  return (
    <div className="page-container-lg my-8">
      <Helmet>
        <title>{data?.surveyById?.name}</title>
        <meta
          name="description"
          content={data?.surveyById?.description || language.en.admin.survey.singleSurvey.description}
        />
        <meta name="robots" content="noindex" data-react-helmet="true" />
      </Helmet>
      <main>
        {loading ? (
          <Loader />
        ) : (
          <>
            <BreadCrumbs pages={bc} className="py-0 pb-10" />
            <HeadingPage
              headline={data?.surveyById?.name}
              color={Color.BLUE}
              description={
                <Details
                  locale={data?.surveyById?.locale.name}
                  date={data?.surveyById?.createdAt && formatDate(data?.surveyById.createdAt)}
                  status={data?.surveyById?.status}
                />
              }
              children={() => {
                return (
                  <div className="flex items-center">
                    <Button
                      variant={ButtonVariant.PRIMARY}
                      color={Color.BLUE}
                      size={ButtonSize.SMALL}
                      onClick={() => navigate(`/dashboard/admin/survey/${data?.surveyById.id}/edit-survey`)}
                    >
                      Edit Assessment
                    </Button>
                  </div>
                )
              }}
            />

            <Tabs tabs={tabsData} />
          </>
        )}
      </main>
    </div>
  )
}

export default SingleSurvey

const GET_A_SINGLE_SURVEY = gql`
  query SurveyById($id: String!) {
    surveyById(id: $id) {
      id
      name
      locale {
        id
        name
      }
      createdAt
      status
      factors {
        id
        name
        headline
        factorType {
          name
        }
        statements {
          id
        }
      }
    }
    questionnaire {
      id
      name
      description
      headline
      questions {
        id
      }
    }
  }
`

const DELETE_POST_SURVEY = gql`
  mutation deletePostSurvey($id: String!) {
    deletePostSurvey(id: $id) {
      status
    }
  }
`

const GET_ALL_FACTOR_TYPES = gql`
  query factorTypes {
    allFactoryTypes {
      id
      name
      code
      description
      color
    }
  }
`
