import { RouteComponentProps, useNavigate, useParams } from '@reach/router'
import HeadingSection from '../../../../components/HeadingSection'
import { Color } from '../../../../color.enum'
import Button from '../../../../components/Button'
import { ButtonVariant, ButtonSize } from '../../../../components/Button'
import { gql, useQuery } from '@apollo/client'
import { formatDate } from '../../../../services/formatDate'
import DemographicQuestionList from './demographicQuestionsList'
import Loader from '../../../../components/Loader/Loader'
import Alert, { AlertVariant, AlertSize } from '../../../../components/Alert'
import { HeadingPage } from '../../../../components'
import { useBreadcrumbs } from '../../../../hooks/useBreadcrumbs'
import BreadCrumbs from '../../../../components/BreadCrumbs/BreadCrumbs'
import { Helmet } from 'react-helmet'

interface SingleDemographicDetails {
  headline: string
  description: string
  createdAt: any
  locale: string
}

const Details: React.FC<SingleDemographicDetails> = ({ headline, description, createdAt, locale }) => {
  return (
    <div className="mt-5 space-y-1">
      {headline && (
        <div>
          <span className="inline text-brand-blue font-extrabold">Headline:</span>
          <span className="ml-1 prose">{headline}</span>
        </div>
      )}
      {description && (
        <div>
          <span className="inline text-brand-blue font-extrabold">Description:</span>
          <span className="ml-1 prose">{description}</span>
        </div>
      )}
      {createdAt && (
        <div>
          <span className="inline text-brand-blue font-medium">Date Created:</span>
          <span className="ml-1">{createdAt && formatDate(createdAt)}</span>
        </div>
      )}
      {locale && (
        <div>
          <span className="inline text-brand-blue font-medium">Locale:</span>
          <span className="ml-1">{locale}</span>
        </div>
      )}
    </div>
  )
}

const SingleDemographicSurvey: React.FC<RouteComponentProps> = () => {
  const { postSurveyId, surveyId } = useParams()
  const [getBreadCrumbs] = useBreadcrumbs()

  const { loading, data, refetch } = useQuery(GET_POST_SURVEY, {
    fetchPolicy: 'no-cache'
  })

  if (loading) return <Loader />

  const postSurvey = data?.questionnaire
  const navigate = useNavigate()
  const bc = getBreadCrumbs('demographic', surveyId, '', 'Participant Questionnaires')

  const questions = postSurvey?.questions.length
    ? postSurvey.questions.sort((a: any, b: any) => a.position - b.position)
    : []

  return (
    <div className="page-container-lg my-8">
      <Helmet>
        <title>{postSurvey?.name}</title>
        <meta name="description" content={postSurvey?.description} />
        <meta name="robots" content="noindex" data-react-helmet="true" />
      </Helmet>
      <main>
        <BreadCrumbs pages={bc} className="py-0 pb-10" />
        <HeadingPage
          headline={postSurvey?.name ? postSurvey?.name : ''}
          color={Color.GRAY}
          description={
            <Details
              headline={postSurvey?.headline}
              description={postSurvey?.description}
              createdAt={postSurvey?.createdAt}
              locale={postSurvey?.locale?.name}
            />
          }
          children={() => {
            return (
              <div className="flex items-center">
                <Button
                  variant={ButtonVariant.PRIMARY}
                  color={Color.BLUE}
                  size={ButtonSize.SMALL}
                  onClick={() =>
                    navigate(`/dashboard/admin/survey/${surveyId}/demographic-survey/${postSurvey.id}/participant/edit`)
                  }
                >
                  Edit
                </Button>
              </div>
            )
          }}
        />

        <HeadingSection
          headline="Demographic Questions"
          color={Color.BLUE}
          className="mb-8"
          children={() => (
            <Button
              className="mt-3"
              iconRight="plus"
              variant={ButtonVariant.PRIMARY}
              color={Color.BLUE}
              size={ButtonSize.SMALL}
              onClick={() => navigate(`/dashboard/admin/survey/${surveyId}/postSurvey/${postSurveyId}/question/create`)}
            >
              Add question
            </Button>
          )}
        />
        {questions.length > 0 ? (
          <DemographicQuestionList
            questions={questions}
            surveyId={surveyId}
            type="participant"
            demoId={postSurveyId}
            surveyStatus={postSurvey?.survey?.status}
            refetch={refetch}
          />
        ) : (
          <Alert size={AlertSize.LARGE} variant={AlertVariant.WARNING} className="my-4">
            Questionnaire has no questions
          </Alert>
        )}
      </main>
    </div>
  )
}

export default SingleDemographicSurvey

const GET_POST_SURVEY = gql`
  query postSurveyById {
    questionnaire {
      id
      name
      description
      headline
      questions {
        id
        text
        code
        responseType
        position
        postSurveyOption {
          id
          text
          index
          code
        }
      }
    }
  }
`
