import React from 'react'
import { classNames } from '../../utils/class-names'
import { Width } from './types'
import { styles } from './styles'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  /** Aditional styles for container */
  className?: string
  /** Children are displayed as the text of the label */
  children?: React.ReactElement | string
  /** Type of Input */
  as?: 'input' | 'textarea'
  /** Variant of input */
  variant?: 'standard'
  /** Width of input */
  width?: keyof Width
  /** Number of rows for textarea */
  rows?: number
  /** Placeholder for input */
  placeHolder?: string
}

export const Input: React.FC<InputProps> = ({
  className,
  as = 'input',
  variant = 'standard',
  width = 'sm',
  ...props
}: InputProps) => {
  const combinedStyles = classNames(styles[variant], styles.widths[width], className)

  if (as === 'input') {
    return <input className={combinedStyles} {...props} />
  }

  return <textarea className={combinedStyles} {...props} />
}

export default Input
