import { useContext, useEffect, useState } from 'react'
import { Link, navigate, RouteComponentProps, useMatch } from '@reach/router'
import { Context } from '../../context/context'
import logo from '../../../public/collectiveLogo.png'
import { useUserQuery } from '../../hooks/useUserQuery'
import Button, { ButtonVariant, ButtonSize } from '../Button'
import { Color } from '../../color.enum'
import { timeBeforeExpiry } from '../../services/formatDate'
import Header from '../Header/Header'
import { useCheckLogin } from '../../hooks/useCheckLogin'
import DropDown from '../DropDown/DropDown'
import HeaderNavItemWrapper from '../HeaderNavItem/HeaderNavItemWrapper'

export const roleMenuItems = (
  user?: any,
  assignmentExpire?: string,
  timeOfExpiration?: string,
  windowWidth?: number
) => {
  const isMobile = windowWidth ? windowWidth < 640 : false
  const items: any = {
    admin: {
      buttonProps: {
        buttonType: 'standard',
        size: ButtonSize.SMALL,
        color: Color.PURPLE,
        variant: ButtonVariant.PRIMARY,
        buttonLabel: 'Sign out',
        iconLeft: 'signIn',
        onClick: () => {
          localStorage.removeItem('osp-token')
          navigate('https://fiftyfourcollective.com/organisation-assessments/')
        }
      },
      items: [
        { label: 'Assessments', to: '/dashboard', description: 'View a list of all users', hidden: true },
        {
          label: 'Users',
          to: '/dashboard/admin/users',
          description: 'View list of all registered users',
          hidden: false
        },
        {
          label: 'Organisations',
          to: '/dashboard/peer/organisations',
          description: 'View a list of all organisations',
          hidden: false
        },
        { label: 'Peers', to: '/dashboard/admin/peers', description: 'Add/Edit/View peers', hidden: false },
        { label: 'Surveys', to: '/dashboard/admin/surveys', description: 'Add/Edit/View surveys', hidden: false },
        { label: 'Reports', to: '/dashboard/admin/reports', description: 'Download csv reports', hidden: false },
        { label: 'Partner Assessments', to: '/dashboard/peer/scans', description: '', hidden: true },
        { label: 'My Org Assessments', to: '/dashboard/scans/organisation', description: '', hidden: true },
        { label: 'My Assessments', to: '/dashboard/scans/my-scans', description: '', hidden: true }
      ],
      title: 'Admin Dashboard',
      heading: 'Dashboard'
    },
    peer: {
      buttonProps: {
        buttonType: 'standard',
        size: ButtonSize.SMALL,
        color: Color.PURPLE,
        variant: ButtonVariant.PRIMARY,
        buttonLabel: 'Sign out',
        iconLeft: 'signIn',
        onClick: () => {
          localStorage.removeItem('osp-token')
          navigate('https://fiftyfourcollective.com/organisation-assessments/')
        }
      },
      items: [
        { label: 'Dashboard', to: '/dashboard', description: '', hidden: true },
        {
          label: 'Organisations',
          to: '/dashboard/peer/organisations',
          description: 'View a list of all organisations',
          hidden: false
        },
        {
          label: 'Partner Assessments',
          to: '/dashboard/peer/scans',
          description: 'Add/Edit/View partner assessments',
          hidden: false
        },
        {
          label: 'My Org Assessments',
          to: '/dashboard/scans/organisation',
          description: `Complete setup of my organisation's assessments`,
          hidden: false
        },
        {
          label: 'My Assessments',
          to: '/dashboard/scans/my-scans',
          description: 'Participate in assessments',
          hidden: false
        }
      ],
      title: 'Peer Dashboard',
      heading: 'Dashboard'
    },
    organization_owner: {
      buttonProps: {
        buttonType: 'notice',
        icon: 'clock',
        color: Color.PURPLE,
        variant: ButtonVariant.PRIMARY,
        buttonLabel: 'Sign out',
        buttonIcon: 'signOut',
        headline: !isMobile && timeOfExpiration ? timeOfExpiration : '',
        description: !isMobile && assignmentExpire ? 'left to complete' : '',
        onClick: () => {
          localStorage.removeItem('osp-token')
          navigate('https://fiftyfourcollective.com/organisation-assessments/')
        }
      },
      items: [
        {
          label: 'FAQs',
          to: 'https://fiftyfourcollective.com/frequently-asked-questions/',
          external: true,
          description: '',
          hidden: true
        },
        {
          label: 'Settings',
          to: 'https://fiftyfourcollective.com/account-settings/',
          external: true,
          description: '',
          hidden: true
        },
        {
          label: 'Users',
          to: `/dashboard/organisation/users`,
          description: 'Add participants to assessment',
          hidden: false
        }
      ],
      title: 'Organisation Dashboard',
      heading: 'Dashboard'
    },
    respondent: {
      buttonProps: {
        buttonType: 'notice',
        icon: 'clock',
        color: Color.PURPLE,
        variant: ButtonVariant.PRIMARY,
        buttonLabel: 'Sign out',
        buttonIcon: 'signOut',
        headline: !isMobile && timeOfExpiration ? timeOfExpiration : '',
        description: !isMobile && assignmentExpire ? 'left to complete' : '',
        onClick: () => {
          localStorage.removeItem('osp-token')
          navigate('https://fiftyfourcollective.com/organisation-assessments/')
        }
      },
      items: [
        {
          label: 'FAQs',
          to: 'https://fiftyfourcollective.com/frequently-asked-questions/',
          external: true,
          description: '',
          hidden: true
        },
        {
          label: 'Settings',
          to: 'https://fiftyfourcollective.com/account-settings/',
          external: true,
          description: '',
          hidden: true
        }
      ]
    }
  }

  if (user.isSuperAdmin) {
    return items.admin
  } else if (user.isPeer) {
    return items.peer
  } else if (user.isOrgLeader) {
    return items.organization_owner
  } else {
    return items.respondent
  }
}

export const MainNav: React.FC<RouteComponentProps> = () => {
  const { contextValue } = useContext(Context) as any
  const { loading, data, refetch } = useUserQuery(false)
  const isLoggedIn = useCheckLogin() && data?.me
  const matchHome = useMatch('/')

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth)
  }
  const isSuperAdmin = data?.me?.isSuperAdmin

  useEffect(() => {
    refetch()
    window.addEventListener('resize', updateWindowWidth)
    return () => {
      window.removeEventListener('resize', updateWindowWidth)
    }
  }, [])

  if (loading) return null

  const assignmentExpire = timeBeforeExpiry(contextValue?.dueDate)
  const timeOfExpiration = assignmentExpire
    ? `${assignmentExpire.day}d ${assignmentExpire.hour}h ${assignmentExpire.minute}m`
    : ''

  const { items = [] } = data?.me ? roleMenuItems(data?.me, assignmentExpire, timeOfExpiration, windowWidth) : []

  const hardcodeItems = [
    { label: 'My Learning', to: 'https://fiftyfourcollective.com/my-learning/', external: true },
    {
      label: 'Resources',
      to: 'https://fiftyfourcollective.com/all-learning-resources/',
      external: true
    },
    { label: 'Live Learning', to: 'https://fiftyfourcollective.com/live-learning/', external: true },
    {
      label: 'Assessments',
      to: '/dashboard'
    }
  ]

  return (
    <Header
      loggedInUser={data?.me?.firstName || ' '}
      renderLogo={
        <a href="https://fiftyfourcollective.com/my-learning/" className="">
          <img className="block w-28 h-auto" src={logo} alt="FiftyFour Collective Logo" />
        </a>
      }
      renderQuickLinks={
        <>
          {isLoggedIn ? (
            <DropDown
              className="text-white"
              label={data?.me?.firstName || 'Menu'}
              options={[
                ...items.filter((item: any) => !item.to.includes('/admin') || isSuperAdmin),
                {
                  label: 'Logout',
                  isSignOut: true,
                  onSignOut: () => {
                    localStorage.removeItem('osp-token')
                    window.location.href =
                      'https://fiftyfourcollective.com/logout/?redirect_to=https://fiftyfourcollective.com/login/'
                  }
                }
              ]}
            />
          ) : null}
          {matchHome && !isLoggedIn && (
            <Button
              size={ButtonSize.SMALL}
              color={Color.PURPLE}
              variant={ButtonVariant.PRIMARY}
              iconLeft="signIn"
              onClick={() => {
                navigate('/login')
              }}
            >
              Sign in
            </Button>
          )}
        </>
      }
      renderNavigation={
        hardcodeItems.length && isLoggedIn ? (
          <>
            {windowWidth > 1280 ? (
              hardcodeItems.map((item: any) =>
                !item.to.includes('/admin') ? (
                  <HeaderNavItemWrapper key={item.label} to={item.to} isExternal={item.external} {...item}>
                    {item.label}
                  </HeaderNavItemWrapper>
                ) : null
              )
            ) : !isSuperAdmin ? (
              <DropDown label="Menu" options={hardcodeItems.filter((item: any) => !item.to.includes('/admin'))} />
            ) : null}

            {windowWidth < 1280 && isSuperAdmin && <DropDown label="Menu" options={hardcodeItems} />}
          </>
        ) : null
      }
    />
  )
}
