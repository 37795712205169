import { RouteComponentProps } from '@reach/router'
import { useQuery, gql } from '@apollo/client'
import { Color } from '../../../../color.enum'
import { User } from '../../../../common/types'
import MyScanList from './MyScanList'
import Loader from '../../../../components/Loader/Loader'
import Alert, { AlertVariant, AlertSize } from '../../../../components/Alert'
import { language } from '../../../../common/i18n'
import { PageWrapper } from '../../../../modules/PageWrapper'

interface OwnerProps extends RouteComponentProps {
  user?: User
  test?: string
}

export const MyScansContainer: React.FC<OwnerProps> = (props) => {
  const { loading, data } = useQuery(GET_MY_ASSIGNMENTS, {
    fetchPolicy: 'no-cache'
  })
  if (loading) return <Loader />
  const { myAssignedAssignments } = data

  return (
    <PageWrapper
      metaTitle="My Assessments"
      metaDesc={language.en.orgOwner.dashboard.description}
      headline="My Assessments"
      description="Welcome to your assessments where you can view the status and results of all of your current and past assessments."
      sectionHeading="Current Assessments"
    >
      {myAssignedAssignments.length > 0 ? (
        <MyScanList
          assignments={myAssignedAssignments}
          loading={loading}
          organizationId={props?.user?.organization?.id}
        />
      ) : (
        <div className="w-full">
          <Alert size={AlertSize.LARGE} variant={AlertVariant.WARNING} className="w-full">
            You do not have any assessments.
          </Alert>
        </div>
      )}
    </PageWrapper>
  )
}

const GET_MY_ASSIGNMENTS = gql`
  query myAssignedAssignments {
    myAssignedAssignments {
      id
      dueDate
      status
      name
      respondentCount
      preSurveyStatus
      isOwner
      respondentProfile {
        id
        surveyStatus
      }
      organization {
        id
      }
    }
  }
`
