import { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Box } from '@plusplusminus/plusplusdash'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate, RouteComponentProps, useLocation } from '@reach/router'
import { InputValidator } from '../../../services/validate'
import { Color } from '../../../color.enum'
import { ButtonVariant, ButtonSize } from '../../../components/Button'
import Button from '../../../components/Button'
import Icon from '../../../components/Icon/Icon'
import parse from 'html-react-parser'
import HeadingPageCenter from '../../../components/HeadingPageCenter'
import Loader from '../../../components/Loader/Loader'
import { COUNTRIES } from '../../../common/countries'
import { Select } from '../../../components/Select/Select'
import FormLabel from '../../../components/FormLabel/FormLabel'
import Input from '../../../components/Input/Input'
import { GET_A_PEER } from '../../../graphql/queries'
import Alert, { AlertSize, AlertVariant } from '../../../components/Alert'
import { User } from '../../../common/types'

const form = [
  {
    label: 'First Name',
    name: 'firstName',
    type: 'text',
    placeholder: 'First Name',
    options: {
      required: 'First name is required',
      validate: (input: string) =>
        new InputValidator(input).validateAlphaNumericWithSpace() || 'Enter a valid first name'
    }
  },
  {
    label: 'Last Name',
    name: 'lastName',
    placeholder: 'Last Name',
    options: {
      required: 'Last name is required',
      validate: (input: string) =>
        new InputValidator(input).validateAlphaNumericWithSpace() || 'Enter a valid last name'
    },

    type: 'text'
  },
  {
    label: 'Email',
    name: 'email',
    placeholder: 'Email',
    options: {
      required: 'Email is required',
      validate: (input: string) => new InputValidator(input).validateEmail() || 'Enter a valid email'
    },
    type: 'email'
  },
  {
    label: 'Organisation Name',
    name: 'peerName',
    placeholder: 'Peer name',
    options: {
      required: 'Organisation name is required',
      validate: (input: string) =>
        new InputValidator(input).validateAlphaNumericWithSpace() || 'Enter a valid peer name'
    },
    type: 'text'
  },
  {
    label: 'Current country of head office',
    name: 'country',
    placeholder: 'Country',
    options: {
      required: 'Country is required'
    },
    type: 'select',
    values: COUNTRIES.map((c) => ({ id: c, name: c }))
  },
  {
    label: 'Custom Message',
    name: 'customMessage',
    placeholder: 'Add custom message to be sent to peer',
    type: 'textArea'
  }
]

interface Props extends RouteComponentProps {
  onSubmit?: (data: any, reset: any, formType: string, e: any) => void
  loading: boolean
  success: any
  userId: string
  formType: string
  error?: string
  user?: User
}

export const AddPeerForm: React.FC<Props> = (props) => {
  const { pathname } = useLocation()
  const [message, setMessage] = useState('')
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control
  } = useForm({ mode: 'onChange', reValidateMode: 'onChange' })

  const onRegister = async (data: any, e: any) => {
    if (props.onSubmit) {
      await props.onSubmit({ ...data, message }, reset, props.formType, e)
      e.target.reset()
    }
  }

  const [loadPeerData, { loading }] = useLazyQuery(GET_A_PEER, {
    variables: { id: props.userId },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const { user } = data.getPeer
      const payload = {
        ...user[0],
        peerName: data.getPeer.name,
        country: data.getPeer.country
      }
      Object.keys(payload).forEach((u) => {
        setValue(u, payload[u])
      })
    }
  })

  useEffect(() => {
    if (props?.userId) {
      loadPeerData()
    }
    const type = pathname.endsWith('add-admin-as-peer') ? true : false
    if (type) {
      setValue('firstName', props.user?.firstName)
      setValue('lastName', props.user?.lastName)
      setValue('email', props.user?.email)
    }
  }, [])

  if (loading) return <Loader />

  return (
    <div>
      <HeadingPageCenter
        headline={props?.formType === 'edit' ? 'Edit Peer' : 'Add New Peer'}
        color={Color.BLUE}
        description={props?.formType === 'create' && parse('Please fill in the peer details below to add a new peer. ')}
      />
      <div className="section-container-sm">
        <form
          action="#"
          autoComplete="no"
          onSubmit={handleSubmit(onRegister)}
          className="grid grid-cols-2 gap-y-4 sm:grid-cols-2 sm:gap-x-8"
        >
          {props.formType === 'edit' && (
            <>
              <Box className="col-span-2 sm:col-span-2">
                <FormLabel>Name</FormLabel>
                <Controller
                  name="peerName"
                  control={control}
                  render={({ field }) => <Input as="input" width="full" {...field} />}
                />
              </Box>
              <Box className="col-span-2 sm:col-span-2">
                <FormLabel>Current country of head office</FormLabel>
                <Select items={form[4].values} name="country" register={register} />
              </Box>
            </>
          )}
          {props.formType !== 'edit' &&
            form.map((field) => {
              if (field.type === 'select') {
                return (
                  <Box className="col-span-2 sm:col-span-2" key={field.name}>
                    <FormLabel>{field.label}</FormLabel>
                    <Select validations={field.options} items={field.values} name={field.name} register={register} />
                    {errors[field.name]?.message && (
                      <p className="text-sm text-red-500">{errors[field.name].message}</p>
                    )}
                  </Box>
                )
              }

              if (field.type === 'text' || field.type === 'email') {
                return (
                  <Box className="col-span-2 sm:col-span-2" key={field.name}>
                    <FormLabel>{field.label}</FormLabel>
                    <Controller
                      rules={{ ...field.options }}
                      {...register(field.name)}
                      control={control}
                      render={({ field }) => <Input as="input" width="full" {...field} />}
                    />
                    {errors[field.name]?.message && (
                      <p className="text-sm text-red-500">{errors[field.name].message}</p>
                    )}
                  </Box>
                )
              }

              if (field.type === 'textArea') {
                return (
                  <Box className="col-span-2 sm:col-span-2">
                    <FormLabel>{field.label}</FormLabel>
                    <Input
                      as="textarea"
                      width="full"
                      name={field.name}
                      onChange={(event) => setMessage(event.target.value)}
                    />
                  </Box>
                )
              }
            })}
          <Box className="col-span-2 sm:col-span-2">
            {props.error ? (
              <Alert
                size={AlertSize.SMALL}
                variant={AlertVariant.ERROR}
                children={<div>{props.error}</div>}
                className="w-full"
              />
            ) : null}
            {props.success.message ? (
              <Alert
                size={AlertSize.SMALL}
                variant={AlertVariant.SUCCESS}
                className="w-full"
                children={<div>{props.success.message}</div>}
              />
            ) : null}
          </Box>

          <div className="flex space-x-2 col-span-2">
            <Button
              className="flex-1 justify-center align-middle my-2"
              variant={ButtonVariant.PLAIN}
              color={Color.BLUE}
              size={ButtonSize.LARGE}
              style={{ width: '100%' }}
              onClick={() => {
                navigate(
                  props.formType === 'create' ? '/dashboard/admin/peers' : `/dashboard/admin/peers/${props.userId}`
                )
              }}
            >
              <Icon className="pr-1" name="arrowLeft" />
              Back
            </Button>
            <Button
              className="flex-1 justify-center align-middle mb-2"
              color={Color.BLUE}
              size={ButtonSize.LARGE}
              type="submit"
              variant={ButtonVariant.PRIMARY}
              disabled={props.loading}
            >
              {props.loading ? 'Loading...' : 'Submit'}
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}
