import React, { Fragment } from 'react'
import { classNames } from '../../utils/class-names'
import { Disclosure, Transition } from '@headlessui/react'
import Button from '../Button/Button'
import { ButtonSize, ButtonVariant } from '../Button/types'
import { Color } from '../../color.enum'

export interface HeaderProps {
  /** Links to be added to the main bar - visible on mobile */
  renderQuickLinks?: React.ReactElement | JSX.Element
  /** Logo for the Nav bar */
  renderLogo?: React.ReactElement
  /** Navigation items for the Nav bar */
  renderNavigation?: React.ReactElement | React.ReactNode
  /** Aditional Styles for the container */
  className?: string
  loggedInUser?: string
}

const Header: React.FC<HeaderProps> = ({
  className,
  renderLogo,
  renderNavigation,
  renderQuickLinks,
  loggedInUser
}: HeaderProps) => {
  return (
    <header className={classNames(className, 'Header top-0 w-full z-40')}>
      <Disclosure as="header">
        {({ open }) => (
          <>
            <div className="w-full bg-brand-blue">
              <div className={classNames(`max-w-7xl mx-auto px-2 sm:px-4 lg:px-8 py-5`)}>
                <div className="flex-wrap items-center flex justify-between">
                  <div className="z-10 flex lg:pr-0">
                    <div className="flex items-center">{renderLogo && renderLogo}</div>
                  </div>

                  {renderNavigation || renderQuickLinks ? (
                    <div className="relative z-10 items-center hidden">
                      <Disclosure.Button>
                        <span className="sr-only">Open menu</span>
                        {open ? (
                          <Button
                            variant={ButtonVariant.PLAIN}
                            iconRight="close"
                            color={Color.BLUE}
                            size={ButtonSize.SMALL}
                          >
                            Close
                          </Button>
                        ) : (
                          <Button
                            variant={ButtonVariant.PLAIN}
                            iconRight="menu"
                            color={Color.BLUE}
                            size={ButtonSize.SMALL}
                          >
                            Menu
                          </Button>
                        )}
                      </Disclosure.Button>
                    </div>
                  ) : null}
                  <div className="z-10 flex items-center sm:space-x-2 space-x-1 ">
                    {renderQuickLinks && renderQuickLinks}
                  </div>
                </div>
              </div>
              {renderNavigation && (
                <div className="w-full bg-[#eaecf4] py-3">
                  <div className={classNames(`max-w-7xl mx-auto px-2 sm:px-4 lg:px-8`)}>
                    <div className="flex flex-row h-12 sm:h-20 items-center justify-between">
                      <h2 className=" flex pr-0 text-brand-blue font-bold text-xl sm:text-3xl py-2.5 flex-shrink-0">
                        Welcome, {loggedInUser}
                      </h2>
                      <div>
                        <nav className="flex md:space-x-4 items-center justify-center" aria-label="Global">
                          {renderNavigation}
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <Transition
              show={open}
              as={Fragment}
              enter="transition duration-200 ease-out"
              enterFrom="transform  opacity-0"
              enterTo="transform  opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform opacity-100"
              leaveTo="transform  opacity-0"
            >
              <Disclosure.Panel as="nav" className="lg:hidden" aria-label="Global">
                {renderNavigation && (
                  <div className="pt-2 pb-3 px-2 space-y-4 flex flex-col bg-[#eaecf4]">{renderNavigation}</div>
                )}
                {renderQuickLinks && (
                  <div className="border-t border-gray-200 pt-4 pb-3">
                    <div className="px-2 flex items-center space-x-2">{renderQuickLinks}</div>
                  </div>
                )}
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </header>
  )
}

export default Header
