import { useEffect, useState } from 'react'
import { RouteComponentProps, useNavigate } from '@reach/router'
import { useMutation, useLazyQuery, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { User } from '../../../common/types'
import { Color } from '../../../color.enum'
import Button, { ButtonVariant, ButtonSize } from '../../../components/Button'
import Loader from '../../../components/Loader/Loader'
import DatePicker from '../../../components/DatePicker/DatePicker'
import dayjs from 'dayjs'
import FormLabel from '../../../components/FormLabel/FormLabel'
import { Box } from '@plusplusminus/plusplusdash'
import { PageWrapper } from '../../../modules/PageWrapper'
import HeadingSection from '../../../components/HeadingSection'
import Notice, { NoticeVariant } from '../../../components/Notice'
import Modal from '../../../components/Modal/Modal'
import AddOrganizations from '../../Organisations/AddOrganizations'
import { Input } from '../../../components/Input/Input'
import moment from 'moment'
import { useUserQuery } from '../../../hooks/useUserQuery'
import Steps from '../../../components/Steps'

interface CreateAssignmentProps extends RouteComponentProps {
  user?: User
  setStep: (step: { step: string; props: any }) => void
}

interface Org extends RouteComponentProps {
  name: string
  id: string
  owner: {
    firstName: string
    lastName: string
    email: string
  }
}

export const CreateScan: React.FC<CreateAssignmentProps | any> = (props) => {
  const { loading, data, refetch } = useUserQuery()
  useEffect(() => {
    refetch()
  }, [])

  if (loading) return <Loader color={Color.BLUE} />

  return <CreateScanForm {...props} user={data.me} />
}

const CreateScanForm: React.FC<CreateAssignmentProps | any> = (props) => {
  const [errors, setErrors] = useState<any>(null)
  const [showCreate, setShowCreate] = useState(false)
  const [showSearch, setSearch] = useState(false)
  const [org, setOrg] = useState<Org>(props.user.organization)
  const [dueDate, setDueDate] = useState<any>()
  const [customMessage, setCustomMessage] = useState<string>('')
  const [scanName, setScanName] = useState<string>('')
  const [scanStatus, setScanStatus] = useState<string>('')

  const navigate = useNavigate()
  const [createScan, { loading }] = useMutation(CREATE_SCAN, {
    onCompleted: (data) => {
      if (data?.createScan?.id) {
        if (props.user.isPeer) {
          navigate('/dashboard/peer/scans')
        } else {
          navigate(`/dashboard/organisation/scan/${data?.createScan?.id}/add-participants`)
        }
      }
    }
  })

  const [fetchScan, { loading: scanLoading }] = useLazyQuery(GET_ASSIGNMENT_DETAILS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data?.assignment) {
        const { organization, dueDate, invitationMessage, name, status } = data.assignment
        setDueDate(moment(dueDate))
        setScanName(name)
        setCustomMessage(invitationMessage)
        setOrg(organization)
        setScanStatus(status)
      }
    }
  })

  useEffect(() => {
    if (props.scanId) {
      fetchScan({ variables: { id: props.scanId } })
    }
  }, [])

  const onSubmit = () => {
    setErrors(null)
    const requiredFields = {
      name: scanName,
      organizationId: org?.id
    }

    const err: any = {}
    let key: keyof typeof requiredFields

    const errMap: any = {
      name: 'Assessment name',
      dueDate: 'Due date',
      organizationId: 'Organisation'
    }

    for (key in requiredFields) {
      if (!requiredFields[key]) {
        err[key] = { name: errMap[key], msg: 'Required field' }
      }
    }

    if (Object.keys(err).length) {
      setErrors(err)
      return
    }

    createScan({
      variables: {
        //peerId: props.user.peer.id,
        input: {
          ...requiredFields,
          dueDate: dueDate ? dayjs(dueDate).format('YYYY-MM-DD') : null,
          customMessage,
          scanId: props?.scanId || null
        }
      }
    })
  }

  const formatError = () => {
    const errArray = Object.values(errors).map((err: any) => err.name)
    return errArray.join(', ')
  }

  if (scanLoading) return <Loader />

  const steps = props.user.peer
    ? [{ title: 'Assessment Details', isComplete: false, color: Color.BLUE }]
    : [
        { title: 'Assessment Details', isComplete: false, color: Color.BLUE },
        { title: 'Assessment Participants', isComplete: false, color: Color.BLUE },
        { title: 'Organisation Information', isComplete: false, color: Color.BLUE }
      ]

  return (
    <>
      <PageWrapper
        metaTitle="9D Capacity Index - Create Assessment"
        headline="Begin your 9D Capacity Index"
        metaDesc="metaDesc"
        description="Name your assessment and pick a date to pave the way for a thorough assessment. You'll soon be prompted to gather knowledgeable participants and detailed information about your organisation, essential for a meaningful analysis across our nine organizational health dimensions."
        sectionHeading=""
        Steps={<Steps steps={steps} activeDimension={0} />}
      >
        <Box className="mb-8">
          <Box className="mt-6 grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-8">
            <Box className="col-span-1 sm:col-span-1" key="name">
              <FormLabel className="text-base font-medium my-1 inline-block">Assessment Name</FormLabel>
              <Input
                as="input"
                min={1}
                width="full"
                type="text"
                placeHolder="Enter assessment name"
                onChange={(e) => setScanName(e.target.value)}
                value={scanName}
              />
              <div className="text-xs py-1 text-brand-blue">
                The name will be used to identify the report. We advise naming the assessment after the group who will
                be taking this assessment.
              </div>
              {errors?.name && <p className="text-sm text-red-500">{errors.name.msg}</p>}
            </Box>

            <Box className="col-span-1 sm:col-span-1" key="name">
              <FormLabel className="text-base font-medium text-brand-blue my-1 inline-block">
                Assessment Close Date
              </FormLabel>
              <DatePicker selectedDate={dueDate} selectDate={(d) => setDueDate(d)} />
              <div className="text-xs py-1 text-brand-blue">
                Set an assessment close date or leave blank for automatic closure one year after creation. We recommend
                a minimum assessment period of 14 days.
              </div>
              {errors?.dueDate && <p className="text-sm text-red-500">{errors.dueDate.msg}</p>}
            </Box>
          </Box>
        </Box>
        {!scanStatus && props.user.peer ? (
          <>
            <HeadingSection
              headline="Organisation"
              color={Color.BLUE}
              children={() => {
                return (
                  <div className="flex space-x-4">
                    <Button
                      variant={ButtonVariant.PRIMARY}
                      color={Color.BLUE}
                      size={ButtonSize.SMALL}
                      iconRight="plus"
                      onClick={() => {
                        setShowCreate(true)
                      }}
                    >
                      Add new
                    </Button>
                    <Button
                      variant={ButtonVariant.PRIMARY}
                      color={Color.BLUE}
                      size={ButtonSize.SMALL}
                      iconRight="search"
                      onClick={() => {
                        setSearch(true)
                      }}
                    >
                      Add existing
                    </Button>
                  </div>
                )
              }}
            />
            {!org ? (
              <Notice variant={NoticeVariant.SMALL} className="py-6 flex-1.5 text-brand-orange" icon="info">
                Please add a organisation. Only one organisation allowed per assessment.
              </Notice>
            ) : (
              <Notice variant={NoticeVariant.SMALL} className="py-6 flex-1.5 text-brand-orange" icon="info">
                <div className="mb-4 flex text-md text-brand-blue justify-between">
                  <div className="flex flex-col">
                    <div className="text-md text-brand-blue mt-1">
                      <div className="text-md text-brand-blue mt-1">
                        <div>
                          <span className="font-medium prose text-brand-blue">Organisation Name:</span> {org.name}
                        </div>
                        <div>
                          <span className="font-medium prose text-brand-blue">Organisation representative :</span>{' '}
                          {org.owner.firstName + ' ' + org.owner.lastName}
                        </div>
                        <div>
                          <span className="font-medium prose text-brand-blue">Organisation representative email :</span>{' '}
                          {org.owner.email}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Notice>
            )}
            <AddNewOrgModal
              showCreate={showCreate}
              showSearch={showSearch}
              setShowCreate={setShowCreate}
              setSearch={setSearch}
              assignmentId={props.assignmentId}
              peerId={''}
              setOrg={setOrg}
            />
            <HeadingSection headline="Invite" color={Color.BLUE} />
            <div key="custom-message-box" className="mb-8">
              <label htmlFor="customMessage" className="text-base font-medium text-brand-blue my-1 inline-block">
                An email will be sent to the Organisation member inviting them to start the assessment. Your custom
                message will be added to their invitation.
              </label>

              <Input
                as="textarea"
                width="full"
                onChange={(e) => setCustomMessage(e.target.value)}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                rows="5"
                value={customMessage}
              />
            </div>
            <Notice variant={NoticeVariant.SMALL} className="py-6 flex-1.5 text-brand-blue" icon="info">
              Organisation and invitation message cannot be changed after the scan has been created
            </Notice>
          </>
        ) : (
          <Notice variant={NoticeVariant.SMALL} className="py-6 flex-1.5 text-brand-orange" icon="info">
            <div className="mb-4 flex text-md text-brand-blue justify-between">
              <div className="flex flex-col">
                <div className="text-md text-brand-blue mt-1">
                  <div className="text-md text-brand-blue mt-1">
                    <div>
                      <span className="font-medium prose text-brand-blue">Organisation Name:</span> {org.name}
                    </div>
                    <div>
                      <span className="font-medium prose text-brand-blue">Organisation owner :</span>{' '}
                      {org.owner.firstName + ' ' + org.owner.lastName}
                    </div>
                    <div>
                      <span className="font-medium prose text-brand-blue">Organisation owner email :</span>{' '}
                      {org.owner.email}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Notice>
        )}

        <Button
          variant={ButtonVariant.PRIMARY}
          color={Color.BLUE}
          size={ButtonSize.LARGE}
          onClick={onSubmit}
          disabled={loading}
          iconRight="arrowRight"
        >
          {loading ? 'Loading' : scanStatus && props.user.peer ? 'Save' : 'Next'}
        </Button>
        {errors && <p className="text-sm text-red-500 py-6">Following information required above: {formatError()}</p>}
      </PageWrapper>
    </>
  )
}

interface ModalProps extends RouteComponentProps {
  showCreate: boolean
  showSearch: boolean
  setShowCreate: (showCreate: boolean) => void
  setSearch: (showSearch: boolean) => void
  assignmentId: string
  peerId: string
  setOrg: (org: Org) => void
}

const AddNewOrgModal: React.FC<ModalProps> = ({
  showCreate,
  showSearch,
  setShowCreate,
  setSearch,
  assignmentId,
  peerId,
  setOrg
}) => {
  const [error, setErrors] = useState<any>()
  const { loading: orgsLoading, data: orgs } = useQuery(GET_ORGS, {
    fetchPolicy: 'no-cache',
    variables: { peerId: peerId },
    onCompleted: (data: any) => {
      setErrors('')
      if (!data?.organizationsByName?.length) {
        setErrors('No organisations found')
      }
    }
  })

  return (
    <>
      <Modal isModalOpen={showCreate} title="" onClose={() => setShowCreate(false)}>
        <div className="flex-1 flex flex-col">
          <AddOrganizations
            assignmentId={assignmentId}
            setShowCreate={setShowCreate}
            setOrg={setOrg}
            className=""
            inModal={true}
          />
        </div>
      </Modal>
      <Modal isModalOpen={showSearch} title="Add existing organisation" onClose={() => setSearch(false)}>
        <div className="flex-1  flex flex-col">
          <div className="flex-0.5 mb-2 py-3">
            {orgs?.organizationsByName.length ? (
              orgs.organizationsByName.map((org: any) => {
                return (
                  <div className="flex text-md text-brand-blue justify-between">
                    <div className="mt-2">{`${org.name} `}</div>
                    <Button
                      variant={ButtonVariant.PRIMARY}
                      size={ButtonSize.SMALL}
                      color={Color.BLUE}
                      className="mt-2 "
                      onClick={() => {
                        setErrors('')
                        setOrg(org)
                        setSearch(false)
                      }}
                      disabled={orgsLoading}
                    >
                      {orgsLoading ? 'Loading' : 'Add'}
                    </Button>
                  </div>
                )
              })
            ) : (
              <p className="text-brand-red mt-3">No organisations with no assessments found</p>
            )}
            <p className="text-brand-red mt-3">{error && error}</p>
          </div>
        </div>
      </Modal>
    </>
  )
}

export const CREATE_ASSIGNMENT = gql`
  mutation createAssignment($input: CreateAssignmentInput!, $id: String, $peerId: String) {
    createAssignment(input: $input, assignmentId: $id, peerId: $peerId) {
      id
      minRespondents
      maxRespondents
    }
  }
`
export const CREATE_SCAN = gql`
  mutation createScan($input: CreateScanInput!, $peerId: String) {
    createScan(input: $input, peerId: $peerId) {
      id
    }
  }
`

const GET_ASSIGNMENT_DETAILS = gql`
  query GetAssignmentDetails($id: String!) {
    assignment(id: $id) {
      id
      name
      dueDate
      canViewResults
      canShareResults
      invitationMessage
      status
      organization {
        id
        name
        country
        owner {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`
const GET_ORGS = gql`
  query organizationsByName($peerId: String!) {
    organizationsByName(peerId: $peerId) {
      id
      name
      owner {
        firstName
        lastName
        email
      }
      assignment {
        id
      }
    }
  }
`
