import { RouteComponentProps, useNavigate, useParams } from '@reach/router'
import HeadingSection from '../../../../components/HeadingSection'
import { Color } from '../../../../color.enum'
import Button, { ButtonVariant, ButtonSize } from '../../../../components/Button'
import { gql, useQuery } from '@apollo/client'
import Dimension from '../../../Survey/MainSurvey/Factor'
import FactorTypeList from './factorType'
import StatementList from '../statements/statementList'
import Loader from '../../../../components/Loader/Loader'
import queryString from 'query-string'
import { ImageUpload } from './components/UploadImage'
import { HeadingPage } from '../../../../components'
import { useBreadcrumbs } from '../../../../hooks/useBreadcrumbs'
import BreadCrumbs from '../../../../components/BreadCrumbs/BreadCrumbs'
import { Helmet } from 'react-helmet'

interface SingleFactorInterface extends RouteComponentProps<any> {
  history: any
}

interface DetailsInterface {
  weight: number
  position: number
  factorType: StringConstructor
}

const Details: React.FC<DetailsInterface> = ({ weight, position, factorType }) => {
  return (
    <div className="mt-5 space-y-1">
      {weight ? (
        <div>
          <span className="inline text-brand-blue font-medium">Weight:</span>
          <span className="ml-1">{weight}</span>
        </div>
      ) : (
        ''
      )}
      {position && (
        <div>
          <span className="inline text-brand-blue font-medium">Position:</span>
          <span className="ml-1">{position}</span>
        </div>
      )}
      {factorType && (
        <div>
          <span className="inline text-brand-blue font-medium">Type:</span>
          <span className="ml-1">{factorType}</span>
        </div>
      )}
    </div>
  )
}

const SingleFactor: React.FC<SingleFactorInterface> = () => {
  const navigate = useNavigate()
  const { factorId, surveyId } = useParams()
  const [getBreadCrumbs] = useBreadcrumbs()
  const { loading, data, refetch } = useQuery(GET_A_SINGLE_FACTOR, {
    fetchPolicy: 'no-cache',
    variables: { id: factorId }
  })
  const createStatementUrl = queryString.stringifyUrl({
    url: `/dashboard/admin/survey/${surveyId}/${factorId}/create-statement`
  })
  const createFactorUrl = queryString.stringifyUrl({
    url: `/dashboard/admin/factor-type/${factorId}/create-archetype`
  })

  if (loading) return <Loader />

  const { factor } = data
  const bc = getBreadCrumbs('factor', factor?.survey?.id, factor?.survey?.name)

  return (
    <div className="page-container-lg my-8">
      <Helmet>
        <title>{factor?.name}</title>
        <meta name="description" content={factor?.name} />
        <meta name="robots" content="noindex" data-react-helmet="true" />
      </Helmet>
      <main>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="mb-4">
            <BreadCrumbs pages={bc} className="py-0 pb-10" />
            <HeadingPage
              headline={`${factor?.name.charAt(0).toUpperCase() + factor?.name.slice(1)}-${factor?.code}`}
              color={Color.BLUE}
              description={
                <Details weight={factor?.weight} position={factor?.position} factorType={factor?.factorType?.name} />
              }
              children={() => {
                return (
                  <div className="flex items-center">
                    <Button
                      variant={ButtonVariant.PRIMARY}
                      color={Color.BLUE}
                      size={ButtonSize.SMALL}
                      onClick={() => {
                        navigate(`/dashboard/admin/survey/${surveyId}/${factorId}/edit-factor`)
                      }}
                    >
                      Edit Dimension
                    </Button>
                  </div>
                )
              }}
            />
          </div>
          <div className="space-y-10">
            <div>
              <HeadingSection headline="Dimension preview" color={Color.BLUE} />
              <Dimension
                headline={factor?.description}
                image={factor?.image ? factor?.image : 'No image'}
                subtitle={factor?.name}
              />
            </div>

            <div>
              <HeadingSection headline="Dimension Image" color={Color.BLUE} />
              <ImageUpload factorId={factorId} refetch={refetch} />
            </div>
            <div className="mb-4">
              <HeadingSection headline="Archetype" color={Color.BLUE} />
              <FactorTypeList
                factorTypes={[factor?.factorType]}
                surveyStatus={factor?.survey?.status}
                refetch={refetch}
                hideRemoveButton={true}
              />
            </div>

            <div>
              <HeadingSection
                headline="Statements"
                color={Color.BLUE}
                children={() => (
                  <div className="flex items-center">
                    <Button
                      variant={ButtonVariant.PRIMARY}
                      iconRight="plus"
                      color={Color.BLUE}
                      size={ButtonSize.SMALL}
                      onClick={() => navigate(createStatementUrl)}
                    >
                      Add Statement
                    </Button>
                  </div>
                )}
              />
              <StatementList statements={factor?.statements} surveyStatus={factor?.survey?.status} refetch={refetch} />
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default SingleFactor

const GET_A_SINGLE_FACTOR = gql`
  query Factor($id: String!) {
    factor(id: $id) {
      id
      name
      headline
      code
      description
      discussionQuestions
      content
      weight
      position
      image
      factorType {
        name
        id
      }
      statements {
        id
        statement
        code
      }
      preSurveyOption {
        id
      }
      survey {
        id
        name
        status
      }
    }
  }
`
