import React from 'react'
import { Table, Box } from '@plusplusminus/plusplusdash'
import { useNavigate } from '@reach/router'
import Button from '../../../components/Button'
import { Color } from '../../../color.enum'
import { ButtonVariant, ButtonSize } from '../../../components/Button'
import { language } from '../../../common/i18n'
interface Peer {
  id: string
  name: string
  country: string
}


interface Peers {
  firstName: string
  lastName: string
  email: string
  role: string
  peer: Peer
  organization: {
    name: string
  }
}



interface ListItems {
  items: Array<Peers>
}

const TABLE_SHAPE = [
  { label: 'Name', key: 'firstName', isSortable: true },
  { label: 'Organisation', key: 'organization', isSortable: true },
  { label: '', key: 'id', isSortable: false }
]

const PeerList: React.FC<ListItems> = ({
  items
}) => {

  const navigate = useNavigate()

  return (
    <Box>
      <Table
        shape={TABLE_SHAPE}
      >
        {items?.map((item) => {
          return (
            <Table.Row
              key={item.peer.id}
              className="cursor-pointer"
              onClick={() => navigate(`/dashboard/admin/peers/${item.peer.id}`)}
            >
              <Table.Cell className="text-gray-900">
                {item?.firstName || "N/A"} {item?.lastName || "N/A"}
              </Table.Cell>
              <Table.Cell className="text-gray-900 flex flex-col">
                <span>{item?.peer?.name ? item.peer.name : '-'}</span>
                <span> {item?.email || "N/A"}</span>
              </Table.Cell>
              <Table.Cell className="text-gray-900">
                <div className="flex space-x-3">
                  {item?.peer?.id && (
                    <Button
                      variant={ButtonVariant.PLAIN}
                      color={Color.BLUE}
                      size={ButtonSize.SMALL}
                      onClick={() => navigate(`/dashboard/admin/peers/${item.peer.id}`)}
                      iconRight="arrowRight"
                    >
                      {language.en.admin.dashBoard.buttonText.viewPeer}
                    </Button>
                  )}
                </div>
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table>
    </Box>
  )
}

export default PeerList
