/* eslint-disable @typescript-eslint/no-var-requires */
import { useQuery } from '@apollo/client'
import { Document, Font, Image, Page, PDFDownloadLink, Text, View } from '@react-pdf/renderer'
import dayjs from 'dayjs'
import React, { useMemo } from 'react'
import { Color } from '../../../color.enum'
import { CONTENT_TYPES } from '../../../common/constants'
import { User } from '../../../common/types'
import Button, { ButtonSize, ButtonVariant } from '../../../components/Button'
import Content from '../../../components/pdf/Content'
import Results from '../../../components/pdf/Results'
import styles from '../../../components/pdf/styles'
import { ArchetypeProps } from '../../../components/Radar/Radar.helpers'
import client from '../../../contentful'
import { ReportQuery, useReportQuery } from '../../../generated'
import theme from '../../../theme'
import { REPORT_TEMPLATE_QUERY } from './contentful-queries'

interface PDFProps {
  assignmentId: string
  radarImage?: ArrayBuffer
  barChartImage?: any
  archetypes: Array<ArchetypeProps>
  statementCount: number
  isIndividual: boolean
  reportName?: string
  userRole?: string
  respondent?: User
  user?: User
}

interface ReportProps {
  report: Array<{ __typename: string; [x: string]: any }>
  data: ReportQuery['report']
}

Font.register({
  family: 'Open Sans',
  fonts: [
    { src: require('../../../fonts/OpenSans-Regular.ttf').default, fontWeight: 500 },
    { src: require('../../../fonts/OpenSans-Bold.ttf').default, fontWeight: 700 },
    { src: require('../../../fonts/OpenSans-Medium.ttf').default, fontWeight: 600 },
    { src: require('../../../fonts/OpenSans-Light.ttf').default, fontWeight: 400 }
  ]
})
Font.registerHyphenationCallback((word) => [word])

const LAYOUT_MAP = {
  [CONTENT_TYPES.LAYOUT_CONTENT]: Content,
  [CONTENT_TYPES.RESULTS]: Results
}

const Layout: React.FC<{
  layout: Array<{ __typename: string; [x: string]: any }>
  radarImage: PDFProps['radarImage']
  barChartImage: PDFProps['barChartImage']
  archetypes: PDFProps['archetypes']
  statementCount: PDFProps['statementCount']
  data: ReportProps['data']
  isIndividual: PDFProps['isIndividual']
}> = ({ layout, radarImage, archetypes, data, statementCount, barChartImage, isIndividual }) => {
  const modules = useMemo(() => {
    return layout
      .filter((l) => l && LAYOUT_MAP[l.__typename])
      .map((l, idx) => {
        const { __typename } = l
        const Component = LAYOUT_MAP[__typename]

        return (
          <View style={styles.layout} break={idx !== 0 && idx !== 1}>
            <Component
              {...l}
              key={l.sys.id}
              id={l.sys.id}
              styles={styles}
              {...(__typename === CONTENT_TYPES.RESULTS
                ? { radarImage, archetypes, data, statementCount, barChartImage, isIndividual }
                : {})}
            />
          </View>
        )
      })
  }, [layout])

  return <>{modules}</>
}

const Report: React.FC<PDFProps & ReportProps> = ({
  radarImage,
  barChartImage,
  report: layout,
  archetypes,
  data,
  statementCount,
  isIndividual,
  respondent
}) => {
  const participants = useMemo(() => {
    return data?.overview.averages.map((val) => val.participantId) ?? []
  }, [data?.overview])

  const d = new Date()
  const year = d.getFullYear()

  return (
    <Document>
      <Page size="A4" style={{ ...styles.page, justifyContent: 'center', alignItems: 'center' }}>
        <Image src={require('../../../../public/pdfLogo.png').default} style={styles.logo} />
        <Text style={{ fontSize: 24, color: theme.colors['brand-blue'], fontWeight: 'medium', marginBottom: 20 }}>
          {data.title}
        </Text>
        <Text style={{ fontSize: 14, fontWeight: 'medium', marginBottom: 20 }}>
          {dayjs(data.date).format('DD MMMM YYYY')}
        </Text>
        {!isIndividual && (
          <Text style={{ fontSize: 14, fontWeight: 'medium', marginBottom: 20 }}>
            Number of respondents: {participants.length}
          </Text>
        )}

        {isIndividual && (
          <Text style={{ fontSize: 14, fontWeight: 'medium', marginBottom: 20 }}>
            {respondent?.firstName} {respondent?.lastName}
          </Text>
        )}

        <Text style={{ fontSize: 14, fontWeight: 'medium' }}>
          {isIndividual ? 'Individual' : 'Organisation'} Report
        </Text>
      </Page>
      <Page size="A4" style={styles.page}>
        <Layout {...{ radarImage, barChartImage, layout, archetypes, data, statementCount, isIndividual }} />
        <Text
          style={[
            styles.text,
            styles.grey,
            styles.center,
            { position: 'absolute', bottom: 50, left: 30, width: '100%' }
          ]}
          render={({ pageNumber }) => pageNumber - 1}
          fixed
        />
        <View
          style={[
            styles.footer,
            styles.row,
            {
              position: 'absolute',
              bottom: 35,
              left: 30,
              width: '600px',
              paddingVertical: '1px',
              paddingHorizontal: '4px',
              margin: -30
            }
          ]}
          fixed
        >
          <View style={{ flexBasis: '80%', justifyContent: 'flex-end' }}>
            <Text style={{ color: 'white' }}>© {year} FiftyFour Collective. ALL RIGHTS RESERVED </Text>
          </View>
          <View style={{ flexBasis: '20%' }}>
            <Image src={require('../../../../public/collectiveLogo.png').default} style={styles.footerLogo} />
          </View>
        </View>
      </Page>
    </Document>
  )
}

const PDF: React.FC<PDFProps> = (props) => {
  const { data, loading } = useQuery(REPORT_TEMPLATE_QUERY, {
    variables: {
      slug:
        props.user?.isOrgLeader && !props.isIndividual
          ? 'lms-organisational-leader-report'
          : props?.userRole === 'peer'
          ? 'lms-peer-report'
          : 'lms-respondent-report'
    },
    client
  })
  const { data: reportData, loading: loadingReport } = useReportQuery({
    variables: { assignmentId: props.assignmentId, isIndividual: props.isIndividual },
    fetchPolicy: 'network-only'
  })
  if (loading || loadingReport || !reportData) return null

  const report = data.reportCollection?.items[0]?.layoutCollection.items

  const reportType = props.isIndividual ? 'Individual' : 'Organisation'

  const reportName = `9D-${props.reportName?.replace(/\s/g, '_').toLowerCase()}-report-${dayjs(new Date()).format(
    'YYYY-MM-DD'
  )}.pdf`

  return (
    <PDFDownloadLink
      document={<Report {...props} report={report} data={reportData.report} archetypes={props.archetypes} />}
      fileName={reportName}
    >
      {({ loading }) => (
        <Button
          size={ButtonSize.MEDIUM}
          variant={ButtonVariant.PLAIN}
          color={Color.BLUE}
          iconLeft="download"
          isDisabled={loading}
        >
          {loading ? 'Loading...' : `Download ${reportType} Report`}
        </Button>
      )}
    </PDFDownloadLink>
  )
}

export default PDF
