import { RouteComponentProps, useNavigate } from '@reach/router'
import { Color } from '../../../color.enum'
import Button from '../../../components/Button'
import { ButtonVariant, ButtonSize } from '../../../components/Button'
import SurveyList from './surveyList'
import { gql, useQuery } from '@apollo/client'
import Loader from '../../../components/Loader/Loader'
import { language } from '../../../common/i18n'
import Alert, { AlertSize, AlertVariant } from '../../../components/Alert'
import { PageWrapper } from '../../../modules/PageWrapper'

const Surveys: React.FC<RouteComponentProps> = () => {
  const navigate = useNavigate()
  const { data, loading, refetch } = useQuery(GET_ALL_SURVEYS)
  const breadCrumbPath = [
    { name: 'Dashboard', path: '/dashboard', current: false },
    { name: 'Surveys', path: '/dashboard/admin/surveys', current: true }
  ]

  return (
    <PageWrapper
      metaTitle={language.en.admin.survey.list.title}
      metaDesc={language.en.admin.survey.list.description}
      breadCrumbs={breadCrumbPath}
      headline="All Surveys"
      description={language.en.admin.survey.list.description}
      sectionHeading="Current Surveys"
      renderCta={() => {
        if (loading) return <></>
        return (
          <div className="flex items-center">
            <Button
              variant={ButtonVariant.PRIMARY}
              color={Color.BLUE}
              size={ButtonSize.SMALL}
              onClick={() => navigate('/dashboard/admin/create-survey')}
              iconRight="plus"
            >
              Create Assessment
            </Button>
          </div>
        )
      }}
    >
      {loading ? (
        <Loader color={Color.BLUE} />
      ) : (
        <>
          {data?.surveys.length > 0 ? (
            <SurveyList surveys={data.surveys} refetch={refetch} />
          ) : (
            <Alert size={AlertSize.LARGE} variant={AlertVariant.INFO} className="mb-4">
              {language.en.admin.survey.emptyList}
            </Alert>
          )}
        </>
      )}
    </PageWrapper>
  )
}

export default Surveys

export const GET_ALL_SURVEYS = gql`
  query Surveys {
    surveys {
      id
      createdAt
      status
      name
      locale {
        name
        id
      }
      factors {
        id
        name
      }
    }
  }
`
