import React from 'react'
import Button, { ButtonSize, ButtonVariant } from '../Button'
import { classNames } from '../../utils/class-names'
import { Color } from '../../color.enum'
import { language } from '../../common/i18n'
import './styles.css'

interface TablePaginationProps {
  page?: number
  limit?: number
  nextPage?: () => void
  prevPage?: () => void
  totalPages?: number
  hasPrevPage?: boolean
  hasNextPage?: boolean
}

const TablePagination: React.FC<TablePaginationProps> = ({
  page,
  nextPage,
  prevPage,
  totalPages,
  hasNextPage,
  hasPrevPage
}) => {
  return (
    <div className={classNames('flex flex-col', !hasNextPage && !hasPrevPage ? 'hidden' : 'flex')}>
      <div className="Pagination flex items-center justify-between w-full">
        <div className="flex flex-1 justify-start">
          <Button
            iconLeft="arrowLeft"
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.SMALL}
            color={Color.BLUE}
            onClick={prevPage}
            disabled={!hasPrevPage}
          >
            {language.en.admin.dashBoard.buttonText.previous}
          </Button>
        </div>

        <div className="sm:flex hidden flex-1 flex-nowrap justify-center">
          <p className="text-brand-blue">
            {language.en.admin.dashBoard.buttonText.page}
            <span className="font-medium text-brand-blue">{` ${page} `}</span>
            {language.en.admin.dashBoard.buttonText.of} {totalPages}
          </p>
        </div>
        <div className="flex flex-1 justify-end">
          <Button
            iconRight="arrowRight"
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.SMALL}
            color={Color.BLUE}
            onClick={nextPage}
            disabled={!hasNextPage}
          >
            Next
          </Button>
        </div>
      </div>
      <div className="sm:hidden pt-2 pr-2 flex flex-1 flex-nowrap justify-end">
        <p className="text-brand-blue">
          {language.en.admin.dashBoard.buttonText.page}
          <span className="font-medium text-brand-blue">{` ${page} `}</span>
          {language.en.admin.dashBoard.buttonText.of} {totalPages}
        </p>
      </div>
    </div>
  )
}

export default TablePagination
