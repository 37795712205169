import { RouteComponentProps } from '@reach/router'
import { useQuery, gql } from '@apollo/client'
import { Color } from '../../../../color.enum'
import { User } from '../../../../common/types'
import OrgScanList from './OrgScanList'
import Loader from '../../../../components/Loader/Loader'
import Alert, { AlertVariant, AlertSize } from '../../../../components/Alert'
import { language } from '../../../../common/i18n'
import { PageWrapper } from '../../../../modules/PageWrapper'

interface OwnerProps extends RouteComponentProps {
  user?: User
  test?: string
}

export const OrgScansContainer: React.FC<OwnerProps> = (props) => {
  return <OrgScans {...props} />
}

const OrgScans: React.FC<OwnerProps> = (props) => {
  const { loading, data: orgAssignments } = useQuery(GET_ORG_ASSIGNMENTS, {
    fetchPolicy: 'no-cache',
    variables: { orgId: props?.user?.organization?.id }
  })

  if (loading) return <Loader color={Color.GRAY} />

  return (
    <PageWrapper
      metaDesc={language.en.orgOwner.dashboard.description}
      metaTitle={language.en.orgOwner.dashboard.title}
      headline="Organisational Assessments"
      sectionHeading="All Current Organisational Assessments"
    >
      {orgAssignments?.organizationAssignments.length > 0 ? (
        <OrgScanList
          assignments={orgAssignments?.organizationAssignments}
          loading={loading}
          organizationId={props?.user?.organization?.id}
        />
      ) : (
        <div className="w-full">
          <Alert size={AlertSize.LARGE} variant={AlertVariant.WARNING} className="w-full">
            You do not have any assessments assigned to you.
          </Alert>
        </div>
      )}
    </PageWrapper>
  )
}

const GET_ORG_ASSIGNMENTS = gql`
  query OrganizationAssignments($orgId: String!) {
    organizationAssignments(orgId: $orgId) {
      id
      dueDate
      status
      name
      respondentCount
      questionnaireStatus
      organization {
        name
      }
      preSurveyStatus
      respondentProfile {
        id
        surveyStatus
      }
    }
  }
`
