import React from 'react'

export type DataItem = {
  country: string
  organisation: string
  year: number
  avg: number
  factor: string
}

const DataContext = React.createContext<DataItem[]>([])

export default DataContext
