import React from 'react'

interface Progress {
  number_organisations: number
  number_scans: number
  completed_scans: number
  count_in_progress: number
  count_completed: number
  count: number
}

interface Props {
  progress: Progress
}

const StatItem: React.FC<{ label: string; value: number }> = ({ label, value }) => (
  <div className="flex items-center justify-between py-3 border-b">
    <p className="font-semibold">{label}</p>
    <p className="font-semibold">{value}</p>
  </div>
)

const StatsComponent: React.FC<Props> = ({ progress }) => {
  const calculateProgressPercentage = (): number => {
    const { completed_scans, number_scans } = progress
    if (number_scans === 0) {
      return 0
    }
    return Math.round((completed_scans / number_scans) * 100)
  }

  const calculateRespondentsProgressPercentage = (): number => {
    const { count_completed, count } = progress
    if (count === 0) {
      return 0
    }
    return Math.round((count_completed / count) * 100)
  }

  const scanProgressPercentage = calculateProgressPercentage()
  const respondentsProgressPercentage = calculateRespondentsProgressPercentage()

  return (
    <div className="mb-12">
      <div className="flex my-8">
        <div className="mr-4 bg-brand-blue-light border-2 p-4">
          <h2 className="mb-4 font-medium leading-normal text-1xl  text-white">Scan Progress</h2>
          <div className="w-64 h-4 bg-gray-200 rounded-full">
            <div className="h-full bg-brand-orange rounded-full" style={{ width: `${scanProgressPercentage}%` }}></div>
          </div>
          <p className="mt-2 text-white">{`${scanProgressPercentage}% Complete`}</p>
        </div>

        <div className="mr-4 bg-brand-blue-light border-2 p-4">
          <h2 className="mb-4 font-medium leading-normal text-1xl  text-white">Respondents Progress</h2>
          <div className="w-64 h-4 bg-gray-200 rounded-full">
            <div
              className="h-full bg-brand-orange rounded-full"
              style={{ width: `${respondentsProgressPercentage}%` }}
            ></div>
          </div>
          <p className="mt-2 text-white">{`${respondentsProgressPercentage}% Complete`}</p>
        </div>
      </div>

      <div>
        <h2 className="mb-4 font-medium leading-normal text-1xl text-brand-blue">Scan Status</h2>
        <StatItem label="Number of Organizations" value={progress.number_organisations} />
        <StatItem label="Total Scans" value={progress.number_scans} />
        <StatItem label="Completed Scans" value={progress.completed_scans} />
      </div>
    </div>
  )
}

export default StatsComponent
