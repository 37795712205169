import React, { useEffect, useRef } from 'react'

interface SliderProps {
  handleChange: any
  value?: number
  questionNumber: number
}

const Slider: React.FC<SliderProps> = ({ handleChange, value, questionNumber }) => {
  const trackSize = 15
  const maxVal = 100
  const getPercentage = (current: number, max: number): number => {
    return (100 * current) / max
  }
  const getValue = (percentage: number, max: number): number => {
    return (max / 100) * percentage
  }
  const getLeft = (percentage: number): any => {
    return `calc(${percentage}% - ${trackSize / 2}px)`
  }

  const sliderRef = useRef<HTMLInputElement>(null)
  const trackRef = useRef<HTMLInputElement>(null)
  const rightRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (trackRef.current && rightRef.current) {
      trackRef.current.style.left = '0%'
      rightRef.current.style.width = '0%'
    }
  }, [questionNumber])

  const handleMouseMove = (event: any): void => {
    const rectLeft = sliderRef?.current?.getBoundingClientRect()?.left
    let newX = rectLeft && event.clientX - rectLeft
    const start = 1
    const end = sliderRef.current?.offsetWidth

    if (newX && newX < start) {
      newX = 1
    }

    const offSetX = trackRef?.current?.offsetWidth

    if (newX && offSetX && end && newX > end - offSetX / 2) {
      newX = end
    }

    const newPercentage = newX && end && getPercentage(newX, end)

    const newValue = newPercentage && getValue(newPercentage, maxVal)
    if (trackRef?.current?.style && rightRef.current) {
      trackRef.current.style.left = newPercentage && getLeft(newPercentage)
      rightRef.current.style.width = `${newPercentage}%`
      rightRef.current.style.left = `calc(100% - ${newPercentage}%)`
    }
    handleChange(newValue?.toFixed(0))
  }

  const handleMouseUp = () => {
    if (trackRef?.current?.style) {
      trackRef.current.style.transform = 'scale(1.1)'
      document.removeEventListener('mouseup', handleMouseUp)
      document.removeEventListener('mousemove', handleMouseMove)
    }
  }

  const handleMouseDownSlider = (event: any) => {
    if (trackRef?.current?.style) {
      event.preventDefault()
      trackRef.current.style.transform = 'scale(1.1)'
      document.addEventListener('mousemove', handleMouseMove)
      document.addEventListener('mouseup', handleMouseUp)
      document.addEventListener('touchmove', handleMouseMove)
    }
  }

  return (
    <div className="cursor-pointer py-4" onClick={handleMouseMove} onMouseDown={handleMouseDownSlider}>
      <div ref={sliderRef} className="relative rounded h-[5px] bg-brand-blue-light cursor-pointer">
        <div
          className={`rounded obsolute h-full  bg-gradient-to-r from-brand-red via-brand-orange to-brand-purple`}
          style={{ width: '50%', left: '100%' }}
          ref={rightRef}
        ></div>
        <div
          ref={trackRef}
          className={`relative scale-100 rounded-full -top-2.5 bg-brand-orange cursor-pointer shadow-[0_1px_2px_rgba(211,211,211,0.5)] transition duration-150`}
          style={{
            width: `${trackSize}px`,
            height: `${trackSize}px`,
            boxShadow: '0 1px 3px rgba(211, 211, 211, 0.5),0 1px 2px rgba(211, 211, 211, 0.4)'
          }}
        ></div>
      </div>
      <div className="flex justify-between mt-3">
        <p className="font-medium text-brand-blue">Never</p>
        <p className="font-medium text-brand-blue">Always</p>
      </div>
    </div>
  )
}

export default Slider
