import { Image, Text, View } from '@react-pdf/renderer'
import { Style } from '@react-pdf/types'
import { useMemo } from 'react'

import { Color } from '../../../color.enum'
import { ReportQuery } from '../../../generated'

import { ArchetypeProps } from '../../Radar/Radar.helpers'
import Bar from '../Bar'
import { BarSize } from '../Bar/Bar'
import PDFRichText, { RichTextProps } from '../RichText'
import styles from '../styles'

interface ResultsProps {
  headline?: string
  content?: RichTextProps['content']
  styles: Record<string, Style>
  radarImage?: any
  barChartImage?: any
  archetypes?: Array<ArchetypeProps>
  data?: ReportQuery['report']
  statementCount?: number
  isIndividual?: boolean
  dimension: string
}

const ResultsWrapper: React.FC<ResultsProps> = (props) => {
  const { headline, content, dimension, styles, archetypes, data, radarImage } = props

  const participants = useMemo(() => {
    return data?.overview.averages.map((val) => val.participantId) ?? []
  }, [data?.overview])

  if (dimension === 'all') {
    return (
      <View break>
        <Text style={styles.heading}>{headline}</Text>
        {content && <PDFRichText content={content} />}
        {radarImage && (
          <Image
            style={{
              width: '250px',
              height: '250px',
              marginHorizontal: 'auto',
              transform: 'scale(1.8) translateY(20px)'
            }}
            src={Buffer.from(radarImage)}
          />
        )}
        {archetypes?.map((arch) => (
          <>
            {arch.dimensions.map((dim) => (
              <View style={{ paddingBottom: 12 }}>
                <Bar color={arch.color} headline={dim.name} scorePercentage={dim.score} size={BarSize.SMALL} />
              </View>
            ))}
          </>
        ))}
      </View>
    )
  }

  return (
    <View>
      {archetypes?.map((arch) => (
        <>
          {arch.dimensions
            .filter((dim) => dim.name.toLocaleLowerCase() === dimension.toLocaleLowerCase())
            .map((dim) => (
              <View style={{ paddingVertical: 10 }}>
                <Text style={[styles.heading, { color: arch.color, paddingTop: 10 }]}>{headline}</Text>
                <View style={{ paddingVertical: 10 }}>
                  {data ? (
                    <StatementsScore
                      color={arch.color}
                      data={data.detailedResults}
                      name={arch.name}
                      dim={dim.name}
                      participants={participants}
                    />
                  ) : null}
                </View>
                {content && <PDFRichText content={content} styleOverrides={{ header: { color: arch.color } }} />}
              </View>
            ))}
        </>
      ))}
    </View>
  )
}

const StatementsScore = (props: {
  data: ReportQuery['report']['detailedResults']
  participants: Array<string>
  name: string
  color: Color
  dim: string
}) => {
  const { data, name, color } = props

  return (
    <View style={styles.table}>
      {data.map((arch) => {
        if (arch.factorType === name) {
          return arch.factors
            .filter((f) => f.headline?.toLocaleLowerCase() === props.dim.toLocaleLowerCase())
            .map((f) => {
              return (
                <View style={{ padding: '20px' }}>
                  {f.statements.map((s) => {
                    return (
                      <View style={[styles.tableRow, { marginBottom: '5px', alignItems: 'center' }]}>
                        <Text
                          style={{
                            ...styles.tableCell,
                            width: '60%',
                            marginTop: '5px',
                            marginRight: '20px'
                          }}
                        >
                          {s.label}
                        </Text>
                        <View style={{ width: '35%' }}>
                          <Bar color={color} scorePercentage={s.average} size={BarSize.SMALL} />
                        </View>
                      </View>
                    )
                  })}
                </View>
              )
            })
        }
      })}
    </View>
  )
}

export default ResultsWrapper
