import { RouteComponentProps } from '@reach/router'
import { Color } from '../../../color.enum'
import { User } from '../../../common/types'
import AssignmentList from './AllAssignmentsList'
import { useQuery, gql } from '@apollo/client'
import Loader from '../../../components/Loader/Loader'
import { language } from '../../../common/i18n'
import { PageWrapper } from '../../../modules/PageWrapper'

interface AllAssignmentsProps extends RouteComponentProps {
  user?: User
  test?: string
}

export const AllAssignments: React.FC<AllAssignmentsProps> = () => {
  const { loading, data, refetch } = useQuery(GET_ALL_ASSIGNMENTS, {
    fetchPolicy: 'no-cache'
  })

  return (
    <PageWrapper
      headline="Assessments"
      sectionHeading="Current Assessments"
      description="You can search all assessments from multiple organisations"
      metaDesc={language.en.admin.assignments.description}
      metaTitle={language.en.admin.assignments.title}
    >
      {loading ? (
        <Loader />
      ) : (
        <AssignmentList
          items={data?.assignments?.docs}
          loading={loading}
          refetch={refetch}
          pagination={{
            currentPage: data?.assignments.page,
            totalPages: data?.assignments.totalPages,
            hasNextPage: data?.assignments.hasNextPage,
            hasPrevPage: data?.assignments.hasPrevPage
          }}
        />
      )}
    </PageWrapper>
  )
}

const GET_ALL_ASSIGNMENTS = gql`
  query Assignments($sort: SortArgs, $pagination: PaginationArgs, $input: QueryAssignmentsInput) {
    assignments(sort: $sort, pagination: $pagination, input: $input) {
      docs {
        id
        dueDate
        name
        status
        respondentCount
        organization {
          name
        }
        peer {
          name
        }
      }
      limit
      totalPages
      page
      hasPrevPage
      hasNextPage
    }
  }
`
