import { RouteComponentProps, useNavigate, useParams } from '@reach/router'
import { Color } from '../../../../color.enum'
import Button from '../../../../components/Button'
import { ButtonVariant, ButtonSize } from '../../../../components/Button'
import { gql, useQuery } from '@apollo/client'
import Loader from '../../../../components/Loader/Loader'
import queryString from 'query-string'
import { HeadingPage } from '../../../../components'
import Content from '../../../../components/Content'
import { useBreadcrumbs } from '../../../../hooks/useBreadcrumbs'
import BreadCrumbs from '../../../../components/BreadCrumbs/BreadCrumbs'
import { Helmet } from 'react-helmet'
import { language } from '../../../../common/i18n'

interface SingleStatementInterface extends RouteComponentProps<any> {
  history: any
}

interface DetailsInterface {
  statement?: React.ReactNode
  subDimension?: string
}

const Details: React.FC<DetailsInterface> = ({ statement, subDimension }) => {
  return (
    <>
      {statement && (
        <Content>
          <p className="text-md text-brand-blue">
            <span className="text-xl">{statement}</span>
          </p>
          <p className="text-md text-brand-blue">
            <span className="text-xl">{subDimension}</span>
          </p>
        </Content>
      )}
    </>
  )
}

const SingleSatement: React.FC<SingleStatementInterface> = () => {
  const navigate = useNavigate()
  const { statementId } = useParams()
  const [getBreadCrumbs] = useBreadcrumbs()
  const { loading, data } = useQuery(GET_SINGLE_STAMENT, {
    fetchPolicy: 'no-cache',
    variables: { id: statementId }
  })

  const editStatementUrl = queryString.stringifyUrl({
    url: `/dashboard/admin/statement/${statementId}/edit-statement`
  })

  if (loading) return <Loader />

  const { statement } = data

  const bc = getBreadCrumbs('statement', statement?.survey?.id, statement?.factor?.id)

  return (
    <div className="page-container-lg">
      <Helmet>
        <title>{language.en.admin.statement.title}</title>
        <meta name="description" content={language.en.admin.statement.description} />
        <meta name="robots" content="noindex" data-react-helmet="true" />
      </Helmet>
      <main>
        {loading ? (
          <Loader />
        ) : (
          <>
            <BreadCrumbs pages={bc} className="py-0 pb-10" />
            <HeadingPage
              headline={`${statement?.label} - ${statement?.code}`}
              color={Color.GRAY}
              description={<Details statement={statement?.statement} subDimension={statement?.subDimension} />}
              children={() => {
                return (
                  <Button
                    variant={ButtonVariant.PRIMARY}
                    color={Color.BLUE}
                    size={ButtonSize.SMALL}
                    onClick={() => {
                      navigate(editStatementUrl)
                    }}
                  >
                    Edit Statement
                  </Button>
                )
              }}
            />
          </>
        )}
      </main>
    </div>
  )
}

export default SingleSatement

const GET_SINGLE_STAMENT = gql`
  query _statement($id: String!) {
    statement(id: $id) {
      statement
      code
      position
      label
      subDimension
      factor {
        id
      }
      survey {
        id
      }
    }
  }
`
