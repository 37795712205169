export const language = {
  en: {
    components: {
      select: {
        defaultText: 'Please Select'
      }
    },

    site: {
      title: 'FiftyFour Collective | 9D Capacity Index',
      description: 'Understanding Your Capacity is Key to the Growth and Longevity of Your Organisation'
    },
    error: {
      requiredField: 'This field is required',
      otp: 'Enter a valid OTP'
    },
    auth: {
      title: {
        register: 'FiftyFour Collective | 9D Capacity Index',
        login: 'Click below to proceed to sign in with your existing FiftyFour profile.',
        otp: 'Please enter your OTP (One time password) here to log in:',
        otpSuccess: 'Check your email for your OTP'
      },
      description: {
        register: "Let's get started Welcome to the FiftyFour Collective | 9D Capacity Index",
        login: 'Please enter your email here to log in'
      },
      register: {
        headline:
          "You're one step away from starting your assessment. To ensure your data remains private and secure, please create a unique password for your account. This will register a FiftyFour profile for your organisation, which you can use to complete the assessment and access FiftyFour courses and events."
      }
    },

    admin: {
      dashBoard: {
        title: 'FiftyFour Collective | 9D Capacity Index - Admin Dashboard',
        description: 'View and Manage; Users, Organisations and Surveys',
        heading: 'Dashboard',

        navItems: {
          heading: {
            users: 'View a list of all Users',
            survey: 'Edit Assessments',
            organisations: 'View a list of all organisations',
            assessments: 'View a list of all assessments',
            reports: 'Reports'
          }
        },
        buttonText: {
          view: 'View',
          addUser: 'Add Superadmin',
          search: 'Search',
          clearReset: 'Clear/reset',
          next: 'Next',
          previous: 'Previous',
          page: 'Page',
          of: 'of',
          viewUser: 'View User',
          addPeer: 'Add Peer',
          viewPeer: 'View Peer'
        },
        users: {
          title: 'All Users',
          headline: 'Current Users'
        },
        peers: {
          headline: 'Peers',
          title: 'Current Peers',
          addPeerForm: {
            firstName: 'First Name',
            lastName: 'Last Name',
            email: 'Email',
            foundation: 'Foundation',
            country: 'Country'
          },
          singlePeerView: {
            description: 'See peer details below',
            title: 'View Peer Details',
            buttons: {
              editPeer: 'Edit Peer'
            },
            labels: {
              joinedOn: 'Joined on:',
              country: 'Country:',
              email: 'Email Address:',
              organisation: 'Organisation:',
              role: 'Role:'
            }
          }
        }
      },

      survey: {
        watchVideoAlert: 'Please watch at least 50% of the video before proceeding.',
        edit: {
          title: 'FiftyFour Collective | 9D Capacity Index - Edit Assessment',
          description: 'Edit the form to change the details of the assessment'
        },
        create: {
          title: 'FiftyFour Collective | 9D Capacity Index - Create Assessment',
          description: 'Fill the form to create a new assessment '
        },
        list: {
          title: 'FiftyFour Collective | 9D Capacity Index- Surveys',
          description: 'You can use this page to view and edit the details of the assessment.'
        },
        emptyList: 'You currently do not have any surveys',

        singleSurvey: {
          title: 'View single Assessment',
          description: 'Single assessment'
        },

        questionnaire: {
          add: {
            title: 'MI - Add new  Questionnaire',
            description: 'Add new  Questionnaire'
          },
          edit: {
            title: 'MI - Edit new  Questionnaire',
            description: 'Edit new  Questionnaire'
          }
        }
      },
      user: {
        singleUserView: {
          title: 'View user details',
          description: 'View user details'
        },
        add: {
          title: 'FiftyFour Collective | 9D Capacity Index - Add New Superadmin',
          description: 'Add New Superadmin'
        },
        edit: {
          title: 'FiftyFour Collective | 9D Capacity Index - Edit new user',
          description: 'Edit new user'
        },
        list: {
          title: 'FiftyFour Collective | 9D Capacity Index - Users',
          description: 'You can use this view to see users you have registered'
        }
      },
      peer: {
        dashBoard: {
          title: 'FiftyFour Collective | 9D Capacity Index - Peer Dashboard',
          description: 'View and Manage; Users, Organisations and Assessments'
        },
        singleUserView: {
          title: 'View peer details',
          description: 'View peer details'
        },
        add: {
          title: 'FiftyFour Collective | 9D Capacity Index - Add new peer',
          description: 'Add new peer'
        },
        edit: {
          title: 'FiftyFour Collective | 9D Capacity Index - Edit new peer',
          description: 'Edit new peer'
        },
        list: {
          title: 'FiftyFour Collective | 9D Capacity Index - Peers',
          description: 'You can use this view to add new peers, or view current peers.'
        },
        organisations: {
          title: 'Organisations',
          description: 'You can use this view to add new organisations, or view current organisations.',
          headline: 'Current Organisations'
        }
      },
      factor: {
        add: {
          title: 'FiftyFour Collective | 9D Capacity Index - Add factor',
          description: 'FiftyFour Collective | 9D Capacity Index Add new factor'
        },
        edit: {
          title: 'FiftyFour Collective | 9D Capacity Index - Edit factor',
          description: 'FiftyFour Collective | 9D Capacity Index Edit ne factor'
        }
      },

      option: {
        title: 'Assessment Option',
        description: 'Assessment Option detaits'
      },

      question: {
        create: {
          title: 'Create New Question',
          description: 'Fill form to add new question to questionare'
        },
        edit: {
          title: 'Edit Question',
          description: 'Change form fields values to edit'
        }
      },

      statement: {
        title: 'View statement details',
        description: 'View statement details',
        create: {
          title: 'Create New Statement',
          description: 'Fill the form to add new statement'
        },
        edit: {
          title: 'Edit Statement',
          description: 'Change form fields values to edit'
        }
      },

      factorType: {
        title: 'View factor type details',
        description: 'View factor type details',
        create: {
          title: 'Create New Archetype',
          description: 'Fill the form to add new factor type'
        },
        edit: {
          title: 'Edit Archetype',
          description: 'Change form fields values to edit'
        }
      },
      assignments: {
        title: ' FiftyFour Collective | 9D Capacity Index - Assignments',
        description: 'List of all assignments from belonging to different organisations'
      },
      organisations: {
        title: 'FiftyFour Collective | 9D Capacity Index - Organisations',
        description: 'List of all organisations'
      }
    },
    orgOwner: {
      dashboard: {
        title: 'Organisation Dashboard',
        description:
          'Welcome to your dashboard where you can view the status and results of all of your current and past assessments.'
      },
      assignment: {
        title: 'Respondent assesment',
        description: 'Create assessment, add repondents and fill pre-assesment',

        results: {
          title: 'FiftyFour Collective | 9D Capacity Index - Respondent assesment results',
          description: 'FiftyFour Collective | 9D Capacity Index - Respondent assesment results'
        }
      }
    },
    respondent: {
      dashboard: {
        title: 'Assessment Dashboard',
        description: 'You can use this view to participate in the assessments you have been invited to.'
      }
    },

    peers: {
      dashboard: {
        navItems: {
          heading: {
            users: 'View a list of all users',
            organisations: 'View a list of all Organisations',
            scans: 'View a list of all assessments'
          }
        }
      }
    },

    buttonText: {
      notApplicable: 'Not applicable',
      cancel: 'Cancel',
      confirm: 'Confirm',
      loading: 'Loading...',
      next: 'Next'
    }
  }
}
