import { Color } from "../../../../color.enum"
import Alert, { AlertSize, AlertVariant } from "../../../../components/Alert"
import { ButtonSize, ButtonVariant } from "../../../../components/Button"
import Button from "../../../../components/Button/Button"
import Modal from "../../../../components/Modal/Modal"

interface RemoveItemModalProps {
    handleClose: () => void
    handleConfirm: () => void
    isOpen: boolean
    loading?: boolean 
}

const RemoveItemModal: React.FC<RemoveItemModalProps> = ({handleClose, handleConfirm, isOpen, loading}) => {
    return (
        <Modal isModalOpen={isOpen} title="Please Confirm" onClose={handleClose}>
              
        <div>
          {

            !loading ? <>
              <p className="text-md text-brand-purple">
                Are you sure you want to remove this item?
              </p><div className="flex space-x-1  mt-3">
                <Button
                  className="flex-1 text-center justify-center outline-none"
                  variant={ButtonVariant.PLAIN}
                  color={Color.RED}
                  size={ButtonSize.LARGE}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  className="flex-1 text-center justify-center"
                  variant={ButtonVariant.PRIMARY}
                  color={Color.BLUE}
                  size={ButtonSize.LARGE}
                  onClick={handleConfirm}
                >
                  Confirm
                </Button>
              </div>
            </>
              : <Alert size={AlertSize.LARGE} variant={AlertVariant.INFO}>
                Removing Item ...
              </Alert>
          }
        </div>
            </Modal>
    )
}

export default RemoveItemModal
