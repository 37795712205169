import { RouteComponentProps, useNavigate } from '@reach/router'
import { User } from '../../common/types'
import { useQuery, gql } from '@apollo/client'
import Loader from '../../components/Loader/Loader'
import RespondentAssignmentList from './RespondentListItems'
import { language } from '../../common/i18n'
import Alert, { AlertVariant, AlertSize } from '../../components/Alert'
import { PageWrapper } from '../../modules/PageWrapper'

interface WelcomeContainerProps extends RouteComponentProps {
  user?: User
}

export const RespondentDashboard: React.FC<WelcomeContainerProps> = ({ user }) => {
  const navigate = useNavigate()

  const { loading, data } = useQuery(GET_ALL_USERS_ASSIGNMENTS, {
    fetchPolicy: 'no-cache',
    onCompleted: (res) => {
      if (res?.userAssignments.length === 1) {
        const respondent = res?.userAssignments[0]
        navigate(`/survey/${respondent.id}/${respondent.assignment.id}`)
      }
    }
  })

  if (loading) return <Loader />

  const { userAssignments } = data

  return (
    <PageWrapper
      metaTitle={language.en.respondent.dashboard.title}
      metaDesc={language.en.respondent.dashboard.description}
      headline={`${user?.organization.name} - ${language.en.respondent.dashboard.title}`}
      description={language.en.respondent.dashboard.description}
      sectionHeading={'Assessments'}
    >
      {userAssignments.length ? (
        <RespondentAssignmentList assignments={userAssignments} />
      ) : (
        <div className="w-full">
          <Alert size={AlertSize.LARGE} variant={AlertVariant.WARNING} className="w-full">
            You do not have any assessments assigned. Please contact the organisation representative.
            <div className="mt-5 space-y-1">
              <div>
                <span className="inline text-brand-blue font-bold">Organisation:</span>
                <span className="ml-1 text-brand-blue">{user?.organization.name}</span>
              </div>

              <div className="mb-3">
                <p className="inline text-brand-blue font-bold">Representative:</p>
                <span className="ml-1 text-brand-blue">
                  {user?.organization?.owner?.firstName} {user?.organization?.owner?.lastName}
                </span>
              </div>
              <div className="mb-3">
                <p className="inline text-brand-blue font-bold">Email:</p>
                <span className="ml-1 text-brand-blue">{user?.organization?.owner?.email}</span>
              </div>
            </div>
          </Alert>
        </div>
      )}
    </PageWrapper>
  )
}

const GET_ALL_USERS_ASSIGNMENTS = gql`
  query GetAllUserAssignments {
    userAssignments {
      id
      postSurveyStatus
      surveyStatus
      user {
        email
        firstName
      }
      assignment {
        id
        name
        dueDate
        status
        survey {
          id
        }
      }
    }
  }
`
