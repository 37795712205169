import { RouteComponentProps, navigate, useLocation } from '@reach/router'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { language } from '../common/i18n'
import { RegistrationForm } from '../components/RegistrationForm'
import client from '../contentful'
import { useQuery } from '@apollo/client'
import Loader from '../components/Loader/Loader'
import { LOGIN_CONTENT_QUERY } from '../graphql/contentful-queries'
import { useUserAuthQuery, useUserQuery } from '../hooks/useUserQuery'

const RegisterPage: React.FC<RouteComponentProps> = () => {
  const location = useLocation()
  const { loading: userLoading, data: user } = useUserAuthQuery()
  const searchParams = new URLSearchParams(location.search)
  const token = searchParams.get('token') || ''

  const { data, loading } = useQuery(LOGIN_CONTENT_QUERY, { client })

  const { headline, image } = data?.contentCollection?.items?.[0] ?? {}
  useEffect(() => {
    if (user) {
      navigate('/dashboard')
    }
  }, [user])

  if (loading || userLoading) return <Loader />

  return (
    <main>
      <Helmet>
        <title>{language.en.auth.title.login}</title>
        <meta name="description" content={language.en.auth.description.login} />
        <meta name="robots" content="noindex" data-react-helmet="true" />
      </Helmet>
      <div className="flex items-center flex-col py-16 space-y-10 sm:flex-row px-0 justify-center sm:py-20 sm:px-3 md:px-28">
        <div className="w-full sm:pt-10 max-w-lg sm:w-4/6 justify-center items-center">
          <h3 className="text-brand-blue font-normal leading-none text-3xl md:text-5xl mx-auto">
            Welcome to FiftyFour
          </h3>
          <h3 className="text-brand-blue my-5 font-normal mx-auto">
            The 9D Capacity Index will give your management team a balanced view across 9 important dimensions of
            organisational health and provide insight into your performance.
          </h3>
          <RegistrationForm
            token={token}
            onSubmitSuccess={() => {
              navigate('/dashboard')
            }}
          />
        </div>
        <div className="w-full max-w-xl sm:w-4/6 justify-center items-center">
          <img src={image?.url} alt="" className="object-contain h-96 w-full" />
        </div>
      </div>
    </main>
  )
}

export default RegisterPage
